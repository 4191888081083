import Toggle from 'components/ToggleComponent';
import { ToggleComponentProps } from '../interfaces/toggleComponentInterfaces';

export const ToggleComponent: React.FC<ToggleComponentProps> = ({
  title,
  subtitle,
  locale,
  onChangeHandler,
  checkedState,
  disableMarginBottom = false,
}) => {
  return (
    <div
      className={`desktop:pl-1 w-full flex flex-col desktop:flex-row justify-between max-w-[690px] mx-auto ${
        disableMarginBottom ? 'my-[30px] mb-0' : 'my-[30px]'
      }`}
    >
      <div className="mb-[15px] desktop:mb-0">
        <p className="text-base font-semibold text-charcoal-gray">{title}</p>
        <p className="text-sm font-semibold text-med-gray">{subtitle}</p>
      </div>
      <div className="flex flex-row justify-center desktop:justify-end items-center cursor-pointer">
        <Toggle
          labelLeft={locale.noOption}
          labelRight={locale.yesOption}
          onChange={onChangeHandler}
          checked={checkedState}
        />
      </div>
    </div>
  );
};
