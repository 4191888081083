import TabsComponent from 'components/tabs/tabsComponent';
import Loader from 'components/loaderComponent';
import { Tab } from 'components/tabs/tabs.interface';
import { useGetPage } from 'hooks/useGetPage';
import {
  LIST_BATCH_SIZE,
  PARAM_MODALS_IDENTIFIERS,
  componentIds,
  pageIds,
} from 'utilities/constants';
import {
  MY_ACCOUNT_PROFILE,
  MY_ACCOUNT_HEALTH_QUESTIONNAIRE,
  MY_ACCOUNT_MY_MEDIA_LIBRARY,
} from 'utilities/routes';
import { useMemo, useState } from 'react';
import RecentSharedMedias from 'app/my-patients/components/RecentSharedMedias';
import { useGetFhirPatientMediaQuery } from 'graphql/generated/remote-schema-hasura';

import { MediaType, SharedMedia } from 'app/my-patients/interfaces/interfaces';
import MediaLibraryTable from './components/MediaLibraryTable';
import { useModalParams } from 'components/modal/useModalManager';
import { SharedMediaModal } from 'app/my-patients/components/SharedMediaModal';
import { DermScoreResults } from 'app/my-skin/components/dermScoreResults';
import { useGetComponent } from 'hooks/useGetComponent';
import {
  capitalizeFirstLetter,
  parseMediaToSharedMedia,
} from 'utilities/functions';
import { SIGNAL_CHANNELS, useChannelSignal } from 'hooks/useChannelSignal';
import { useAuth } from 'auth/context/AuthContext';

const RECENT_MEDIA_LIMIT = 3;
const RECENT_MEDIA_SORT = 0;

const MyMediaLibrary: React.FC = () => {
  const { data: tabsLocale, loading: tabsLocaleLoading } = useGetPage({
    locale: 'en',
    pageId: pageIds.MY_PROFILE,
  });
  const { data: locale } = useGetComponent({
    locale: 'en',
    componentId: componentIds.SHARED_MEDIA_LIBRARY,
  });

  const { isOpen: isMediaModalOpen } = useModalParams(
    PARAM_MODALS_IDENTIFIERS.SHARED_MEDIA_MODAL_ID,
  );
  const { isOpen: isDermSCOREModalOpen } = useModalParams(
    PARAM_MODALS_IDENTIFIERS.MY_PATIENTS_DERMSCORE_SCANS_MODAL_ID,
  );

  const tabs: Tab[] = [
    {
      name: tabsLocale?.title,
      url: MY_ACCOUNT_PROFILE,
    },
    {
      name: tabsLocale?.healthQuestionnaire,
      url: MY_ACCOUNT_HEALTH_QUESTIONNAIRE,
    },
    {
      name: tabsLocale?.myMediaLibrary,
      url: MY_ACCOUNT_MY_MEDIA_LIBRARY,
    },
  ];
  const [fromDate, setFromDate] = useState<string | undefined>();
  const [toDate, setToDate] = useState<string | undefined>();
  const [type, setType] = useState<MediaType | null>(null);
  const [orderBy, setOrderBy] = useState<string>();
  const [sharedMedia, setSharedMedia] = useState<SharedMedia[]>([]);
  const [recentMedia, setRecentMedia] = useState<SharedMedia[]>([]);
  const [limit, setLimit] = useState<number>(LIST_BATCH_SIZE);
  const [total, setTotal] = useState<number>(0);
  const orderByNumber: number | undefined =
    orderBy === locale?.mostRecentSortFiler
      ? 0
      : orderBy === locale?.oldestSortFilter
      ? 1
      : undefined;
  const [filterFromDate, filterToDate] = useMemo<
    [Date | undefined, Date | undefined]
  >(() => {
    const beginningOfTheDay = (!!fromDate && new Date(fromDate)) || undefined;
    beginningOfTheDay?.setHours(0, 0, 0, 0);

    const endOfTheDay = (!!toDate && new Date(toDate)) || undefined;
    endOfTheDay?.setHours(23, 59, 59, 999);
    return [beginningOfTheDay, endOfTheDay];
  }, [fromDate, toDate]);

  const { loading: sharedMediaLoading, refetch: refetchShared } =
    useGetFhirPatientMediaQuery({
      variables: {
        limit,
        from: filterFromDate?.toISOString(),
        to: filterToDate?.toISOString(),
        type,
        sort: orderByNumber,
      },
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        setTotal(data.getFHIRMediaByRequestPatientCodexID.total);
        setSharedMedia(
          parseMediaToSharedMedia(
            data.getFHIRMediaByRequestPatientCodexID.media,
          ),
        );
      },
    });

  const { loading: recentMediaLoading, refetch: refetchRecent } =
    useGetFhirPatientMediaQuery({
      variables: {
        limit: RECENT_MEDIA_LIMIT,
        sort: RECENT_MEDIA_SORT,
      },
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        setRecentMedia(
          parseMediaToSharedMedia(
            data.getFHIRMediaByRequestPatientCodexID.media,
          ),
        );
      },
    });

  const { user } = useAuth();

  useChannelSignal(
    (first) => {
      if (!first) {
        refetchRecent();
        refetchShared();
      }
    },
    SIGNAL_CHANNELS.SHARED_MEDIA,
    user?.uuid,
  );

  const handleLoadMore = () => {
    setLimit((prev) => prev + LIST_BATCH_SIZE);
  };

  const handleOnFromDateChange = (date: string | undefined) => {
    if (toDate && date && new Date(date).getTime() > new Date(toDate).getTime())
      setToDate(undefined);
    setFromDate(date);
  };

  const handleOnToDateChange = (date: string | undefined) => {
    if (
      fromDate &&
      date &&
      new Date(date).getTime() < new Date(fromDate).getTime()
    )
      setFromDate(undefined);

    setToDate(date);
  };

  if (!locale || !tabsLocale || tabsLocaleLoading) return <Loader />;

  return (
    <>
      {isMediaModalOpen && <SharedMediaModal />}
      {isDermSCOREModalOpen && <DermScoreResults />}
      <div>
        <TabsComponent tabs={tabs} />
        <div className="px-7 pt-[30px] desktop:pt-0">
          {recentMedia.length === 0 && !recentMediaLoading ? (
            <div className="flex justify-center desktop:justify-start mt-[30px]">
              <p className="text-base text-dark-gray font-medium">
                {locale?.noResults}
              </p>
            </div>
          ) : (
            <>
              <h1 className="text-h2 desktop:text-h1 text-dark-gray font-medium">
                {tabsLocale?.mediaLibrarySectionTitle}
              </h1>
              <div className="flex flex-col px-5 pt-5 pb-10 my-[30px] desktop:p-[30px] desktop:mb-[30px] gap-5 bg-white rounded-10">
                <h3 className="text-h3 text-dark-gray font-semibold">
                  {capitalizeFirstLetter(locale?.mostRecent)}
                </h3>
                <hr />
                {recentMediaLoading ? (
                  <Loader />
                ) : (
                  <RecentSharedMedias
                    sharedMedia={recentMedia}
                    locale={locale}
                  />
                )}
              </div>

              <MediaLibraryTable
                onLoadMore={handleLoadMore}
                onFromDateChange={(date) =>
                  handleOnFromDateChange(date || undefined)
                }
                onToDateChange={(date) =>
                  handleOnToDateChange(date || undefined)
                }
                loading={sharedMediaLoading}
                total={total}
                fromDate={fromDate || ''}
                toDate={toDate || ''}
                localeByProp={locale}
                sharedMedia={sharedMedia}
                onTypeChange={setType}
                type={type}
                orderBy={orderBy}
                setOrderBy={setOrderBy}
              />
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default MyMediaLibrary;
