import { TabContent } from 'app/my-patients/interfaces/tab.interface';
import { TabsBasedNavigator } from 'app/my-patients/components/TabsBasedNavigator';
import {
  MY_DERMSCORE,
  MY_MEDICATION_HISTORY,
  MY_VISIT_SUMMARIES,
} from 'utilities/routes';
import { useGetPage } from 'hooks/useGetPage';
import { pageIds } from 'utilities/constants';
import Loader from 'components/loaderComponent';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { CodexFeatureFlags } from 'utilities/interfaces';
import { MyDermscorePage } from 'app/my-skin/pages/MyDermscore';
import MyVisitSummaries from 'app/my-skin/pages/MyVisitSummaries';
import MyMedicationHistory from 'app/my-skin/pages/MyMedicationHistory';

const tabs: TabContent[] = [
  {
    id: MY_DERMSCORE.replaceAll('/', ''),
    content: MyDermscorePage,
  },
  {
    id: MY_VISIT_SUMMARIES.replaceAll('/', ''),
    content: MyVisitSummaries,
  },
  {
    id: MY_MEDICATION_HISTORY.replaceAll('/', ''),
    content: MyMedicationHistory,
  },
];

const MySkinPage: React.FC = () => {
  const { enableDermscoreTabs } = useFlags<CodexFeatureFlags>();
  const { data: locale, loading } = useGetPage({
    locale: 'en',
    pageId: pageIds.MY_DERMSCORE,
  });

  if (!enableDermscoreTabs) {
    return <MyDermscorePage />;
  }
  if (loading || !locale) return <Loader />;
  return (
    <TabsBasedNavigator
      tabsContent={tabs}
      locale={locale.tabs}
      customContainerProps="desktop:justify-start border-b border-graph-border-color"
      inactiveColor="text-black text-bold"
      customTabsProps="desktop:mx-8 desktop:min-w-[100px]"
    />
  );
};

export default MySkinPage;
