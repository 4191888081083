import InputComponent from 'components/inputComponent';

export interface AppointmentPreworkRadioListProps {
  selected: string | undefined;
  setSelected: (selected: string) => void;
  items: string[];
}

const AppointmentPreworkRadioList: React.FC<
  AppointmentPreworkRadioListProps
> = ({ items, selected, setSelected }) => {
  const onRadioClick = (selected: string) => () => {
    setSelected(selected);
  };
  return (
    <div className="flex flex-col">
      {items.map((item) => (
        <InputComponent
          type="radio"
          radioInputProps={{
            radioLabelTextWeight: 'font-semibold text-med-gray',
            radioError: null,
            radioInputValue: item,
            radioInputLabel: item,
            radioInputCheckedValue: selected,
            onRadioClick: onRadioClick(item),
          }}
        />
      ))}
    </div>
  );
};

export default AppointmentPreworkRadioList;
