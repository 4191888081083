import ButtonComponent from 'components/button/buttonComponent';
import Loader from 'components/loaderComponent';
import { useGetGenericActions } from 'hooks/useGetGenericActions';
import {
  PARAM_MODALS_IDENTIFIERS,
  genericActionsIds,
  pageIds,
} from 'utilities/constants';
import { ReactComponent as ChevronLeftIcon } from 'assets/icons/chevron-left.svg';
import { Tabs } from 'app/my-patients/components/Tabs';
import { useCallback, useMemo, useRef, useState } from 'react';
import { Tab } from 'app/my-patients/interfaces/tab.interface';
import MyProviders from 'app/my-appointments/components/select-a-provider/MyProviders';
import PastProviders from 'app/my-appointments/components/select-a-provider/PastProviders';
import FindAProvider from 'app/my-appointments/components/select-a-provider/FindAProvider';
import { useGetPage } from 'hooks/useGetPage';
import { useModalParams } from 'components/modal/useModalManager';
import { AcuityModal } from 'app/my-appointments/components/AcuityModal';
import { MyProviderTabIds } from 'app/my-appointments/pages/MyProvider.interface';
import { SelectAProviderTabsProps } from './SelectAProviderTabsInterfaces';

const SelectAProviderTabs: React.FC<SelectAProviderTabsProps> = ({
  onProviderSelect,
}) => {
  const { data: genericAction, loading: genericActionsLocaleLoading } =
    useGetGenericActions({
      locale: 'en',
      genericActionId: [genericActionsIds.BACK],
    });
  const { loading: localeLoading, data: locale } = useGetPage({
    locale: 'en',
    pageId: pageIds.SELECT_A_PROVIDER,
  });
  const [activeTab, setActiveTab] = useState<string>(
    MyProviderTabIds.myProviders,
  );
  const { isOpen: isAcuityModal } = useModalParams(
    PARAM_MODALS_IDENTIFIERS.ACUITY_CALENDAR_MODAL,
  );
  const alreadyRedirected = useRef(false);
  const handleOnNoProviderFound = useCallback(() => {
    if (
      activeTab !== MyProviderTabIds.findAProvider &&
      !alreadyRedirected.current
    ) {
      setActiveTab(MyProviderTabIds.findAProvider);
      alreadyRedirected.current = true;
    }
  }, [activeTab]);

  const tabs = useMemo<Tab[]>(
    () =>
      Object.values(MyProviderTabIds).map((id) => ({
        id,
        display: locale?.[id],
      })),
    [locale],
  );
  if (genericActionsLocaleLoading || localeLoading) return <Loader />;
  let CurrentTab: React.FC<SelectAProviderTabsProps> | null;
  switch (activeTab) {
    case MyProviderTabIds.myProviders:
      CurrentTab = MyProviders;
      break;
    case MyProviderTabIds.pastProviders:
      CurrentTab = PastProviders;
      break;
    case MyProviderTabIds.findAProvider:
      CurrentTab = FindAProvider;
      break;
    default:
      CurrentTab = null;
  }
  if (!locale) return null;
  return (
    <>
      {isAcuityModal && <AcuityModal />}
      <div className="flex flex-col gap-5 grow mx-3 desktop:mx-0">
        <div className="flex flex-row">
          <ButtonComponent
            type="underline"
            Icon={ChevronLeftIcon}
            iconPosition="left"
            iconWidth="w-2.5"
            iconHeight="h-[18px]"
          >
            <p className="uppercase">
              {genericAction?.[genericActionsIds.BACK].back}
            </p>
          </ButtonComponent>
        </div>
        <h1 className="font-exo font-medium text-h1">
          {locale.selectAProvider}
        </h1>
        <h5 className="font-semibold text-lg">{locale.scheduleWith}</h5>
        <Tabs
          customContainerProps="desktop:justify-start"
          customTabsProps="desktop:min-w-[240px]"
          tabs={tabs}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
        {CurrentTab && (
          <CurrentTab
            onNoProviderFound={handleOnNoProviderFound}
            onProviderSelect={onProviderSelect}
          />
        )}
      </div>
    </>
  );
};

export default SelectAProviderTabs;
