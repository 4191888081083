import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { Tab, TabsBasedNavigatorProps } from '../interfaces/tab.interface';
import { Tabs } from './Tabs';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { capitalizeFirstLetter } from 'utilities/functions';
import { useNavigateRef } from 'hooks/useNavigateRef';

export const TabsBasedNavigator: React.FC<TabsBasedNavigatorProps> = ({
  tabsContent,
  locale,
  activeColor,
  customContainerProps,
  customTabsProps,
  inactiveColor,
}) => {
  const getActiveTabByLocation = useCallback(() => {
    const pathnames = window.location.pathname.split('/');
    for (const path of pathnames) {
      const tab = tabsContent.find((tab) => tab.id === path);
      if (tab) {
        return tab.id;
      }
    }
    return tabsContent[0].id;
  }, [tabsContent]);
  const [activeTab, setActiveTab] = useState<string>(getActiveTabByLocation);
  const navigate = useNavigateRef();
  const location = useLocation();

  useEffect(() => {
    const currentActiveTab = getActiveTabByLocation();
    if (currentActiveTab !== activeTab) {
      navigate.current(activeTab);
    }
  }, [activeTab, getActiveTabByLocation, navigate]);

  useEffect(() => {
    const currentActiveTab = getActiveTabByLocation();
    if (currentActiveTab !== activeTab) {
      setActiveTab(currentActiveTab);
    }
  }, [location, getActiveTabByLocation, activeTab]);

  const tabs = useMemo<Tab[]>(
    () =>
      tabsContent.map<Tab>((tab) => ({
        id: tab.id,
        display:
          (locale && locale[tab.id]) ||
          capitalizeFirstLetter(tab.id.replaceAll(/-/g, ' ')),
      })),
    [tabsContent, locale],
  );

  return (
    <div className="flex flex-col grow gap-3">
      <Tabs
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        tabs={tabs}
        activeColor={activeColor}
        customContainerProps={customContainerProps}
        customTabsProps={customTabsProps}
        inactiveColor={inactiveColor}
      />
      <div className="flex flex-col pt-8 grow">
        <Routes>
          {tabsContent.map((tab) => {
            const TabContent = tab.content;
            const Content: JSX.Element =
              typeof TabContent === 'function' ? <TabContent /> : TabContent;
            return <Route key={tab.id} path={tab.id} element={Content} />;
          })}
          <Route path="/*" element={<Navigate to={tabsContent[0].id} />} />
        </Routes>
      </div>
    </div>
  );
};
