import { Scan } from 'app/dashboard/interfaces/scan.interfaces';
import React from 'react';
import ScanActions from 'app/dashboard/components/ScanActions';
import ScanIndicators from 'app/dashboard/components/ScanIndicators';
import ScanCarousel from 'app/dashboard/components/ScanCarousel';
import ScanSource from 'app/dashboard/components/ScanSource';
import ScanImage from 'components/ScanImage';

interface ScansRecentResultsProps {
  scans: Scan[];
  useCurrentPathNavigate?: boolean;
  patientId?: string | null;
}

const ScansRecentResults: React.FC<ScansRecentResultsProps> = ({
  scans,
  useCurrentPathNavigate = false,
  patientId = null,
}) => {
  return (
    <>
      <div className="flex flex-col w-full items-center gap-5 desktop:hidden">
        <ScanCarousel scans={scans} showHr={false} />
      </div>
      <div
        className={`hidden desktop:flex desktop:${
          scans.length < 3 ? 'justify-start' : 'justify-between'
        } desktop:space-x-8 desktop:flex-row desktop:align-middle`}
      >
        {scans.map((scan, index) => (
          <div
            key={scan.id}
            className={`flex flex-col ${
              index === 2 ? 'max-[1360px]:pr-5' : ''
            }`}
          >
            <div className="flex flex-row">
              <div className="mr-10">
                <ScanImage
                  className="overflow-hidden rounded-10 w-[160px] h-[200px] bg-cover bg-center"
                  imageId={scan.imageId}
                  patientId={patientId}
                />

                <div className="mt-4">
                  <ScanActions
                    useCurrentPathNavigate={useCurrentPathNavigate}
                    scan={scan}
                  />
                </div>
              </div>
              <div className="flex flex-col">
                <div className="mb-4">
                  <ScanIndicators
                    inflammations={scan.inflammations}
                    comedones={scan.comedones}
                    pih={scan.pih}
                    total={scan.total}
                    date={scan.date}
                    orientation="col"
                  />
                </div>
                <ScanSource source={scan.source} />
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default ScansRecentResults;
