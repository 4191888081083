import { useEffect } from 'react';
import { useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  useFirebaseSignOut,
  useVerifyActionCode,
} from '../../../firebase/hooks';
import {
  AUTH_LOGIN,
  AUTH_RESET_PASSWORD,
  AUTH_SEND_VERIFICATION_EMAIL,
} from 'utilities/routes';

export const Action = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const mode = queryParams.get('mode');
  const oobCode = queryParams.get('oobCode') || '';
  const actionChecked = useRef<boolean>(false);
  const [, handleVerifyActionCode] = useVerifyActionCode();
  const signOutUser = useFirebaseSignOut();

  useEffect(() => {
    const checkActions = async () => {
      if (mode === 'resetPassword') {
        navigate(`${AUTH_RESET_PASSWORD}?oobCode=${oobCode}`, {
          replace: true,
        });
      } else if (mode === 'verifyEmail') {
        const result = await handleVerifyActionCode(oobCode);
        if (result) {
          navigate(`${AUTH_LOGIN}?verifiedEmail=true`, { replace: true });
        } else {
          navigate(`${AUTH_SEND_VERIFICATION_EMAIL}?linkExpired=true`, {
            replace: true,
          });
        }
      } else if (mode === 'revertSecondFactorAddition') {
        const result = await handleVerifyActionCode(oobCode);
        await signOutUser();
        navigate(
          result
            ? `${AUTH_LOGIN}?mfaRemoved=true`
            : `${AUTH_LOGIN}?mfaLinkExpired=true`,
          { replace: true },
        );
      } else {
        navigate(AUTH_LOGIN, { replace: true });
      }
    };

    if (!actionChecked.current) {
      checkActions();
      actionChecked.current = true;
    }
  }, [mode, oobCode, navigate, handleVerifyActionCode, signOutUser]);

  return null;
};
