import { useCallback, useState } from 'react';
import { useModalParams } from 'components/modal/useModalManager';
import { PARAM_MODALS_IDENTIFIERS, pageIds } from 'utilities/constants';
import {
  formatMMDDYYYYtoYYYYMMDD,
  getOrderMethodForScanResults,
  mapFhirSkintelligentTest,
  populateScanResult,
} from 'utilities/functions';
import { useGetPage } from 'hooks/useGetPage';
import { Scan } from 'app/dashboard/interfaces/scan.interfaces';
import {
  Order_By,
  Image_Source_Types_Enum,
  GetSkintellingentTestByUserIdQuery,
  Skintelligent_Tests,
} from 'graphql/generated/hasura';
import { Record_Order_By } from 'components/codexTable/interfaces/record.interfaces';
import Loader from 'components/loaderComponent';
import ScansRecentResults from 'app/my-skin/components/ScanRecentResults';
import DermScoreHistory from 'app/my-skin/components/DermScoreHistory';
import { DermScoreResults } from 'app/my-skin/components/dermScoreResults';
import {
  GetFhirSkintellingentTestsByUserIdQuery,
  useGetFhirSkintellingentTestsByUserIdQuery,
} from 'graphql/generated/remote-schema-hasura';

import { useParams } from 'react-router-dom';
import { useAuth } from 'auth/context/AuthContext';
import { useChannelSignal, SIGNAL_CHANNELS } from 'hooks/useChannelSignal';

const NUMBER_OF_ROWS = 10;

export const DermScoreHistoryPage: React.FC = () => {
  const { patientId } = useParams();

  const { data: locale, loading } = useGetPage({
    locale: 'en',
    pageId: pageIds.DERMSCORE_SCANS_PROVIDER_TAB,
  });
  const { isOpen: isDermSCOREModalOpen } = useModalParams(
    PARAM_MODALS_IDENTIFIERS.MY_PATIENTS_DERMSCORE_SCANS_MODAL_ID,
  );

  const [scans, setScans] = useState<Scan[]>([]);
  const [scansTotal, setScansTotal] = useState<number>(0);
  const [recentScans, setRecentScans] = useState<Scan[]>([]);
  const [fromDate, setFromDate] = useState<string | undefined>();
  const [toDate, setToDate] = useState<string | undefined>();
  const [orderBy, setOrderBy] = useState<Record_Order_By>({
    skintelligent_tests_test: {
      created_at: Order_By.Desc,
    },
  });
  const [source, setSource] = useState<Image_Source_Types_Enum | null>(null);

  const mapFhirSkintelligentTestsByUserId = (
    data: GetFhirSkintellingentTestsByUserIdQuery,
  ): GetSkintellingentTestByUserIdQuery => {
    const mappedFhirData =
      data.getFHIRSkintelligentTestsByUserId.skintelligent_tests.map((test) => {
        return mapFhirSkintelligentTest(test);
      });

    return {
      skintelligent_tests: mappedFhirData,
      skintelligent_tests_aggregate: {
        aggregate: {
          count: data.getFHIRSkintelligentTestsByUserId.total,
        },
      },
    } as unknown as GetSkintellingentTestByUserIdQuery;
  };

  const populateScanData = async (
    scans: GetSkintellingentTestByUserIdQuery,
    isLoadMore = false,
  ) => {
    if (scans.skintelligent_tests.length) {
      const populatedScans: Scan[] = [];
      for (const scan of scans.skintelligent_tests) {
        const populatedScan = populateScanResult(scan as Skintelligent_Tests);
        populatedScans.push(populatedScan);
      }
      if (isLoadMore) {
        setScans((scans) => [...scans, ...populatedScans]);
      } else {
        setScans(populatedScans);
      }
      setScansTotal(scans?.skintelligent_tests_aggregate.aggregate?.count || 0);
    } else {
      setScans([]);
      setScansTotal(0);
    }
  };

  const {
    fetchMore,
    loading: loadingFhirSkintelligentTestsByUser,
    data: dataFhirSkintelligentTestsByUser,
    refetch,
  } = useGetFhirSkintellingentTestsByUserIdQuery({
    variables: {
      from: fromDate ? `${formatMMDDYYYYtoYYYYMMDD(fromDate)}T00:00:00Z` : null,
      to: toDate ? `${formatMMDDYYYYtoYYYYMMDD(toDate)}T23:59:59Z` : null,
      codexPatientId: patientId || '',
      limit: NUMBER_OF_ROWS,
      orderBy: getOrderMethodForScanResults(JSON.stringify(orderBy)),
    },

    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      populateScanData(mapFhirSkintelligentTestsByUserId(data));
    },
  });

  const handleLoadMore = useCallback(() => {
    if (scans) {
      fetchMore({
        variables: {
          offset: scans.length,
          codexPatientId: patientId || '',
          limit: scans.length + NUMBER_OF_ROWS,
          from: fromDate
            ? `${formatMMDDYYYYtoYYYYMMDD(fromDate)}T00:00:00Z`
            : null,
          to: toDate ? `${formatMMDDYYYYtoYYYYMMDD(toDate)}T23:59:59Z` : null,
          orderBy: getOrderMethodForScanResults(JSON.stringify(orderBy)),
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          return !fetchMoreResult
            ? prev
            : {
                ...prev,
                getFHIRSkintelligentTestsByUserId: {
                  total:
                    fetchMoreResult.getFHIRSkintelligentTestsByUserId.total,
                  skintelligent_tests: [
                    ...prev.getFHIRSkintelligentTestsByUserId
                      .skintelligent_tests,
                    ...fetchMoreResult.getFHIRSkintelligentTestsByUserId
                      .skintelligent_tests,
                  ],
                },
              };
        },
      });
    }
  }, [patientId, fetchMore, fromDate, orderBy, scans, toDate]);

  const handleOnFromDateChange = (date: string | undefined) => {
    if (toDate && date && new Date(date).getTime() > new Date(toDate).getTime())
      setToDate(undefined);
    setFromDate(date);
  };

  const handleOnToDateChange = (date: string | undefined) => {
    if (
      fromDate &&
      date &&
      new Date(date).getTime() < new Date(fromDate).getTime()
    )
      setFromDate(undefined);
    setToDate(date);
  };

  if (!recentScans.length && scans.length) {
    setRecentScans(scans.slice(0, 3));
  }

  const { user } = useAuth();

  useChannelSignal(
    (first) => {
      if (!first) refetch();
    },
    SIGNAL_CHANNELS.TESTKIT,
    user?.uuid,
  );

  if (loading || !locale || !dataFhirSkintelligentTestsByUser)
    return <Loader />;

  return (
    <>
      {isDermSCOREModalOpen && <DermScoreResults />}
      <div className="px-7 pt-[30px] desktop:pt-0">
        {recentScans.length === 0 ? (
          <div className="flex justify-center desktop:justify-start mt-[30px] rounded-10 px-5 bg-white">
            <p className="text-xl text-med-gray font-semibold">
              {locale.noResults}
            </p>
          </div>
        ) : (
          <>
            <div className="flex flex-col px-5 pt-5 pb-10 my-[30px] desktop:p-[30px] desktop:mb-[30px] gap-5 bg-white rounded-10">
              <h3 className="text-h3 text-dark-gray font-semibold">
                {locale?.recentResults}
              </h3>
              <hr />
              <ScansRecentResults
                useCurrentPathNavigate
                scans={recentScans}
                patientId={patientId}
              />
            </div>

            <DermScoreHistory
              onLoadMore={handleLoadMore}
              onFromDateChange={(date) =>
                handleOnFromDateChange(date || undefined)
              }
              onToDateChange={(date) => handleOnToDateChange(date || undefined)}
              onMobileSortChange={(nextSort) => setOrderBy(nextSort)}
              onColumnSortChange={(nextSort) => setOrderBy(nextSort)}
              loading={loadingFhirSkintelligentTestsByUser}
              scans={scans}
              total={scansTotal}
              fromDate={fromDate || ''}
              toDate={toDate || ''}
              orderBy={orderBy}
              useCurrentPathNavigate
              showCreateNewScanButton={false}
              showSourceFilter
              onSourceChange={setSource}
              source={source}
            />
          </>
        )}
      </div>
    </>
  );
};
