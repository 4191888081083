import { useEffect } from 'react';
import { useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  AUTH_LOGIN,
  TEST_KITS_MANAGEMENT_BARCODE_CAMERA,
  MY_SKIN_FACE_CAMERA,
} from 'utilities/routes';
import { LOCAL_STORAGE_USER_FROM_QR } from 'utilities/constants';
import { useValidateCustomToken } from '../../firebase/hooks';

export const QrAction = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { handleValidateCustomToken } = useValidateCustomToken();
  const queryParams = new URLSearchParams(location.search);
  const action = queryParams.get('action');
  const token = queryParams.get('token');
  const actionChecked = useRef<boolean>(false);

  useEffect(() => {
    const checkActions = async () => {
      try {
        if (token && action) {
          await handleValidateCustomToken(token);
          localStorage.setItem(LOCAL_STORAGE_USER_FROM_QR, 'true');

          if (action === 'scanFace') {
            navigate(MY_SKIN_FACE_CAMERA, {
              state: { token },
              replace: true,
            });
          } else if (action === 'scanBarcode') {
            navigate(TEST_KITS_MANAGEMENT_BARCODE_CAMERA, {
              state: { token },
              replace: true,
            });
          } else {
            navigate(AUTH_LOGIN, { replace: true });
          }
        }
      } catch (error) {
        console.error('There was an error when try to validate custom token.');
        localStorage.setItem(LOCAL_STORAGE_USER_FROM_QR, 'false');
        navigate(AUTH_LOGIN, { replace: true });
      }
    };

    if (!actionChecked.current) {
      checkActions();
      actionChecked.current = true;
    }
  }, [token, action, handleValidateCustomToken, navigate]);

  return null;
};
