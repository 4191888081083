import { ReactComponent as CameraIcon } from 'assets/icons/camera.svg';
import { ReactComponent as ChatIcon } from 'assets/icons/chat.svg';
import { ReactComponent as MessageIcon } from 'assets/icons/message.svg';
import { ReactComponent as EyeIcon } from 'assets/icons/pwEyeShow.svg';
import { ReactComponent as CalendarCancelIcon } from 'assets/icons/calendarCancel.svg';
import { ReactComponent as ResendIcon } from 'assets/icons/resend.svg';

export enum AppointmentType {
  video = 0,
  chat,
  message,
}

export enum UpcomingAppointmentAction {
  View,
  Cancel,
  Resend,
}

export interface ActionRenderInfo {
  icon: React.FC<React.SVGProps<SVGSVGElement>>;
}

export const appointmentActionRenderInfo: Record<
  UpcomingAppointmentAction,
  ActionRenderInfo
> = {
  [UpcomingAppointmentAction.View]: {
    icon: EyeIcon,
  },
  [UpcomingAppointmentAction.Cancel]: {
    icon: CalendarCancelIcon,
  },
  [UpcomingAppointmentAction.Resend]: {
    icon: ResendIcon,
  },
} as const;

export const defaultUpcomingAppointmentsActions: UpcomingAppointmentAction[] = [
  UpcomingAppointmentAction.View,
  UpcomingAppointmentAction.Cancel,
];

export interface UpcomingAnointmentType {
  date: string;
  provider: string;
  type: AppointmentType;
  actions?: UpcomingAppointmentAction[];
  id: string;
}

export const appointmentRenderInfo: Record<
  AppointmentType,
  {
    icon: React.FC<React.SVGProps<SVGSVGElement>>;
    text: string;
  }
> = {
  [AppointmentType.video]: {
    icon: CameraIcon,
    text: 'video',
  },
  [AppointmentType.chat]: {
    icon: ChatIcon,
    text: 'chat',
  },
  [AppointmentType.message]: {
    icon: MessageIcon,
    text: 'message',
  },
} as const;

export interface UpcomingAppointmentsLocale extends Record<string, string> {
  viewAppointmentsHistory: string;
  upcomingAppointmentsTitle: string;
  upcomingAppointmentsSubtitle: string;
}

export interface UpcomingAppointmentsProps {
  locale: UpcomingAppointmentsLocale;
  upcomingAppointments: UpcomingAnointmentType[];
  count?: Record<string, number>;
}

export interface UpcomingAppointmentsRowProps {
  locale: UpcomingAppointmentsLocale;
  appointment: UpcomingAnointmentType;
  onActionClicked: (action: UpcomingAppointmentAction) => void;
}

export interface UpcomingAppointmentsRowActionProps {
  action: UpcomingAppointmentAction;
  appointmentId?: string;
}
