import { ReactComponent as ChevronDownIcon } from 'assets/icons/chevron-down.svg';
import Loader from 'components/loaderComponent';
import {
  MediaType,
  SharedMedia,
  SharedMediaModalLocationState,
} from 'app/my-patients/interfaces/interfaces';
import MediaLibraryTableFilter from './MediaLibraryTableFilter';
import { useNavigate } from 'react-router-dom';
import { useGetFhirSkintelligentTestByTestIdAndUserIdLazyQuery } from 'graphql/generated/remote-schema-hasura';

import {
  mapFhirSkintelligentTest,
  populateScanResult,
} from 'utilities/functions';
import { Scan } from 'app/dashboard/interfaces/scan.interfaces';
import { PARAM_MODALS_IDENTIFIERS } from 'utilities/constants';

interface MediaLibraryTableProps {
  sharedMedia: SharedMedia[];
  onLoadMore: () => void;
  onFromDateChange: (date: string) => void;
  onToDateChange: (date: string) => void;
  fromDate: string;
  toDate: string;
  total: number;
  loading: boolean;
  localeByProp?: { [key: string]: string };
  onTypeChange?: (type: MediaType | null) => void;
  type?: MediaType | null;
  orderBy?: string;
  setOrderBy: (orderBy: string) => void;
}

const MediaLibraryTable: React.FC<MediaLibraryTableProps> = ({
  sharedMedia,
  onLoadMore,
  onFromDateChange,
  onToDateChange,
  fromDate,
  toDate,
  total,
  localeByProp = undefined,
  onTypeChange = () => {
    return;
  },
  type = undefined,
  setOrderBy,
  orderBy,
  loading: dataLoading,
}) => {
  const locale = localeByProp;
  const navigate = useNavigate();

  const [getFhirSkintelligentTestByTestIdAndUserId, { loading: scanLoading }] =
    useGetFhirSkintelligentTestByTestIdAndUserIdLazyQuery({});
  const loading = dataLoading || scanLoading;

  const getScanData = async (scanId: string): Promise<Scan | undefined> => {
    const { data } = await getFhirSkintelligentTestByTestIdAndUserId({
      variables: { testId: scanId },
    });
    if (!data?.getFHIRSkintelligentTestByTestIdAndUserId.skintelligent_test)
      return;
    const test = mapFhirSkintelligentTest(
      data.getFHIRSkintelligentTestByTestIdAndUserId.skintelligent_test,
    );
    return populateScanResult(test);
  };

  const onMediaClick = async (media: SharedMedia) => {
    if (media.dermscoreId) {
      const scan = await getScanData(media.dermscoreId);
      if (!scan) return;
      navigate(
        `.?${PARAM_MODALS_IDENTIFIERS.MY_PATIENTS_DERMSCORE_SCANS_MODAL_ID}=true`,
        {
          state: {
            scan: {
              ...scan,
              imageUrl: media.img,
            },
            fileUrl: media.img,
            onlyView: true,
          },
        },
      );
      return;
    }
    const state: SharedMediaModalLocationState = {
      mediaDate: media.date,
      mediaSrc: media.img,
      appointmentCodexId: media.appointmentId,
      bodySite: media.bodySite,
      description: media.description,
    };
    navigate(`.?${PARAM_MODALS_IDENTIFIERS.SHARED_MEDIA_MODAL_ID}=true`, {
      state,
    });
  };

  const canLoadMore = total > sharedMedia.length;

  return (
    <div className="p-4 bg-white rounded-10 w-full">
      <div className="flex flex-col mb-4 items-start desktop:flex-row desktop:justify-between desktop:items-center">
        <div className="flex flex-col desktop:flex-row desktop:items-center">
          <h3 className="text-h3 text-dark-gray font-semibold">
            {locale?.yourPhotosTableTitle}
          </h3>
          <p className="text-base text-med-gray font-semibold mt-2.5 desktop:mt-0 desktop:ml-4 ">
            {locale?.showing} {sharedMedia.length} {locale?.of} {total}
          </p>
        </div>
        <MediaLibraryTableFilter
          fromDate={fromDate}
          toDate={toDate}
          orderBy={orderBy || null}
          handleFromDateChange={(e) => onFromDateChange(e.target.value)}
          handleToDateChange={(e) => onToDateChange(e.target.value)}
          handleSortChange={(e) => setOrderBy(e.target.value)}
          localeByProp={localeByProp}
          showSourceFilter
          type={type}
          onTypeChange={onTypeChange}
        />
      </div>
      {loading ? (
        <Loader />
      ) : (
        <>
          {sharedMedia.length === 0 ? (
            <div className="py-10 flex justify-center">
              {locale?.noMatchingResults}
            </div>
          ) : (
            <>
              <div className="grid grid-rows-[96px] auto-rows-[96px] gap-5 justify-items-center items-center grid-cols-auto-fill-24 py-5">
                {sharedMedia.map((media) => (
                  <div
                    key={media.id}
                    className="overflow-hidden rounded-10 w-24 h-24 bg-cover bg-center cursor-pointer"
                    onClick={() => onMediaClick(media)}
                  >
                    <img
                      src={media.img}
                      className="object-cover w-full h-full"
                      alt={locale?.sharedMediaAltText}
                    />
                  </div>
                ))}
              </div>
              {canLoadMore && (
                <>
                  <hr />

                  <div className="flex flex-col desktop:flex-row w-full justify-between items-center p-0 gap-[30px] mt-2.5">
                    <div className="flex flex-row justify-center items-center p-0 gap-[7px] cursor-pointer">
                      <div
                        className="flex flex-row justify-center items-center p-0 gap-[7px] cursor-pointer"
                        onClick={onLoadMore}
                      >
                        <div className="text-base text-clc-blue font-bold underline">
                          {locale?.loadMore}
                        </div>
                        <ChevronDownIcon className="w-[18px] h-2.5 fill-clc-blue" />
                      </div>
                    </div>
                  </div>
                </>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default MediaLibraryTable;
