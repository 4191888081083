import React, { useEffect, useState } from 'react';

type ToggleTypes = 'default' | 'large';

interface ToggleProps {
  checked?: boolean;
  type?: ToggleTypes;
  labelLeft: string;
  labelRight: string;
  onChange: (checked: boolean) => void;
}

const Toggle: React.FC<ToggleProps> = ({
  checked = false,
  type = 'default',
  labelLeft,
  labelRight,
  onChange,
}) => {
  const [isChecked, setIsChecked] = useState(checked);
  let containerClass;
  let ballClass;
  let translateClass;

  switch (type) {
    case 'large':
      containerClass = 'w-[100px] w-9';
      ballClass = 'w-[26px] h-[26px]';
      translateClass = 'translate-x-16';
      break;

    default:
      containerClass = 'w-16 h-7';
      ballClass = 'w-[18px] h-[18px]';
      translateClass = 'translate-x-9';
      break;
  }

  const handleToggle = () => {
    const newChecked = !isChecked;
    setIsChecked(newChecked);
    onChange(newChecked);
  };

  // Update internal state if checked prop changes
  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  return (
    <>
      <p
        className={`text-sm font-medium mr-[5px] ${
          !isChecked ? 'text-clc-blue' : 'text-neutral-gray'
        }`}
      >
        {labelLeft}
      </p>
      <div
        className={`${containerClass} p-0.5 bg-white border border-gray-blur border-solid rounded-full cursor-pointer transition-colors`}
      >
        <div
          className={`w-full p-0.5 rounded-full transition-colors ${
            isChecked ? 'bg-clc-blue' : 'bg-light-blue'
          }`}
          onClick={handleToggle}
        >
          <div
            className={`${ballClass} border border-gray-blur border-solid transform transition-transform ${
              isChecked ? translateClass : 'translate-x-0'
            } rounded-full bg-white shadow`}
          />
        </div>
      </div>
      <p
        className={`text-sm font-medium ml-[5px] ${
          isChecked ? 'text-clc-blue' : 'text-neutral-gray'
        }`}
      >
        {labelRight}
      </p>
    </>
  );
};

export default Toggle;
