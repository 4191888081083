import {
  UpcomingAppointmentsRowProps,
  appointmentRenderInfo,
  defaultUpcomingAppointmentsActions,
} from '../interfaces/upcomingAppointments.interface';

import { formatDateToCustomFormat } from 'utilities/functions';
import { UpcomingAppointmentsRowAction } from './UpcomingAppointmentsRowAction';
import { useMemo } from 'react';

export const UpcomingAppointmentsRow: React.FC<
  UpcomingAppointmentsRowProps
> = ({ locale, appointment }) => {
  const typeRenderInfo = appointmentRenderInfo[appointment.type];
  if (!typeRenderInfo) {
    console.error(`No render info for appointment type ${appointment.type}`);
  }
  const TypeIcon = typeRenderInfo?.icon;
  const typeText = locale[typeRenderInfo?.text] || '';

  const actions = useMemo(() => {
    const customAppointmentActions =
      appointment.actions?.filter(
        (action) => !defaultUpcomingAppointmentsActions.includes(action),
      ) || [];
    return [...defaultUpcomingAppointmentsActions, ...customAppointmentActions];
  }, [appointment.actions]);

  return (
    <div className="flex flex-col gap-2.5">
      <div className="p-2.5 bg-neutral-100 rounded-10 flex-col justify-start items-stretch gap-2.5 flex">
        <div className="justify-start items-start gap-2.5 flex">
          <div className="w-6 h-6 justify-center items-center flex">
            {/* TODO COD-1757: Set a fallback icon */}
            {TypeIcon && <TypeIcon />}
          </div>
          <div className="flex-1 justify-start items-start gap-2.5 flex">
            <span className="text-neutral-500 text-base font-normal leading-snug">
              {typeText} - {formatDateToCustomFormat(appointment.date)}
            </span>
          </div>
        </div>
        <div className="flex items-stretch justify-between desktop:self-start desktop:px-6 desktop:gap-[5px]">
          {actions.map((action) => (
            <UpcomingAppointmentsRowAction
              action={action}
              key={action}
              appointmentId={appointment.id}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
