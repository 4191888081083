import { useGetUserEmailPreferencesQuery } from 'graphql/generated/hasura';
import {
  EmailPreferencesState,
  EmailPreferencesProps,
  EmailPreferencesKeys,
} from '../interfaces/emailPreferencesInterfaces';
import { ToggleComponent } from './ToggleComponent';
import { useContext, useEffect, useState } from 'react';
import { AuthContext } from 'auth/context/AuthContext';

import InputComponent from 'components/inputComponent';
import { AccountSettingsState } from '../interfaces/providerModuleInterfaces';

export const EmailPreferences: React.FC<EmailPreferencesProps> = ({
  locale,
  setAccountSettings,
  setIsAllSectionsLoaded,
}) => {
  const [emailPreferencesStatus, setEmailPreferencesStatus] =
    useState<EmailPreferencesState | null>(null);

  const { user: loggedUser } = useContext(AuthContext);

  useGetUserEmailPreferencesQuery({
    variables: {
      id: loggedUser?.uuid,
    },
    onCompleted: (data) => {
      setEmailPreferencesStatus({
        EMAIL_MONTHLY_NEWSLETTER: data.users[0].EMAIL_MONTHLY_NEWSLETTER,
        EMAIL_NEW_FEATURES_SERVICES_SETTINGS:
          data.users[0].EMAIL_NEW_FEATURES_SERVICES_SETTINGS,
        EMAIL_SPECIAL_OFFER_FRIENDS_SETTING:
          data.users[0].EMAIL_SPECIAL_OFFER_FRIENDS_SETTING,
        EMAIL_SPECIAL_OFFERS_SETTING:
          data.users[0].EMAIL_SPECIAL_OFFERS_SETTING,
        EMAIL_UNSUBSCRIBED: data.users[0].EMAIL_UNSUBSCRIBED,
      });
      setIsAllSectionsLoaded((prevState) => ({
        ...prevState,
        emailPreferences: true,
      }));
    },
  });

  const toggleHandler = (key: EmailPreferencesKeys, state: boolean) => {
    setEmailPreferencesStatus({
      ...emailPreferencesStatus,
      [EmailPreferencesKeys[key]]: state,
    } as EmailPreferencesState);
    setHasCheckboxEffectRun(false);
  };

  const handleToggleCheckbox = () => {
    setEmailPreferencesStatus({
      ...emailPreferencesStatus,
      EMAIL_UNSUBSCRIBED:
        emailPreferencesStatus && !emailPreferencesStatus.EMAIL_UNSUBSCRIBED,
    } as EmailPreferencesState);
  };

  useEffect(() => {
    if (emailPreferencesStatus?.EMAIL_UNSUBSCRIBED === true) {
      setEmailPreferencesStatus((prevState) => {
        return {
          EMAIL_MONTHLY_NEWSLETTER: false,
          EMAIL_NEW_FEATURES_SERVICES_SETTINGS: false,
          EMAIL_SPECIAL_OFFER_FRIENDS_SETTING: false,
          EMAIL_SPECIAL_OFFERS_SETTING: false,
          EMAIL_UNSUBSCRIBED:
            (prevState && prevState.EMAIL_UNSUBSCRIBED) ?? false,
        };
      });
    }
  }, [emailPreferencesStatus?.EMAIL_UNSUBSCRIBED]);

  const [hasCheckboxEffectRun, setHasCheckboxEffectRun] = useState(false);

  useEffect(() => {
    if (!hasCheckboxEffectRun) {
      setEmailPreferencesStatus((prevState) => {
        return {
          ...prevState,
          EMAIL_UNSUBSCRIBED: false,
        } as EmailPreferencesState;
      });
      setHasCheckboxEffectRun(true);
    }
  }, [emailPreferencesStatus, hasCheckboxEffectRun]);

  useEffect(() => {
    setAccountSettings((prevState) => {
      return {
        ...prevState,
        ...emailPreferencesStatus,
      } as AccountSettingsState;
    });
  }, [emailPreferencesStatus, setAccountSettings]);

  return (
    <div>
      <div className="mb-[30px]">
        <p className="text-h7 font-bold uppercase">{locale.sectionTitle}</p>
        <p className="text-sm font-semibold text-med-gray">
          {locale.sectionSubtitle}
        </p>
        {emailPreferencesStatus !== null && (
          <>
            <ToggleComponent
              title={locale.toggleTitle1}
              subtitle={locale.toggleSubtitle1}
              onChangeHandler={(state) =>
                toggleHandler(
                  EmailPreferencesKeys.EMAIL_SPECIAL_OFFERS_SETTING,
                  state,
                )
              }
              checkedState={emailPreferencesStatus.EMAIL_SPECIAL_OFFERS_SETTING}
              locale={locale}
            />
            <ToggleComponent
              title={locale.toggleTitle2}
              subtitle={locale.toggleSubtitle2}
              onChangeHandler={(state) =>
                toggleHandler(
                  EmailPreferencesKeys.EMAIL_SPECIAL_OFFER_FRIENDS_SETTING,
                  state,
                )
              }
              checkedState={
                emailPreferencesStatus.EMAIL_SPECIAL_OFFER_FRIENDS_SETTING
              }
              locale={locale}
            />
            <ToggleComponent
              title={locale.toggleTitle3}
              subtitle={locale.toggleSubtitle3}
              onChangeHandler={(state) =>
                toggleHandler(
                  EmailPreferencesKeys.EMAIL_NEW_FEATURES_SERVICES_SETTINGS,
                  state,
                )
              }
              checkedState={
                emailPreferencesStatus.EMAIL_NEW_FEATURES_SERVICES_SETTINGS
              }
              locale={locale}
            />
            <ToggleComponent
              title={locale.toggleTitle4}
              subtitle={locale.toggleSubtitle4}
              onChangeHandler={(state) =>
                toggleHandler(
                  EmailPreferencesKeys.EMAIL_MONTHLY_NEWSLETTER,
                  state,
                )
              }
              checkedState={emailPreferencesStatus.EMAIL_MONTHLY_NEWSLETTER}
              locale={locale}
            />
            <div className="w-full flex flex-col justify-between max-w-[690px] mx-auto my-[30px]">
              <p className="text-base font-semibold text-charcoal-gray mb-[5px]">
                {locale.checkboxTitle}
              </p>
              <InputComponent
                type="checkbox"
                checkboxProps={{
                  checkboxLabel: locale.checkboxLabel,
                  checkboxLabelSize: 'text-sm',
                  checked: emailPreferencesStatus.EMAIL_UNSUBSCRIBED,
                  onCheckboxChange: handleToggleCheckbox,
                }}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};
