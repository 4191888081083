import { ReactComponent as BodyBackSvg } from 'assets/images/human-body/body-back.svg';
import { ReactComponent as BodyFrontSvg } from 'assets/images/human-body/body-front.svg';
import { ReactComponent as HeadFrontSvg } from 'assets/images/human-body/head-front.svg';
import { ReactComponent as HeadBackSvg } from 'assets/images/human-body/head-back.svg';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Dot } from './interfaces';

interface BodyDotPlacerProps {
  type: 'HeadFront' | 'HeadBack' | 'BodyFront' | 'BodyBack';
  dots: Dot[];
  hideNotSelectedDots?: boolean;
  onDotSelected: (id: number) => void;
}

const ORIGINAL_HEAD_IMAGE_SVG_WIDTH = 120;
const ORIGINAL_BODY_IMAGE_SVG_WIDTH = 230;
const RADIX = 10;

export const DotPlacer: React.FC<BodyDotPlacerProps> = ({
  type,
  dots,
  hideNotSelectedDots = false,
  onDotSelected,
}) => {
  const [scalingFactor, setScalingFactor] = useState(1);
  const svgContainerRef = useRef<HTMLDivElement>(null);

  const getOriginalSvgWidth = useCallback(() => {
    switch (type) {
      case 'HeadFront':
      case 'HeadBack':
        return ORIGINAL_HEAD_IMAGE_SVG_WIDTH;

      case 'BodyFront':
      case 'BodyBack':
        return ORIGINAL_BODY_IMAGE_SVG_WIDTH;

      default:
        return ORIGINAL_BODY_IMAGE_SVG_WIDTH;
    }
  }, [type]);

  const renderSvgBasedOnType = () => {
    switch (type) {
      case 'HeadFront':
        return <HeadFrontSvg />;
      case 'HeadBack':
        return <HeadBackSvg />;
      case 'BodyFront':
        return <BodyFrontSvg />;
      case 'BodyBack':
        return <BodyBackSvg />;
      default:
        return <BodyFrontSvg />;
    }
  };

  const getDotXPosition = (dot: Dot) => {
    if (type === 'HeadFront' || type === 'HeadBack') {
      return dot.headPoints?.x ?? '0';
    } else return dot.bodyPoints?.x ?? '0';
  };

  const getDotYPosition = (dot: Dot) => {
    if (type === 'HeadFront' || type === 'HeadBack') {
      return dot.headPoints?.y ?? '0';
    } else return dot.bodyPoints?.y ?? '0';
  };

  useEffect(() => {
    const svgElement = svgContainerRef.current?.querySelector('svg');

    const updateScalingFactor = () => {
      if (svgElement) {
        const svgRect = svgElement.getBoundingClientRect();
        setScalingFactor(svgRect.width / getOriginalSvgWidth());
      }
    };

    const resizeObserver = new ResizeObserver(updateScalingFactor);
    if (svgElement) {
      resizeObserver.observe(svgElement);
    }

    return () => {
      if (svgElement) {
        resizeObserver.unobserve(svgElement);
      }
    };
  }, [getOriginalSvgWidth]);

  const calculatePosition = (value: string) =>
    `${parseInt(value, RADIX) * scalingFactor}px`;

  const toggleDot = (id: number): void => {
    onDotSelected(id);
  };

  return (
    <div ref={svgContainerRef} className="relative h-auto mt-3 self-center">
      {renderSvgBasedOnType()}
      {dots.map((dot) => {
        if (hideNotSelectedDots && !dot.selected) return null;
        return (
          <div
            key={`${dot.id}-${type}`}
            className={`absolute w-2.5 h-2.5 rounded-full cursor-pointer ${
              dot.selected ? 'bg-orange-body-dot' : 'bg-blue-body-dot'
            }`}
            style={{
              left: calculatePosition(getDotXPosition(dot)),
              top: calculatePosition(getDotYPosition(dot)),
            }}
            onClick={() => toggleDot(dot.id)}
          />
        );
      })}
    </div>
  );
};

export default DotPlacer;
