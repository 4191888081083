import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { TabsProps, TextCapitalization } from './tabs.interface';
import { MY_ACCOUNT_HEALTH_QUESTIONNAIRE } from 'utilities/routes';
import MobileTabs from './MobileTabs';

const TabsComponent: React.FC<TabsProps> = ({
  tabs,
  isNotification = false,
  textCapitalization = TextCapitalization.NORMALCASE,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const isDnaTestKitsFlow = location.state?.isDnaTestKitsFlow || false;
  const [selectedTab, setSelectedTab] = useState(tabs[0].name);
  useEffect(() => {
    const tab = tabs.find((tab) => tab.url === location.pathname);
    if (tab) {
      setSelectedTab(tab.name);
    }
  }, [location, tabs]);

  const desktopClass = isNotification
    ? 'w-full justify-center flex border-b mb-3 desktop:justify-start desktop:w-fit desktop:mb-[30px] desktop:before:absolute desktop:before:w-full desktop:before:border-b desktop:before:border-b-1 desktop:before:bottom-[30px]'
    : 'hidden desktop:flex border-b mb-[30px]';
  const mobileClass = isNotification
    ? 'hidden'
    : 'space-y-2.5 mb-[30px] desktop:hidden ';

  return (
    <>
      <div className={desktopClass}>
        {tabs.map((tab) => {
          const isActive = location.pathname === tab.url;
          return (
            <button
              key={tab.url}
              className={`py-4 px-[53px] block text-sm border-b-1 font-bold ${textCapitalization} ${
                isActive
                  ? 'text-clc-blue border-b-[6px] border-clc-blue'
                  : 'text-med-gray border-b-[6px] border-transparent'
              }`}
              onClick={
                tab.onClick ??
                (() => {
                  if (
                    isDnaTestKitsFlow &&
                    tab.url === MY_ACCOUNT_HEALTH_QUESTIONNAIRE
                  ) {
                    return tab.referringPage
                      ? navigate(tab.url, {
                          state: {
                            isDnaTestKitsFlow: true,
                            referringPage: tab.referringPage,
                            ...(tab.customState ?? {}),
                          },
                        })
                      : navigate(tab.url, {
                          state: {
                            isDnaTestKitsFlow: true,
                            ...(tab.customState ?? {}),
                          },
                        });
                  }
                  tab.referringPage
                    ? navigate(tab.url, {
                        state: {
                          referringPage: tab.referringPage,
                          ...(tab.customState ?? {}),
                        },
                      })
                    : navigate(tab.url, {
                        state: {
                          ...(tab.customState ?? {}),
                        },
                      });
                })
              }
            >
              {tab.name}
            </button>
          );
        })}
      </div>
      <div className={mobileClass}>
        <MobileTabs
          tabs={tabs}
          selectedTab={selectedTab}
          textCapitalization={textCapitalization}
        />
      </div>
    </>
  );
};

export default TabsComponent;
