import { useNavigate } from 'react-router-dom';
import { MobileTabsProps } from './tabs.interface';
import { useEffect, useState } from 'react';
import { ReactComponent as LeftArrowIcon } from '../../assets/icons/blue-arrow-left.svg';
import { ReactComponent as RightArrowIcon } from '../../assets/icons/blue-arrow-right.svg';
import { twMerge } from 'tailwind-merge';

const focusedTabClass = 'font-bold text-clc-blue';

const MobileTabs: React.FC<MobileTabsProps> = ({
  tabs,
  selectedTab,
  textCapitalization,
}) => {
  const navigate = useNavigate();
  const selectedItem = tabs.findIndex((tab) => tab.name === selectedTab);
  const [itemToView, setItemToview] = useState(0);
  const visibleItems =
    itemToView === 0
      ? tabs.slice(itemToView, itemToView + 2)
      : tabs.slice(itemToView - 1, itemToView + 2);
  const getTabIndex = (value: string) =>
    tabs.findIndex((tab) => tab.name === value);
  const selectedTabIdx = getTabIndex(tabs[selectedItem].name);
  const selectedViewIdx = getTabIndex(tabs[itemToView].name);

  const handleOnTabSelected = (value: string) => {
    const indexFound = tabs.findIndex((currTab) => value === currTab.name);
    if (indexFound < 0) {
      return setItemToview(0);
    }
    setItemToview(indexFound);
    tabs[indexFound].referringPage
      ? navigate(tabs[indexFound].url, {
          state: tabs[indexFound].referringPage,
        })
      : navigate(tabs[indexFound].url);
  };

  const handleOnLeftClicked = () => setItemToview((prevState) => prevState - 1);

  const handleOnRightClicked = () =>
    setItemToview((prevState) => prevState + 1);

  const getItemClassBasedOnSelectedIdx = (value: string) => {
    let baseClass = `text-center font-semibold text-h6 text-med-gray relative flex justify-center flex-col ${textCapitalization}`;
    const indexFound = tabs.findIndex((currTab) => value === currTab.name);
    if (itemToView === 0 && indexFound === 0 && tabs.length > 2)
      baseClass += ' col-start-2';
    if (itemToView === tabs.length - 1 && indexFound === tabs.length - 1)
      baseClass += '';
    else baseClass += ' text-clip overflow-hidden break-all';
    if (indexFound === selectedItem)
      baseClass += ` ${selectedItem === indexFound ? focusedTabClass : ''}`;
    return baseClass;
  };

  const getTabDirection = (currIdx: number) => {
    if (currIdx + 1 === selectedTabIdx || currIdx + 1 === selectedViewIdx)
      return 'rtl';
    if (currIdx - 1 === selectedTabIdx || currIdx - 1 === selectedViewIdx)
      return 'ltr';
    return 'ltr';
  };

  const applyGradient = (currIdx: number) => {
    if (visibleItems.length === 2) return '';
    if (currIdx === selectedTabIdx) {
      if (currIdx === selectedViewIdx) return 'text-clc-blue';
      if (currIdx - 1 === selectedViewIdx)
        return 'text-transparent bg-clip-text bg-gradient-to-r from-clc-blue';
      if (currIdx + 1 === selectedViewIdx)
        return 'text-transparent bg-clip-text bg-gradient-to-l from-clc-blue';
    }
    if (currIdx === selectedViewIdx) {
      return '';
    }
    if (currIdx + 1 === selectedTabIdx || currIdx + 1 === selectedViewIdx)
      return 'text-transparent bg-clip-text bg-gradient-to-l from-med-gray';
    if (currIdx - 1 === selectedTabIdx || currIdx - 1 === selectedViewIdx)
      return 'text-transparent bg-clip-text bg-gradient-to-r from-med-gray';
    return '';
  };

  const getActiveClass = (value: string) => {
    const foundIndex = getTabIndex(value);
    if (foundIndex === selectedItem) return 'border-b-[6px] border-clc-blue';
    return 'border-b-[6px] border-transparent';
  };

  const getListClass = () => {
    if (tabs.length === 1) return 'grid-cols-1';
    if (tabs.length === 2) return 'grid-cols-2';
    if (tabs.length > 2 && visibleItems.length === 2)
      return 'grid-cols-[20px_1fr_1fr_20px]';
    return 'grid-cols-[20px_1fr_200px_1fr_20px]';
  };

  useEffect(() => {
    const selectedTabIdx = tabs.findIndex((tab) => tab.name === selectedTab);
    setItemToview(selectedTabIdx || 0);
  }, [selectedTab, tabs]);

  return (
    <div className="flex justify-between items-center truncate">
      <ul
        className={twMerge(
          `grid w-full gap-2.5 h-[60px] border-b-[1px] border-b-gray-opacity-15 ${getListClass()}`,
        )}
      >
        {itemToView > 0 && tabs.length > 2 && (
          <LeftArrowIcon
            className="w-5 h-5 self-center"
            onClick={handleOnLeftClicked}
          />
        )}
        {visibleItems.map((tab) => {
          const currentTabIdx = getTabIndex(tab.name);

          if (
            visibleItems.length === 2 &&
            (selectedTabIdx === tabs.length - 1 ||
              selectedViewIdx === tabs.length - 1) &&
            currentTabIdx === tabs.length - 1
          ) {
            return (
              <li
                key={tab.name}
                className={twMerge(
                  `${getItemClassBasedOnSelectedIdx(tab.name)}
                 ${applyGradient(currentTabIdx)} ${getActiveClass(tab.name)}`,
                )}
                onClick={() => handleOnTabSelected(tab.name)}
              >
                {tab.name}
              </li>
            );
          }
          if (
            visibleItems.length === 2 &&
            selectedViewIdx === 0 &&
            currentTabIdx === 0
          ) {
            return (
              <li
                key={tab.name}
                className={twMerge(
                  `${getItemClassBasedOnSelectedIdx(tab.name)} ${applyGradient(
                    currentTabIdx,
                  )} ${getActiveClass(tab.name)}`,
                )}
                onClick={() => handleOnTabSelected(tab.name)}
              >
                {tab.name}
              </li>
            );
          }
          return (
            <li
              dir={getTabDirection(currentTabIdx)}
              key={tab.name}
              className={twMerge(
                `${getItemClassBasedOnSelectedIdx(tab.name)} ${applyGradient(
                  currentTabIdx,
                )} ${getActiveClass(tab.name)}`,
              )}
              onClick={() => handleOnTabSelected(tab.name)}
            >
              {tab.name}
            </li>
          );
        })}
        {itemToView < tabs.length - 1 && tabs.length > 2 && (
          <RightArrowIcon
            className="w-5 h-5 self-center"
            onClick={handleOnRightClicked}
          />
        )}
      </ul>
    </div>
  );
};

export default MobileTabs;
