import { messageTemplatesIds, pageIds } from 'utilities/constants';
import { useGetPage } from 'hooks/useGetPage';
import { Notification } from '../interfaces/notifications.interfaces';
import { NotificationItem } from 'app/notifications/components/NotificationItem';
import { useNavigate } from 'react-router-dom';
import { useGetMessageTemplates } from 'hooks/useGetMessageTemplates';
import {
  User_Notification_Status_Enum,
  useUpdateInAppNotificationMutation,
} from 'graphql/generated/hasura';
import { useState } from 'react';
import { NOTIFICATIONS } from 'utilities/routes';
import { ProfileSectionTypes } from 'app/my-account/pages/my-profile/enums';

const TIME_TO_CHANGE_NOTIFICATION_COLOR = 300;

interface NotificationListProps {
  notifications: Notification[];
}

const NotificationsList: React.FC<NotificationListProps> = ({
  notifications,
}) => {
  const navigate = useNavigate();
  const [updateInAppNotificationMutation] = useUpdateInAppNotificationMutation(
    {},
  );

  const { data: locale, loading } = useGetPage({
    locale: 'en',
    pageId: pageIds.NOTIFICATIONS,
  });

  const { data: messageLocale, loading: messageLoading } =
    useGetMessageTemplates({
      locale: 'en',
      templateId: [
        messageTemplatesIds.MY_PROFILE,
        messageTemplatesIds.MY_PERSONAL_INFO,
        messageTemplatesIds.MY_CONTACT_INFO,
        messageTemplatesIds.HEALTH_QUESTIONNAIRE,
      ],
    });
  const [selectedItemIndex, setSelectedItemIndex] = useState<number | null>(
    null,
  );
  const [textColor, setTextColor] = useState<string>(
    'text-dark-teal font-medium',
  );

  const validateMyProfileNotification = (notification: string) => {
    return messageLocale?.includes(notification);
  };

  const handleOnClick = async (
    notificationId: number,
    route: string,
    message = '',
    isRead = false,
    index: number,
  ) => {
    setSelectedItemIndex(index);
    setTextColor('text-dark-teal font-medium');
    setTimeout(() => {
      setTextColor('text-med-gray');
    }, TIME_TO_CHANGE_NOTIFICATION_COLOR);
    try {
      const response = await updateInAppNotificationMutation({
        variables: {
          id: String(notificationId),
          status:
            validateMyProfileNotification(message) && !isRead
              ? User_Notification_Status_Enum.Seen
              : User_Notification_Status_Enum.Read,
        },
      });
      if (!response.data) {
        console.log(notificationId, response.errors);
        throw new Error('Failed to update notification');
      }

      if (
        message
          .toLocaleUpperCase()
          .includes(ProfileSectionTypes.PROFILE.toLocaleUpperCase())
      ) {
        return navigate(route, {
          state: { section: ProfileSectionTypes.PROFILE },
        });
      }
      if (
        message
          .toLocaleUpperCase()
          .includes(ProfileSectionTypes.CONTACT.toLocaleUpperCase())
      ) {
        return navigate(route, {
          state: { section: ProfileSectionTypes.CONTACT },
        });
      }
      return navigate(route);
    } catch (error: unknown) {
      console.log(error);
      throw new Error('Failed to update notification');
    }
  };

  if ((loading && !locale) || (messageLoading && messageLocale)) return null;

  return (
    <>
      <div className="flex flex-col items-start gap-[10px] text-base text-dark-gray font-medium">
        {notifications.map((item, index) => (
          <NotificationItem
            key={item.id}
            body={item.body}
            date={item.date}
            isRead={item.isRead}
            isSelect={index === selectedItemIndex}
            textColor={textColor}
            onClick={() =>
              handleOnClick(
                item.id,
                item?.action?.route ?? NOTIFICATIONS,
                item.body,
                item.isRead,
                index,
              )
            }
            isWidget
          />
        ))}
      </div>
      <hr className="flex flex-row w-full items-center h-px bg-black-blur" />
    </>
  );
};

export default NotificationsList;
