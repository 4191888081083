import DefaultPatient from 'assets/images/defaultPatient.svg';
import { twMerge } from 'tailwind-merge';
interface AvatarProps {
  imageUrl?: string | null;
  size: 'xs' | 'sm' | 's' | 'md' | 'lg';
  customClasses?: string;
  children?: React.ReactNode | null;
}
const Avatar: React.FC<AvatarProps> = ({ imageUrl, size, customClasses }) => {
  const baseClasses = 'rounded-[200px]';
  let sizeClasses;
  switch (size) {
    case 'xs':
      sizeClasses = 'h-6 w-6';
      break;
    case 'sm':
      sizeClasses = 'h-9 w-9';
      break;
    case 's':
      sizeClasses = 'h-20 w-20';
      break;
    case 'md':
      sizeClasses = 'h-[106px] w-[106px]';
      break;
    case 'lg':
      sizeClasses = 'h-40 w-40';
      break;
    default:
      sizeClasses = 'h-9 w-9';
  }
  return (
    <img
      className={twMerge(
        `${baseClasses} ${sizeClasses} object-cover`,
        customClasses,
      )}
      src={imageUrl ? imageUrl : DefaultPatient}
      alt="Avatar"
    />
  );
};

export default Avatar;
