import { useState } from 'react';
import { ReactComponent as ChevronLeftIcon } from 'assets/icons/chevron-left.svg';
import { ReactComponent as ChevronRightIcon } from 'assets/icons/chevron-right.svg';
import CarouselDots from 'components/carouselDotsComponent';
import CarouselArrow from 'components/carouselArrow';
import { SharedMedia } from '../interfaces/interfaces';
import { SharedMediaRecentResult } from './SharedMediaRecentResult';

interface SharedMediaCarouselProps {
  medias: SharedMedia[];
  locale: Record<string, string>;
  showHr?: boolean;
}

const SharedMediaCarousel: React.FC<SharedMediaCarouselProps> = ({
  medias,
  locale,
  showHr = true,
}) => {
  const [selectedScanIndex, setSelectedScanIndex] = useState(0);

  const incrementScanIndex = () => {
    setSelectedScanIndex((index) => (index + 1) % medias.length);
  };

  const decrementScanIndex = () => {
    setSelectedScanIndex(
      (index) => (index - 1 + medias.length) % medias.length,
    );
  };

  return (
    <>
      <div className="flex flex-row w-full items-center justify-between p-0 gap-[15px]">
        {medias.length > 1 && (
          <CarouselArrow onClick={decrementScanIndex} icon={ChevronLeftIcon} />
        )}
        <div className="flex flex-row w-full items-start justify-center">
          <SharedMediaRecentResult
            locale={locale}
            media={medias[selectedScanIndex]}
          />
        </div>
        {medias.length > 1 && (
          <CarouselArrow onClick={incrementScanIndex} icon={ChevronRightIcon} />
        )}
      </div>
      {medias.length > 1 && (
        <div className="flex flex-row items-center justify-center w-full">
          <CarouselDots
            carouselLength={medias.length}
            selectedDotIndex={selectedScanIndex}
          />
        </div>
      )}
      {showHr && (
        <hr className="flex flex-row w-full items-center h-px bg-black-blur" />
      )}
    </>
  );
};

export default SharedMediaCarousel;
