import ButtonComponent from 'components/button/buttonComponent';
import { ReactComponent as ChevronDownIcon } from 'assets/icons/chevron-down.svg';
import { ReactComponent as ChevronUpIcon } from 'assets/icons/chevron-up-fix.svg';
import { useContext, useMemo, useState } from 'react';
import Input from 'components/inputComponent';
import { ReactComponent as MagnifierIcon } from 'assets/icons/magnifier.svg';
import DatePicker from 'components/datePicker/DatePicker';
import { useMyAppointmentsLocale } from 'app/my-appointments/pages/MyAppointments';
import {
  AppointmentsHistoryFilterProps,
  HandleAppointmentFilterChangeEvent,
  appointmentRenderInfo,
} from 'app/my-appointments/interfaces';
import { AuthContext } from 'auth/context/AuthContext';
import { Roles } from '../../../../firebase/interfaces';
import { AppointmentTypeEnum } from 'graphql/generated/hasura';

export const AppointmentsHistoryFilter: React.FC<
  AppointmentsHistoryFilterProps
> = ({
  filter,
  onFilter,
  setFilter,
  patientsList,
  providersList,
  locale: localeProp,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const ArrowIcon = isExpanded ? ChevronUpIcon : ChevronDownIcon;
  const handleExpandIconClick = () => {
    setIsExpanded((isExpanded) => !isExpanded);
  };
  const { user } = useContext(AuthContext);
  const { locale: localeHook } = useMyAppointmentsLocale();
  const locale = useMemo(
    () => ({ ...localeProp, ...localeHook }),
    [localeProp, localeHook],
  );

  const [byTypeEntries, localeToValueMapByType] = useMemo(() => {
    const localeToValueMap: Record<string, AppointmentTypeEnum> = {};
    const noDnaVisitAppointmentType = {
      [AppointmentTypeEnum.Chat]: appointmentRenderInfo.CHAT,
      [AppointmentTypeEnum.Video]: appointmentRenderInfo.VIDEO,
      [AppointmentTypeEnum.Email]: appointmentRenderInfo.EMAIL,
    };
    const byTypeEntries = Object.entries(noDnaVisitAppointmentType).map(
      ([key, value]) => {
        const text = locale[value.text];
        localeToValueMap[text] = key as AppointmentTypeEnum;
        return text;
      },
    );
    byTypeEntries.unshift(locale.all);
    return [byTypeEntries, localeToValueMap];
  }, [locale]);

  const [patientsEntries, patientsNameIdMap] = useMemo(() => {
    const patients = patientsList ? patientsList : [];
    const patientsNameIdMap = patients.reduce<Record<string, string>>(
      (acc, patient) => {
        acc[patient.name] = patient.id;
        return acc;
      },
      {},
    );
    const patientIdNameMap = patients.reduce<Record<string, string>>(
      (acc, patient) => {
        acc[patient.id] = patient.name;
        return acc;
      },
      {},
    );
    const patientsEntries = patients.map((patient) => patient.name);
    patientsEntries.unshift(locale.all);
    return [patientsEntries, patientsNameIdMap, patientIdNameMap];
  }, [patientsList, locale.all]);

  const [providersEntries] = useMemo(() => {
    const providers = providersList ? providersList : [];
    const providersEntries = providers.map((provider) => provider.name);
    providersEntries.unshift(locale.all);
    return [providersEntries];
  }, [providersList, locale.all]);

  const filterTypeText =
    filter !== undefined && filter.type !== undefined
      ? appointmentRenderInfo[filter.type].text
      : undefined;

  const getTypeFilterName = (userRole: string | undefined) => {
    if (userRole) {
      switch (userRole) {
        case Roles.PROVIDER:
          return locale.byType;
        case Roles.PATIENT:
          return locale.type;
        default:
          return locale.type;
      }
    }
  };

  const getValueFromLocale = (value: string, name: string): string | number => {
    if (name === 'provider' || name === 'patient') {
      if (value === locale?.all) return '';
      return value;
    }
    const localeToValueMap: Record<string, Record<string, string | number>> = {
      type: localeToValueMapByType,
      patient: patientsNameIdMap,
    };
    return localeToValueMap[name] ? localeToValueMap[name][value] : value;
  };

  const handleFilterChange = (
    e: HandleAppointmentFilterChangeEvent,
    name?: string,
  ) => {
    let currentName: string = name || '';
    if (!currentName) {
      if (!e.target.name) {
        throw new Error('No name provided for the filter');
      }
      currentName = e.target.name;
    }
    const value = getValueFromLocale(e.target.value, currentName);
    if (currentName === 'from') {
      const newFromDate = new Date(value);
      if (filter.to && newFromDate > new Date(filter.to)) {
        setFilter &&
          setFilter((oldFilter) => ({
            ...oldFilter,
            to: undefined,
            from: value as string,
          }));
      }
    } else if (currentName === 'to') {
      const newToDate = new Date(value);
      if (filter.from && newToDate < new Date(filter.from)) {
        setFilter &&
          setFilter((oldFilter) => ({
            ...oldFilter,
            from: undefined,
            to: value as string,
          }));
      }
    }
    setFilter &&
      setFilter((oldFilter) => {
        return {
          ...oldFilter,
          [currentName]: value,
        };
      });
  };

  return (
    <div
      className={
        'hidden desktop:flex flex-col items-center px-3 pt-2 pb-[5px] desktop:p-3 gap-3 bg-base-content rounded-10 w-full'
      }
    >
      <div className="flex flex-col w-full">
        <div className="flex flex-row w-full items-center justify-between p-0 gap-2.5">
          <div className="flex flex-row items-end gap-[7px]">
            <p className="flex text-base text-dark-gray font-semibold">
              {locale.findAndFilter}
            </p>
          </div>
          <div>
            <ButtonComponent
              type="underline"
              iconPosition="right"
              Icon={ArrowIcon}
              fill="black"
              stroke="black"
              onClick={handleExpandIconClick}
            />
          </div>
        </div>
      </div>
      {isExpanded && (
        <>
          <hr className="flex flex-row w-full items-center h-px bg-black-blur" />
          <div className="flex flex-row w-full gap-1 items-stretch justify-between">
            <div className="flex flex-col items-start gap-3 justify-around">
              <p className="text-sm font-medium">{locale.find}</p>
              <p className="text-sm font-medium">
                {getTypeFilterName(user?.role)}
              </p>
            </div>
            <div className="flex flex-col items-start gap-3 flex-1">
              <div className="flex flex-row w-full gap-8">
                <div className="flex-1 h-full">
                  <Input
                    type="text"
                    decorator={<MagnifierIcon />}
                    decoratorLeft
                    noMarginBottom
                    value={filter?.find || ''}
                    name="find"
                    onChange={handleFilterChange}
                    placeholder={locale.findPlaceholder}
                  />
                </div>
                <div className="flex flex-row gap-2 items-center">
                  <p className="text-sm font-medium text-right pl-3 pr-1">
                    {locale.from}
                  </p>
                  <div>
                    <DatePicker
                      name="from"
                      onChange={handleFilterChange}
                      fullWidthDesktop
                      value={filter?.from}
                    />
                  </div>
                  <p className="text-sm font-medium text-right pl-3 pr-1">
                    {locale.to}
                  </p>
                  <div className="flex-1">
                    <DatePicker
                      name="to"
                      onChange={handleFilterChange}
                      fullWidthDesktop
                      value={filter.to}
                    />
                  </div>
                </div>
              </div>
              <div className="flex flex-row w-full items-center justify-between">
                <div className="flex-[2]">
                  <Input
                    type="select"
                    name="type"
                    value={filterTypeText && locale[filterTypeText]}
                    selectInputProps={{
                      selectValues: byTypeEntries,
                      fullWidth: true,
                      onSelectChange: handleFilterChange,
                    }}
                  />
                </div>
                {user?.role === Roles.PROVIDER ? (
                  <>
                    <label
                      className="text-sm font-medium text-right pl-3 pr-1"
                      htmlFor="patient"
                    >
                      {locale.byPatient}
                    </label>
                    <div className="flex-[2]">
                      <Input
                        type="select"
                        value={filter.patient || locale.all}
                        name="patient"
                        selectInputProps={{
                          selectValues: patientsEntries,
                          fullWidth: true,
                          onSelectChange: handleFilterChange,
                        }}
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <label
                      className="text-sm font-medium text-right pl-3 pr-1"
                      htmlFor="provider"
                    >
                      {locale.provider}
                    </label>
                    <div className="flex-[2]">
                      <Input
                        type="select"
                        value={filter.provider || locale.all}
                        name="provider"
                        selectInputProps={{
                          selectValues: providersEntries,
                          fullWidth: true,
                          onSelectChange: handleFilterChange,
                        }}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          <hr className="flex flex-row w-full items-center h-px bg-black-blur" />
          <ButtonComponent
            onClick={() => onFilter && onFilter()}
            className="self-end bg-base-content my-2"
            type="outlined"
          >
            {locale.updateResults}
          </ButtonComponent>
        </>
      )}
    </div>
  );
};
