import { Card } from './Card';
import { TestKitResultRow } from './TestKitResultRow';
import { TestKitsResultsProps } from '../interfaces/testKits.interfaces';
import { capitalizeFirstLetter } from 'utilities/functions';
import { useNavigate } from 'react-router-dom';
import { MY_PATIENTS_BIO_INFO_TEST_KIT_RESULTS } from 'utilities/routes';

export const TestKitsResults: React.FC<TestKitsResultsProps> = ({
  locale,
  total,
  testKitResult,
}) => {
  const title = capitalizeFirstLetter(locale.testKitsResultsTitle);
  const subtitle = capitalizeFirstLetter(
    `${locale.showing} ${testKitResult.length} ${locale.of} ${total}`,
  );

  const navigate = useNavigate();
  const goToTestKitResults = () => {
    navigate(`../${MY_PATIENTS_BIO_INFO_TEST_KIT_RESULTS}`, {
      replace: true,
    });
  };

  return (
    <Card
      title={title}
      subtitle={subtitle}
      buttonText={locale.viewAllButton}
      buttonCTA={goToTestKitResults}
    >
      {testKitResult.map((testResult, index) => (
        <TestKitResultRow {...testResult} key={`${testResult.name}${index}`} />
      ))}
    </Card>
  );
};
