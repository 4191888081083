/* eslint-disable @typescript-eslint/no-unused-vars */
import { AppRouter } from './router/AppRouter';
import './App.css';
import { useInitializeConfig } from 'utilities/initializeConfig';

function App() {
  useInitializeConfig();

  return <AppRouter />;
}

export default App;
