import React, { useEffect, useRef, useState } from 'react';
import {
  BarcodeReader,
  Configuration,
  StrichSDK,
} from '@pixelverse/strichjs-sdk';
import { getStrichConfig } from 'utilities/config';

interface ScannerProps {
  onDetected: (code: string) => void;
}

export const BarScanner: React.FC<ScannerProps> = ({ onDetected }) => {
  const { licenseKey } = getStrichConfig();
  const hostElemRef = useRef<HTMLDivElement | null>(null);
  const barcodeReaderRef = useRef<BarcodeReader | null>(null);
  const [sdkState, setSdkState] = useState(
    StrichSDK.isInitialized() ? 'initialized' : undefined,
  );

  const createBarcodeReaderConfig = (hostElem: HTMLElement): Configuration => {
    return {
      selector: hostElem,
      engine: {
        symbologies: ['code128'],
        duplicateInterval: 2500,
        numScanlines: 20,
        minScanlinesNeeded: 3,
      },
      locator: {
        regionOfInterest: {
          left: 0.05,
          right: 0.05,
          top: 0.4,
          bottom: 0.4,
        },
      },
      overlay: {
        showCameraSelector: false,
        showFlashlight: true,
      },
      frameSource: {
        resolution: 'full-hd',
      },
      feedback: {
        audio: true,
        vibration: true,
      },
    };
  };

  useEffect(() => {
    const initializeSDK = async () => {
      if (StrichSDK.isInitialized()) {
        setSdkState('initialized');
      } else {
        try {
          await StrichSDK.initialize(licenseKey);
          setSdkState('initialized');
        } catch (error) {
          console.error(`'Could not initialize Strich SDK': ${error}`);
          setSdkState('initialization-error');
        }
      }
    };

    if (sdkState === undefined) {
      setSdkState('initializing');
      initializeSDK();
    }
  }, [licenseKey, sdkState]);

  useEffect(() => {
    if (sdkState === 'initialized' && barcodeReaderRef.current === null) {
      const barcodeReaderInitialization = async () => {
        try {
          const barcodeReader = new BarcodeReader(
            createBarcodeReaderConfig(hostElemRef.current as HTMLElement),
          );
          barcodeReaderRef.current = barcodeReader;
          await barcodeReader.initialize();

          barcodeReader.detected = (detections) => {
            onDetected(detections[0].data);
          };
          await barcodeReader.start();
        } catch (error) {
          console.error(
            'There was an problem initializing the barcode reader',
            error,
          );
        }
      };

      barcodeReaderInitialization();
    }
  }, [sdkState, onDetected]);

  useEffect(() => {
    return () => {
      if (barcodeReaderRef.current && barcodeReaderRef.current.getVisible()) {
        barcodeReaderRef.current.destroy();
        barcodeReaderRef.current = null;
      }
    };
  }, []);

  return <div ref={hostElemRef} className="relative w-full h-full" />;
};
