import { ReactComponent as ChatIcon } from 'assets/icons/appointmentsIcons/chatIconTable.svg';
import { ReactComponent as MessagingIcon } from 'assets/icons/appointmentsIcons/messagingIconTable.svg';
import { ReactComponent as VideoIcon } from 'assets/icons/appointmentsIcons/videoIconTable.svg';
import { AppointmentTypeEnum } from 'graphql/generated/hasura';
import { ReactComponent as DnaVisitTypeIcon } from 'assets/icons/appointmentsIcons/dnaVisitTypeIconTable.svg';
import { ChangeEvent } from 'react';
import { PatientDropdownOption } from 'app/appointments/components/interfaces';
import { FHIR_APPOINTMENT_STATUS } from 'utilities/interfaces';

export enum AppointmentType {
  VIDEO = 'VIDEO',
  CHAT = 'CHAT',
  MESSAGING = 'EMAIL',
}

export interface UpcomingAppoinment {
  date: string;
  name: string;
  appointmentType: AppointmentTypeEnum;
  appointmentId: string;
  providerId: string;
  patientId: string;
  status: FHIR_APPOINTMENT_STATUS;
}

export const appointmentRenderInfo: Record<
  AppointmentTypeEnum,
  {
    icon: React.FC<React.SVGProps<SVGSVGElement>>;
    text: string;
  }
> = {
  [AppointmentTypeEnum.Video]: {
    icon: VideoIcon,
    text: 'videoTypeText',
  },
  [AppointmentTypeEnum.Chat]: {
    icon: ChatIcon,
    text: 'chatTypeText',
  },
  [AppointmentTypeEnum.Email]: {
    icon: MessagingIcon,
    text: 'messagingTypeText',
  },
  [AppointmentTypeEnum.Dnavisit]: {
    icon: DnaVisitTypeIcon,
    text: 'dnaVisitTypeText',
  },
} as const;

export enum AppointmentStatus {
  PROPOSED = 'proposed',
  BOOKED = 'booked',
  ARRIVED = 'arrived',
  FULFILLED = 'fulfilled',
  NOSHOW = 'noshow',
  ENTEREDINERROR = 'entered-in-error',
  CHECKEDIN = 'checked-in',
  WAITLIST = 'waitlist',
  CANCELLED = 'cancelled',
  RESOLVED = 'fulfilled',
  PENDING = 'pending',
}

export enum AppointmentStatusFilterOptions {
  CANCELLED = 'cancelled',
  NOSHOW = 'noshow',
}

export const AppointmentStatusFilterRenderInfo: Record<
  AppointmentStatusFilterOptions,
  {
    text: string;
    textColorClass: string;
    borderColorClass: string;
  }
> = {
  [AppointmentStatusFilterOptions.CANCELLED]: {
    text: 'cancelledStatus',
    textColorClass: 'text-flag-red',
    borderColorClass: 'border-flag-red',
  },
  [AppointmentStatusFilterOptions.NOSHOW]: {
    text: 'noshowStatus',
    textColorClass: 'text-flag-purple',
    borderColorClass: 'border-flag-purple',
  },
};

export type FhirAppointmentStatuses =
  | 'proposed'
  | 'pending'
  | 'booked'
  | 'arrived'
  | 'fulfilled'
  | 'cancelled'
  | 'noshow'
  | 'entered-in-error'
  | 'checked-in'
  | 'waitlist';

export interface AppointmentHistoryRowProps {
  date: string;
  name: string;
  type: AppointmentTypeEnum;
  logs: string[];
  actions?: {
    text: string;
    onClick: () => void;
  }[];
  summaryCompleted?: boolean;
  id: string;
  locale?: Record<string, string>;
  hideProvider?: boolean;
  patientCodexId: string;
  providerCodexId: string;
}

export interface HistoricAppointmentsFilter {
  find?: string;
  from?: string;
  to?: string;
  patient?: string;
  provider?: string;
  status?: AppointmentStatusFilterOptions;
  type?: AppointmentTypeEnum;
  sort?: string;
  sortDirection?: 0 | 1;
}

export interface AppointmentsHistoryFilterProps {
  onFilter?: () => void;
  filter: HistoricAppointmentsFilter;
  setFilter: React.Dispatch<React.SetStateAction<HistoricAppointmentsFilter>>;
  patientsList?: PatientDropdownOption[];
  providersList?: PatientDropdownOption[];
  locale?: Record<string, string>;
}

export interface AppointmentsHistoryFilterMobileProps
  extends AppointmentsHistoryFilterProps {
  sortColumns: string[];
  locale?: Record<string, string>;
}

export enum UpcomingAppointmentsDayFilter {
  NOFILTER = 'No Filter',
  TODAY = 'today',
  TOMORROW = 'tomorrow',
  WEEK = 'week',
  MONTH = 'month',
}
export const UpcomingAppointmentsDayFilterArray = Object.values(
  UpcomingAppointmentsDayFilter,
);

export interface UpcomingAppointmentsCardRightComponentProps {
  locale: Record<string, string>;
  appointmentsForValue: string;
  appointmentsForEntries: string[];
  onAppointmentsForSelectChange: (e: ChangeEvent<HTMLSelectElement>) => void;
}

export interface HistoricAppointmentsSortFilter {
  column: string;
  direction: 0 | 1;
}

export type HandleAppointmentFilterChangeEvent =
  | React.ChangeEvent<HTMLSelectElement | HTMLInputElement>
  | { target: HTMLInputElement };

export interface AlertState {
  message: string;
  type: 'positive' | 'negative';
}
