import { DotCollection } from './interfaces';

export const initialDotsState: DotCollection = {
  front: [
    {
      id: 1,
      location: '',
      strapiKey: 'abdomen',
      bodyPoints: {
        area: '105,194 124,194 135,260 94,260',
        x: '110',
        y: '210',
      },
    },
    {
      id: 2,
      location: '',
      strapiKey: 'instepRight',
      bodyPoints: {
        area: '112,568 93,569 85,581 107,585',
        x: '93',
        y: '572',
      },
    },
    {
      id: 3,
      location: '',
      strapiKey: 'armCreaseLeft',
      bodyPoints: {
        area: '165,193 183,192 192,220 166,225',
        x: '172',
        y: '208',
      },
    },
    {
      id: 4,
      location: '',
      strapiKey: 'kneeLeft',
      bodyPoints: {
        area: '118,403 151,404 148,439 122,439',
        x: '130',
        y: '414',
      },
    },
    {
      id: 5,
      location: '',
      strapiKey: 'armCreaseRight',
      bodyPoints: {
        area: '65,193 47,192 38,220 64,225',
        x: '48',
        y: '208',
      },
    },
    {
      id: 6,
      location: '',
      strapiKey: 'kneeRight',
      bodyPoints: {
        area: '78,403 112,404 107,439 82,439',
        x: '91',
        y: '414',
      },
    },
    {
      id: 7,
      location: '',
      strapiKey: 'armLowerFrontLeft',
      bodyPoints: {
        area: '168,234 194,229 201,276 185,280',
        x: '182',
        y: '251',
      },
    },
    {
      id: 8,
      location: '',
      strapiKey: 'legLowerFrontLeft',
      bodyPoints: {
        area: '119,469 154,472 139,527 122,527',
        x: '130',
        y: '490',
      },
    },

    {
      id: 9,
      location: '',
      strapiKey: 'armLowerFrontRight',
      bodyPoints: {
        area: '62,234 36,229 29,276 45,280',
        x: '39',
        y: '251',
      },
    },
    {
      id: 10,
      location: '',
      strapiKey: 'legLowerFrontRight',
      bodyPoints: {
        area: '110,469 75,472 90,527 107,527',
        x: '90',
        y: '490',
      },
    },
    {
      id: 11,
      location: '',
      strapiKey: 'armUpperFrontLeft',
      bodyPoints: {
        area: '166,131 185,131 183,186 164,187',
        x: '171',
        y: '154',
      },
    },
    {
      id: 12,
      location: '',
      strapiKey: 'legUpperFrontLeft',
      bodyPoints: {
        area: '122,310 166,277 167,314 160,359, 149,400 120,400',
        x: '141',
        y: '333',
      },
    },
    {
      id: 13,
      location: '',
      strapiKey: 'armUpperFrontRight',
      bodyPoints: {
        area: '63,131 44,131 46,186 65,187',
        x: '51',
        y: '154',
      },
    },
    {
      id: 14,
      location: '',
      strapiKey: 'legUpperFrontRight',
      bodyPoints: {
        area: '107,310 63,277 62,314 69,359 80,400 109,400',
        x: '82',
        y: '333',
      },
    },
    {
      id: 15,
      location: '',
      strapiKey: 'checksLeft',
      bodyPoints: {
        area: '121,43 134,43 134,59 121,59',
        x: '124',
        y: '47',
      },
      headPoints: {
        area: '70,58 89,58 89,78 70,78',
        x: '77',
        y: '62',
      },
    },
    {
      id: 16,
      location: '',
      strapiKey: 'lipsMouth',
      bodyPoints: {
        area: '110,59 120,59 120,66 110,66',
        x: '110',
        y: '60',
      },
      headPoints: {
        area: '54,77 69,77 69,89 54,89',
        x: '57',
        y: '78',
      },
    },
    {
      id: 17,
      location: '',
      strapiKey: 'checksRight',
      bodyPoints: {
        area: '96,43 109,43 109,59 96,59',
        x: '96',
        y: '47',
      },
      headPoints: {
        area: '34,58 53,58 53,78 34,78',
        x: '38',
        y: '62',
      },
    },
    {
      id: 18,
      location: '',
      strapiKey: 'neckThroat',
      bodyPoints: {
        area: '98,72 115,80, 132,72 131,96 98,96',
        x: '110',
        y: '88',
      },
    },
    {
      id: 19,
      location: '',
      strapiKey: 'chestLeft',
      bodyPoints: {
        area: '127,130 163,133 160,163 126,160',
        x: '145',
        y: '140',
      },
    },
    {
      id: 20,
      location: '',
      strapiKey: 'palmLeft',
      bodyPoints: {
        area: '191,303 214,304 213,317 193,315',
        x: '196',
        y: '305',
      },
    },
    {
      id: 21,
      location: '',
      strapiKey: 'chestMiddle',
      bodyPoints: {
        area: '102,130 127,130 126,160 103,160',
        x: '110',
        y: '133',
      },
    },
    {
      id: 22,
      location: '',
      strapiKey: 'palmRight',
      bodyPoints: {
        area: '39,303 16,304 17,317 37,315',
        x: '23',
        y: '305',
      },
    },
    {
      id: 23,
      location: '',
      strapiKey: 'chestRight',
      bodyPoints: {
        area: '102,130 66,133 69,163 103,160',
        x: '78',
        y: '140',
      },
    },
    {
      id: 24,
      location: '',
      strapiKey: 'pelvis',
      bodyPoints: {
        area: '102,276 125,276 125,292 102,292',
        x: '110',
        y: '281',
      },
    },
    {
      id: 25,
      location: '',
      strapiKey: 'chinJawline',
      bodyPoints: {
        area: '110,70 120,70 120,80 110,80',
        x: '110',
        y: '71',
      },
      headPoints: {
        area: '54,92 69,92 69,112 54,112',
        x: '57',
        y: '99',
      },
    },
    {
      id: 26,
      location: '',
      strapiKey: 'philtrum',
      bodyPoints: {
        area: '110,51 120,51 120,58 110,58',
        x: '110',
        y: '51',
      },
      headPoints: {
        area: '54,67 69,67 69,76 54,76',
        x: '57',
        y: '67',
      },
    },
    {
      id: 27,
      location: '',
      strapiKey: 'eyesEyelidsFrontLeft',
      bodyPoints: {
        area: '118,31 136,31 136,42 118,42',
        x: '122',
        y: '34',
      },
      headPoints: {
        area: '65,38 90,38 90,58 65,58',
        x: '73',
        y: '46',
      },
    },
    {
      id: 28,
      location: '',
      strapiKey: 'shouldersFrontLeft',
      bodyPoints: {
        area: '138,103 164,92 195,112 162,118',
        x: '153',
        y: '98',
      },
    },
    {
      id: 29,
      location: '',
      strapiKey: 'eyesEyelidsFrontRight',
      bodyPoints: {
        area: '94,31 112,31 112,42 94,42',
        x: '98',
        y: '34',
      },
      headPoints: {
        area: '33,38 58,38 58,58 33,58',
        x: '41',
        y: '46',
      },
    },
    {
      id: 30,
      location: '',
      strapiKey: 'shouldersFrontRight',
      bodyPoints: {
        area: '92,103 66,92 35,112 68,118',
        x: '67',
        y: '98',
      },
    },
    {
      id: 31,
      location: '',
      strapiKey: 'footLeft',
      bodyPoints: {
        area: '120,550 135,550 137,566 117,565',
        x: '123',
        y: '553',
      },
    },
    {
      id: 32,
      location: '',
      strapiKey: 'sideOfBodyLeft',
      bodyPoints: {
        area: '154,232 132,241 124,194 154,196 153,215',
        x: '147',
        y: '194',
      },
    },
    {
      id: 35,
      location: '',
      strapiKey: 'forehead',
      bodyPoints: {
        area: '101,10 126,10 126,26 101,26',
        x: '110',
        y: '16',
      },
      headPoints: {
        area: '40,10 82,10 82,36 40,36',
        x: '57',
        y: '20',
      },
    },
    {
      id: 33,
      location: '',
      strapiKey: 'footRight',
      bodyPoints: {
        area: '109,550 94,550 92,566 112,565',
        x: '97',
        y: '553',
      },
    },
    {
      id: 34,
      location: '',
      strapiKey: 'sideOfBodyRight',
      bodyPoints: {
        area: '75,232, 97,241 105,194 75,196 76,215',
        x: '73',
        y: '194',
      },
    },
    {
      id: 36,
      location: '',
      strapiKey: 'toesLeft',
      bodyPoints: {
        area: '124,590 149,586 160,593 155,597 143,599 129,598',
        x: '142',
        y: '591',
      },
    },
    {
      id: 37,
      location: '',
      strapiKey: 'groin',
      bodyPoints: {
        area: '102,294 125,294 125,312 102,312',
        x: '110',
        y: '301',
      },
    },

    {
      id: 38,
      location: '',
      strapiKey: 'toesRight',
      bodyPoints: {
        area: '106,590 81,586 70,593 75,597 87,599 101,598',
        x: '79',
        y: '591',
      },
    },
    {
      id: 39,
      location: '',
      strapiKey: 'hipsLeft',
      bodyPoints: {
        area: '144,268 161,257 166,277 149,286',
        x: '152',
        y: '267',
      },
    },
    {
      id: 40,
      location: '',
      strapiKey: 'wristLeft',
      bodyPoints: {
        area: '187,285 202,282 210,294 190,297',
        x: '193',
        y: '285',
      },
    },
    {
      id: 41,
      location: '',
      strapiKey: 'hipsRight',
      bodyPoints: {
        area: '85,268 68,257 63,277 80,286',
        x: '68',
        y: '267',
      },
    },
    {
      id: 42,
      location: '',
      strapiKey: 'wristRight',
      bodyPoints: {
        area: '43,285 27,282 19,294 39,297',
        x: '28',
        y: '285',
      },
    },
    {
      id: 43,
      location: '',
      strapiKey: 'instepLeft',
      bodyPoints: {
        area: '118,568 137,569 145,581 123,585',
        x: '126',
        y: '572',
      },
    },
  ],
  back: [
    {
      id: 44,
      location: '',
      strapiKey: 'ankleLeft',
      bodyPoints: {
        area: '110,550 95,550 93,580 112 ,580',
        x: '100',
        y: '566',
      },
    },
    {
      id: 45,
      location: '',
      strapiKey: 'footSoleRight',
      bodyPoints: {
        area: '139,581 156,581 156,600 139,600',
        x: '142',
        y: '587',
      },
    },
    {
      id: 46,
      location: '',
      strapiKey: 'ankleRight',
      bodyPoints: {
        area: '120,550 135,550 137,580 118,580',
        x: '122',
        y: '566',
      },
    },
    {
      id: 47,
      location: '',
      strapiKey: 'handBackLeft',
      bodyPoints: {
        area: '41,290 16,292 17,317 38,315',
        x: '25',
        y: '301',
      },
    },
    {
      id: 48,
      location: '',
      strapiKey: 'armLowerBackLeft',
      bodyPoints: {
        area: '62,234 36,229 29,276 45,280',
        x: '38',
        y: '253',
      },
    },
    {
      id: 49,
      location: '',
      strapiKey: 'handBackRight',
      bodyPoints: {
        area: '189,290 214,292 213,317 192,315',
        x: '197',
        y: '301',
      },
    },
    {
      id: 50,
      location: '',
      strapiKey: 'armLowerBackRight',
      bodyPoints: {
        area: '168,234 194,229 201,276 185,280',
        x: '183',
        y: '253',
      },
    },
    {
      id: 51,
      location: '',
      strapiKey: 'headBack',
      bodyPoints: {
        area: '93,13 137,13 141,31 137,59 94,59 89,31',
        x: '110',
        y: '29',
      },
      headPoints: {
        area: '32,14 88,14 95,32 89,78 31,78 25,32',
        x: '55',
        y: '30',
      },
    },
    {
      id: 52,
      location: '',
      strapiKey: 'armUpperBackLeft',
      bodyPoints: {
        area: '64,131 45,131 47,186 66,187',
        x: '53',
        y: '176',
      },
    },
    {
      id: 53,
      location: '',
      strapiKey: 'heelLeft',
      bodyPoints: {
        area: '94,581 111,581 111,600 94,600',
        x: '100',
        y: '590',
      },
    },
    {
      id: 54,
      location: '',
      strapiKey: 'armUpperBackRight',
      bodyPoints: {
        area: '166,131 185,131 183,186 164,187',
        x: '170',
        y: '176',
      },
    },
    {
      id: 55,
      location: '',
      strapiKey: 'heelRight',
      bodyPoints: {
        area: '120,581 137,581 137,600 120,600',
        x: '122',
        y: '590',
      },
    },
    {
      id: 56,
      location: '',
      strapiKey: 'backLower',
      bodyPoints: {
        area: '102,218 125,218 125,251 102,251',
        x: '110',
        y: '231',
      },
    },
    {
      id: 57,
      location: '',
      strapiKey: 'kneeBackLeft',
      bodyPoints: {
        area: '79,406 113,406 113,420 108,440 83,440',
        x: '89',
        y: '421',
      },
    },
    {
      id: 58,
      location: '',
      strapiKey: 'backMiddle',
      bodyPoints: {
        area: '105,160 124,160 135,218 94,218',
        x: '110',
        y: '187',
      },
    },
    {
      id: 59,
      location: '',
      strapiKey: 'kneeBackRight',
      bodyPoints: {
        area: '152,406 118,406 118,420 122,440 148,440',
        x: '133',
        y: '421',
      },
    },
    {
      id: 60,
      location: '',
      strapiKey: 'backUpper',
      bodyPoints: {
        area: '102,130 127,130 126,160 103,160',
        x: '110',
        y: '140',
      },
    },
    {
      id: 61,
      location: '',
      strapiKey: 'legLowerBackLeft',
      bodyPoints: {
        area: '110,469 76,472 90,527 107,527',
        x: '90',
        y: '482',
      },
    },
    {
      id: 62,
      location: '',
      strapiKey: 'buttocksLeft',
      bodyPoints: {
        area: '68,257 111,263 109,312 61,312',
        x: '89',
        y: '288',
      },
    },
    {
      id: 63,
      location: '',
      strapiKey: 'legLowerBackRight',
      bodyPoints: {
        area: '120,469 155,472 140,527 123,527',
        x: '132',
        y: '482',
      },
    },
    {
      id: 64,
      location: '',
      strapiKey: 'buttocksRight',
      bodyPoints: {
        area: '162,257 120,263 122,312 170,312',
        x: '132',
        y: '288',
      },
    },
    {
      id: 65,
      location: '',
      strapiKey: 'legUpperBackLeft',
      bodyPoints: {
        area: '111,320 62,315 64,324 71,369 82,405 111,405',
        x: '85',
        y: '361',
      },
    },
    {
      id: 66,
      location: '',
      strapiKey: 'earLeft',
      bodyPoints: {
        area: '80,35 92,33 100,59 88,62',
        x: '87',
        y: '45',
      },
      headPoints: {
        area: '15,44 30,42 39,79 23,82',
        x: '22',
        y: '59',
      },
    },
    {
      id: 67,
      location: '',
      strapiKey: 'legUpperBackRight',
      bodyPoints: {
        area: '120,320 169,315 167,324 160,369 150,405 120,405',
        x: '137',
        y: '361',
      },
    },
    {
      id: 68,
      location: '',
      strapiKey: 'earRight',
      bodyPoints: {
        area: '151,35 139,33 131,59 143,62',
        x: '135',
        y: '45',
      },
      headPoints: {
        area: '106,44 91,42 82,79 98,82',
        x: '89',
        y: '59',
      },
    },
    {
      id: 69,
      location: '',
      strapiKey: 'neckBack',
      bodyPoints: {
        area: '95.5,62 135.5,62 131.5,71 132.5,87 98.5,87 99.5,71',
        x: '110',
        y: '72',
      },
      headPoints: {
        area: '31,78 89,78 82,95 82.5,115 37,115 38,95',
        x: '55',
        y: '97',
      },
    },
    {
      id: 70,
      location: '',
      strapiKey: 'elbowLeft',
      bodyPoints: {
        area: '65,193 47,192 37,226 62,231',
        x: '49',
        y: '213',
      },
    },
    {
      id: 71,
      location: '',
      strapiKey: 'scalp',
      bodyPoints: {
        area: '111,1 118,1 128,4 137,13 93,13 102,4',
        x: '110',
        y: '5',
      },
      headPoints: {
        area: '55,0 63,0 75,3 88,14 32,14 45,3',
        x: '55',
        y: '5',
      },
    },
    {
      id: 72,
      location: '',
      strapiKey: 'elbowRight',
      bodyPoints: {
        area: '165,193 183,192 193,226 168,231',
        x: '174',
        y: '213',
      },
    },
    {
      id: 73,
      location: '',
      strapiKey: 'shoulderBackLeft',
      bodyPoints: {
        area: '92,103 66,92 35,112 68,118',
        x: '68',
        y: '99',
      },
    },
    {
      id: 74,
      location: '',
      strapiKey: 'fingersLeft',
      bodyPoints: {
        area: '17,317 38,315 43,343 13,343',
        x: '22',
        y: '325',
      },
    },
    {
      id: 75,
      location: '',
      strapiKey: 'shoulderBackRight',
      bodyPoints: {
        area: '138,103 164,92 195,112 162,118',
        x: '155',
        y: '99',
      },
    },
    {
      id: 76,
      location: '',
      strapiKey: 'fingersRight',
      bodyPoints: {
        area: '191,315 213,317 219,343 192,343',
        x: '201',
        y: '325',
      },
    },
    {
      id: 77,
      location: '',
      strapiKey: 'shoulderBladeLeft',
      bodyPoints: {
        area: '102,130 66,133 69,163 103,160',
        x: '83',
        y: '145',
      },
    },
    {
      id: 78,
      location: '',
      strapiKey: 'footSoleLeft',
      bodyPoints: {
        area: '75,581 92,581 92,600 75,600',
        x: '79',
        y: '587',
      },
    },
    {
      id: 79,
      location: '',
      strapiKey: 'shoulderBladeRight',
      bodyPoints: {
        area: '127,130 163,133 160,163 126,160',
        x: '138',
        y: '145',
      },
    },
  ],
};
