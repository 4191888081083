import ButtonComponent from 'components/button/buttonComponent';
import InputComponent from 'components/inputComponent';
import { ReactComponent as RightArrowIcon } from 'assets/icons/blue-arrow-right.svg';
import { Fragment, useCallback, useEffect, useState } from 'react';
import { filterOptions } from '../mocks/sidebarFilters';
import { ProviderToggleButtonGroup } from './ProviderToggleButtonGroup';
import { useGetPage } from 'hooks/useGetPage';
import {
  allAvailableFilters,
  availableLanguages,
  availableSpecialties,
  componentIds,
  genericActionsIds,
  pageIds,
} from 'utilities/constants';
import { useGetGenericActions } from 'hooks/useGetGenericActions';
import {
  AcceptedAppointmentsFilterEnum,
  GenderFilterEnum,
} from 'app/my-appointments/pages/MyProvider.interface';
import { useGetComponent } from 'hooks/useGetComponent';
import {
  ProviderSideBarFilterProps,
  ProvidersFilterOptionType,
  ProvidersFilterType,
} from './ProviderSideBarFilterInterfaces';

export const ProviderSideBarFilter: React.FC<ProviderSideBarFilterProps> = ({
  selectedFilters,
  setSelectedFilters,
}) => {
  const { data: locale, loading } = useGetPage({
    locale: 'en',
    pageId: pageIds.PROVIDERS_SELECTION,
  });

  const { data: localeSidebar, loading: localeLoading } = useGetComponent({
    locale: 'en',
    componentId: componentIds.PROVIDER_SIDEBAR_FILTERS,
  });

  const { data: genericActions } = useGetGenericActions({
    locale: 'en',
    genericActionId: [genericActionsIds.VIEW_ALL],
  });
  const { loading: selectAProviderLocaleLoading, data: selectAProviderLocale } =
    useGetPage({
      locale: 'en',
      pageId: pageIds.SELECT_A_PROVIDER,
    });
  const { data: specialtiesAndLanguagesLocales } = useGetComponent({
    locale: 'en',
    componentId: componentIds.AVAILABLE_SPECIALTIES_AND_LANGUAGES,
  });

  const formatOptions = useCallback(
    (
      options: string[],
      locale: Record<string, string>,
    ): ProvidersFilterOptionType[] => {
      const allOption = {
        label: selectAProviderLocale?.all,
        type: 'radio',
        value: selectAProviderLocale?.all,
      };
      return [
        allOption,
        ...options.map((option) => ({
          type: 'checkbox',
          value: option,
          label: locale[option],
        })),
      ];
    },
    [selectAProviderLocale?.all],
  );

  const loadAllFiltersForType = useCallback(
    (type: string) => () => {
      if (!allAvailableFilters[type]) return;
      setFilters((prevFilters) => ({
        ...prevFilters,
        [type]: {
          options: formatOptions(
            allAvailableFilters[type],
            specialtiesAndLanguagesLocales[type],
          ),
        },
      }));
    },
    [formatOptions, specialtiesAndLanguagesLocales],
  );

  useEffect(() => {
    if (!selectAProviderLocale || !specialtiesAndLanguagesLocales) return;
    const filters: Record<string, ProvidersFilterType> = {
      specialty: {
        options: formatOptions(
          availableSpecialties.slice(0, 3),
          specialtiesAndLanguagesLocales.specialty,
        ),
        canLoadMore: true,
        onLoadMore: loadAllFiltersForType('specialty'),
      },
      acceptedAppointments: {
        options: formatOptions(
          Object.values(AcceptedAppointmentsFilterEnum),
          selectAProviderLocale,
        ),
      },
      gender: {
        options: formatOptions(
          Object.values(GenderFilterEnum),
          selectAProviderLocale,
        ),
      },
      languages: {
        options: formatOptions(
          availableLanguages.slice(0, 3),
          specialtiesAndLanguagesLocales.languages,
        ),
        canLoadMore: true,
        onLoadMore: loadAllFiltersForType('languages'),
      },
    };
    setFilters(filters);
  }, [
    loadAllFiltersForType,
    formatOptions,
    selectAProviderLocale,
    specialtiesAndLanguagesLocales,
  ]);

  const [filters, setFilters] =
    useState<Record<string, ProvidersFilterType>>(filterOptions);
  const [selectedButton, setSelectedButton] = useState<string>('$');

  const handleFilterChange = (
    filterType: string,
    value: string,
    type: string,
  ) => {
    setSelectedFilters((prevFilters) => {
      const currentFilters = prevFilters[filterType] ?? [];

      if (type === 'radio') {
        return {
          ...prevFilters,
          [filterType]: currentFilters.length
            ? []
            : filters[filterType].options
                .filter((opt) => opt.value !== selectAProviderLocale?.all)
                .map((opt) => opt.value),
        };
      }

      const updatedFilters = currentFilters.includes(value)
        ? currentFilters.filter((filter) => filter !== value)
        : [...currentFilters, value];

      return {
        ...prevFilters,
        [filterType]: updatedFilters.length ? updatedFilters : undefined,
      };
    });
  };

  const handleButtonChange = (button: string) => {
    setSelectedButton(button);
  };

  if (
    loading ||
    !locale ||
    !genericActions ||
    selectAProviderLocaleLoading ||
    localeLoading
  )
    return null;

  return (
    <div className="flex flex-col w-full py-4 px-8 gap-4 bg-base-content rounded-10">
      <p className="font-semibold text-dark-gray text-h5">
        {locale?.providerBio?.filters}
      </p>
      <hr className="flex flex-row w-full items-center h-px bg-black-blur" />
      <div className="flex desktop:hidden flex-col gap-4 justify-end w-full">
        <p className="text-dark-gray font-semibold text-h6">
          {locale?.findAProvider?.filters?.sort}
        </p>
        <div className="w-full">
          <InputComponent
            type="select"
            name="sort"
            selectInputProps={{ fullWidth: true }}
          />
        </div>
      </div>
      <ProviderToggleButtonGroup
        buttons={localeSidebar.toggleButtonGroupMock.buttons}
        selectedButton={selectedButton}
        onButtonChange={handleButtonChange}
      />
      {Object.entries(filters).map(
        ([filterType, { options, canLoadMore, onLoadMore }]) => (
          <div key={filterType} className="flex flex-col gap-2">
            <p className="font-semibold text-dark-gray text-h6">
              {selectAProviderLocale[filterType]}
            </p>
            {options.map((option) => (
              <Fragment key={option.value}>
                {option.type === 'button' ? null : (
                  <InputComponent
                    name={option.label}
                    type={option.type}
                    {...(option.type === 'radio' && {
                      radioInputProps: {
                        radioInputValue: filterType + option.value,
                        radioInputLabel: option.label,
                        onRadioClick() {
                          handleFilterChange(filterType, option.value, 'radio');
                        },
                        radioError: null,
                      },
                    })}
                    {...(option.type === 'checkbox' && {
                      checkboxProps: {
                        checkboxInputLabel: option.value,
                        checkboxLabel: option.label,
                        checked: selectedFilters[filterType]?.includes(
                          option.value,
                        ),
                        onCheckboxChange() {
                          handleFilterChange(
                            filterType,
                            option.value,
                            'checkbox',
                          );
                        },
                      },
                    })}
                  />
                )}
              </Fragment>
            ))}
            {canLoadMore && (
              <div className="flex items-center">
                <ButtonComponent
                  fullWidthClassName="w-auto"
                  type="underline"
                  Icon={RightArrowIcon}
                  onClick={onLoadMore}
                >
                  {genericActions[genericActionsIds.VIEW_ALL]?.viewAll}
                </ButtonComponent>
              </div>
            )}
          </div>
        ),
      )}
    </div>
  );
};
