interface NumballProps {
  count: number;
  alternateBackground?: boolean;
}

const Numball: React.FC<NumballProps> = ({
  count,
  alternateBackground = false,
}) => (
  <div
    className={`flex flex-col h-[25px] items-center justify-center px-2 ${
      alternateBackground ? 'bg-gray-opacity-15' : 'bg-black-blur'
    } bg-black-blur rounded-[5px]`}
  >
    <div
      className={`flex items-center text-xs leading-[15px] font-semibold ${
        alternateBackground ? 'text-dark-gray' : 'text-white'
      }`}
    >
      {count}
    </div>
  </div>
);

export default Numball;
