import { usePasswordResetEmail } from '../../../firebase/hooks';
import Alert from 'components/alertComponent';
import ButtonComponent from 'components/button/buttonComponent';
import { pageIds } from 'utilities/constants';
import { useGetPage } from 'hooks/useGetPage';
import { useLocation } from 'react-router-dom';

export const SendResetPasswordEmail = () => {
  const { data: locale, loading } = useGetPage({
    locale: 'en',
    pageId: pageIds.RESET_PASSWORD_EMAIL,
  });
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const email = queryParams.get('email') || '';
  const [errorMsg, handlePasswordResetEmail] = usePasswordResetEmail();
  const handleResendResetPasswordEmail = async () => {
    await handlePasswordResetEmail(email);
  };

  if (loading && !locale) return null;

  return (
    <>
      <div className="flex flex-row w-full mb-[10px]">
        <Alert
          type="positive"
          testID="success-alert"
          text={locale?.resetEmailSent}
        />
      </div>
      <div className="flex flex-col items-center desktop:justify-center px-[20px] py-[30px] desktop:px-[60px] desktop:py-[40px] gap-[30px] rounded-10 bg-white">
        <div className="flex flex-col items-start desktop:items-center p-0 gap-[20px] desktop:gap-[30px]">
          <p className="text-base font-medium text-med-gray">
            {locale?.openTheLink}
          </p>
          <p className="text-base font-medium text-med-gray">
            {locale?.checkSpamFolder}
          </p>
        </div>
        <div className="flex flex-col items-center gap-2.5">
          <p className="text-center text-h4 font-bold text-med-gray">
            {locale?.emailNotReceived}
          </p>
          <ButtonComponent
            testID="resend-button"
            type="submit"
            paddingX="px-5"
            paddingY="py-[9.5px]"
            disabled={false}
            onClick={handleResendResetPasswordEmail}
          >
            {locale?.resendButton}
          </ButtonComponent>
          {errorMsg && <p className="text-red">{errorMsg}</p>}
        </div>
      </div>
    </>
  );
};
