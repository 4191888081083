import React, { useContext, useEffect, useRef, useState } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { ReactComponent as ChevronDownIcon } from 'assets/icons/chevron-down.svg';
import { ReactComponent as MenuHamburgerIcon } from 'assets/icons/menu-hamburger.svg';
import { ReactComponent as NotificationIcon } from 'assets/icons/notification.svg';
import { Section } from './navbar.interfaces';
import { Roles } from '../../firebase/interfaces';
import { useFetchUserByEmail, useFirebaseSignOut } from '../../firebase/hooks';
import { useNavbarOptions } from 'hooks/useNavbarOptions';
import { useGetPage } from 'hooks/useGetPage';
import { pageIds } from 'utilities/constants';
import { AUTH_LOGIN, DASHBOARD, NOTIFICATIONS } from 'utilities/routes';
import {
  useClearAllSavedDnaScannedDataForUserIdMutation,
  useGetFileUrlFromStorageMutation,
} from 'graphql/generated/hasura';
import { AuthContext } from 'auth/context/AuthContext';
import Avatar from 'components/avatarComponent';
import Dropdown from 'components/dropdown/dropdownComponent';
import useDropdown from 'components/dropdown/useDropdown';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { CodexFeatureFlags } from 'utilities/interfaces';
import { useNotificationCount } from 'notifications/contexts/NotificationsContext';

interface AuthenticatedNavbarProps {
  openNavbarMobile: () => void;
}

export const AuthenticatedNavbar: React.FC<AuthenticatedNavbarProps> = ({
  openNavbarMobile,
}) => {
  const { data: locale, loading } = useGetPage({
    locale: 'en',
    pageId: pageIds.NAVBAR_COMPONENT,
  });
  const { providersSelectionDisabled, appointmentsSelectionDisabled } =
    useFlags<CodexFeatureFlags>();

  const {
    loadingOptions,
    MySkinOptions,
    ManageTestKitsOptions,
    ProvidersOptions,
    ShopOptions,
    MyAccountOptions,
    MySkinRoutes,
    ManageTestKitsRoutes,
    ShopRoutes,
    MyAccountRoutes,
    ProvidersRoutes,
    ProviderMyAccountOptions,
    ProviderMyAccountRoutes,
    AppointmentsRoutes,
    AppointmentsOptions,
  } = useNavbarOptions();

  const { profilePicture, setProfilePicture, user } = useContext(AuthContext);
  const [getFileUrlFromStorage] = useGetFileUrlFromStorageMutation({});
  const { fetchUserByEmailPromise } = useFetchUserByEmail();

  const userRole = user?.role;
  const [selectedSection, setSelectedSection] = useState<Section>();
  const navigate = useNavigate();
  const location = useLocation();
  const signOutUser = useFirebaseSignOut();
  const firstRendered = useRef(true);

  const getMyAccountOptionsBasedOnRole = () => {
    switch (userRole) {
      case Roles.PATIENT:
        return MyAccountOptions;
      case Roles.PROVIDER:
        return ProviderMyAccountOptions;
      default:
        return MyAccountOptions;
    }
  };

  const getMyAccountRoutesBasedOnRole = () => {
    switch (userRole) {
      case Roles.PATIENT:
        return MyAccountRoutes;
      case Roles.PROVIDER:
        return ProviderMyAccountRoutes;
      default:
        return MyAccountRoutes;
    }
  };

  useEffect(() => {
    const getProfilePicture = async (profilePicture: string) => {
      const image = await getFileUrlFromStorage({
        variables: {
          fileId: profilePicture,
        },
      });
      setProfilePicture(image.data?.GetSignUrlFormStorage?.url || null);
    };
    if (
      (userRole === Roles.PATIENT ||
        userRole === Roles.PROVIDER ||
        userRole === Roles.ADMIN) &&
      user &&
      user.profilePicture &&
      firstRendered.current
    ) {
      getProfilePicture(user.profilePicture);
      firstRendered.current = false;
    }
  }, [
    userRole,
    user,
    getFileUrlFromStorage,
    setProfilePicture,
    fetchUserByEmailPromise,
  ]);

  useEffect(() => {
    const route = location.pathname.split('/')[1];
    setSelectedSection((route as Section) || Section.Dashboard);
  }, [location]);

  const {
    openDropdownId,
    handleDropdownClick,
    registerDropdownRef,
    handleHoverOut,
  } = useDropdown();

  const [clearAllSavedDnaScannedDataForUserIdMutation] =
    useClearAllSavedDnaScannedDataForUserIdMutation({
      variables: {
        userId: user?.uuid || '',
      },
    });

  const handleDropdownOptionSelected = async (
    section: Section,
    subRoute: string,
  ) => {
    if (subRoute === MyAccountRoutes[locale?.myAccountOptions.logOut]) {
      await clearAllSavedDnaScannedDataForUserIdMutation().catch((e) =>
        console.log('Error on clearing DNA saved data', e),
      );
      signOutUser();
      navigate(AUTH_LOGIN);
    } else if (section === Section.Shop) {
      window.open(
        `${locale?.shopOptionsUrl}${subRoute}`,
        '_blank',
        'noreferrer',
      );
    } else if (
      section === Section.MyAccount ||
      section === Section.ManageTestKits ||
      section === Section.Providers ||
      section === Section.Appointments
    ) {
      navigate(subRoute);
    } else if (section === Section.MySkin) {
      navigate(`${subRoute}`);
    } else {
      navigate(`/${section}/${subRoute}`);
    }
  };

  const isSectionSelected = (section: Section): boolean =>
    selectedSection === section;

  const { notificationCount: notifications } = useNotificationCount();

  if ((loading && !locale) || loadingOptions) return null;

  return (
    <div className="flex flex-row h-full desktop:gap-12">
      <NavLink
        to={DASHBOARD}
        className="hidden desktop:flex desktop:flex-col cursor-pointer relative"
      >
        <div className="flex h-[84px] items-center pt-1.5 px-2.5">
          <div
            className={`text-base font-bold ${
              isSectionSelected(Section.Dashboard)
                ? 'text-clc-blue'
                : 'text-med-gray'
            }`}
          >
            {locale?.dashboard}
          </div>
        </div>
        <div className="absolute bottom-0 w-full h-[30px] desktop:hover:bg-pnav-hover" />
        <div
          className={`h-1.5 ${
            isSectionSelected(Section.Dashboard) ? 'bg-clc-blue' : ''
          }`}
        />
      </NavLink>
      <div
        className="hidden desktop:flex flex-col cursor-pointer relative"
        onClick={() => handleDropdownClick('my-skin')}
        onMouseEnter={() => handleDropdownClick('my-skin')}
        onMouseLeave={() => handleHoverOut()}
      >
        <div ref={(ref) => registerDropdownRef('my-skin', ref)}>
          <div className="flex h-[84px] items-center pt-1.5 px-2.5">
            <div
              className={`text-base font-bold flex items-center ${
                isSectionSelected(Section.MySkin)
                  ? 'text-clc-blue'
                  : 'text-med-gray'
              }`}
            >
              <span className="mr-[5px]">{locale?.mySkin}</span>
              <ChevronDownIcon className="w-3 h-1.5 fill-current" />
            </div>
          </div>
          <div className="absolute bottom-0 w-full h-[30px] desktop:hover:bg-pnav-hover" />
          <div
            className={`h-1.5 ${
              isSectionSelected(Section.MySkin) ? 'bg-clc-blue' : ''
            }`}
          />
          {openDropdownId === 'my-skin' && (
            <Dropdown
              options={MySkinOptions}
              onClick={(option) =>
                handleDropdownOptionSelected(
                  Section.MySkin,
                  MySkinRoutes[option].toString(),
                )
              }
            />
          )}
        </div>
      </div>
      <div
        className="hidden desktop:flex flex-col cursor-pointer relative"
        onClick={() => handleDropdownClick('manage-test-kits')}
        onMouseEnter={() => handleDropdownClick('manage-test-kits')}
        onMouseLeave={() => handleHoverOut()}
      >
        <div ref={(ref) => registerDropdownRef('manage-test-kits', ref)}>
          <div className="flex h-[84px] items-center pt-1.5 px-2.5">
            <div
              className={`text-base font-bold flex items-center ${
                isSectionSelected(Section.ManageTestKits)
                  ? 'text-clc-blue'
                  : 'text-med-gray'
              }`}
            >
              <span className="mr-[5px]">{locale?.manageTestKits}</span>
              <ChevronDownIcon className="w-3 h-1.5 fill-current" />
            </div>
          </div>
          <div className="absolute bottom-0 w-full h-[30px] desktop:hover:bg-pnav-hover" />
          <div
            className={`h-1.5 ${
              isSectionSelected(Section.ManageTestKits) ? 'bg-clc-blue' : ''
            }`}
          />
          {openDropdownId === 'manage-test-kits' && (
            <Dropdown
              options={ManageTestKitsOptions}
              onClick={(option) =>
                handleDropdownOptionSelected(
                  Section.ManageTestKits,
                  ManageTestKitsRoutes[option].toString(),
                )
              }
            />
          )}
        </div>
      </div>

      {!appointmentsSelectionDisabled && (
        <div
          className="hidden desktop:flex flex-col cursor-pointer relative"
          onClick={() => handleDropdownClick('appointments')}
          onMouseEnter={() => handleDropdownClick('appointments')}
          onMouseLeave={() => handleHoverOut()}
        >
          <div ref={(ref) => registerDropdownRef('appointments', ref)}>
            <div className="flex h-[84px] items-center pt-1.5 px-2.5">
              <div
                className={`text-base font-bold flex items-center ${
                  isSectionSelected(Section.Appointments)
                    ? 'text-clc-blue'
                    : 'text-med-gray'
                }`}
              >
                <span className="mr-[5px]">{locale?.appointments}</span>
                <ChevronDownIcon className="w-3 h-1.5 fill-current" />
              </div>
            </div>
            <div className="absolute bottom-0 w-full h-[30px] desktop:hover:bg-pnav-hover" />
            <div
              className={`h-1.5 ${
                isSectionSelected(Section.Appointments) ? 'bg-clc-blue' : ''
              }`}
            />
            {openDropdownId === 'appointments' && (
              <Dropdown
                options={AppointmentsOptions}
                onClick={(option) =>
                  handleDropdownOptionSelected(
                    Section.Appointments,
                    AppointmentsRoutes[option].toString(),
                  )
                }
              />
            )}
          </div>
        </div>
      )}

      {!providersSelectionDisabled && (
        <div
          className="hidden desktop:flex flex-col cursor-pointer relative"
          onClick={() => handleDropdownClick('providers')}
          onMouseEnter={() => handleDropdownClick('providers')}
          onMouseLeave={() => handleHoverOut()}
        >
          <div ref={(ref) => registerDropdownRef('providers', ref)}>
            <div className="flex h-[84px] items-center pt-1.5 px-2.5">
              <div
                className={`text-base font-bold flex items-center ${
                  isSectionSelected(Section.Providers)
                    ? 'text-clc-blue'
                    : 'text-med-gray'
                }`}
              >
                <span className="mr-[5px]">{locale?.providers}</span>
                <ChevronDownIcon className="w-3 h-1.5 fill-current" />
              </div>
            </div>
            <div className="absolute bottom-0 w-full h-[30px] desktop:hover:bg-pnav-hover" />
            <div
              className={`h-1.5 ${
                isSectionSelected(Section.Providers) ? 'bg-clc-blue' : ''
              }`}
            />
            {openDropdownId === 'providers' && (
              <Dropdown
                options={ProvidersOptions}
                onClick={(option) =>
                  handleDropdownOptionSelected(
                    Section.Providers,
                    ProvidersRoutes[option].toString(),
                  )
                }
              />
            )}
          </div>
        </div>
      )}

      <div
        className="hidden desktop:flex desktop:flex-col cursor-pointer relative"
        onClick={() => handleDropdownClick('shop')}
        onMouseEnter={() => handleDropdownClick('shop')}
        onMouseLeave={() => handleHoverOut()}
      >
        <div ref={(ref) => registerDropdownRef('shop', ref)}>
          <div className="flex h-[84px] items-center pt-1.5 px-2.5">
            <div
              className={`text-base font-bold flex items-center ${
                isSectionSelected(Section.Shop)
                  ? 'text-clc-blue'
                  : 'text-med-gray'
              }`}
            >
              <span className="mr-[5px]">{locale?.shop}</span>
              <ChevronDownIcon className="w-3 h-1.5 fill-current" />
            </div>
          </div>
          <div className="absolute bottom-0 w-full h-[30px] desktop:hover:bg-pnav-hover" />
          <div
            className={`h-1.5 ${
              isSectionSelected(Section.Shop) ? 'bg-clc-blue' : ''
            }`}
          />
          {openDropdownId === 'shop' && (
            <Dropdown
              options={ShopOptions}
              onClick={(option) =>
                handleDropdownOptionSelected(
                  Section.Shop,
                  ShopRoutes[option].toString(),
                )
              }
            />
          )}
        </div>
      </div>
      <div className="hidden desktop:flex desktop:flex-row">
        <NavLink to={NOTIFICATIONS} className="cursor-pointer relative">
          <div className="flex h-[84px] items-center pt-1.5 px-2.5 relative">
            {notifications > 0 && (
              <div className="absolute top-7 right-2 h-2 w-2 rounded-full bg-clc-red" />
            )}
            <NotificationIcon
              className={`h-6 w-6 ${
                isSectionSelected(Section.Notifications)
                  ? 'fill-clc-blue'
                  : 'fill-med-gray'
              }`}
            />
          </div>
          <div className="absolute bottom-0 w-full h-[30px] desktop:hover:bg-pnav-hover" />
          <div
            className={`flex h-1.5 ${
              isSectionSelected(Section.Notifications) ? 'bg-clc-blue' : ''
            }`}
          />
        </NavLink>

        <div
          className="hidden desktop:flex flex-col cursor-pointer relative"
          onClick={() => handleDropdownClick('my-account')}
          onMouseEnter={() => handleDropdownClick('my-account')}
          onMouseLeave={() => handleHoverOut()}
        >
          <div ref={(ref) => registerDropdownRef('my-account', ref)}>
            <div className="flex h-[84px] w-16 items-center justify-center pt-1 px-0">
              <Avatar size="sm" imageUrl={profilePicture} />
            </div>
            <div className="absolute bottom-0 w-full h-[30px] hover:bg-pnav-hover" />
            <div
              className={`flex h-1.5 ${
                isSectionSelected(Section.MyAccount) ? 'bg-clc-blue' : ''
              }`}
            />
            {openDropdownId === 'my-account' && (
              <Dropdown
                options={getMyAccountOptionsBasedOnRole()}
                right={true}
                onClick={(option) =>
                  handleDropdownOptionSelected(
                    Section.MyAccount,
                    getMyAccountRoutesBasedOnRole()[option].toString(),
                  )
                }
              />
            )}
          </div>
        </div>
      </div>
      <div className="flex flex-col desktop:hidden cursor-pointer relative">
        <NavLink to={NOTIFICATIONS} className="cursor-pointer relative">
          <div className="flex h-[70px] w-[45px] items-center justify-center">
            {notifications > 0 && (
              <div className="absolute top-5 right-2 h-2 w-2 rounded-full bg-clc-red" />
            )}
            <NotificationIcon
              className={`h-6 w-6 ${
                isSectionSelected(Section.Notifications)
                  ? 'fill-clc-blue'
                  : 'fill-med-gray'
              }`}
            />
          </div>
          <div
            className={`absolute bottom-0 w-full h-1.5 ${
              isSectionSelected(Section.Notifications) ? 'bg-clc-blue' : ''
            }`}
          />
        </NavLink>
      </div>
      <div className="flex flex-col desktop:hidden">
        <div
          className="flex h-[70px] w-[45px] items-center justify-center cursor-pointer"
          onClick={openNavbarMobile}
        >
          <Avatar size="xs" imageUrl={profilePicture} />
        </div>
      </div>
      <div className="flex desktop:hidden">
        <div className="flex h-[70px] desktop:h-[84px] w-[45px] desktop:w-16 items-center justify-center desktop:pt-1 desktop:px-0">
          <MenuHamburgerIcon
            className="h-8 w-[45px] cursor-pointer"
            onClick={openNavbarMobile}
          />
        </div>
      </div>
    </div>
  );
};
