import { MY_PATIENTS_BIO_INFO_DERMSCORE_HISTORY } from 'utilities/routes';
import { Card } from '../Card';
import { MyPatientsScanImage } from './MyPatientsScanImage';
import { MyPatientsScanIndicators } from './MyPatientsScanIndicators';
import { MyPatientsDermSCOREScansProps } from 'app/my-patients/interfaces/DermSCOREScans.interface';
import { useNavigate } from 'react-router-dom';

export const MyPatientsDermSCOREScans: React.FC<
  MyPatientsDermSCOREScansProps
> = ({ scan, locale, count, patientId = null }) => {
  const navigate = useNavigate();
  const goToDermScores = () => {
    navigate(`../${MY_PATIENTS_BIO_INFO_DERMSCORE_HISTORY}`, {
      replace: true,
    });
  };

  return (
    <Card
      title={locale.dermSCOREScans}
      subtitle={String(count ?? locale.dermSCOREScansSubtitle)}
      buttonText={locale.viewAllButton}
      borderSubtitle
      customYPadding="py-0"
      buttonCTA={goToDermScores}
    >
      <div className="flex flex-col gap-2.5">
        <span className="text-zinc-800 text-sm font-bold uppercase leading-tight">
          {locale.mostRecent}
        </span>
        <div className="flex flex-col items-center desktop:items-stretch desktop:flex-row desktop:gap-7">
          <MyPatientsScanImage
            scan={scan}
            locale={locale}
            patientId={patientId}
          />
          <MyPatientsScanIndicators scan={scan} locale={locale} />
        </div>
      </div>
    </Card>
  );
};
