import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  InMemoryCache,
  NormalizedCacheObject,
  split,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { getTokenFromFirebase, userHasCustomClaims } from '../firebase/hooks';
import { createClient } from 'graphql-ws';
import React, { createContext, useContext } from 'react';
import { getHasuraConfig } from 'utilities/config';
import { Roles } from 'firebase/interfaces';
import { useAuth } from 'auth/context/AuthContext';

const { hasuraGraphQlApi, hasuraGraphQlWs } = getHasuraConfig();

type DataContexType = { client: ApolloClient<NormalizedCacheObject> };

const dataContext = createContext<DataContexType>({} as DataContexType);

export const useDataProvider = () => {
  const ctx = useContext(dataContext);
  if (!ctx.client)
    throw new Error(
      'useDataProvider must be called from within a DataProvider',
    );
  return ctx;
};

type DPType = React.FC<{ children: React.ReactNode; role?: Roles }>;

export const DataProvider: DPType = ({ children, role }) => {
  const { user } = useAuth();
  const httpLink = createHttpLink({
    uri: hasuraGraphQlApi,
  });

  const authLink = setContext(async (_, { headers }) => {
    const token = await getTokenFromFirebase();
    const hasCustomClaims = await userHasCustomClaims();
    const userRole = role ?? user?.role;

    return {
      headers: {
        ...headers,
        ...(hasCustomClaims && { Authorization: `Bearer ${token}` }),
        ...(userRole && { 'X-Hasura-Role': userRole }),
      },
    };
  });

  const wsLink = new GraphQLWsLink(
    createClient({
      url: hasuraGraphQlWs,
      connectionParams: async () => {
        const token = await getTokenFromFirebase();
        const hasCustomClaims = await userHasCustomClaims();

        return {
          headers: {
            ...(hasCustomClaims && { Authorization: `Bearer ${token}` }),
          },
        };
      },
    }),
  );

  const link = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      );
    },
    wsLink,
    authLink.concat(httpLink),
  );

  const client = new ApolloClient({
    link,
    cache: new InMemoryCache({
      typePolicies: {
        HasuraMedicationItem: {
          keyFields: ['id', 'responseType', 'value'],
        },
      },
    }),
  });

  return (
    <ApolloProvider client={client}>
      <dataContext.Provider value={{ client }}>{children}</dataContext.Provider>
    </ApolloProvider>
  );
};
