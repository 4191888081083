import { useState, useMemo, useContext, useEffect } from 'react';
import { DEFAULT_COUNTRY_CODE_NUMBER, pageIds } from 'utilities/constants';
import { useGetPage } from 'hooks/useGetPage';
import { useGetCountriesQuery } from 'graphql/generated/hasura';

import { AuthContext } from 'auth/context/AuthContext';
import { useLocation, useNavigate } from 'react-router-dom';
import { min7OnlyDigitsPattern } from 'utilities/variables';
import { normalizePhoneNumber } from 'utilities/functions';
import { useFirebaseSignOut } from '../../../firebase/hooks';
import { AUTH_LOGIN, AUTH_MFA_VERIFY } from 'utilities/routes';
import ButtonComponent from 'components/button/buttonComponent';
import Alert from 'components/alertComponent';
import MfaSmsComponent from 'components/multiFactorAuth/MfaSmsComponent';
import { SelectWithFlagsValues } from 'components/inputComponent';

export const MultiFactorAuthSetup = () => {
  const { setPhoneNumber, setCountry, user } = useContext(AuthContext);
  const [phoneError, setPhoneError] = useState<string>('');
  const [localPhoneNumber, setLocalPhoneNumber] = useState<string>('');
  const [localCountry, setLocalCountry] = useState<string>('');
  const [disableButton, setDisableButton] = useState<boolean>(true);
  const signOutUser = useFirebaseSignOut();
  const navigate = useNavigate();
  const location = useLocation();
  const locationEmail = location.state?.email;

  const { data: locale, loading } = useGetPage({
    locale: 'en',
    pageId: pageIds.MULTI_FACTOR_AUTH,
  });

  const { data } = useGetCountriesQuery({});

  const mappedCountriesValues = useMemo(
    () =>
      data?.countries
        ? [...data.countries]
            .sort((a, b) => {
              const codea = parseInt(a.code);
              const codeb = parseInt(b.code);

              if (codea < codeb) {
                return -1;
              } else if (codea > codeb) {
                return 1;
              } else {
                return 0;
              }
            })
            .map((country) => {
              return { code: country.code, flag: country.flag };
            })
        : [],
    [data?.countries],
  );

  const handleOnCountryChange = (country: SelectWithFlagsValues) => {
    setLocalCountry(country.value);
  };

  const validatePhoneNumber = (phoneNumber: string) =>
    new RegExp(min7OnlyDigitsPattern).test(phoneNumber);

  const handleOnPhoneNumberChange = (phoneNumber: string) => {
    const normalizedPhoneNumber = normalizePhoneNumber(phoneNumber);
    const validePhoneNumber = validatePhoneNumber(normalizedPhoneNumber);
    setPhoneError(!validePhoneNumber ? locale.phoneError : '');
    setDisableButton(!validePhoneNumber);
    setLocalPhoneNumber(normalizedPhoneNumber);
  };

  const handleSavePhoneNumber = async () => {
    if (!validatePhoneNumber(localPhoneNumber)) {
      setPhoneError(locale.phoneError);
      return;
    }

    if (localCountry) {
      setCountry(localCountry);
    }

    if (localPhoneNumber) {
      setPhoneNumber(localPhoneNumber);
    }

    navigate(AUTH_MFA_VERIFY, {
      state: {
        setupMode: true,
        phoneNumber: normalizePhoneNumber(localPhoneNumber),
        country:
          localCountry === 'null' ? DEFAULT_COUNTRY_CODE_NUMBER : localCountry,
        email: locationEmail,
      },
    });
  };

  useEffect(() => {
    if (!user || !user.email) {
      (async () => {
        await signOutUser();
        navigate(AUTH_LOGIN);
      })();
    }
  }, [user, signOutUser, navigate]);

  useEffect(() => {
    if (user && user.phoneNumber && user.country) {
      setLocalCountry(user.country);
      setLocalPhoneNumber(normalizePhoneNumber(user.phoneNumber));
      setDisableButton(false);
    }
  }, [user]);

  if (loading && !locale) return null;

  if (data?.countries.length && !localCountry) {
    setLocalCountry(data.countries[0].code);
  }

  return (
    <>
      <div className="bg-transparent shadow desktop:rounded-lg desktop:mb-0 desktop:p-0 desktop:w-full desktop:max-w-screen-xl desktop:mx-auto">
        <div className="w-full bg-white bg-border rounded-t-lg desktop:basis-3/5">
          <div className="flex flex-col gap-2.5 desktop:flex-row desktop:items-center desktop:justify-between px-5 py-5 desktop:px-[60px] desktop:pt-[30px]">
            <h3 className="text-h4 desktop:text-h3 text-dark-gray font-semibold">
              {locale.protectAccount}
            </h3>
          </div>

          <hr className="flex flex-row w-full items-center h-px bg-black-blur" />

          <div className="flex flex-col gap-2.5 px-5 py-[30px] desktop:px-[60px] desktop:pt-[30px]">
            <p className="text-med-gray font-semibold">
              {locale.securityImportant}
            </p>
            <h6 className="text-h7 text-dark-gray font-bold mb-[5px] desktop:mt-10 uppercase">
              {locale.mfaOptions}
            </h6>
            <p className="text-base text-med-gray mb-[15px]">
              <strong className="text-dark-gray">{locale.pleaseNote} </strong>
              <span className="text-dark-gray">{locale.note}</span>
            </p>

            {phoneError && (
              <div className="mb-[15px]">
                <Alert type="warning" text={phoneError} />
              </div>
            )}

            <MfaSmsComponent
              countries={mappedCountriesValues}
              country={localCountry}
              onCountryChange={handleOnCountryChange}
              onPhoneNumberChange={handleOnPhoneNumberChange}
              phoneNumber={localPhoneNumber}
            />

            <div className="flex flex-row w-full desktop:w-auto desktop:justify-end">
              <ButtonComponent
                disabled={disableButton}
                onClick={handleSavePhoneNumber}
              >
                {locale.saveButton}
              </ButtonComponent>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
