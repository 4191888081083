import { Card } from './Card';
import { SharedMediaProps } from '../interfaces/interfaces';
import { useHandleMediaClick } from 'hooks/useHandleMediaClick';
import { MY_PATIENTS_BIO_INFO_SHARED_MEDIA } from 'utilities/routes';
import { useNavigate } from 'react-router-dom';

export const SharedMedia: React.FC<SharedMediaProps> = ({
  locale,
  sharedMedia,
  total,
}) => {
  const title = locale.sharedMediaTitle;
  const subtitle = `${locale.showing} ${sharedMedia.length} ${locale.of} ${total}`;
  const [handleMediaClick] = useHandleMediaClick();
  const navigate = useNavigate();
  const goToSharedMedia = () => {
    navigate(`../${MY_PATIENTS_BIO_INFO_SHARED_MEDIA}`, {
      replace: true,
    });
  };

  return (
    <Card
      title={title}
      subtitle={subtitle}
      buttonText={locale.viewAllButton}
      customYPadding="py-0"
      buttonCTA={goToSharedMedia}
    >
      <div className="grid flex-grow grid-cols-3 grid-rows-auto auto-rows-fr gap-y-5 desktop:gap-y-[34px] gap-x-5 desktop:gap-x-[51px]">
        {sharedMedia.map((media) => (
          <div className="flex min-w-[70px]" key={media.id}>
            <img
              src={media.img}
              onClick={() => handleMediaClick(media)}
              alt={locale.sharedMediaAltImage}
              className="object-cover w-full min-w-[70px] aspect-square cursor-pointer"
            />
          </div>
        ))}
      </div>
    </Card>
  );
};
