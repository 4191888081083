import { ReactComponent as SmsIcon } from 'assets/icons/smsMfa.svg';
import { MfaPhoneInputComponent } from './MfaPhoneInputComponent';
import { pageIds } from 'utilities/constants';
import { useGetPage } from 'hooks/useGetPage';
import { SelectWithFlagsValues } from 'components/inputComponent';

interface MfaSmsComponentProps {
  country: string;
  countries: {
    code: string | null | undefined;
    flag: string | null | undefined;
  }[];
  onCountryChange: (country: SelectWithFlagsValues) => void;
  onPhoneNumberChange: (phoneNumber: string) => void;
  phoneNumber: string;
  marginBottom?: string;
}

const MfaSmsComponent: React.FC<MfaSmsComponentProps> = ({
  country,
  countries,
  onCountryChange,
  onPhoneNumberChange,
  phoneNumber,
  marginBottom = 'mb-5',
}) => {
  const { data: locale, loading } = useGetPage({
    locale: 'en',
    pageId: pageIds.MULTI_FACTOR_AUTH,
  });

  if (loading && !locale) return null;

  return (
    <div
      className={`bg-base-content px-7 py-6 rounded p-5 ${marginBottom} desktop:pb-[25px]`}
    >
      <div className="flex flex-col desktop:items-center desktop:flex-row desktop:justify-between">
        <div className="flex flex-row">
          <SmsIcon className="w-9 h-9 mt-0.5 desktop:hidden" />
          <SmsIcon className="w-[60px] h-[60px] hidden desktop:block" />
          <div className="flex flex-col desktop:ml-[21px]">
            <p className="text-base text-dark-gray font-semibold mt-2.5 ml-3.5 desktop:ml-0">
              {locale?.mfaSMS.byText}
            </p>

            <p className="text-sm text-med-gray hidden desktop:block">
              {locale?.mfaSMS.smsNote}
            </p>
          </div>
        </div>
        <p className="text-base text-med-gray mb-[10px] mt-2.5 desktop:hidden">
          {locale?.mfaSMS.smsNote}
        </p>
      </div>

      <MfaPhoneInputComponent
        country={country}
        countries={countries}
        onCountryChange={onCountryChange}
        onPhoneNumberChange={onPhoneNumberChange}
        phoneNumber={phoneNumber}
      />
    </div>
  );
};

export default MfaSmsComponent;
