import { ReactComponent as ChevronRightIcon } from 'assets/icons/chevron-right.svg';
import { useNavigate } from 'react-router-dom';
import { pageIds } from 'utilities/constants';
import { useGetPage } from 'hooks/useGetPage';
import { populateScanResult } from 'utilities/functions';
import { useEffect, useState } from 'react';
import {
  Skintelligent_Tests,
  GetSkintellingentTestByUserIdQuery,
} from 'graphql/generated/hasura';
import { useGetFhirSkintellingentTestsLazyQuery } from 'graphql/generated/remote-schema-hasura';
import { Scan } from '../interfaces/scan.interfaces';
import { MY_DERMSCORE_NEW_SCAN, MY_SKIN_DERMSCORE } from 'utilities/routes';
import ButtonComponent from 'components/button/buttonComponent';
import Numball from 'components/numballComponent';
import NoScans from './NoScans';
import ScansList from './ScansList';
import { useAuth } from 'auth/context/AuthContext';
import { useChannelSignal, SIGNAL_CHANNELS } from 'hooks/useChannelSignal';

const DermscoreWidget: React.FC = () => {
  const navigate = useNavigate();
  const [scans, setScans] = useState<Scan[]>([]);
  const [scansCount, setScansCount] = useState<number>(0);

  const [
    fhirGetSkintelligentTests,
    { loading: fhirLoadingSkintelligentTests, refetch },
  ] = useGetFhirSkintellingentTestsLazyQuery({
    fetchPolicy: 'network-only',
    variables: {
      limit: 3,
    },
    onCompleted: (data) => {
      setScansCount(data.getFHIRSkintelligentTests.total);
      const mappedFhirData =
        data.getFHIRSkintelligentTests.skintelligent_tests.map((test) => {
          return {
            ...test,
            skintelligent_tests_image_source_type: {
              value: test.image_source,
            },
            skintelligent_tests_test: {
              created_at: test.created_at,
            },
          };
        });

      populateScanData({
        skintelligent_tests: mappedFhirData,
      } as unknown as GetSkintellingentTestByUserIdQuery);
    },
  });

  useEffect(() => {
    fhirGetSkintelligentTests();
  }, [fhirGetSkintelligentTests]);

  const populateScanData = async (
    scans: GetSkintellingentTestByUserIdQuery,
  ) => {
    if (scans.skintelligent_tests.length) {
      const populatedScans: Scan[] = [];
      for (const scan of scans.skintelligent_tests) {
        const populatedScan = populateScanResult(scan as Skintelligent_Tests);
        populatedScans.push(populatedScan);
      }
      setScans(populatedScans);
    }
  };

  const { data: locale, loading } = useGetPage({
    locale: 'en',
    pageId: pageIds.DASHBOARD,
  });

  const { user } = useAuth();

  useChannelSignal(
    (first) => {
      if (!first) refetch();
    },
    SIGNAL_CHANNELS.TESTKIT,
    user?.uuid,
  );

  if (loading || !locale || fhirLoadingSkintelligentTests) return null;

  return (
    <div className="flex flex-col items-center px-[20px] pt-[20px] pb-[30px] desktop:p-[30px] gap-[20px] bg-white rounded-10">
      <div className="flex flex-col w-full items-start p-0 gap-2.5">
        <div className="hidden desktop:flex flex-row items-center p-0 gap-2.5">
          <div className="flex text-h5 text-dark-gray font-semibold desktop:text-h4">
            {locale?.dermscore?.scansTitle}
          </div>
          <Numball count={scansCount || 0} />
        </div>
        <div className="desktop:hidden flex text-h4 text-dark-gray font-semibold">
          {locale?.dermscore?.scansTitle}
        </div>
        <div className="desktop:hidden flex">
          <Numball count={scansCount || 0} />
        </div>
        <hr className="flex flex-row w-full items-center h-px bg-black-blur" />
      </div>

      <div className="flex flex-col w-full items-start p-0 gap-[20px]">
        {scans.length > 0 ? <ScansList scans={scans} /> : <NoScans />}
      </div>

      <div className="flex flex-col desktop:flex-row w-full justify-between items-center p-0 gap-[30px]">
        <ButtonComponent
          type="underline"
          Icon={ChevronRightIcon}
          iconPosition="right"
          iconWidth="w-[10px]"
          iconHeight="h-[18px]"
          onClick={() => navigate(MY_SKIN_DERMSCORE)}
        >
          {locale?.dermscore?.viewAllScans}
        </ButtonComponent>

        <div className="flex flex-row w-full desktop:w-auto">
          <ButtonComponent onClick={() => navigate(MY_DERMSCORE_NEW_SCAN)}>
            {locale?.dermscore?.createNewScan}
          </ButtonComponent>
        </div>
      </div>
    </div>
  );
};

export default DermscoreWidget;
