import { ReactComponent as FileDownloadIcon } from 'assets/icons/file-download.svg';
import { ReactComponent as PrintIcon } from 'assets/icons/print.svg';
import { ReactComponent as EyeIcon } from 'assets/icons/pwEyeShow.svg';
import { printPdf } from 'utilities/functions';
import { usePdfDownload } from 'hooks/usePdfDownload';
import { useIsMobile } from 'hooks/useIsMobile';
import {
  SharedMedia,
  SharedMediaModalLocationState,
} from '../interfaces/interfaces';
import { useNavigate } from 'react-router-dom';
import { PARAM_MODALS_IDENTIFIERS } from 'utilities/constants';

interface SharedMediaActionsProps {
  media: SharedMedia;
  alignIconsRight?: boolean;
  hidePrint?: boolean;
  hideShow?: boolean;
}

const SharedMediaActions: React.FC<SharedMediaActionsProps> = ({
  alignIconsRight = false,
  media,
  hidePrint = false,
  hideShow = false,
}) => {
  const alignIconsRightClass = alignIconsRight
    ? 'justify-end'
    : 'justify-center';

  const { downloadPdfWithURI } = usePdfDownload();
  const navigate = useNavigate();
  const isMobile = useIsMobile();

  const handleOnView = async () => {
    const state: SharedMediaModalLocationState = {
      mediaDate: media.date,
      mediaSrc: media.img,
      appointmentCodexId: media.appointmentId,
      bodySite: media.bodySite,
      description: media.description,
    };
    navigate(`.?${PARAM_MODALS_IDENTIFIERS.SHARED_MEDIA_MODAL_ID}=true`, {
      state,
    });
  };

  const handleOnDownload = async () => {
    downloadPdfWithURI(media.img);
  };

  const handleOnPrint = async () => {
    printPdf(media.img);
  };

  return (
    <div
      className={`flex flex-row w-full desktop:w-auto items-center justify-center desktop:${alignIconsRightClass} p-0 gap-2.5 desktop:gap-[2px]`}
    >
      {!hideShow && (
        <div className="flex flex-row items-center justify-center px-2.5 gap-2.5 rounded-10">
          <EyeIcon
            onClick={handleOnView}
            className="w-[18px] h-2.5 fill-current cursor-pointer"
          />
        </div>
      )}
      <div className="flex flex-row items-center justify-center px-2.5 gap-2.5 rounded-10">
        <FileDownloadIcon
          onClick={handleOnDownload}
          className="w-[15px] h-[18px] cursor-pointer fill-clc-blue"
        />
      </div>
      {!isMobile && !hidePrint && (
        <div className="flex flex-row items-center justify-center px-2.5 gap-2.5 rounded-10">
          <PrintIcon
            onClick={handleOnPrint}
            className="w-[17px] h-[16px] cursor-pointer fill-clc-blue"
          />
        </div>
      )}
    </div>
  );
};

export default SharedMediaActions;
