import { useGetPage } from 'hooks/useGetPage';
import {
  DEFAULT_COUNTRY_CODE_NUMBER,
  ISO_CODE_TO_EXCLUDE,
  NON_ALPHABETIC_REGEX_PRESERVES_SPACES_AND_CHARACTERS,
  STATES_TO_EXCLUDE,
  genericActionsIds,
  pageIds,
} from 'utilities/constants';
import { ReactComponent as ChevronDownIcon } from 'assets/icons/chevron-down.svg';
import { ReactComponent as ChevronUpIcon } from 'assets/icons/chevron-up.svg';
import {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useGetGenericActions } from 'hooks/useGetGenericActions';
import { useGetCountriesQuery } from 'graphql/generated/hasura';

import {
  GeneralStates,
  ProviderProfileDataOptional,
} from 'app/my-account/interfaces/profile.interfaces';
import {
  allowAllCharactersWithAllowedCharacters,
  allowOnlyNumbers,
  normalizePhoneNumber,
  parseMappedCountriesIntoSelectValues,
  parseMultipleValuesIntoSelectFormat,
  parsePhoneNumber,
  parseSelectedValue,
  parseValueIntoSelectFormat,
  scrollToTop,
} from 'utilities/functions';
import ButtonComponent from 'components/button/buttonComponent';
import { usePatchFhirProviderAccountInfoByCodexIdMutation } from 'graphql/generated/remote-schema-hasura';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { CodexFeatureFlags } from 'utilities/interfaces';
import { ProviderContactInfoProps, AddressInformation } from './interfaces';
import InputComponent, {
  SelectWithFlagsValues,
} from 'components/inputComponent';
import { PhoneDataProps } from 'components/dynamicQuestionnaire/interfaces/SignUpProviderModule.interface';
import {
  ALLOWED_SELECT_CHARACTERS,
  contactInfoOfficeAddressRequiredFields,
  contactInfoPersonalAddressRequiredFields,
} from '../constants';
import useFormValidation, { ValidationRules } from 'hooks/useFormValidation';
import ErrorMessageWithIcon from 'components/errorMessageWithIcon';
import Select, { SingleValue, components } from 'react-select';
import { DropdownItem } from 'components/dynamicQuestionnaire/interfaces/dynamicQuestionnaireResponse.interface';
import { parseIso } from 'components/dynamicQuestionnaire/utils/utils';
import { City, State } from 'country-state-city';

export const ContactInfo: React.FC<ProviderContactInfoProps> = ({
  office_information,
  address_information,
  handleProviderProfileInfoChange,
  handleUpdatedProviderContactInfo,
}) => {
  const [isSubmiting, setIsSubmiting] = useState(false);
  const { useFhirFlow } = useFlags<CodexFeatureFlags>();
  const { data: locale, loading } = useGetPage({
    locale: 'en',
    pageId: pageIds.MY_PROFILE,
  });
  const { data: genericAction, loading: genericActionLoading } =
    useGetGenericActions({
      locale: 'en',
      genericActionId: [genericActionsIds.UPDATE],
    });
  const [isFormDisplayed, setIsFormDisplayed] = useState<boolean>(true);
  const [formValues, setFormValues] = useState<ProviderProfileDataOptional>(
    () =>
      ({
        office_information,
        address_information,
      } as ProviderProfileDataOptional),
  );

  const [officeAddressInformation, setOfficeAddressInformation] =
    useState<AddressInformation>({
      city: formValues.office_information?.city ?? '',
      country: formValues.office_information?.country ?? '',
      state: formValues.office_information?.state ?? '',
    });

  const [personalAddressInformation, setPersonalAddressInformation] =
    useState<AddressInformation>({
      city: address_information?.city ?? '',
      country: address_information?.country ?? '',
      state: address_information?.state ?? '',
    });

  const [officeCountryIso, setOfficeCountryIso] = useState<string>(
    parseIso(officeAddressInformation.country) ?? '',
  );

  const [personalCountryIso, setPersonalCountryIso] = useState<string>(
    parseIso(personalAddressInformation.country) ?? '',
  );

  const [officeStateData, setOfficeStateData] = useState<string[]>([
    officeAddressInformation.state || '',
  ]);

  const [personalStateData, setPersonalStateData] = useState<string[]>([
    personalAddressInformation.state || '',
  ]);

  const [officeStateIso, setOfficeStateIso] = useState<string>(
    parseIso(officeAddressInformation.state) || '',
  );

  const [personalStateIso, setPersonalStateIso] = useState<string>(
    parseIso(personalAddressInformation.state) || '',
  );

  const [officeCityData, setOfficeCityData] = useState<string[]>([
    officeAddressInformation.city || '',
  ]);

  const [personalCityData, setPersonalCityData] = useState<string[]>([
    personalAddressInformation.city || '',
  ]);

  const [isOfficeCountryClicked, setIsOfficeCountryClicked] =
    useState<boolean>(false);

  const [isPersonalCountryClicked, setIsPersonalCountryClicked] =
    useState<boolean>(false);

  const [isOfficeStateClicked, setIsOfficeStateClicked] =
    useState<boolean>(false);

  const [isPersonalStateClicked, setIsPersonalStateClicked] =
    useState<boolean>(false);

  const [triggerCopyData, setTriggerCopyData] = useState(false);

  const evaluateSameOfficeAddressCheckboxStatus = () => {
    const { office_information, address_information } = formValues;

    if (
      office_information?.address1 &&
      office_information?.country &&
      office_information?.state &&
      office_information?.city &&
      office_information?.zipCode &&
      address_information?.address1 &&
      address_information?.country &&
      address_information?.state &&
      address_information?.city &&
      address_information?.zipCode
    ) {
      if (
        office_information.address1 === address_information.address1 &&
        office_information.country === address_information.country &&
        office_information.state === address_information.state &&
        office_information.city === address_information.city &&
        office_information.zipCode === address_information.zipCode
      ) {
        return true;
      }
    }

    return false;
  };

  const [
    isSameOfficeAddresCheckboxChecked,
    setIsSameOfficeAddresCheckboxChecked,
  ] = useState(evaluateSameOfficeAddressCheckboxStatus);

  const [patchFhirProviderAccountInfo] =
    usePatchFhirProviderAccountInfoByCodexIdMutation({});

  const handleShowForm = () => {
    setIsFormDisplayed(!isFormDisplayed);
  };

  const parsedPhoneNumber = parsePhoneNumber(
    formValues.office_information?.phone ?? '',
  );

  const [phoneData, setPhoneData] = useState<PhoneDataProps>({
    phone: parsedPhoneNumber?.number,
    country: parsedPhoneNumber?.country ?? DEFAULT_COUNTRY_CODE_NUMBER,
    extension: parsedPhoneNumber?.extension ?? '',
  });

  const handleOnInputChange = (
    e: ChangeEvent<HTMLInputElement>,
    level: string,
    property: string,
  ) => {
    const { value } = e.target;

    switch (level) {
      case 'office_information':
        switch (property) {
          case 'officeName':
          case 'address1':
          case 'address2':
            if (allowAllCharactersWithAllowedCharacters(value)) {
              if (isSameOfficeAddresCheckboxChecked) {
                setFormValues({
                  ...formValues,
                  office_information: {
                    ...formValues.office_information,
                    [property]: value,
                  },
                  address_information: {
                    ...formValues.address_information,
                    [property]: value,
                  },
                });
              } else {
                setFormValues({
                  ...formValues,
                  office_information: {
                    ...formValues.office_information,
                    [property]: value,
                  },
                });
              }
            }
            break;
          case 'zipCode':
            if (allowOnlyNumbers(value)) {
              if (isSameOfficeAddresCheckboxChecked) {
                setFormValues({
                  ...formValues,
                  office_information: {
                    ...formValues.office_information,
                    [property]: value,
                  },
                  address_information: {
                    ...formValues.address_information,
                    [property]: value,
                  },
                });
              } else {
                setFormValues({
                  ...formValues,
                  office_information: {
                    ...formValues.office_information,
                    [property]: value,
                  },
                });
              }
            }
            break;
          default:
            setFormValues({
              ...formValues,
              office_information: {
                ...formValues.office_information,
                [property]: value,
              },
            });
        }
        break;
      case 'address_information':
        switch (property) {
          case 'address1':
          case 'address2':
            if (allowAllCharactersWithAllowedCharacters(value)) {
              setFormValues({
                ...formValues,
                address_information: {
                  ...formValues.address_information,
                  [property]: value,
                },
              });
            }
            break;
          case 'zipCode':
            if (allowOnlyNumbers(value)) {
              setFormValues({
                ...formValues,
                address_information: {
                  ...formValues.address_information,
                  [property]: value,
                },
              });
            }
            break;
          default:
            setFormValues({
              ...formValues,
              address_information: {
                ...formValues.address_information,
                [property]: value,
              },
            });
        }
        break;
      default:
    }
  };

  const handlePhoneChange = (
    value: string,
    type: 'country' | 'phone' | 'extension',
  ) => {
    if (type === 'country') {
      setPhoneData({
        phone: phoneData.phone,
        country: value,
        extension: phoneData.extension,
      });
      const extensionPart = phoneData.extension
        ? ` ${phoneData.extension}`
        : '';
      const valueParsed = `+${value} ${phoneData.phone}${extensionPart}`;
      setFormValues({
        ...formValues,
        office_information: {
          ...formValues.office_information,
          phone: valueParsed,
        },
      });
    }

    if (type === 'phone') {
      setPhoneData({
        phone: normalizePhoneNumber(value),
        country: phoneData.country,
        extension: phoneData.extension,
      });
      const extensionPart = phoneData.extension
        ? ` ${phoneData.extension}`
        : '';
      const valueParsed = `+${phoneData.country} ${normalizePhoneNumber(
        value,
      )}${extensionPart}`;
      setFormValues({
        ...formValues,
        office_information: {
          ...formValues.office_information,
          phone: valueParsed,
        },
      });
    }

    if (type === 'extension') {
      setPhoneData({
        phone: phoneData.phone,
        country: phoneData.country,
        extension: normalizePhoneNumber(value),
      });
      const extensionPart =
        value.length > 0 ? ` ${normalizePhoneNumber(value)}` : '';
      const valueParsed = `+${phoneData.country} ${phoneData.phone}${extensionPart}`;
      setFormValues({
        ...formValues,
        office_information: {
          ...formValues.office_information,
          phone: valueParsed,
        },
      });
    }
  };

  const validationRules: ValidationRules = {
    office_name: [
      {
        validator: (value) => value.length > 0,
        message: locale?.providerLocales.fieldRequired,
      },
      {
        validator: (value) => value.length <= 99,
        message: locale?.providerLocales.maxCharactersReached,
      },
    ],
    office_phone: [
      {
        validator: (value) => value.length > 0,
        message: locale?.providerLocales.fieldRequired,
      },
    ],
    office_address_1: [
      {
        validator: (value) => value.length > 0,
        message: locale?.providerLocales.fieldRequired,
      },
      {
        validator: (value) => value.length <= 99,
        message: locale?.providerLocales.maxCharactersReached,
      },
    ],
    office_address_2: [
      {
        validator: (value) => value.length <= 99,
        message: locale?.providerLocales.maxCharactersReached,
      },
    ],
    office_zipCode: [
      {
        validator: (value) => value.length > 0,
        message: locale?.providerLocales.fieldRequired,
      },
      {
        validator: (value) => value.length === 5 || value.length === 9,
        message: locale?.providerLocales.zipCodeLength,
      },
    ],
    personal_address_1: [
      {
        validator: (value) => value.length > 0,
        message: locale?.providerLocales.fieldRequired,
      },
      {
        validator: (value) => value.length <= 99,
        message: locale?.providerLocales.maxCharactersReached,
      },
    ],
    personal_address_2: [
      {
        validator: (value) => value.length <= 99,
        message: locale?.providerLocales.maxCharactersReached,
      },
    ],
    personal_zipCode: [
      {
        validator: (value) => value.length > 0,
        message: locale?.providerLocales.fieldRequired,
      },
      {
        validator: (value) => value.length === 5 || value.length === 9,
        message: locale?.providerLocales.zipCodeLength,
      },
    ],
    office_country: [
      {
        validator: (value) => value.length > 0,
        message: locale?.providerLocales.fieldRequired,
      },
    ],
    office_state: [
      {
        validator: (value) => value.length > 0,
        message: locale?.providerLocales.fieldRequired,
      },
    ],
    office_city: [
      {
        validator: (value) => value.length > 0,
        message: locale?.providerLocales.fieldRequired,
      },
    ],
    personal_country: [
      {
        validator: (value) => value.length > 0,
        message: locale?.providerLocales.fieldRequired,
      },
    ],
    personal_state: [
      {
        validator: (value) => value.length > 0,
        message: locale?.providerLocales.fieldRequired,
      },
    ],
    personal_city: [
      {
        validator: (value) => value.length > 0,
        message: locale?.providerLocales.fieldRequired,
      },
    ],
  };

  const { errors, validateForm } = useFormValidation(validationRules);

  useEffect(() => {
    handleProviderProfileInfoChange(formValues);
  }, [formValues, handleProviderProfileInfoChange]);

  const handleValidation = useCallback(
    (providerData: ProviderProfileDataOptional) => {
      const formDataParsed = {
        office_name: providerData?.office_information?.officeName ?? '',
        office_email: providerData?.office_information?.email ?? '',
        office_phone: phoneData.phone ?? '',
        office_address_1: providerData?.office_information?.address1 ?? '',
        office_address_2: providerData?.office_information?.address2 ?? '',
        office_country: providerData?.office_information?.country ?? '',
        office_state: providerData?.office_information?.state ?? '',
        office_city: providerData?.office_information?.city ?? '',
        office_zipCode: providerData?.office_information?.zipCode ?? '',
        personal_address_1: providerData?.address_information?.address1 ?? '',
        personal_address_2: providerData?.address_information?.address2 ?? '',
        personal_country: providerData?.address_information?.country ?? '',
        personal_state: providerData?.address_information?.state ?? '',
        personal_city: providerData?.address_information?.city ?? '',
        personal_zipCode: providerData?.address_information?.zipCode ?? '',
      };
      return validateForm(formDataParsed);
    },
    [phoneData.phone, validateForm],
  );

  const prevLocalFormDataRef = useRef<ProviderProfileDataOptional | null>(null);
  const validationExecutedRef = useRef(false);

  const handleValidationCallback = useCallback(() => {
    // Check if the validation has already been executed
    if (!validationExecutedRef.current) {
      if (handleValidation) {
        handleValidation(formValues);
      }
      validationExecutedRef.current = true;
    }
  }, [formValues, handleValidation]);

  useEffect(() => {
    // Check if localFormData is different from the previous data
    if (formValues !== prevLocalFormDataRef.current) {
      // Update the ref with the latest current data
      prevLocalFormDataRef.current = formValues;

      // Reset the validation executed ref when localFormData changes
      validationExecutedRef.current = false;

      // Run validation when localFormData changes
      handleValidationCallback();
    }
  }, [formValues, handleValidationCallback]);

  const { data } = useGetCountriesQuery({});

  const mappedCountriesValues = useMemo(
    () =>
      data?.countries
        ? [...data.countries]
            .sort((a, b) => {
              const codea = parseInt(a.code);
              const codeb = parseInt(b.code);

              if (codea < codeb) {
                return -1;
              } else if (codea > codeb) {
                return 1;
              } else {
                return 0;
              }
            })
            .map((country) => {
              return { code: country.code, flag: country.flag };
            })
        : [],
    [data?.countries],
  );

  const getMemoizedCurrentCountryValue = useMemo(
    () =>
      phoneData?.country &&
      parseSelectedValue(phoneData.country as string, mappedCountriesValues),
    [mappedCountriesValues, phoneData],
  );

  const getMemoizedDefaultParsedValue = useMemo(
    () => parseMappedCountriesIntoSelectValues(mappedCountriesValues)[0],
    [mappedCountriesValues],
  );

  const getMemoizedParsedCountries = useMemo(
    () => parseMappedCountriesIntoSelectValues(mappedCountriesValues),
    [mappedCountriesValues],
  );

  const parsedOptionsCountry: DropdownItem[] = [
    { label: 'US - United States', value: 'US - United States' },
  ];

  const handleOnOfficeAddressSelectChange = (
    value: SingleValue<string | DropdownItem>,
    name: string,
  ) => {
    if (name === 'country') {
      let countryCode = '';
      let countryValue = '';

      if (typeof value !== 'string') {
        countryValue = value?.value ?? '';
      }

      if (typeof value === 'string') {
        countryCode = value.split(' - ')[0];
      } else {
        countryCode = value?.value.split(' - ')[0] ?? '';
      }

      setOfficeCountryIso(countryCode);
      const statesIso = State.getStatesOfCountry(countryCode).map(
        (state) => `${state.isoCode}`,
      );
      setOfficeStateIso(statesIso[0]);
      setOfficeAddressInformation({
        ...officeAddressInformation,
        country: countryValue,
        state: '',
        city: '',
      });
      setIsOfficeCountryClicked(true);
      setIsOfficeStateClicked(true);
      setFormValues({
        ...formValues,
        office_information: {
          ...formValues.office_information,
          country: countryValue,
          state: '',
          city: '',
        },
      });
    }
    if (name === 'state') {
      let stateCode = '';
      let stateValue = '';
      if (typeof value !== 'string') {
        stateValue = value?.value ?? '';
      }

      if (typeof value === 'string') {
        stateCode = value.split(' - ')[0];
      } else {
        stateCode = value?.value.split(' - ')[0] ?? '';
      }
      setOfficeStateIso(stateCode);
      setOfficeAddressInformation({
        ...officeAddressInformation,
        state: stateValue,
      });
      setIsOfficeStateClicked(true);
      setFormValues({
        ...formValues,
        office_information: {
          ...formValues.office_information,
          state: stateValue,
          city: '',
        },
      });
    }
    if (name === 'city') {
      const cityValue =
        typeof value === 'string'
          ? value.replace(
              NON_ALPHABETIC_REGEX_PRESERVES_SPACES_AND_CHARACTERS,
              '',
            )
          : value?.value?.replace(
              NON_ALPHABETIC_REGEX_PRESERVES_SPACES_AND_CHARACTERS,
              '',
            );

      setOfficeAddressInformation({
        ...officeAddressInformation,
        city: cityValue as string,
      });
      setFormValues({
        ...formValues,
        office_information: {
          ...formValues.office_information,
          city: cityValue,
        },
      });
    }
  };

  const handleOnPersonalAddressSelectChange = (
    value: SingleValue<string | DropdownItem>,
    name: string,
  ) => {
    if (name === 'country') {
      let countryCode = '';
      let countryValue = '';

      if (typeof value !== 'string') {
        countryValue = value?.value ?? '';
      }

      if (typeof value === 'string') {
        countryCode = value.split(' - ')[0];
      } else {
        countryCode = value?.value.split(' - ')[0] ?? '';
      }

      setPersonalCountryIso(countryCode);
      const statesIso = State.getStatesOfCountry(countryCode).map(
        (state) => `${state.isoCode}`,
      );
      setPersonalStateIso(statesIso[0]);
      setPersonalAddressInformation({
        ...personalAddressInformation,
        country: countryValue,
        state: '',
        city: '',
      });
      setIsPersonalCountryClicked(true);
      setIsPersonalStateClicked(true);
      setFormValues({
        ...formValues,
        address_information: {
          ...formValues.address_information,
          country: countryValue,
          state: '',
          city: '',
        },
      });
    }
    if (name === 'state') {
      let stateCode = '';
      let stateValue = '';
      if (typeof value !== 'string') {
        stateValue = value?.value ?? '';
      }

      if (typeof value === 'string') {
        stateCode = value.split(' - ')[0];
      } else {
        stateCode = value?.value.split(' - ')[0] ?? '';
      }
      setPersonalStateIso(stateCode);
      setPersonalAddressInformation({
        ...personalAddressInformation,
        state: stateValue,
      });
      setIsPersonalStateClicked(true);
      setFormValues({
        ...formValues,
        address_information: {
          ...formValues.address_information,
          state: stateValue,
        },
      });
    }
    if (name === 'city') {
      const cityValue =
        typeof value === 'string'
          ? value.replace(
              NON_ALPHABETIC_REGEX_PRESERVES_SPACES_AND_CHARACTERS,
              '',
            )
          : value?.value?.replace(
              NON_ALPHABETIC_REGEX_PRESERVES_SPACES_AND_CHARACTERS,
              '',
            );

      setPersonalAddressInformation({
        ...personalAddressInformation,
        city: cityValue as string,
      });
      setFormValues({
        ...formValues,
        address_information: {
          ...formValues.address_information,
          city: cityValue,
        },
      });
    }
  };

  const getValueForSelect = (type: string, level: string) => {
    if (level === 'office_information') {
      switch (type) {
        case 'country':
          return parseValueIntoSelectFormat(
            officeAddressInformation.country as string,
          ) as DropdownItem;
        case 'state':
          return parseValueIntoSelectFormat(
            officeAddressInformation.state as string,
          ) as DropdownItem;
        case 'city':
          return parseValueIntoSelectFormat(
            officeAddressInformation.city as string,
          ) as DropdownItem;

        default:
          return { label: '', value: '' };
      }
    } else if (level === 'address_information') {
      switch (type) {
        case 'country':
          return parseValueIntoSelectFormat(
            personalAddressInformation.country as string,
          ) as DropdownItem;
        case 'state':
          return parseValueIntoSelectFormat(
            personalAddressInformation.state as string,
          ) as DropdownItem;
        case 'city':
          return parseValueIntoSelectFormat(
            personalAddressInformation.city as string,
          ) as DropdownItem;
        default:
          return { label: '', value: '' };
      }
    }
  };

  const officeInfoFields = [
    {
      label: locale?.providerLocales?.officeName,
      type: 'text',
      placeholder: locale?.personalInfo.accountDetails.firstNamePlaceholder,
      name: 'office_information_office_name',
      value: formValues.office_information?.officeName,
      onChange: handleOnInputChange,
      maxLengthValue: 100,
      level: 'office_information',
      property: 'officeName',
      errorProperty: 'office_name',
    },
    {
      label: locale?.personalInfo.accountDetails.emailLabel,
      type: 'text',
      placeholder: locale?.personalInfo.accountDetails.emailPlaceholder,
      name: 'office_information_email',
      value: formValues.office_information?.email,
      onChange: handleOnInputChange,
      isDisabled: true,
      level: 'office_information',
      property: 'email',
    },
    {
      label: locale?.contactInfo.phoneLabel,
      type: 'text',
      name: 'office_information_phone',
      value: phoneData?.phone,
      onChange: handleOnInputChange,
      level: 'office_information',
      errorProperty: 'office_phone',
    },
    {
      label: locale?.contactInfo.address1Label,
      type: 'text',
      name: 'office_information_address_1',
      value: formValues.office_information?.address1,
      onChange: handleOnInputChange,
      level: 'office_information',
      property: 'address1',
      errorProperty: 'office_address_1',
      maxLengthValue: 100,
    },
    {
      label: locale?.contactInfo.address2Label,
      type: 'text',
      name: 'office_information_address_2',
      value: formValues.office_information?.address2,
      onChange: handleOnInputChange,
      level: 'office_information',
      property: 'address2',
      errorProperty: 'office_address_2',
      disableRequired: true,
      maxLengthValue: 100,
    },
    {
      label: locale?.contactInfo.countryLabel,
      type: 'select',
      name: 'office_information_country',
      options: parsedOptionsCountry,
      onChangeType: 'country',
      level: 'office_information',
      property: 'country',
      errorProperty: 'office_country',
      maxLengthValue: 100,
    },
    {
      label: locale?.contactInfo.stateLabel,
      type: 'select',
      name: 'office_information_state',
      options: parseMultipleValuesIntoSelectFormat(officeStateData),
      onChangeType: 'state',
      level: 'office_information',
      property: 'state',
      errorProperty: 'office_state',
      maxLengthValue: 100,
    },
    {
      label: locale?.contactInfo.cityLabel,
      type: 'select',
      name: 'office_information_city',
      options: parseMultipleValuesIntoSelectFormat(officeCityData),
      onChangeType: 'city',
      level: 'office_information',
      property: 'city',
      errorProperty: 'office_city',
      maxLengthValue: 100,
    },
    {
      label: locale?.contactInfo.zipLabel,
      type: 'text',
      name: 'office_information_zip_code',
      value: formValues.office_information?.zipCode,
      onChange: handleOnInputChange,
      level: 'office_information',
      property: 'zipCode',
      errorProperty: 'office_zipCode',
      maxLengthValue: 9,
    },
  ];

  const contactInfoFields = [
    {
      label: locale?.contactInfo.address1Label,
      type: 'text',
      name: 'address_information_address_1',
      value: formValues.address_information?.address1,
      onChange: handleOnInputChange,
      level: 'address_information',
      property: 'address1',
      errorProperty: 'personal_address_1',
      maxLengthValue: 100,
    },
    {
      label: locale?.contactInfo.address2Label,
      type: 'text',
      name: 'address_information_address_2',
      value: formValues.address_information?.address2,
      onChange: handleOnInputChange,
      level: 'address_information',
      errorProperty: 'personal_address_2',
      property: 'address2',
      disableRequired: true,
      maxLengthValue: 100,
    },
    {
      label: locale?.contactInfo.countryLabel,
      type: 'select',
      name: 'address_information_country',
      options: parsedOptionsCountry,
      level: 'address_information',
      property: 'country',
      onChangeType: 'country',
      errorProperty: 'personal_country',
      maxLengthValue: 100,
    },
    {
      label: locale?.contactInfo.stateLabel,
      type: 'select',
      name: 'address_information_state',
      options: parseMultipleValuesIntoSelectFormat(personalStateData),
      level: 'address_information',
      property: 'state',
      onChangeType: 'state',
      errorProperty: 'personal_state',
      maxLengthValue: 100,
    },
    {
      label: locale?.contactInfo.cityLabel,
      type: 'select',
      name: 'address_information_city',
      options: parseMultipleValuesIntoSelectFormat(personalCityData),
      level: 'address_information',
      property: 'city',
      onChangeType: 'city',
      errorProperty: 'personal_city',
      maxLengthValue: 100,
    },
    {
      label: locale?.contactInfo.zipLabel,
      type: 'text',
      name: 'address_information_zip_code',
      value: formValues.address_information?.zipCode,
      onChange: handleOnInputChange,
      level: 'address_information',
      property: 'zipCode',
      errorProperty: 'personal_zipCode',
      maxLengthValue: 9,
    },
  ];

  const validateRequiredFields = (): boolean => {
    const requiredOfficeKeys = contactInfoOfficeAddressRequiredFields;
    const requiredAddressKeys = contactInfoPersonalAddressRequiredFields;

    const officeInfo = formValues.office_information as
      | {
          officeName?: string;
          email?: string;
          phone?: string;
          address1?: string;
          country?: string;
          city?: string;
          state?: string;
          zipCode?: string;
        }
      | undefined;

    const addressInfo = formValues.address_information as
      | {
          address1?: string;
          country?: string;
          city?: string;
          state?: string;
          zipCode?: string;
        }
      | undefined;

    if (officeInfo) {
      for (const key of requiredOfficeKeys) {
        const value = officeInfo[key as keyof typeof officeInfo];
        if (value === undefined || value.trim() === '') {
          return false;
        }
      }
    } else {
      return false;
    }
    if (addressInfo) {
      for (const key of requiredAddressKeys) {
        const value = addressInfo[key as keyof typeof addressInfo];
        if (value === undefined || value.trim() === '') {
          return false;
        }
      }
    }

    return true;
  };

  const handleOnSubmit = async () => {
    const isFormValid = validateRequiredFields();
    if (!isFormValid)
      return handleUpdatedProviderContactInfo(GeneralStates.MISSING_INFO);

    try {
      setIsSubmiting(true);
      if (useFhirFlow) {
        const response = await patchFhirProviderAccountInfo({
          variables: {
            providerAccountInfo: {
              officeInformation: {
                officeName: formValues.office_information?.officeName ?? '',
                email: formValues.office_information?.email ?? '',
                phone: formValues.office_information?.phone ?? '',
                address1: formValues.office_information?.address1 ?? '',
                address2: formValues.office_information?.address2 ?? '',
                country: formValues.office_information?.country ?? '',
                state: formValues.office_information?.state ?? '',
                city: formValues.office_information?.city ?? '',
                zipCode: formValues.office_information?.zipCode ?? '',
              },
              addressInformation: {
                address1: formValues.address_information?.address1 ?? '',
                address2: formValues.address_information?.address2 ?? '',
                country: formValues.address_information?.country ?? '',
                state: formValues.address_information?.state ?? '',
                city: formValues.address_information?.city ?? '',
                zipCode: formValues.address_information?.zipCode ?? '',
              },
            },
          },
        });
        if (!response.data) {
          setIsSubmiting(false);
          handleUpdatedProviderContactInfo(GeneralStates.ERROR);
          scrollToTop();
          throw new Error('Failed to update provider');
        }
      }

      handleUpdatedProviderContactInfo(GeneralStates.SUCCESS);

      setIsSubmiting(false);
      scrollToTop();
    } catch (error: unknown) {
      setIsSubmiting(false);
      console.log('There has been an error while updating the provider', error);
      handleUpdatedProviderContactInfo(GeneralStates.ERROR);
      scrollToTop();
      throw new Error('Failed to update provider');
    }
  };

  const handleAddressSelectChange = (
    e: { label?: string; value?: string } | undefined,
    onChangeType: string,
    level: string,
  ) => {
    const addressData = {
      label: e?.label ?? '',
      value: e?.value ?? '',
    };

    if (level === 'office_information') {
      handleOnOfficeAddressSelectChange(
        addressData as DropdownItem,
        onChangeType,
      );
    } else {
      handleOnPersonalAddressSelectChange(
        addressData as DropdownItem,
        onChangeType,
      );
    }
  };

  const handleCheckboxCopyData = (checked: boolean) => {
    setIsSameOfficeAddresCheckboxChecked(!checked);
    setTriggerCopyData(true);
  };

  const allowOnlyCharactersInCityField = (
    event: React.KeyboardEvent<HTMLDivElement>,
  ) => {
    if (!ALLOWED_SELECT_CHARACTERS.test(event.key)) {
      event.preventDefault();
    }
  };

  const prevIsSameCheckboxChecked = useRef(isSameOfficeAddresCheckboxChecked);

  useEffect(() => {
    if (
      prevIsSameCheckboxChecked.current !== isSameOfficeAddresCheckboxChecked
    ) {
      prevIsSameCheckboxChecked.current = isSameOfficeAddresCheckboxChecked;

      if (isSameOfficeAddresCheckboxChecked === true) {
        setTriggerCopyData(true);
        setPersonalAddressInformation({
          country: officeAddressInformation.country,
          state: officeAddressInformation.state,
          city: officeAddressInformation.city,
        });
        setPersonalStateData(officeStateData);
        setPersonalCityData(officeCityData);
        setFormValues({
          ...formValues,
          address_information: {
            ...formValues.address_information,
            address1: formValues.office_information?.address1,
            address2: formValues.office_information?.address2,
            zipCode: formValues.office_information?.zipCode,
            country: officeAddressInformation.country as string,
            state: officeAddressInformation.state,
            city: officeAddressInformation.city,
          },
        });
      }

      if (isSameOfficeAddresCheckboxChecked === false) {
        setTriggerCopyData(false);
        setPersonalAddressInformation({
          country: '',
          state: '',
          city: '',
        });
        setPersonalStateData([]);
        setPersonalCityData([]);
        setFormValues({
          ...formValues,
          address_information: {
            address1: '',
            address2: '',
            country: '',
            state: '',
            city: '',
            zipCode: '',
          },
        });
      }
    }
  }, [
    isSameOfficeAddresCheckboxChecked,
    officeAddressInformation,
    officeStateData,
    officeCityData,
    formValues,
  ]);

  useEffect(() => {
    if (officeCountryIso) {
      const states = State.getStatesOfCountry(officeCountryIso)
        .filter(
          (state) =>
            !state.isoCode.includes(ISO_CODE_TO_EXCLUDE) &&
            !STATES_TO_EXCLUDE.includes(state.name),
        )
        .map((state) => `${state.isoCode} - ${state.name}`);

      setOfficeStateData(states);
      if (isOfficeCountryClicked) {
        const stateCode = states?.[0]?.split(' - ')[0] || '';
        setOfficeStateIso(stateCode);
        setOfficeAddressInformation((prevFormValues) => ({
          ...prevFormValues,
          addressInformation: {
            ...prevFormValues,
            state: states?.[0] || '',
            city: '',
          },
        }));
        setIsOfficeCountryClicked(false);
        setIsOfficeStateClicked(false);
      }
    } else {
      setOfficeStateData([]);
      setOfficeCityData([]);
      setFormValues({
        ...formValues,
        office_information: {
          ...formValues.office_information,
          state: '',
          city: '',
        },
      });
    }
  }, [officeCountryIso, isOfficeCountryClicked, formValues]);

  useEffect(() => {
    if (personalCountryIso !== '') {
      const states = State.getStatesOfCountry(personalCountryIso)
        .filter(
          (state) =>
            !state.isoCode.includes(ISO_CODE_TO_EXCLUDE) &&
            !STATES_TO_EXCLUDE.includes(state.name),
        )
        .map((state) => `${state.isoCode} - ${state.name}`);

      setPersonalStateData(states);
      if (isPersonalCountryClicked) {
        const stateCode = states?.[0]?.split(' - ')[0] || '';
        setPersonalStateIso(stateCode);
        setPersonalAddressInformation((prevFormValues) => ({
          ...prevFormValues,
          addressInformation: {
            ...prevFormValues,
            state: states?.[0] || '',
            city: '',
          },
        }));
        setIsPersonalCountryClicked(false);
        setIsPersonalStateClicked(false);
        setTriggerCopyData(true);
      }
    } else {
      setPersonalStateData([]);
      setPersonalCityData([]);
      setFormValues({
        ...formValues,
        address_information: {
          ...formValues.address_information,
          state: '',
          city: '',
        },
      });
    }
  }, [personalCountryIso, isPersonalCountryClicked, formValues]);

  useEffect(() => {
    if (officeCountryIso && officeStateIso) {
      const cities = City.getCitiesOfState(
        officeCountryIso,
        officeStateIso,
      ).map((city) => `${city.name}`);
      setOfficeCityData(cities);
      if (isOfficeStateClicked) {
        setOfficeAddressInformation({
          ...officeAddressInformation,
          city: '',
        });
        setFormValues({
          ...formValues,
          office_information: {
            ...formValues.office_information,
            city: '',
          },
        });
        setIsOfficeStateClicked(false);
        setTriggerCopyData(true);
      }
    } else {
      setOfficeCityData([]);
      setFormValues({
        ...formValues,
        office_information: {
          ...formValues.office_information,
          city: '',
        },
      });
    }
  }, [
    officeStateIso,
    officeCountryIso,
    isOfficeStateClicked,
    officeAddressInformation,
    formValues,
  ]);

  useEffect(() => {
    if (personalCountryIso !== '' && personalStateIso !== '') {
      const cities = City.getCitiesOfState(
        personalCountryIso,
        personalStateIso,
      ).map((city) => `${city.name}`);
      setPersonalCityData(cities);
      if (isPersonalStateClicked) {
        setPersonalAddressInformation({
          ...personalAddressInformation,
          city: '',
        });
        setFormValues({
          ...formValues,
          address_information: {
            ...formValues.address_information,
            city: '',
          },
        });
        setIsPersonalStateClicked(false);
        setTriggerCopyData(true);
      }
    } else {
      setPersonalCityData([]);
      setFormValues({
        ...formValues,
        address_information: {
          ...formValues.address_information,
          city: '',
        },
      });
    }
  }, [
    personalStateIso,
    personalCountryIso,
    isPersonalStateClicked,
    personalAddressInformation,
    formValues,
  ]);

  useEffect(() => {
    if (triggerCopyData) {
      if (isSameOfficeAddresCheckboxChecked) {
        setPersonalAddressInformation({
          country: formValues.office_information?.country as string,
          state: formValues.office_information?.state as string,
          city: formValues.office_information?.city as string,
        });
        setFormValues({
          ...formValues,
          address_information: {
            ...formValues.address_information,
            address1: formValues?.office_information?.address1,
            address2: formValues?.office_information?.address2,
            country: formValues?.office_information?.country,
            state: formValues?.office_information?.state,
            city: formValues?.office_information?.city,
            zipCode: formValues?.office_information?.zipCode,
          },
        });

        setTriggerCopyData(false);
      }
    }
  }, [
    formValues,
    isSameOfficeAddresCheckboxChecked,
    officeAddressInformation.city,
    officeAddressInformation.country,
    officeAddressInformation.state,
    triggerCopyData,
  ]);

  if (loading || !locale || genericActionLoading || !genericAction) return null;

  return (
    <div className="flex flex-col items-center bg-white w-full p-5 desktop:p-[30px] gap-5 rounded-10">
      <div
        className="flex flex-col items-start w-full gap-2.5 p-0 cursor-pointer desktop:cursor-default"
        onClick={handleShowForm}
      >
        <div className="flex flex-row w-full items-center justify-between">
          <div className="flex flex-row items-center">
            <h3 className="text-h5 desktop:text-h4 text-dark-gray font-semibold desktop:mr-2.5">
              {locale?.providerLocales?.contactInfoSection}
            </h3>
          </div>
          {isFormDisplayed ? (
            <ChevronUpIcon
              className="desktop:hidden w-[18px] h-2.5 fill-current cursor-pointer"
              onClick={handleShowForm}
            />
          ) : (
            <ChevronDownIcon
              className="desktop:hidden w-[18px] h-2.5 fill-current cursor-pointer"
              onClick={handleShowForm}
            />
          )}
        </div>
        <hr
          className={`desktop:flex flex-row w-full items-center h-px bg-black-blur ${
            isFormDisplayed ? 'flex' : 'hidden'
          }`}
        />
      </div>

      <div
        className={`w-full desktop:flex flex-col gap-[15px] ${
          isFormDisplayed ? 'flex' : 'hidden'
        }`}
      >
        <div className="flex flex-col items-center w-full">
          <div className="my-[15px] w-full items-start">
            <p className="text-h7 font-bold uppercase mb-[15px]">
              {locale?.providerLocales?.officeDetails}
            </p>
          </div>
          {officeInfoFields.map((field) => (
            <div
              key={field.name}
              className="flex flex-col desktop:flex-row w-full desktop:w-[690px] items-center desktop:items-center"
            >
              <label
                className="w-full desktop:w-3/12 text-base text-dark-gray font-semibold mb-2"
                htmlFor={field.name}
              >
                {field.label}
                {field.disableRequired !== true && (
                  <span className="text-base font-bold text-clc-red">
                    {locale?.accountDeletion.form.required}
                  </span>
                )}
              </label>
              <div className="w-full desktop:w-9/12">
                {field.name === 'office_information_phone' ? (
                  <div className="flex desktop:flex-row flex-col">
                    <div className="flex w-full mr-5">
                      <div className="desktop:max-w-full">
                        <InputComponent
                          testID="phoneNumber-country-select"
                          type="select-with-country-icons"
                          name="country"
                          borderRadiusRight={true}
                          selectInputWithFlagsProps={{
                            currentValue:
                              getMemoizedCurrentCountryValue as SelectWithFlagsValues,
                            defaultValue:
                              getMemoizedDefaultParsedValue as SelectWithFlagsValues,
                            options:
                              getMemoizedParsedCountries as SelectWithFlagsValues[],
                            onSelectChange: (e) => {
                              handlePhoneChange(e.label, 'country');
                            },
                          }}
                        />
                      </div>
                      <div className="w-full min-w-[105px] desktop:max-w-[195px]">
                        <InputComponent
                          value={phoneData?.phone}
                          testID="phoneNumber-input"
                          type="tel"
                          name="phoneNumber"
                          flexGrow={1}
                          borderRadiusLeft={true}
                          onChange={(e) => {
                            handlePhoneChange(e.target.value, 'phone');
                          }}
                          errorStatus={!!errors.office_phone}
                        />
                        {errors.office_phone && (
                          <ErrorMessageWithIcon message={errors.office_phone} />
                        )}
                      </div>
                    </div>
                    <div className="flex">
                      <label
                        className="mt-3 mb-2.5 mr-2.5 text-base text-dark-gray font-bold desktop:w-2/12 desktop:mb-2"
                        htmlFor="phoneNumberExtension"
                      >
                        {locale?.providerLocales?.phoneExtension}
                      </label>
                      <div className="flex w-full desktop:max-w-[130px] max-h-[58px]">
                        <InputComponent
                          value={phoneData?.extension as string}
                          flexGrow={1}
                          type="ext"
                          name="phoneNumberExtension"
                          maxLengthValue={4}
                          onChange={(e) => {
                            handlePhoneChange(e.target.value, 'extension');
                          }}
                        />
                      </div>
                    </div>
                  </div>
                ) : field.type === 'select' ? (
                  <div className="mb-2.5">
                    <Select
                      className="dropdown"
                      classNamePrefix={
                        errors[field.errorProperty as string]
                          ? 'react-select-with-error'
                          : 'react-select'
                      }
                      placeholder={locale?.selectLanguagePlaceholder}
                      value={getValueForSelect(
                        field.onChangeType as string,
                        field.level,
                      )}
                      options={field.options}
                      onChange={(e) => {
                        setTriggerCopyData(true);
                        handleAddressSelectChange(
                          {
                            label: e?.label ?? '',
                            value: e?.value ?? '',
                          } as DropdownItem,
                          field.onChangeType as string,
                          field.level,
                        );
                      }}
                      isMulti={false}
                      noOptionsMessage={() => locale?.selectLanguageNoOptions}
                      components={{
                        Input: (props) => (
                          <components.Input
                            {...props}
                            maxLength={field.maxLengthValue}
                          />
                        ),
                      }}
                      onKeyDown={allowOnlyCharactersInCityField}
                    />
                    {errors[field.errorProperty as string] && (
                      <ErrorMessageWithIcon
                        message={
                          errors[field.errorProperty as string] as string
                        }
                      />
                    )}
                  </div>
                ) : (
                  <div
                    className={`${
                      field.property === 'zipCode' ? 'w-[160px]' : 'w-full'
                    }`}
                  >
                    <InputComponent
                      type={field.type}
                      name={field.name}
                      value={field.value as string}
                      onChange={(e) => {
                        field &&
                          field.onChange &&
                          field.onChange(e, field.level, field.property ?? '');
                      }}
                      isDisabled={field.isDisabled}
                      maxLengthValue={field.maxLengthValue}
                      errorStatus={!!errors[field.errorProperty as string]}
                      errorMsgWithIcon={errors[field.errorProperty as string]}
                    />
                  </div>
                )}
              </div>
            </div>
          ))}
          <hr className="flex flex-row w-full items-center h-px bg-black-blur my-[25px] desktop:w-[690px]" />
          <div className="desktop:w-[690px]">
            <div className="mb-[15px]">
              <p className="text-h7 font-bold uppercase mb-[15px]">
                {locale?.providerLocales?.contactInfoTitle}
              </p>
              <p className="text-med-gray text-base">
                {locale?.providerLocales?.contactInfoParagraph}
              </p>
            </div>

            <div className="flex items-center mb-5">
              <InputComponent
                type="checkbox"
                checkboxProps={{
                  checked: isSameOfficeAddresCheckboxChecked,
                  onCheckboxChange: handleCheckboxCopyData,
                }}
              />
              <p className="font-semibold text-med-gray text-sm ml-[10px]">
                {locale?.providerLocales?.checkbox}
              </p>
            </div>
          </div>
          {contactInfoFields.map((field) => (
            <div
              key={field.name}
              className="flex flex-col desktop:flex-row w-full desktop:w-[690px] items-center desktop:items-center"
            >
              <label
                className="w-full desktop:w-3/12 text-base text-dark-gray font-semibold mb-2"
                htmlFor={field.name}
              >
                {field.label}
                {field.disableRequired !== true && (
                  <span className="text-base font-bold text-clc-red">
                    {locale?.accountDeletion.form.required}
                  </span>
                )}
              </label>
              <div className="w-full desktop:w-9/12">
                {field.name === 'address_information_phone' ? (
                  <div className="flex desktop:flex-row flex-col">
                    <div className="flex w-full mr-5">
                      <div className="desktop:max-w-full">
                        <InputComponent
                          testID="phoneNumber-country-select"
                          type="select-with-country-icons"
                          name="country"
                          borderRadiusRight={true}
                          selectInputWithFlagsProps={{
                            currentValue:
                              getMemoizedCurrentCountryValue as SelectWithFlagsValues,
                            defaultValue:
                              getMemoizedDefaultParsedValue as SelectWithFlagsValues,
                            options:
                              getMemoizedParsedCountries as SelectWithFlagsValues[],
                            onSelectChange: (e) => {
                              handlePhoneChange(e.label, 'country');
                            },
                          }}
                        />
                      </div>
                      <div className="w-full min-w-[105px] desktop:max-w-[195px]">
                        <InputComponent
                          value={phoneData?.phone}
                          testID="phoneNumber-input"
                          type="tel"
                          name="phoneNumber"
                          flexGrow={1}
                          borderRadiusLeft={true}
                          onChange={(e) => {
                            handlePhoneChange(e.target.value, 'phone');
                          }}
                          errorStatus={!!errors.phone}
                        />
                        {errors.phone && (
                          <ErrorMessageWithIcon message={errors.phone} />
                        )}
                      </div>
                    </div>
                    <div className="flex">
                      <label
                        className="mt-3 mb-2.5 mr-2.5 text-base text-dark-gray font-bold desktop:w-2/12 desktop:mb-2"
                        htmlFor="phoneNumberExtension"
                      >
                        {locale?.providerLocales?.phoneExtension}
                      </label>
                      <div className="flex w-full desktop:max-w-[130px] max-h-[58px]">
                        <InputComponent
                          value={phoneData?.extension as string}
                          flexGrow={1}
                          type="ext"
                          name="phoneNumberExtension"
                          maxLengthValue={4}
                          onChange={(e) => {
                            handlePhoneChange(e.target.value, 'extension');
                          }}
                        />
                      </div>
                    </div>
                  </div>
                ) : field.type === 'select' ? (
                  <div className="mb-2.5">
                    <Select
                      isDisabled={isSameOfficeAddresCheckboxChecked}
                      className="dropdown"
                      classNamePrefix={
                        errors[field.errorProperty as string]
                          ? 'react-select-with-error'
                          : 'react-select'
                      }
                      placeholder={locale?.selectLanguagePlaceholder}
                      value={getValueForSelect(
                        field.onChangeType as string,
                        field.level,
                      )}
                      options={field.options}
                      onChange={(e) => {
                        handleAddressSelectChange(
                          {
                            label: e?.label ?? '',
                            value: e?.value ?? '',
                          } as DropdownItem,
                          field.onChangeType as string,
                          field.level,
                        );
                      }}
                      isMulti={false}
                      noOptionsMessage={() => locale?.selectLanguageNoOptions}
                      components={{
                        Input: (props) => (
                          <components.Input
                            {...props}
                            maxLength={field.maxLengthValue}
                          />
                        ),
                      }}
                      onKeyDown={allowOnlyCharactersInCityField}
                    />
                    {errors[field.errorProperty as string] && (
                      <ErrorMessageWithIcon
                        message={
                          errors[field.errorProperty as string] as string
                        }
                      />
                    )}
                  </div>
                ) : (
                  <div
                    className={`${
                      field.property === 'zipCode' ? 'w-[160px]' : 'w-full'
                    }`}
                  >
                    <InputComponent
                      isDisabled={isSameOfficeAddresCheckboxChecked}
                      type={field.type}
                      name={field.name}
                      value={field.value as string}
                      onChange={(e) =>
                        field &&
                        field.onChange &&
                        field.onChange(e, field.level, field.property ?? '')
                      }
                      maxLengthValue={field.maxLengthValue}
                      errorStatus={!!errors[field.errorProperty as string]}
                      errorMsgWithIcon={errors[field.errorProperty as string]}
                    />
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>

        <div className="hidden w-full desktop:flex justify-end">
          <ButtonComponent
            disabled={isSubmiting}
            iconPosition="right"
            className="px-10"
            onClick={handleOnSubmit}
          >
            {genericAction?.[genericActionsIds.UPDATE].update}
          </ButtonComponent>
        </div>
      </div>
    </div>
  );
};
