import { NotificationsContent } from '../components/NotificationsContent';
import { NotificationsHeader } from '../components/NotificationsHeader';
import { useModalParams } from 'components/modal/useModalManager';
import { NotificationModal } from '../components/NotificationModal';
import { useLocation, useNavigate } from 'react-router-dom';
import { useNotifications } from 'hooks/useNotifications';
import { User_Notification_Status_Enum } from 'graphql/generated/hasura';
import { useState } from 'react';

const notificationsBatchSize = 10;

export const NotificationsUnread = () => {
  const [limit, setLimit] = useState(notificationsBatchSize);
  const notificationsResult = useNotifications({
    includeStatuses: [
      User_Notification_Status_Enum.Seen,
      User_Notification_Status_Enum.Delivered,
    ],
    limit,
  });
  const { isOpen: isMultiActionModal } = useModalParams('multiaction-modal');
  const location = useLocation();
  const navigate = useNavigate();
  const fetchMore = () => setLimit((l) => l + notificationsBatchSize);

  if (isMultiActionModal) {
    return (
      <NotificationModal
        notification={location.state?.body}
        notificationId={location.state?.notificationId}
        action={location.state?.action}
        extraActions={location.state?.extraActions}
        onClose={() => navigate(-1)}
      />
    );
  }

  return (
    <>
      <NotificationsHeader
        userNotificationsQuery={notificationsResult}
        fetchMore={fetchMore}
      />
      <NotificationsContent
        userNotificationsQuery={notificationsResult}
        fetchMore={fetchMore}
      />
    </>
  );
};
