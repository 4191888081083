import { JoinableAppointmentParams } from 'app/dashboard/components/upcomingAppointments/interfaces/upcomingAppointments.interfaces';
import { useEffect, useState } from 'react';
import { FHIR_APPOINTMENT_STATUS } from 'utilities/interfaces';

const joinableStatuses = [FHIR_APPOINTMENT_STATUS.BOOKED];

export function useIsJoinable(appointment: JoinableAppointmentParams) {
  const { startDate: appointmentStartDate, status } = appointment;
  const [isJoinable, setIsJoinable] = useState(false);
  const tenMinutesInMilliseconds = 10 * 60000;
  const oneMinuteInMilliseconds = 1000;
  useEffect(() => {
    const checkTime = () => {
      if (!joinableStatuses.includes(status)) {
        setIsJoinable(false);
        return;
      }
      const appointmentTime = new Date(appointmentStartDate).getTime();
      const now = Date.now();
      const timeDiff = Math.abs(appointmentTime - now);
      if (timeDiff <= tenMinutesInMilliseconds) {
        setIsJoinable(true);
      } else {
        setIsJoinable(false);
      }
    };
    checkTime();
    const intervalId = setInterval(checkTime, oneMinuteInMilliseconds);

    return () => clearInterval(intervalId);
  }, [appointmentStartDate, status, tenMinutesInMilliseconds]);

  return isJoinable;
}
