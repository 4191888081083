import * as VideoExpress from '@vonage/video-express';
import ParticipantWrapper from '@vonage/video-express/dist/mp/participant';
import { SignalEvent } from '@vonage/video-express/dist/types/signaling';
import { VonageMessageType } from 'app/my-appointments/components/interfaces';
import { useCallback, useEffect, useState } from 'react';
import { decodeMediaInChat } from 'utilities/functions';

export interface Message {
  data: string;
  isSentByMe: boolean;
  from: ParticipantWrapper | null | undefined;
  date: string;
  imageData?: {
    mediaId: string;
    mediaUrl: string;
  };
}

export const useSignal = ({ room }: { room: VideoExpress.Room | null }) => {
  const [listOfMessages, setListOfMessages] = useState<Message[]>([]);

  const sendSignal = useCallback(
    (data: string, type: string) => {
      if (room) {
        room.signal({ type: type, data: data }).catch((e) => e);
      }
    },
    [room],
  );

  const addMessageToList = useCallback(
    (
      data: string,
      isSentByMe: boolean,
      from: ParticipantWrapper | null | undefined,
      date: string,
      type?: string,
    ) => {
      if (type?.includes(VonageMessageType.IMAGE)) {
        const decodedData = decodeMediaInChat(data);
        if (decodedData) {
          return setListOfMessages((prev) => [
            ...prev,
            {
              data,
              isSentByMe,
              from,
              date,
              imageData: {
                mediaId: decodedData.mediaId,
                mediaUrl: decodedData.mediaUrl,
              },
            },
          ]);
        }
      }
      setListOfMessages((prev) => [...prev, { data, isSentByMe, from, date }]);
    },
    [],
  );

  const signalListener = useCallback(
    ({ data, isSentByMe, from, type }: SignalEvent) => {
      const date = new Intl.DateTimeFormat('en', {
        hour: 'numeric',
        minute: 'numeric',
        hour12: false,
      }).format(new Date());
      addMessageToList(data as string, isSentByMe, from, date, type);
    },
    [addMessageToList],
  );

  useEffect(() => {
    if (room) {
      room.on('signal', signalListener);
    }
    return function cleanup() {
      if (room) {
        room.off('signal:text', signalListener);
      }
    };
  }, [room, signalListener]);

  return {
    sendSignal,
    listOfMessages,
  };
};
