import { ReactComponent as Inflammations } from 'assets/icons/inflammations.svg';
import { ReactComponent as Comedones } from 'assets/icons/comedones.svg';
import { ReactComponent as Pih } from 'assets/icons/pih.svg';
import { ReactComponent as Total } from 'assets/icons/total.svg';
import { MyPatientsDermSCOREScansProps } from 'app/my-patients/interfaces/DermSCOREScans.interface';

export const MyPatientsScanIndicators: React.FC<
  MyPatientsDermSCOREScansProps
> = ({ scan, locale }) => {
  return (
    <div className="flex desktop:grow gap-1.5 flex-col min-w-[200px]">
      <div className="flex gap-2.5 items-center">
        <Inflammations className="w-3.5" />
        <div className="text-neutral-500 text-base font-normal leading-snug">
          {scan.inflammations} {locale.inflammations}
        </div>
      </div>
      <div className="flex gap-2.5 items-center">
        <Comedones className="w-3.5" />
        <div className="text-neutral-500 text-base font-normal leading-snug">
          {scan.comedones} {locale.comedones}
        </div>
      </div>
      <div className="flex gap-2.5 items-center">
        <Pih className="w-3.5" />
        <div className="text-neutral-500 text-base font-normal leading-snug">
          {scan.pih} {locale.pih}
        </div>
      </div>
      <div className="flex gap-2.5 items-center">
        <Total className="w-3.5" />
        <div className="text-neutral-500 text-base font-normal leading-snug">
          {scan.total} {locale.total}
        </div>
      </div>
      <div className="text-neutral-500 text-base font-normal leading-snug mt-2.5">
        {scan.date}
      </div>
    </div>
  );
};
