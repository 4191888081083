import { useNavigate } from 'react-router-dom';
import { UpcomingAppointmentsProps } from '../interfaces/upcomingAppointments.interface';
import { Card } from './Card';
import { UpcomingAppointmentsRow } from './UpcomingAppointmentsRow';
import { MY_PATIENTS_BIO_INFO_APPOINTMENT_HISTORY } from 'utilities/routes';
import { interpolateVariables } from 'utilities/functions';

export const UpcomingAppointments: React.FC<UpcomingAppointmentsProps> = ({
  locale,
  upcomingAppointments,
  count,
}) => {
  const navigate = useNavigate();

  const goToAppointmentHistory = () => {
    navigate(`../${MY_PATIENTS_BIO_INFO_APPOINTMENT_HISTORY}`, {
      replace: true,
    });
  };

  return (
    <Card
      title={locale.upcomingAppointmentsTitle}
      subtitle={interpolateVariables(locale.upcomingAppointmentsSubtitle, {
        current: String(count?.current ?? 0),
        total: String(count?.total ?? 0),
      })}
      buttonText={locale.viewAppointmentsHistory}
      borderSubtitle
      buttonCTA={goToAppointmentHistory}
    >
      {upcomingAppointments.map((appointment) => (
        <UpcomingAppointmentsRow
          key={appointment.id}
          appointment={appointment}
          // TODO COD-1758: Implement a handler for the action
          onActionClicked={() => {
            return;
          }}
          locale={locale}
        />
      ))}
    </Card>
  );
};
