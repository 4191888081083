import { ModalContext, ModalContextType } from 'layout/ModalContext';
import { ModalSpecification } from 'layout/ModalSpecification';
import { UrlModalSearchManager } from 'layout/UrlModalSearchManager';
import { useContext, Context } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { VoidCallback } from 'utilities/functions';

export function useModal<TReturn = unknown, TState = unknown>(
  spec: ModalSpecification<TReturn, TState>,
): [
  (state: TState, canBeDismissed?: boolean) => Promise<TReturn> | undefined,
  VoidCallback<TReturn>,
] {
  const ctx = useContext<ModalContextType<TReturn, TState>>(
    ModalContext as Context<ModalContextType<TReturn, TState>>,
  );

  if (!ctx.openModal) {
    throw new Error('useModal must be used from within a ModalProvider');
  }

  const navigate = useNavigate();
  const location = useLocation();

  const openModal = (
    state: TState,
    canBeDismissed = true,
  ): Promise<TReturn> | undefined => {
    if (spec.deepLinkable) {
      const builder = new UrlModalSearchManager(location.search);
      builder.addModal(spec.key, state);

      navigate(
        `.?${UrlModalSearchManager.appendModal(
          location.search,
          spec.key,
          state,
        )}`,
      );
    } else {
      return ctx.openModal(spec, canBeDismissed, state);
    }
  };

  const closeModal = (result: TReturn) => {
    ctx.closeModal(spec.key, result);
  };

  return [openModal, closeModal as VoidCallback<TReturn>];
}
