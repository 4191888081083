import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ModalButtonProps } from 'components/modal/modal.interfaces';
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
import { ReactComponent as MobileIcon } from 'assets/icons/mobile.svg';
import { ReactComponent as UploadIcon } from 'assets/icons/upload.svg';
import { useGetPage } from 'hooks/useGetPage';
import {
  AllowedFormats,
  ImageFormat,
  genericActionsIds,
  pageIds,
} from 'utilities/constants';
import { useGetGenericActions } from 'hooks/useGetGenericActions';
import { useIsMobile } from 'hooks/useIsMobile';
import {
  DASHBOARD,
  MY_DERMSCORE_RESIZE_PHOTO,
  MY_DERMSCORE_SCAN_QR,
  MY_SKIN_FACE_CAMERA,
} from 'utilities/routes';
import { convertFileToBase64 } from 'utilities/functions';
import { Helmet } from 'react-helmet';
import IconComponent from 'components/iconComponent';
import AlertComponent from 'components/alertComponent';
import ScanCarousel from '../../../components/dermscoreScan/carousel/scanCarousel';
import Modal from 'components/modal/modalComponent';
import step1Image from 'assets/images/step1.png';
import step2Image from 'assets/images/step2.png';
import step3Image from 'assets/images/step3.png';
import step4Image from 'assets/images/step4.png';

export const NewDermScoreScan = () => {
  const { data: locale, loading } = useGetPage({
    locale: 'en',
    pageId: pageIds.NEW_DERMSCORE_SCAN,
  });

  const { data: genericAction, loading: genericActionLoading } =
    useGetGenericActions({
      locale: 'en',
      genericActionId: [genericActionsIds.CANCEL],
    });

  const navigate = useNavigate();
  const location = useLocation();
  const isMobile = useIsMobile();
  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const fileError = location.state?.fileError || false;
    const qrCodeError = location.state?.qrCodeError || false;
    const faceError = location.state?.noFaceFound || false;
    const faceSegmentationError =
      location.state?.faceSegmentationError || false;

    if (fileError) {
      setAlertMessage(locale?.fileQualityError);
    }
    if (qrCodeError) {
      setAlertMessage(locale?.qrCodeError);
    }

    if (faceSegmentationError) {
      setAlertMessage(locale?.faceSegmentationError);
    }

    if (faceError) {
      setAlertMessage(locale?.notFoundFaceError);
    }
  }, [location, locale]);

  const handleCancel = () => {
    if (alertMessage) {
      navigate(DASHBOARD);
    } else {
      navigate(-1);
    }
  };

  const validateImage = (image: File): Promise<void> => {
    const minWidth = 500;
    const minHeight = 500;

    if (!AllowedFormats.includes(image.type as ImageFormat)) {
      setAlertMessage(locale?.fileTypeError);
      return Promise.reject();
    }

    const img = new Image();
    img.src = URL.createObjectURL(image);

    return new Promise((resolve, reject) => {
      img.onload = () => {
        if (img.width >= minWidth && img.height >= minHeight) {
          setAlertMessage(null);
          resolve();
        } else {
          setAlertMessage(locale?.fileSizeError);
          reject();
        }
      };
    });
  };

  const handleUploadPhoto = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleImageUpload = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const file = event.target.files && event.target.files[0];

    if (file) {
      try {
        await validateImage(file);

        const base64Image = await convertFileToBase64(file);

        navigate(MY_DERMSCORE_RESIZE_PHOTO, {
          state: {
            image: base64Image,
            filename: file.name,
            mimeType: file.type,
          },
        });
      } catch (error) {
        error && console.error(error);
      }
    }
  };

  const handleTakePhoto = () => {
    if (isMobile) {
      navigate(MY_SKIN_FACE_CAMERA);
    } else {
      navigate(MY_DERMSCORE_SCAN_QR);
    }
  };

  const Buttons: ModalButtonProps[] = [
    {
      label: locale?.takePhoto,
      onClick: handleTakePhoto,
      Icon: MobileIcon,
    },
    {
      label: locale?.uploadPhoto,
      type: 'outlined',
      Icon: UploadIcon,
      onClick: handleUploadPhoto,
    },
    {
      label: genericAction?.[genericActionsIds.CANCEL].cancel,
      type: 'underline',
      onClick: handleCancel,
    },
  ];

  const scanSteps = [
    {
      imageUrl: step1Image,
      description: locale?.step1Text,
    },
    {
      imageUrl: step2Image,
      description: locale?.step2Text,
    },
    {
      imageUrl: step3Image,
      description: locale?.step3Text,
    },
    {
      imageUrl: step4Image,
      description: locale?.step4Text,
    },
  ];

  if ((loading && !locale) || (genericActionLoading && !genericAction))
    return null;

  return (
    <>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <Modal
        isOpen={true}
        title={locale?.newScan}
        buttons={Buttons}
        onClose={handleCancel}
      >
        {alertMessage && <AlertComponent type="warning" text={alertMessage} />}
        <p className="text-base text-med-gray">{locale?.scanHeader}</p>
        <ScanCarousel steps={scanSteps} />
        <div className="flex flex-col desktop:flex-row items-center text-center p-5 desktop:py-3 px-5 gap-[15px] bg-white rounded-b-lg">
          <IconComponent
            width="w-12 desktop:w-6"
            height="h-12 desktop:h-6"
            icon={InfoIcon}
            fill="fill-clc-blue"
          />
          <p className="text-base font-semibold text-med-gray">
            {locale?.scanPhotoSize}
          </p>
        </div>
        <input
          type="file"
          id="myImage"
          onChange={handleImageUpload}
          ref={fileInputRef}
          className="hidden"
        />
      </Modal>
    </>
  );
};
