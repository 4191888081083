import AlertComponent from 'components/alertComponent';
import { SidebarComponent } from 'components/sidebar/sidebarComponent';
import TabsComponent from 'components/tabs/tabsComponent';
import { PersonalInfo } from '../patientComponents/PersonalInfo';
import { ContactInfo } from '../patientComponents/ContactInfo';
import { SecurityAndPrivacy } from '../patientComponents/SecurityAndPrivacy';
import ButtonComponent from 'components/button/buttonComponent';
import { ProfileData } from 'app/my-account/interfaces/profile.interfaces';
import { ReactComponent as CloseIcon } from 'assets/icons/iconClose.svg';
import { PatientModuleInterfaceProps } from './interfaces/patientModuleInterfaces';
import { SidebarItem } from 'components/sidebar/sidebar.interface';
import { useGetPage } from 'hooks/useGetPage';
import { genericActionsIds, pageIds } from 'utilities/constants';
import Loader from 'components/loaderComponent';
import { Tab } from 'components/tabs/tabs.interface';
import {
  MY_ACCOUNT_DELETION,
  MY_ACCOUNT_HEALTH_QUESTIONNAIRE,
  MY_ACCOUNT_MY_MEDIA_LIBRARY,
  MY_ACCOUNT_PROFILE,
} from 'utilities/routes';
import { useLocation, useNavigate } from 'react-router-dom';
import { useGetGenericActions } from 'hooks/useGetGenericActions';
import { Pharmacies } from '../patientComponents/Pharmacies/Pharmacies';

export const PatientModule: React.FC<PatientModuleInterfaceProps> = ({
  alertProps,
  setAlertProps,
  profileData,
  handleUpdatedPersonalInfo,
  handleProfileInfoChange,
  handleUpdatedContactInfo,
  handleUpdatedPharmacyInfo,
  disableButton,
  displayMFAForm,
  displaySecurityAndPrivacy,
  handleOnDisplayMFAForm,
  setDisplaySecurityAndPrivacy,
  handleResendCode,
  handleOnSavePhoneNumber,
  setCode,
  handleOnCountryChange,
  handleOnPhoneNumberChange,
  handleVerifyMFACode,
  mfaError,
  phoneError,
  securityAndPrivacySubmitting,
  submitting,
  handleOnSubmit,
  currentSidebarItem,
  setCurrentSidebarItem,
  userVerificationStatus,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const referringPage = location.state?.referringPage || '';
  const { data: locale, loading } = useGetPage({
    locale: 'en',
    pageId: pageIds.MY_PROFILE,
  });
  const { data: genericAction, loading: genericActionLoading } =
    useGetGenericActions({
      locale: 'en',
      genericActionId: [genericActionsIds.UPDATE, genericActionsIds.VERIFY],
    });

  const sidebarItems: SidebarItem[] = [
    {
      name: locale?.personalInfo?.title,
    },
    {
      name: locale?.contactInfo?.title,
    },
    {
      name: locale?.securityAndPrivacy?.title,
    },
    { name: locale?.pharmacies?.title },
  ];

  const tabs: Tab[] = [
    {
      name: locale?.title,
      url: MY_ACCOUNT_PROFILE,
    },
    {
      name: locale?.healthQuestionnaire,
      url: MY_ACCOUNT_HEALTH_QUESTIONNAIRE,
      referringPage,
    },
    {
      name: locale?.myMediaLibrary,
      url: MY_ACCOUNT_MY_MEDIA_LIBRARY,
    },
  ];

  const handleOnClick = (item: string) => {
    setAlertProps(null);
    setCurrentSidebarItem(item);
  };

  if (loading || !locale || genericActionLoading) {
    return <Loader />;
  }

  return (
    <div className="flex flex-col">
      {alertProps && alertProps.isActionCta && (
        <div className="flex flex-row mb-[30px] px-7">
          <AlertComponent
            isNotification={alertProps.isNotification ?? false}
            type={alertProps.type}
            customContent={
              <div className="flex flex-col w-full desktop:flex-row justify-between items-center desktop:relative">
                <div className="flex flex-col desktop:flex-row w-full gap-5 desktop:gap-[30px]">
                  <p
                    className={`text-base font-semibold ${
                      alertProps.type === 'info' ? 'text-alert-yellow' : ''
                    } ${
                      alertProps.type === 'positive'
                        ? 'text-alert-positive'
                        : 'text-alert-negative'
                    } desktop:mr-[35px]`}
                  >
                    {alertProps.text}
                  </p>
                </div>

                <div className="flex">
                  <div className="desktop:pt-0 mr-4 pt-4">
                    <ButtonComponent
                      type={
                        alertProps.type === 'info' ? 'underline' : 'outlined'
                      }
                      iconPosition="right"
                      className="desktop:min-w-[205px]"
                      disabled={submitting}
                      onClick={() =>
                        alertProps.actionCallback && alertProps.actionCallback()
                      }
                    >
                      {alertProps.actionLabel}
                    </ButtonComponent>
                  </div>
                </div>
              </div>
            }
          />
        </div>
      )}

      {alertProps && !alertProps.isActionCta && (
        <div className="flex flex-row mb-[30px] px-7">
          <AlertComponent
            type={alertProps.type}
            customContent={
              <div className="flex flex-col w-full desktop:flex-row justify-between items-center desktop:relative">
                <div className="flex flex-col desktop:flex-row w-full gap-5 desktop:gap-[30px]">
                  <p
                    className={`text-base font-semibold 
                    ${alertProps.type === 'info' ? 'text-alert-yellow' : ''} 
                    ${
                      alertProps.type === 'positive'
                        ? 'text-alert-positive'
                        : 'text-alert-negative'
                    } text-center desktop:mr-[35px]`}
                  >
                    {alertProps.text}
                  </p>
                </div>

                <CloseIcon
                  className="absolute top-5 right-5 desktop:top-0 desktop:right-0 w-6 h-6 stroke-med-gray cursor-pointer"
                  onClick={() => setAlertProps(null)}
                />
              </div>
            }
          />
        </div>
      )}

      <TabsComponent tabs={tabs} />
      <div className="flex flex-col gap-[30px] px-7">
        <div className="flex flex-col gap-[30px] desktop:flex-row desktop:items-center desktop:justify-between">
          <h1 className="text-h2 desktop:text-h1 text-dark-gray">
            {locale?.title}
          </h1>
          <p className="text-sm text-clc-red font-bold">
            {locale?.requiredText}
          </p>
        </div>

        <div className="flex flex-row items-start desktop:pb-[60px]">
          <SidebarComponent
            items={sidebarItems}
            currentItem={currentSidebarItem}
            onClick={handleOnClick}
          />
          <div className="flex flex-col items-start w-full gap-2.5 desktop:gap-5">
            <div
              className={`w-full ${
                currentSidebarItem === locale?.personalInfo?.title
                  ? 'desktop:flex'
                  : 'desktop:hidden'
              }`}
            >
              <PersonalInfo
                {...(profileData as ProfileData)}
                handleUpdatedPersonalInfo={handleUpdatedPersonalInfo}
                handlePersonalInfoChange={handleProfileInfoChange}
                userVerificationStatus={userVerificationStatus}
              />
            </div>

            <div
              className={`w-full ${
                currentSidebarItem === locale?.contactInfo?.title
                  ? 'desktop:flex'
                  : 'desktop:hidden'
              }`}
            >
              <ContactInfo
                {...(profileData as ProfileData)}
                handleUpdatedContactInfo={handleUpdatedContactInfo}
                handleContactInfoChange={handleProfileInfoChange}
              />
            </div>

            <div
              className={`w-full ${
                currentSidebarItem === locale?.securityAndPrivacy?.title
                  ? 'desktop:flex'
                  : 'desktop:hidden'
              }`}
            >
              <SecurityAndPrivacy
                {...(profileData as ProfileData)}
                disableButton={disableButton}
                displayMFAForm={displayMFAForm}
                displaySecurityAndPrivacy={displaySecurityAndPrivacy}
                onDisplayMFAForm={handleOnDisplayMFAForm}
                onDisplaySecurityAndPrivacy={setDisplaySecurityAndPrivacy}
                onResendCode={handleResendCode}
                onSavePhoneNumber={handleOnSavePhoneNumber}
                onCodeChange={setCode}
                onCountryChange={handleOnCountryChange}
                onPhoneNumberChange={handleOnPhoneNumberChange}
                onVerifyMFACode={handleVerifyMFACode}
                mfaError={mfaError}
                phoneError={phoneError}
                securityAndPrivacySubmitting={securityAndPrivacySubmitting}
              />
            </div>

            <div
              className={`w-full ${
                currentSidebarItem === locale?.pharmacies?.title
                  ? 'desktop:flex'
                  : 'desktop:hidden'
              }`}
            >
              <Pharmacies
                {...(profileData as ProfileData)}
                handleUpdatedPharmacyInfo={handleUpdatedPharmacyInfo}
                handlePharmacyInfoChange={handleProfileInfoChange}
              />
            </div>

            <div className="desktop:hidden w-full flex justify-end mt-2.5">
              <ButtonComponent
                iconPosition="right"
                className="px-10"
                disabled={submitting}
                onClick={() =>
                  !submitting &&
                  (displayMFAForm
                    ? handleVerifyMFACode(true)
                    : handleOnSubmit())
                }
              >
                {displayMFAForm
                  ? genericAction?.[genericActionsIds.VERIFY].verify
                  : genericAction?.[genericActionsIds.UPDATE].update}
              </ButtonComponent>
            </div>

            <div className="flex flex-col items-center desktop:items-start w-full pt-2.5 pb-10 desktop:py-2 gap-5">
              <div className="flex flex-row items-center desktop:items-start cursor-pointer">
                <ButtonComponent
                  type="underline"
                  onClick={() =>
                    navigate(MY_ACCOUNT_DELETION, {
                      state: {
                        currentSidebarItem,
                      },
                    })
                  }
                >
                  {locale?.closeAccount}
                </ButtonComponent>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
