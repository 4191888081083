import { TestKitsTableData } from 'app/manage-test-kits/components/DNATestKitTable/interfaces';
import TableContent from 'components/codexTable/TableContent';
import {
  CustomClassNamesProps,
  KeyMapProps,
} from 'components/codexTable/interfaces/keymap.interfaces';
import { Record_Order_By } from 'components/codexTable/interfaces/record.interfaces';
import {
  Order_By,
  useGetProviderPatientSignedUrlMutation,
  useGetSignedUrlFromStorageMutation,
} from 'graphql/generated/hasura';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { ReactComponent as PrintIcon } from 'assets/icons/print.svg';
import { ReactComponent as DownloadIcon } from 'assets/icons/file-download.svg';
import { ReactComponent as EyeIcon } from 'assets/icons/pwEyeShow.svg';
import TableContentMobile from 'components/codexTable/TableContentMobile';
import {
  DNATestKitTableProps,
  TestKitsResultsModalNavigationState,
  TestKitsTableDataWithPatientInfo,
} from '../interfaces/testKits.interfaces';
import { useNavigate, useParams } from 'react-router-dom';
import { usePdfDownload } from 'hooks/usePdfDownload';
import { useGetFileFromStorage } from 'hooks/useGetFileFromStorage';
import { printPdf } from 'utilities/functions';

import { useIsMobile } from 'hooks/useIsMobile';
import { PARAM_MODALS_IDENTIFIERS } from 'utilities/constants';
import { AuthContext } from 'auth/context/AuthContext';
import { Roles } from '../../../firebase/interfaces';

const keyMap: KeyMapProps = {
  date: {
    column: 'date',
    isActive: (orderBy: Record_Order_By) => (orderBy.date ? true : false),
    direction: (orderBy: Record_Order_By) =>
      (orderBy.date as Order_By) || Order_By.Desc,
  },
  'test type': {
    column: 'test type',
    isActive: (orderBy: Record_Order_By) =>
      orderBy['test type'] ? true : false,
    direction: (orderBy: Record_Order_By) =>
      (orderBy['test type'] as Order_By) || Order_By.Desc,
  },
  testID: {
    column: 'testID',
    isActive: (orderBy: Record_Order_By) => (orderBy.testID ? true : false),
    direction: (orderBy: Record_Order_By) =>
      (orderBy.testID as Order_By) || Order_By.Desc,
  },
  provider: {
    column: 'provider',
    isActive: (orderBy: Record_Order_By) => (orderBy.provider ? true : false),
    direction: (orderBy: Record_Order_By) =>
      (orderBy.provider as Order_By) || Order_By.Desc,
  },
};

const customClasses: CustomClassNamesProps = {
  date: {
    customRowClassName: 'text-left pl-2.5',
  },
  'test type': {
    customRowClassName: 'text-left pl-2.5',
  },
  testID: {
    customRowClassName: 'text-left pl-2.5',
  },
  provider: {
    customRowClassName: 'text-left pl-2.5',
  },
};

const ActionsComponent: React.FC<TestKitsTableDataWithPatientInfo> = (
  current,
) => {
  const { user: loggedUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const { patientId } = useParams();
  const [pdfSrc, setPdfSrc] = useState<string>();
  const [getSignedUrlFromStorage] = useGetSignedUrlFromStorageMutation({});

  const { fetchFileFromStorage, loading: printLoading } =
    useGetFileFromStorage();

  const [getProviderPatientsSignedUrl] = useGetProviderPatientSignedUrlMutation(
    {},
  );

  const { downloadPdf } = usePdfDownload();

  useEffect(() => {
    (async () => {
      if (current.patientPdfId) {
        try {
          if (loggedUser?.role === Roles.PROVIDER && patientId) {
            const url = await getProviderPatientsSignedUrl({
              variables: {
                fileId: current.patientPdfId,
                patientCodexId: patientId,
              },
            });
            if (url.data?.GetProviderPatientSignedUrl?.url) {
              setPdfSrc(url.data?.GetProviderPatientSignedUrl.url);
            }
          } else {
            const url = await getSignedUrlFromStorage({
              variables: { fileId: current.patientPdfId },
            });
            if (url.data?.GetSignUrlFormStorage?.url) {
              setPdfSrc(url.data?.GetSignUrlFormStorage?.url);
            }
          }
        } catch (error) {
          console.error('Error getting pdf signed url', error);
        }
      }
    })();
  }, [
    current.patientPdfId,
    patientId,
    loggedUser?.role,
    getSignedUrlFromStorage,
    getProviderPatientsSignedUrl,
  ]);

  const onEyeIconClick = () => {
    if (!pdfSrc) return;
    const state: TestKitsResultsModalNavigationState = {
      patientName: current.patientName,
      patientProfileImageSrc: current.patientProfileImageSrc,
      testPdfSrc: pdfSrc,
      testDate: new Date(current.date),
      testName: current['test type'],
    };
    navigate(`.?${PARAM_MODALS_IDENTIFIERS.TEST_KIT_RESULT_MODAL_ID}=true`, {
      state,
    });
  };
  const handleOnPrint = async () => {
    if (!current.patientPdfId || printLoading) return;
    try {
      const file = await fetchFileFromStorage(current.patientPdfId);
      printPdf(file);
    } catch (error) {
      console.error('Error getting pdf', error);
    }
  };
  const handleOnDownload = async () => {
    if (!current.patientPdfId) return;
    downloadPdf(current.patientPdfId);
  };

  if (!current.patientPdfId) return null;
  return (
    <div className="flex items-center justify-around desktop:justify-end">
      <div className="w-11 inline-flex justify-center items-center hover:cursor-pointer">
        <EyeIcon
          onClick={onEyeIconClick}
          className={pdfSrc ? 'fill-clc-blue' : 'fill-disabled'}
        />
      </div>
      <div className="w-11 inline-flex justify-center items-center hover:cursor-pointer">
        <DownloadIcon
          onClick={handleOnDownload}
          className={current.patientPdfId ? 'fill-clc-blue' : 'fill-disabled'}
        />
      </div>
      <div className="w-11 inline-flex justify-center items-center hover:cursor-pointer">
        <PrintIcon
          onClick={handleOnPrint}
          className={
            current.patientPdfId && !printLoading
              ? 'fill-clc-blue'
              : 'fill-med-gray'
          }
        />
      </div>
    </div>
  );
};

const columnKeys: (keyof TestKitsTableData)[] = [
  'date',
  'test type',
  'testID',
  'provider',
];

export const DNATestKitTable: React.FC<DNATestKitTableProps> = ({
  data,
  locale,
  orderBy,
  setOrderBy,
}) => {
  const tableData = useMemo(
    () => data.map((d) => ({ ...d, id: d.id })),
    [data],
  );
  const isMobile = useIsMobile();
  if (isMobile) {
    return (
      <div>
        <TableContentMobile
          keyMap={keyMap}
          columnKeys={columnKeys}
          data={tableData}
          actions={ActionsComponent as () => JSX.Element}
          locale={locale}
          vertical
        />
      </div>
    );
  }
  return (
    <div className="mt-[30px] desktop:flex desktop:flex-col">
      <TableContent
        columnKeys={columnKeys}
        data={tableData}
        keyMap={keyMap}
        orderBy={orderBy}
        onColumnSortChange={setOrderBy}
        customClasses={customClasses}
        actions={ActionsComponent as () => JSX.Element}
        locale={locale}
        bottomBorder
      />
    </div>
  );
};

export default DNATestKitTable;
