import Avatar from 'components/avatarComponent';
import { ReactComponent as SendMessageIcon } from 'assets/icons/sendMessage.svg';
import { ReactComponent as PrescribeIcon } from 'assets/icons/prescribe.svg';
import { ReactComponent as ReferProviderIcon } from 'assets/icons/referProvider.svg';
import { ReactComponent as PdfFileIcon } from 'assets/icons/pdfFile.svg';
import ButtonComponent from 'components/button/buttonComponent';
import { PatientHeaderProps } from '../interfaces/patient.interface';
import { capitalizeFirstLetter } from 'utilities/functions';
import { VirtualPharmaciesModal } from './VirtualPharmaciesModal';
import { useModal } from 'layout/useModal';

export const PatientHeader: React.FC<PatientHeaderProps> = ({
  patientCodexId,
  age,
  city,
  gender,
  name,
  profileImgSrc,
  state,
  locale,
  showN4Buttons,
}) => {
  const [openVirtualPharmaciesModal] = useModal(VirtualPharmaciesModal);

  return (
    <div className="flex flex-col desktop:flex-row items-center gap-[15px] desktop:gap-[30px]">
      <Avatar size="md" imageUrl={profileImgSrc} />
      <div className="flex flex-col items-center desktop:items-start gap-[15px] desktop:gap-2.5">
        <div className="flex flex-col desktop:flex-row items-center gap-[15px] desktop:gap-[30px]">
          <span className="text-charcoal-gray text-[28px] font-medium font-exo leading-[38px] capitalize">
            {name}
          </span>
          <div className="pb-2 desktop:pb-0 justify-center items-center gap-2.5 flex">
            <span className="text-med-gray text-base font-semibold leading-snug">
              {capitalizeFirstLetter(gender)}
            </span>
            <div className="w-1.5 justify-center items-center flex">
              <div className="w-1.5 h-1.5 bg-neutral-400 rounded-full" />
            </div>
            <span className="text-med-gray text-base font-semibold leading-snug">
              {age} {locale.yearsOld}
            </span>
            <div className="w-1.5 justify-center items-center flex">
              <div className="w-1.5 h-1.5 bg-neutral-400 rounded-full" />
            </div>
            <span className="text-med-gray text-base font-semibold leading-snug">
              {city}, {state}
            </span>
          </div>
        </div>
        <div className="flex flex-col desktop:flex-row items-center gap-5 desktop:gap-[60px] self-stretch">
          {showN4Buttons && (
            <>
              <div className="justify-center items-center flex self-stretch">
                <ButtonComponent
                  Icon={SendMessageIcon}
                  iconPosition="left"
                  paddingX="px-5"
                  paddingY="py-2"
                  className="font-normal font-exo text-sm"
                  borderRadius="rounded-[5px]"
                  stroke="stroke-none"
                >
                  {locale.sendMessage}
                </ButtonComponent>
              </div>
              <div className="justify-center items-center gap-[7px] inline-flex border-[1px] border-transparent cursor-pointer active:border-b-clc-blue">
                <div className="justify-center items-center flex">
                  <ReferProviderIcon />
                </div>
                <div className="text-clc-blue text-base font-bold leading-snug">
                  {locale.referProvider}
                </div>
              </div>
            </>
          )}
          <div
            className="justify-center items-center gap-[7px] inline-flex border-[1px] border-transparent cursor-pointer active:border-b-clc-blue"
            onClick={() =>
              openVirtualPharmaciesModal({ patientId: patientCodexId }, true)
            }
          >
            <div className="justify-center items-center flex">
              <PrescribeIcon />
            </div>
            <div className="text-clc-blue text-base font-bold leading-snug">
              {locale.prescribe}
            </div>
          </div>
          <div className="justify-center items-center gap-[7px] inline-flex border-[1px] border-transparent cursor-pointer active:border-b-clc-blue">
            <div className="justify-center items-center flex">
              <PdfFileIcon />
            </div>
            <div className="text-clc-blue text-base font-bold leading-snug">
              {locale.patientHealthHistory}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
