import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { genericActionsIds, pageIds } from 'utilities/constants';
import { useGetPage } from 'hooks/useGetPage';
import { ReactComponent as FileDownloadIcon } from 'assets/icons/file-download.svg';
import { ReactComponent as PrintIcon } from 'assets/icons/print.svg';
import { ModalButtonProps } from 'components/modal/modal.interfaces';
import { Scan } from 'app/dashboard/interfaces/scan.interfaces';
import { printPdf, populateScanResult } from 'utilities/functions';
import { useGetFileFromStorage } from 'hooks/useGetFileFromStorage';
import { useGetGenericActions } from 'hooks/useGetGenericActions';
import { DASHBOARD, MY_DERMSCORE_NEW_SCAN } from 'utilities/routes';
import { usePdfDownload } from 'hooks/usePdfDownload';
import ButtonComponent from 'components/button/buttonComponent';
import Modal from 'components/modal/modalComponent';
import ScanSource from 'app/dashboard/components/ScanSource';
import ScanIndicators from 'app/dashboard/components/ScanIndicators';
import AlertComponent from 'components/alertComponent';
import ScanImage from 'components/ScanImage';

export const DermScoreResults = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [scan, setScan] = useState<Scan>();
  const { fetchFileFromStorage } = useGetFileFromStorage();
  const { downloadPdf } = usePdfDownload();
  const onlyView = location.state?.onlyView || false;

  useEffect(() => {
    if (onlyView) {
      setScan(location.state?.scan);
    } else {
      const populatedScan = populateScanResult(location.state?.scan);
      setScan(populatedScan);
    }
  }, [location, onlyView]);

  const { data: locale, loading } = useGetPage({
    locale: 'en',
    pageId: pageIds.DERMSCORE_RESULTS,
  });

  const { data: genericAction, loading: genericActionLoading } =
    useGetGenericActions({
      locale: 'en',
      genericActionId: [genericActionsIds.CLOSE, genericActionsIds.DOWNLOAD],
    });

  const handleClose = () => {
    if (onlyView) {
      navigate(-1);
    } else {
      navigate(DASHBOARD);
    }
  };

  const handleCreateAnotherScan = () => {
    navigate(MY_DERMSCORE_NEW_SCAN);
  };

  const handleOnDownload = async () => {
    scan?.reportId && downloadPdf(scan.reportId);
  };

  const handleOnPrint = async () => {
    const fileUrl = await fetchFileFromStorage(scan?.reportId || '');
    printPdf(fileUrl);
  };

  const Buttons: ModalButtonProps[] = [
    {
      label: genericAction?.[genericActionsIds.CLOSE].close,
      onClick: handleClose,
    },
    {
      label: locale?.createAnotherScan,
      type: 'outlined',
      onClick: handleCreateAnotherScan,
    },
  ];

  if ((loading && !locale) || (genericActionLoading && !genericAction) || !scan)
    return null;

  return (
    <>
      <Modal
        isOpen={true}
        title={locale?.dermScoreResults}
        buttons={Buttons}
        onClose={handleClose}
      >
        {!onlyView && (
          <AlertComponent type="positive" text={locale?.successResults} />
        )}

        <div className="flex flex-col desktop:flex-row gap-[30px] desktop:gap-[60px] desktop:align-middle">
          <ScanImage
            className="overflow-hidden rounded-[10px] w-full min-h-[400px] desktop:w-[375px] desktop:h-[420px] gap-y-[30px] bg-cover bg-center contents desktop:flex desktop:items-center"
            imageId={scan.imageId}
          />

          <div className="flex flex-col gap-[30px]">
            <div className="grow text-center desktop:text-left">
              <div className="mx-[25%] desktop:mx-auto">
                <ScanIndicators
                  inflammations={scan.inflammations}
                  comedones={scan.comedones}
                  pih={scan.pih}
                  total={scan.total}
                  date={scan.date}
                  hideDate={true}
                  orientation="col"
                  flagSize="large"
                />
              </div>
            </div>

            <div className="flex flex-col">
              <h5 className="text-base text-dark-gray font-bold">
                <ScanSource source={scan.source} />
              </h5>
              <h5 className="text-base text-med-gray font-medium">
                {locale?.resultsHeader} {scan.date}
              </h5>
            </div>

            <div className="hidden desktop:flex desktop:flex-row desktop:gap-x-2.5">
              <ButtonComponent
                type="icon"
                Icon={FileDownloadIcon}
                onClick={handleOnDownload}
              />
              <ButtonComponent
                type="icon"
                Icon={PrintIcon}
                onClick={handleOnPrint}
              />
            </div>
          </div>

          <div className="flex flex-row gap-2.5 justify-center desktop:hidden">
            <ButtonComponent
              iconPosition="left"
              type="icon"
              Icon={FileDownloadIcon}
              onClick={handleOnDownload}
            >
              {genericAction?.[genericActionsIds.DOWNLOAD].download}
            </ButtonComponent>
          </div>
        </div>
      </Modal>
    </>
  );
};
