import { ReactComponent as FileDownloadIcon } from 'assets/icons/file-download.svg';
import { ReactComponent as EyeIcon } from 'assets/icons/pwEyeShow.svg';
import ScanImage from 'components/ScanImage';
import { usePdfDownload } from 'hooks/usePdfDownload';
import { MyPatientsDermSCOREScansProps } from 'app/my-patients/interfaces/DermSCOREScans.interface';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { PARAM_MODALS_IDENTIFIERS } from 'utilities/constants';

export const MyPatientsScanImage: React.FC<MyPatientsDermSCOREScansProps> = ({
  scan,
  patientId = null,
}) => {
  const { downloadPdf } = usePdfDownload();
  const navigate = useNavigate();

  const handleOnDownload = async () => {
    scan.reportId && downloadPdf(scan.reportId);
  };
  const openDermSCOREModal = useCallback(() => {
    navigate(
      `.?${PARAM_MODALS_IDENTIFIERS.MY_PATIENTS_DERMSCORE_SCANS_MODAL_ID}=true`,
      {
        state: { onlyView: true, scan },
      },
    );
  }, [scan, navigate]);
  return (
    <div className="flex flex-col self-stretch items-center">
      <ScanImage
        className="overflow-hidden rounded-10 w-[120px] h-[150px] bg-cover bg-center"
        imageId={scan.imageId}
        objectFit="object-cover"
        patientId={patientId}
      />
      <div className="flex flex-row py-2.5 justify-around self-stretch">
        <EyeIcon
          className="w-[15px] h-[18px] cursor-pointer fill-clc-blue"
          onClick={openDermSCOREModal}
        />
        <FileDownloadIcon
          onClick={handleOnDownload}
          className="w-[15px] h-[18px] cursor-pointer fill-clc-blue"
        />
      </div>
    </div>
  );
};
