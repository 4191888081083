import { ReactNode, useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from 'auth/context/AuthContext';
import { LOCAL_STORAGE_USER_FROM_QR } from 'utilities/constants';

interface Props {
  children: ReactNode;
}

export const QrProtectedRoute = ({ children }: Props) => {
  const { isLoggedIn } = useContext(AuthContext);
  const userFromQrCode = localStorage.getItem(LOCAL_STORAGE_USER_FROM_QR);

  return isLoggedIn || userFromQrCode === 'true' ? (
    <>{children}</>
  ) : (
    <Navigate to="/auth/login" />
  );
};
