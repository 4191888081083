import { PatientDetailsProps } from '../interfaces/patientDetails.interface';

export const PatientDetailsMobile: React.FC<PatientDetailsProps> = ({
  locale,
  patientDetails,
}) => {
  const {
    dateOfBirth,
    age,
    height,
    weight,
    waistSize,
    hipSize,
    birthGender,
    genderSelfIdentification,
    primaryPharmacy,
  } = patientDetails;
  return (
    <div className="desktop:hidden flex flex-col gap-4">
      <div className="flex flex-row gap-20">
        <div className="flex flex-col gap-4">
          <span className="text-zinc-800 text-base font-semibold leading-snug">
            {locale.dateOfBirth}
          </span>
          <span className="text-zinc-800 text-base font-semibold leading-snug">
            {locale.age}
          </span>
          <span className="text-zinc-800 text-base font-semibold leading-snug">
            {locale.height}
          </span>
          <span className="text-zinc-800 text-base font-semibold leading-snug">
            {locale.weight}
          </span>
          <span className="text-zinc-800 text-base font-semibold leading-snug">
            {locale.waistSize}
          </span>
          <span className="text-zinc-800 text-base font-semibold leading-snug">
            {locale.hipSize}
          </span>
          <span className="text-zinc-800 text-base font-semibold leading-snug">
            {locale.birthGender}
          </span>
          <span className="text-zinc-800 text-base font-semibold leading-snug whitespace-pre-wrap">
            {locale.genderSelfIdentification}
          </span>
        </div>
        <div className="flex flex-col gap-4">
          <span className="text-neutral-500 text-base font-semibold leading-snug">
            {dateOfBirth}
          </span>
          <span className="text-neutral-500 text-base font-semibold leading-snug">
            {age} {locale.yearsOld}
          </span>
          <span className="text-neutral-500 text-base font-semibold leading-snug">
            {height.map(
              (measure) => `${measure.value} ${locale[measure.unit]} `,
            )}
          </span>
          <span className="text-neutral-500 text-base font-semibold leading-snug">
            {weight.map(
              (measure) => `${measure.value} ${locale[measure.unit]} `,
            )}{' '}
          </span>
          <span className="text-neutral-500 text-base font-semibold leading-snug">
            {waistSize.map(
              (measure) => `${measure.value} ${locale[measure.unit]} `,
            )}
          </span>
          <span className="text-neutral-500 text-base font-semibold leading-snug">
            {hipSize.map(
              (measure) => `${measure.value} ${locale[measure.unit]} `,
            )}
          </span>
          <span className="text-neutral-500 text-base font-semibold leading-snug">
            {locale[birthGender]}
          </span>
          <span className="text-neutral-500 text-base font-semibold leading-snug">
            {locale[genderSelfIdentification]}
          </span>
        </div>
      </div>
      {primaryPharmacy && (
        <div className="flex flex-col">
          <span className="text-zinc-800 text-base font-semibold leading-snug">
            {locale.primaryPharmacy}
          </span>
          <span className="text-neutral-500 text-base font-semibold leading-snug">
            {primaryPharmacy.name}
          </span>
          <span className="text-neutral-500 text-base font-normal leading-snug">
            {primaryPharmacy.id}
          </span>
          <span className="text-neutral-500 text-base font-normal leading-snug">
            {primaryPharmacy.address}
          </span>
          <span className="text-neutral-500 text-base font-normal leading-snug">
            {primaryPharmacy.city}, {primaryPharmacy.state}{' '}
            {primaryPharmacy.zip}
          </span>
          <span className="text-neutral-500 text-base font-normal leading-snug">
            {primaryPharmacy.phone}
          </span>
        </div>
      )}
    </div>
  );
};
