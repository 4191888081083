import { useGetUserPrivacyPreferencesQuery } from 'graphql/generated/hasura';
import { ToggleComponent } from './ToggleComponent';
import { useContext, useEffect, useState } from 'react';
import { AuthContext } from 'auth/context/AuthContext';

import {
  PrivacyPreferencesProps,
  PrivacyPreferencesState,
  PrivacyPreferencesKeys,
} from '../interfaces/privacyPreferences';
import { AccountSettingsState } from '../interfaces/providerModuleInterfaces';

export const PrivacyPreferences: React.FC<PrivacyPreferencesProps> = ({
  locale,
  setAccountSettings,
  setIsAllSectionsLoaded,
}) => {
  const [privacyPreferencesStatus, setPrivacyPreferencesStatus] =
    useState<PrivacyPreferencesState | null>(null);

  const { user: loggedUser } = useContext(AuthContext);

  useGetUserPrivacyPreferencesQuery({
    variables: {
      id: loggedUser?.uuid,
    },
    onCompleted: (data) => {
      setPrivacyPreferencesStatus({
        PRIVACY_AFFILIATES_CODEX_LABS_SETTING:
          data.users[0].PRIVACY_AFFILIATES_CODEX_LABS_SETTING,
        PRIVACY_CALIFORNIA_SETTINGS: data.users[0].PRIVACY_CALIFORNIA_SETTINGS,
        PRIVACY_NON_AFFILIATES_CODEX_LABS_SETTING:
          data.users[0].PRIVACY_NON_AFFILIATES_CODEX_LABS_SETTING,
      });
      setIsAllSectionsLoaded((prevState) => ({
        ...prevState,
        privacyPreferences: true,
      }));
    },
  });

  const toggleHandler = (key: PrivacyPreferencesKeys, state: boolean) => {
    setPrivacyPreferencesStatus({
      ...privacyPreferencesStatus,
      [PrivacyPreferencesKeys[key]]: state,
    } as PrivacyPreferencesState);
  };

  useEffect(() => {
    setAccountSettings((prevState) => {
      return {
        ...prevState,
        ...privacyPreferencesStatus,
      } as AccountSettingsState;
    });
  }, [privacyPreferencesStatus, setAccountSettings]);

  return (
    <div>
      <div className="mb-[30px]">
        <p className="text-h7 font-bold uppercase">{locale.privacyTitle}</p>
        <p className="text-sm font-semibold text-med-gray">
          {locale.privacySubtitle}
        </p>
        {privacyPreferencesStatus !== null && (
          <>
            <ToggleComponent
              title={locale.privacyToggleTitle1}
              subtitle={locale.privacyToggleSubtitle1}
              onChangeHandler={(state) =>
                toggleHandler(
                  PrivacyPreferencesKeys.PRIVACY_AFFILIATES_CODEX_LABS_SETTING,
                  state,
                )
              }
              checkedState={
                privacyPreferencesStatus.PRIVACY_AFFILIATES_CODEX_LABS_SETTING
              }
              locale={locale}
            />
            <ToggleComponent
              title={locale.privacyToggleTitle2}
              subtitle={locale.privacyToggleSubtitle2}
              onChangeHandler={(state) =>
                toggleHandler(
                  PrivacyPreferencesKeys.PRIVACY_NON_AFFILIATES_CODEX_LABS_SETTING,
                  state,
                )
              }
              checkedState={
                privacyPreferencesStatus.PRIVACY_NON_AFFILIATES_CODEX_LABS_SETTING
              }
              locale={locale}
            />
            <ToggleComponent
              title={locale.privacyToggleTitle3}
              subtitle={locale.privacyToggleSubtitle3}
              onChangeHandler={(state) =>
                toggleHandler(
                  PrivacyPreferencesKeys.PRIVACY_CALIFORNIA_SETTINGS,
                  state,
                )
              }
              checkedState={
                privacyPreferencesStatus.PRIVACY_CALIFORNIA_SETTINGS
              }
              locale={locale}
            />
          </>
        )}
      </div>
    </div>
  );
};
