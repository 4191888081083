import { ReactComponent as ChevronDownIcon } from 'assets/icons/chevron-down.svg';
import { MediaType, SharedMedia } from '../interfaces/interfaces';
import Loader from 'components/loaderComponent';
import { useHandleMediaClick } from 'hooks/useHandleMediaClick';
import MediaLibraryTableFilter from 'app/my-account/pages/my-media-library/components/MediaLibraryTableFilter';
import { useGetComponent } from 'hooks/useGetComponent';
import { useMemo } from 'react';
import { componentIds } from 'utilities/constants';

interface SharedMediaTableProps {
  sharedMedia: SharedMedia[];
  onLoadMore: () => void;
  onFromDateChange: (date: string) => void;
  onToDateChange: (date: string) => void;
  fromDate: string;
  toDate: string;
  total: number;
  loading: boolean;
  localeByProp?: { [key: string]: string };
  onTypeChange?: (type: MediaType | null) => void;
  type?: MediaType | null;
  orderBy?: string;
  setOrderBy: (orderBy: string) => void;
}

const SharedMediaTable: React.FC<SharedMediaTableProps> = ({
  sharedMedia,
  onLoadMore,
  onFromDateChange,
  onToDateChange,
  fromDate,
  toDate,
  total,
  localeByProp = undefined,
  onTypeChange = () => {
    return;
  },
  type = undefined,
  loading,
  orderBy,
  setOrderBy,
}) => {
  const { data: localeByPage, loading: localeLoading } = useGetComponent({
    locale: 'en',
    componentId: componentIds.SHARED_MEDIA_LIBRARY,
  });

  const locale = useMemo(
    () => ({ ...(localeByPage || {}), ...(localeByProp || {}) }),
    [localeByPage, localeByProp],
  );

  const canLoadMore = total > sharedMedia.length;

  const [handleMediaClick, scansLoading] = useHandleMediaClick();

  if (scansLoading || localeLoading) return <Loader />;

  return (
    <div className="p-4 bg-white rounded-10 w-full">
      <div className="flex flex-col mb-4 items-start desktop:flex-row desktop:justify-between desktop:items-center">
        <div className="flex flex-col desktop:flex-row desktop:items-center">
          <h3 className="text-h3 text-dark-gray font-semibold">
            {locale?.sharedMediaTableTitle}
          </h3>
          <p className="text-base text-med-gray font-semibold mt-2.5 desktop:mt-0 desktop:ml-4 ">
            {locale?.showing} {sharedMedia.length} {locale?.of} {total}
          </p>
        </div>
        <MediaLibraryTableFilter
          fromDate={fromDate}
          toDate={toDate}
          orderBy={orderBy || null}
          handleFromDateChange={(e) => onFromDateChange(e.target.value)}
          handleToDateChange={(e) => onToDateChange(e.target.value)}
          handleSortChange={(e) => setOrderBy(e.target.value)}
          localeByProp={locale}
          showSourceFilter
          type={type}
          onTypeChange={onTypeChange}
        />
      </div>
      {loading ? (
        <Loader />
      ) : (
        <>
          {sharedMedia.length === 0 ? (
            <div className="py-10 flex justify-center">
              {locale?.noMatchingResults}
            </div>
          ) : (
            <>
              <div className="grid grid-rows-[96px] auto-rows-[96px] gap-5 justify-items-center items-center grid-cols-auto-fill-24 py-5">
                {sharedMedia.map((media) => (
                  <div
                    key={media.id}
                    className="overflow-hidden rounded-10 w-24 h-24 bg-cover bg-center cursor-pointer"
                  >
                    <img
                      src={media.img}
                      onClick={() => handleMediaClick(media)}
                      className="object-cover w-full h-full"
                      alt={locale?.sharedMediaAltText}
                    />
                  </div>
                ))}
              </div>
              {canLoadMore && (
                <>
                  <hr />

                  <div className="flex flex-col desktop:flex-row w-full justify-between items-center p-0 gap-[30px] mt-2.5">
                    <div className="flex flex-row justify-center items-center p-0 gap-[7px] cursor-pointer">
                      <div
                        className="flex flex-row justify-center items-center p-0 gap-[7px] cursor-pointer"
                        onClick={onLoadMore}
                      >
                        <div className="text-base text-clc-blue font-bold underline">
                          {locale?.loadMore}
                        </div>
                        <ChevronDownIcon className="w-[18px] h-2.5 fill-clc-blue" />
                      </div>
                    </div>
                  </div>
                </>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default SharedMediaTable;
