/* eslint-disable @typescript-eslint/no-unused-vars */
import { VonageContext } from 'context/VonageContext';
import {
  AppointmentChatMessageFrom,
  useGetVonageSessionMutation,
  useUploadVonageFileToStorageMutation,
} from 'graphql/generated/hasura';
import {
  RoomCredentials,
  RoomPublisherOptions,
  RoomVideoEffects,
  useRoom,
} from 'hooks/vonage/useRoom';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { ReactComponent as ChatIcon } from 'assets/icons/appointmentsIcons/chatIconL.svg';
import { ReactComponent as VideoIcon } from 'assets/icons/appointmentsIcons/videoIconL.svg';
import { MY_APPOINTMENTS } from 'utilities/routes';
import { ReactComponent as VideoCallIcon } from 'assets/icons/videoIcons/video.svg';
import { ReactComponent as AudioCallIcon } from 'assets/icons/videoIcons/audio.svg';
import { ReactComponent as ScreenShareIcon } from 'assets/icons/videoIcons/screenShare.svg';
import { ReactComponent as VideoCallDisableIcon } from 'assets/icons/videoIcons/videoDisable.svg';
import { ReactComponent as AudioCallDisableIcon } from 'assets/icons/videoIcons/audioDisable.svg';
import { ReactComponent as ScreenShareDisableIcon } from 'assets/icons/videoIcons/screenShareDisable.svg';
import { ReactComponent as EndCallIcon } from 'assets/icons/videoIcons/endCall.svg';
import { ReactComponent as AudioMobileDisableIcon } from 'assets/icons/videoIcons/mobile/audioDisable.svg';
import { ReactComponent as AttatchMedia } from 'assets/icons/attatchMedia.svg';
import { ReactComponent as AudioMobileEnableIcon } from 'assets/icons/videoIcons/mobile/audioEnable.svg';
import { ReactComponent as VideoMobileEnableIcon } from 'assets/icons/videoIcons/mobile/videoEnable.svg';
import { ReactComponent as VideoMobileDisableIcon } from 'assets/icons/videoIcons/mobile/videoDisable.svg';
import { ReactComponent as ScreenShareMobileDisableIcon } from 'assets/icons/videoIcons/mobile/shareScreenDisable.svg';
import { ReactComponent as ScreenShareMobileEnableIcon } from 'assets/icons/videoIcons/mobile/shareScreenEnable.svg';
import { ReactComponent as EndCallMobileIcon } from 'assets/icons/videoIcons/mobile/endCall.svg';
import { ChatSolidIcon } from 'assets/icons/ChatSolidIcon';
import { PreworkSolidIcon } from 'assets/icons/PreworkSolidIcon';
import { PreworkOutlineIcon } from 'assets/icons/PreworkOutlineIcon';
import { ChatOutlineIcon } from 'assets/icons/ChatOutlineIcon';
import { MyNotesOutlineIcon } from 'assets/icons/MyNotesOutlineIcon';
import { MyNotesSolidIcon } from 'assets/icons/MyNotesSolidIcon';
import {
  ChangeTextEvent,
  ChatMessage,
  MessageType,
  OnKeyDownEvent,
  SenderType,
  Tabs,
  VonageMessageType,
} from '../interfaces';
import { ChatComponent } from '../ChatComponent';
import { useGetPage } from 'hooks/useGetPage';
import {
  AllowedFormats,
  ImageFormat,
  KEY_CODE,
  genericActionsIds,
  pageIds,
} from 'utilities/constants';
import { Message, useSignal } from 'hooks/vonage/useSignal';
import { ReactComponent as ChevronRightIcon } from 'assets/icons/chevron-right-white.svg';
import CollapseTab from '../CollapseTab';
import Modal from 'components/modal/modalComponent';
import InputComponent from 'components/inputComponent';
import ButtonComponent from 'components/button/buttonComponent';
import { useScreenSharing } from 'hooks/vonage/useScreenSharing';
import { useIsMobile } from 'hooks/useIsMobile';
import BottomTabs from 'components/tabs/bottomTabs';
import {
  AppointmentChatMessageInput,
  AppointmentTypeEnum,
  useAddMessageToAppointmentMessageListByAppointmentCodexIdMutation,
  useGetAppointmentByCodexAppointmentIdQuery,
} from 'graphql/generated/remote-schema-hasura';
import { useGetGenericActions } from 'hooks/useGetGenericActions';
import { useInAppointmentActions } from 'hooks/useInAppointmentActions';
import { PreworkComponent } from '../PreworkComponent';

import { MyNotesComponent } from '../MyNotesComponents';
import { AuthContext, AuthContextType } from 'auth/context/AuthContext';
import { Roles } from '../../../../firebase/interfaces';
import { initializeTabsState } from './tabsUtils';
import Alert from 'components/alertComponent';
import { encodeMediaInChat } from 'utilities/functions';

export const groupChatMessages = (messages: ChatMessage[]) => {
  const messageList: ChatMessage[] = [];
  for (const message of messages) {
    const senderType = message.senderType;

    if (messageList.length && message.type !== MessageType.MEDIA) {
      const lastIndex = messageList.length - 1;
      const lastMessage = messageList[lastIndex];
      if (
        lastMessage.message &&
        message.message &&
        lastMessage.senderType === senderType &&
        lastMessage.type !== MessageType.MEDIA
      ) {
        messageList[lastIndex] = {
          ...lastMessage,
          messageGroup: lastMessage.messageGroup
            ? [...lastMessage.messageGroup, message]
            : [lastMessage, message],
        };
        continue;
      }
    }

    messageList.push(message);
  }
  return messageList;
};

const TAB_INACTIVE = -99;

const formatMessage = (
  message: Message[],
  currentRole: Roles,
): ChatMessage[] => {
  return message.map<ChatMessage>((message, key) => {
    const currentRoleAsSenderType =
      currentRole === Roles.PATIENT ? SenderType.PATIENT : SenderType.PROVIDER;
    return {
      id: key.toString(),
      message: message.data,
      senderType: message.isSentByMe
        ? currentRoleAsSenderType
        : currentRoleAsSenderType === SenderType.PATIENT
        ? SenderType.PROVIDER
        : SenderType.PATIENT,
      senderPicture: undefined,
      type: message.imageData ? MessageType.MEDIA : MessageType.MESSAGE,
      mediaURL: message.imageData?.mediaUrl,
    };
  });
};

export const VideoRoom: React.FC = () => {
  const { user: loggedUser } = useContext<AuthContextType>(AuthContext);
  const { user } = useContext(VonageContext);
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const [credentials, setCredentials] = useState<Record<string, string> | null>(
    null,
  );
  const { data: appoinmentsLocale, loading: appointmentsLoading } = useGetPage({
    locale: 'en',
    pageId: pageIds.MY_APPOINTMENTS,
  });
  const { data: genericAction, loading: genericActionLoading } =
    useGetGenericActions({
      locale: 'en',
      genericActionId: [genericActionsIds.CLOSE],
    });
  const [addMessages] =
    useAddMessageToAppointmentMessageListByAppointmentCodexIdMutation({});
  const [hasAudio, setHasAudio] = useState(user?.defaultSettings.publishAudio);
  const [hasVideo, setHasVideo] = useState(user?.defaultSettings.publishVideo);
  const [error, setError] = useState<Error | null>(null);
  const [chatMessages, setChatMessages] = useState<ChatMessage[]>([]);
  const location = useLocation();
  const appointmentType = location.state?.appointmentType;
  const [getCredentials] = useGetVonageSessionMutation({
    fetchPolicy: 'no-cache',
  });
  const { createCall, room } = useRoom();
  const { isScreenSharing, startScreenSharing, stopScreenSharing } =
    useScreenSharing({ room });
  const { listOfMessages, sendSignal } = useSignal({ room });
  const roomContainer = useRef<HTMLDivElement | null>(null);
  const [notes, setNotes] = useState('');
  const { leftEvent } = useInAppointmentActions(location.state.room, notes);
  const { data: locale, loading } = useGetPage({
    locale: 'en',
    pageId: pageIds.COMPLETE_MY_VISIT_SUMMARY_MODAL,
  });
  const firstRoomRender = useRef(false);
  const remainingTime = useRef<number>();
  const remainingTimeTimer = useRef<NodeJS.Timeout>();
  const [remainingTimeText, setRemainingTimeText] = useState('');
  const inputButtonRef = useRef<HTMLInputElement | null>(null);
  useEffect(
    () => () => {
      if (remainingTimeTimer.current !== undefined) {
        clearTimeout(remainingTimeTimer.current);
      }
    },
    [],
  );

  const parseRemainingTime = useCallback(() => {
    const seconds = Math.floor((remainingTime.current ?? 0) / 1000);
    const minutes = Math.floor(seconds / 60);
    if (minutes < 1) {
      return setRemainingTimeText(`${seconds} ${appoinmentsLocale.seconds}`);
    }
    return setRemainingTimeText(`${minutes} ${appoinmentsLocale.minutes}`);
  }, [appoinmentsLocale]);

  const { data: appointmentData, loading: appointmentDataLoading } =
    useGetAppointmentByCodexAppointmentIdQuery({
      variables: { appointmentCodexId: location.state.room },
      skip: !location.state.room,
    });

  const [active, setActive] = useState(Tabs.Chat);
  const [text, setText] = useState('');
  const [showTimeReminder, setShowTimeReminder] = useState(true);

  const handleOnToggle = (tab: number) => {
    if (tab === TAB_INACTIVE) return;
    setActive((prev) => (prev === tab ? -1 : tab));
  };

  const sendMessage = useCallback(
    (text: string, type = VonageMessageType.TEXT) => {
      if (room) sendSignal(text, type);
    },
    [room, sendSignal],
  );

  const changeText = (event: ChangeTextEvent) => {
    setText(event.target.value);
  };
  const onKeyDown = (e: OnKeyDownEvent) => {
    if (e.keyCode === KEY_CODE.ENTER) {
      e.preventDefault();
      sendMessage(text);
      setText('');
    }
  };

  useEffect(() => {
    setChatMessages(
      groupChatMessages(
        formatMessage(listOfMessages, loggedUser?.role ?? Roles.PATIENT),
      ),
    );
  }, [listOfMessages, loggedUser?.role]);

  useEffect(() => {
    const getVonageCredentials = async () => {
      try {
        const { data } = await getCredentials({
          variables: {
            room: location.state.room,
          },
        });
        const vonageCredentials = data?.GetVonageSession;
        setCredentials({
          apikey: vonageCredentials?.apiKey ?? '',
          sessionId: vonageCredentials?.sessionId ?? '',
          token: vonageCredentials?.token ?? '',
        });
      } catch (error) {
        setError(error as Error);
        console.log(`Error getting vonage credentials: ${error}`);
      }
    };
    getVonageCredentials();
  }, [getCredentials, location.state.room]);

  useEffect(() => {
    if (credentials && roomContainer.current && !firstRoomRender.current) {
      createCall(
        credentials as unknown as RoomCredentials,
        roomContainer.current as unknown as string,
        user?.userName as string,
        user?.videoEffects as unknown as RoomVideoEffects,
        {
          ...user?.defaultSettings,
        } as RoomPublisherOptions,
      );
      firstRoomRender.current = true;
    }
  }, [createCall, credentials, user, loading, appointmentsLoading]);

  const [uploadFile, { loading: isMediaSaving }] =
    useUploadVonageFileToStorageMutation();

  const handleImageUpload = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files && event.target.files[0];
      event.target.value = '';
      if (!file) return;
      if (!file || !AllowedFormats.includes(file.type as ImageFormat)) {
        return;
      }
      const buffer = await file.arrayBuffer();
      const result = await uploadFile({
        variables: {
          file: {
            mimetype: file.type,
            originalname: file.name,
            buffer: Array.from(new Uint8Array(buffer)),
          },
          appointmentId: location.state.room,
        },
      });
      const { fileId: mediaId, url: mediaUrl } =
        result.data?.UploadVonageFileToStorage?.file ?? {};
      if ((result.errors && result.errors.length) || !mediaId || !mediaUrl) {
        // TODO COD-2916: show error message
        return;
      }
      const message = encodeMediaInChat(mediaId, mediaUrl);
      sendMessage(message, VonageMessageType.IMAGE);
    },
    [location.state.room, sendMessage, uploadFile],
  );

  useEffect(() => {
    if (
      appointmentType &&
      appointmentType === AppointmentTypeEnum.Chat &&
      room &&
      room.camera
    ) {
      const camera = room.camera;
      camera.disableAudio();
      camera.disableVideo();
    }
  }, [appointmentType, room]);

  const toggleAudio = () => {
    if (room && room.camera) {
      const camera = room.camera;
      const isAudioEnabled = camera.isAudioEnabled();
      if (isAudioEnabled) {
        camera.disableAudio();
        setHasAudio(false);
      } else {
        camera.enableAudio();
        setHasAudio(true);
      }
    }
  };

  const toggleVideo = () => {
    if (room && room.camera) {
      const { camera } = room;
      const isVideoEnabled = camera.isVideoEnabled();
      if (isVideoEnabled) {
        camera.disableVideo();
        setHasVideo(false);
      } else {
        camera.enableVideo();
        setHasVideo(true);
      }
    }
  };

  const toIsoStringWithDay = (timeString: string): string => {
    const [hours, minutes] = timeString.split(':').map(Number);
    const now = new Date();
    now.setHours(hours, minutes, 0, 0);
    return now.toISOString();
  };

  const endCall = useCallback(() => {
    leftEvent();
    if (loggedUser?.role === Roles.PROVIDER) {
      const currentEventSource =
        loggedUser?.role === Roles.PROVIDER
          ? AppointmentChatMessageFrom.Provider
          : AppointmentChatMessageFrom.Patient;
      const messages = listOfMessages.map<AppointmentChatMessageInput>(
        (message) => ({
          message: message.data,
          from: message.isSentByMe
            ? currentEventSource
            : currentEventSource === AppointmentChatMessageFrom.Provider
            ? AppointmentChatMessageFrom.Patient
            : AppointmentChatMessageFrom.Provider,
          date: toIsoStringWithDay(message.date),
        }),
      );
      addMessages({
        variables: {
          appointmentCodexId: location.state.room,
          messages,
        },
      });
    }
    if (room) {
      room.leave();
    }
    if (loggedUser?.role === Roles.PROVIDER) {
      navigate(MY_APPOINTMENTS);
    } else if (loggedUser?.role === Roles.PATIENT) {
      navigate(MY_APPOINTMENTS, {
        state: {
          appointmentComplete: true,
        },
      });
    }
  }, [
    addMessages,
    leftEvent,
    listOfMessages,
    location.state.room,
    loggedUser?.role,
    navigate,
    room,
  ]);

  const handleTimeReminder = useCallback(
    (appointmentEndDate: Date) => {
      remainingTimeTimer.current = undefined;
      remainingTime.current = appointmentEndDate.getTime() - Date.now();
      if (remainingTime.current <= 0) {
        return endCall();
      }
      parseRemainingTime();
      remainingTimeTimer.current = setTimeout(
        () => handleTimeReminder(appointmentEndDate),
        1000,
      );
    },
    [endCall, parseRemainingTime],
  );

  const endTime = appointmentData?.getFHIRAppointmentByCodexId.appointment?.end;
  useEffect(() => {
    if (endTime) {
      if (remainingTimeTimer.current) {
        clearTimeout(remainingTimeTimer.current);
      }
      handleTimeReminder(new Date(endTime));
    }
  }, [endTime, handleTimeReminder]);

  const [tabs, setTabs] = useState(() =>
    initializeTabsState(appointmentType, loggedUser),
  );

  const handleTabSelected = (index: number) => {
    const updatedTabs = tabs.map((tab, i) => ({
      ...tab,
      isSelected: i === index,
    }));
    setTabs(updatedTabs);
  };

  const getIcon = () => {
    switch (appointmentType) {
      case AppointmentTypeEnum.Video:
        return <VideoIcon height="20" width="20" />;
      case AppointmentTypeEnum.Chat:
      default:
        return <ChatIcon height="20" width="20" />;
    }
  };

  const getTitle = () => {
    switch (appointmentType) {
      case AppointmentTypeEnum.Video:
        return appoinmentsLocale?.video?.deets;
      case AppointmentTypeEnum.Chat:
      default:
        return appoinmentsLocale?.chat?.deets;
    }
  };

  if (
    error ||
    !locale ||
    loading ||
    !appoinmentsLocale ||
    appointmentsLoading ||
    genericActionLoading ||
    !genericAction ||
    appointmentDataLoading
  )
    return null;

  return (
    <Modal
      isOpen={true}
      title={getTitle()}
      icon={getIcon()}
      onClose={endCall}
      modalDesktopWidth="desktop:max-w-full"
      modalDesktopHeight="desktop:h-full"
      disableOverflow={true}
      contentPaddingX="0"
      contentPaddingY="0"
      hFull
      customContentContainerClass="overflow-auto max-w-[100vw]"
    >
      <div className="flex flex-col h-full desktop:p-4 gap-2 overflow-auto">
        {showTimeReminder && (
          <Alert
            type="info"
            text={`${appoinmentsLocale.appointmentEnding} ${remainingTimeText}`}
            onClose={() => setShowTimeReminder(false)}
            isNotification
          />
        )}
        <div className="flex flex-row grow gap-4 justify-center items-center overflow-auto">
          <div
            className={`flex flex-col w-full justify-center items-center h-full ${
              appointmentType === AppointmentTypeEnum.Chat && !isMobile
                ? 'hidden desktop:hidden'
                : ''
            }`}
          >
            {appointmentType &&
            appointmentType === AppointmentTypeEnum.Video ? (
              <div
                id="roomContainer"
                className={`relative h-[85vh] top-0 w-screen desktop:w-full desktop:h-full ${
                  isMobile ? (tabs[0].isSelected ? 'flex' : 'hidden') : ''
                }`}
                ref={roomContainer}
              >
                <div
                  id="screenSharingContainer"
                  className="absolute top-0 left-0 z-10"
                >
                  {isScreenSharing && (
                    <div className="absolute top-0 left-0 right-0 bottom-0 z-10 flex justify-center items-center text-h4 font-medium text-neutral-50">
                      {appoinmentsLocale?.screenSharing}
                    </div>
                  )}
                </div>
                {isMobile && (
                  <div className="absolute top-[10%] left-4 z-20 flex flex-col gap-[30px]">
                    {hasVideo ? (
                      <VideoMobileEnableIcon
                        className="cursor-pointer"
                        onClick={toggleVideo}
                      />
                    ) : (
                      <VideoMobileDisableIcon
                        className="cursor-pointer"
                        onClick={toggleVideo}
                      />
                    )}
                    {hasAudio ? (
                      <AudioMobileEnableIcon
                        className="cursor-pointer"
                        onClick={toggleAudio}
                      />
                    ) : (
                      <AudioMobileDisableIcon
                        className="cursor-pointer"
                        onClick={toggleAudio}
                      />
                    )}
                    {isScreenSharing ? (
                      <ScreenShareMobileDisableIcon
                        className="cursor-pointer"
                        onClick={stopScreenSharing}
                      />
                    ) : (
                      <ScreenShareMobileEnableIcon
                        className="cursor-pointer"
                        onClick={startScreenSharing}
                      />
                    )}
                    <EndCallMobileIcon
                      className="cursor-pointer"
                      onClick={endCall}
                    />
                  </div>
                )}
              </div>
            ) : (
              <>
                <div id="roomContainer" ref={roomContainer} />
                <div id="screenSharingContainer" />
              </>
            )}
            {isMobile && (
              <div
                className={`flex flex-col w-full h-[85vh] p-5 pt-10 bg-white rounded-10 overflow-auto ${
                  isMobile && appointmentType === AppointmentTypeEnum.Video
                    ? tabs[1].isSelected
                      ? 'flex'
                      : 'hidden'
                    : tabs[1].isSelected
                    ? 'flex'
                    : 'hidden'
                }`}
              >
                <PreworkComponent
                  prework={appointmentData}
                  symptomsLocation={
                    appointmentData?.getFHIRAppointmentByCodexId.appointment
                      ?.symptomsLocation
                  }
                  locale={locale}
                />
              </div>
            )}
            {isMobile && loggedUser?.role === Roles.PROVIDER && (
              <div
                className={`flex flex-col w-full h-[85vh] p-5 pt-10 bg-white rounded-10 overflow-hidden ${
                  isMobile && appointmentType === AppointmentTypeEnum.Video
                    ? tabs[3].isSelected
                      ? 'flex'
                      : 'hidden'
                    : tabs[2].isSelected
                    ? 'flex'
                    : 'hidden'
                }`}
              >
                <MyNotesComponent
                  locale={appoinmentsLocale.myNotes}
                  value={notes}
                  onChange={setNotes}
                />
              </div>
            )}
            {isMobile && (
              <div
                className={`flex flex-col w-full h-[85vh] p-2 bg-white rounded-10 overflow-hidden ${
                  isMobile && appointmentType === AppointmentTypeEnum.Video
                    ? tabs[2].isSelected
                      ? 'flex'
                      : 'hidden'
                    : tabs[0].isSelected
                    ? 'flex'
                    : 'hidden'
                }`}
              >
                <div className="h-[80vh] overflow-y-auto">
                  <ChatComponent
                    locale={locale.chatMessage}
                    messages={chatMessages}
                  />
                </div>
                <div className="flex items-center justify-center w-full gap-2">
                  <div className="flex w-full">
                    <InputComponent
                      testID="chatMessage-input"
                      type="text"
                      name="chatMessage"
                      onChange={changeText}
                      value={text}
                      onKeyDown={onKeyDown}
                      noMarginBottom
                      maxLengthValue={250}
                    />
                  </div>
                  <div>
                    <ButtonComponent
                      borderRadius="rounded-[5px]"
                      disabled={!text}
                      paddingX="px-[8px] desktop:px-[2px]"
                      paddingY="py-[5px] desktop:py-[5px]"
                      Icon={ChevronRightIcon}
                      onClick={() => {
                        sendMessage(text);
                        setText('');
                      }}
                    />
                  </div>
                </div>
              </div>
            )}
            {isMobile && (
              <div className="flex w-full h-[8vh]">
                <BottomTabs tabs={tabs} onTabSelected={handleTabSelected} />
              </div>
            )}
            {!isMobile &&
              appointmentType &&
              appointmentType === AppointmentTypeEnum.Video && (
                <div className="flex w-full justify-center items-center gap-[30px] bg-white p-[30px] rounded-b-10">
                  {hasVideo ? (
                    <VideoCallIcon
                      className="cursor-pointer"
                      onClick={toggleVideo}
                    />
                  ) : (
                    <VideoCallDisableIcon
                      className="cursor-pointer"
                      onClick={toggleVideo}
                    />
                  )}
                  {hasAudio ? (
                    <AudioCallIcon
                      className="cursor-pointer"
                      onClick={toggleAudio}
                    />
                  ) : (
                    <AudioCallDisableIcon
                      className="cursor-pointer"
                      onClick={toggleAudio}
                    />
                  )}
                  {isScreenSharing ? (
                    <ScreenShareDisableIcon
                      className="cursor-pointer"
                      onClick={stopScreenSharing}
                    />
                  ) : (
                    <ScreenShareIcon
                      className="cursor-pointer"
                      onClick={startScreenSharing}
                    />
                  )}
                  <EndCallIcon className="cursor-pointer" onClick={endCall} />
                </div>
              )}
          </div>
          {!isMobile && (
            <>
              {appointmentType === AppointmentTypeEnum.Chat && (
                <div className="flex flex-col w-5/12 h-full bg-white rounded-10 overflow-hidden">
                  <CollapseTab
                    active
                    activeIcon={PreworkSolidIcon}
                    icon={PreworkOutlineIcon}
                    label={locale.preworkTab}
                    onClick={() => handleOnToggle(TAB_INACTIVE)}
                    standalone
                    hiddeArrow={appointmentType === AppointmentTypeEnum.Chat}
                  >
                    <PreworkComponent
                      prework={appointmentData}
                      symptomsLocation={
                        appointmentData?.getFHIRAppointmentByCodexId.appointment
                          ?.symptomsLocation
                      }
                      locale={locale}
                    />
                  </CollapseTab>
                </div>
              )}
              <div
                className={`flex flex-col ${
                  appointmentType === AppointmentTypeEnum.Video
                    ? 'w-5/12'
                    : 'w-full'
                } h-full bg-white rounded-10 overflow-hidden`}
              >
                {appointmentType === AppointmentTypeEnum.Video && (
                  <CollapseTab
                    active={
                      active === Tabs.Prework ||
                      appointmentType === AppointmentTypeEnum.Chat
                    }
                    activeIcon={PreworkSolidIcon}
                    icon={PreworkOutlineIcon}
                    label={locale.preworkTab}
                    onClick={() => handleOnToggle(Tabs.Prework)}
                  >
                    <PreworkComponent
                      prework={appointmentData}
                      symptomsLocation={
                        appointmentData?.getFHIRAppointmentByCodexId.appointment
                          ?.symptomsLocation
                      }
                      locale={locale}
                    />
                  </CollapseTab>
                )}
                <CollapseTab
                  active={
                    active === Tabs.Chat ||
                    appointmentType === AppointmentTypeEnum.Chat
                  }
                  activeIcon={ChatSolidIcon}
                  borderBottom={active === Tabs.Chat}
                  icon={ChatOutlineIcon}
                  label={locale.chatTab}
                  standalone={appointmentType === AppointmentTypeEnum.Chat}
                  onClick={() =>
                    handleOnToggle(
                      appointmentType === AppointmentTypeEnum.Video
                        ? Tabs.Chat
                        : TAB_INACTIVE,
                    )
                  }
                  hiddeArrow={appointmentType === AppointmentTypeEnum.Chat}
                >
                  <div
                    className={`h-full flex flex-col gap-2 justify-between ${
                      isMediaSaving ? 'opacity-50' : ''
                    }`}
                  >
                    <div className="overflow-y-auto">
                      <ChatComponent
                        locale={locale.chatMessage}
                        messages={chatMessages}
                      />
                    </div>
                    <div className="flex items-center justify-center w-full pt-1 gap-2">
                      <input
                        type="file"
                        accept="image/*"
                        onChange={handleImageUpload}
                        ref={inputButtonRef}
                        className="hidden"
                      />
                      <div
                        className="cursor-pointer flex items-center justify-center rounded-full"
                        onClick={() => inputButtonRef.current?.click()}
                      >
                        <AttatchMedia className="w-6 h-6 fill-clc-blue" />
                      </div>
                      <div className="flex grow">
                        <InputComponent
                          testID="chatMessage-input"
                          type="text"
                          name="chatMessage"
                          onChange={changeText}
                          value={text}
                          onKeyDown={onKeyDown}
                          noMarginBottom
                          maxLengthValue={250}
                        />
                      </div>
                      <ButtonComponent
                        borderRadius="rounded-[5px]"
                        disabled={!text}
                        paddingX="px-[8px] desktop:px-[2px]"
                        paddingY="py-[5px] desktop:py-[5px]"
                        Icon={ChevronRightIcon}
                        onClick={() => {
                          sendMessage(text);
                          setText('');
                        }}
                      />
                    </div>
                  </div>
                </CollapseTab>
                {appointmentType === AppointmentTypeEnum.Video &&
                  loggedUser?.role === Roles.PROVIDER && (
                    <CollapseTab
                      active={
                        active === Tabs.MyNotes ||
                        appointmentType === AppointmentTypeEnum.Chat
                      }
                      activeIcon={MyNotesSolidIcon}
                      icon={MyNotesOutlineIcon}
                      label={locale.myNotes}
                      onClick={() => handleOnToggle(Tabs.MyNotes)}
                    >
                      <MyNotesComponent
                        locale={appoinmentsLocale.myNotes}
                        value={notes}
                        onChange={setNotes}
                      />
                    </CollapseTab>
                  )}
              </div>
              {appointmentType === AppointmentTypeEnum.Chat &&
                loggedUser?.role === Roles.PROVIDER && (
                  <div className="flex flex-col w-5/12 h-full bg-white rounded-10 overflow-hidden">
                    <CollapseTab
                      active
                      activeIcon={MyNotesSolidIcon}
                      icon={MyNotesOutlineIcon}
                      label={locale.myNotes}
                      onClick={() => handleOnToggle(TAB_INACTIVE)}
                      hiddeArrow={appointmentType === AppointmentTypeEnum.Chat}
                      inverted
                    >
                      <MyNotesComponent
                        locale={appoinmentsLocale.myNotes}
                        value={notes}
                        onChange={setNotes}
                      />
                    </CollapseTab>
                  </div>
                )}
            </>
          )}
        </div>
      </div>
    </Modal>
  );
};
