import { CheckboxComponentProps } from '../interfaces/checkboxInterfaces';
import InputComponent from 'components/inputComponent';
import { AlertPreferencesKeys } from '../interfaces/alertPreferencesInterfaces';

export const CheckboxComponent: React.FC<CheckboxComponentProps> = ({
  title,
  subtitle,
  locale,
  onChangeHandler,
  option1CheckedState,
  option2CheckedState,
  disableMarginBottom = false,
  identifier,
}) => {
  return (
    <div
      className={`desktop:pl-1 w-full flex flex-col justify-between max-w-[690px] mx-auto ${
        disableMarginBottom ? 'my-[30px] mb-0' : 'my-[30px]'
      }`}
    >
      <div className="mb-[15px] desktop:mb-0">
        <p className="text-base font-semibold text-charcoal-gray">{title}</p>
        <p className="text-sm font-semibold text-med-gray mb-1">{subtitle}</p>
      </div>
      <div className="flex flex-col justify-start cursor-pointer gap-2">
        <InputComponent
          type="checkbox"
          checkboxProps={{
            checkboxLabel: locale.alertCheckboxOption1,
            checked: option1CheckedState,
            onCheckboxChange: () =>
              onChangeHandler(AlertPreferencesKeys.PRIMARY_EMAIL, identifier),
          }}
        />
        <InputComponent
          type="checkbox"
          checkboxProps={{
            checkboxLabel: locale.alertCheckboxOption2,
            checked: option2CheckedState,
            onCheckboxChange: () =>
              onChangeHandler(AlertPreferencesKeys.MOBILE_MESSAGE, identifier),
          }}
        />
      </div>
    </div>
  );
};
