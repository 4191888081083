import React from 'react';
import { ButtonProps } from './button.interfaces';
import { twMerge } from 'tailwind-merge';
import IconComponent from 'components/iconComponent';
import Spinner from 'components/Spinner';

const ButtonComponent: React.FC<ButtonProps> = ({
  borderRadius,
  children,
  desktopFullWidth = false,
  onClick,
  disabled = false,
  paddingX,
  paddingY,
  type,
  Icon,
  fill,
  fontSize,
  fontWeightUnderline,
  stroke,
  hoverFill,
  hoverStroke,
  iconPosition = 'right',
  iconWidth,
  iconHeight,
  testID,
  className = '',
  containerClassName = '',
  noUpperCase = false,
  fullWidthClassName = 'w-full',
  showSpinner = false,
}) => {
  const commonClasses = `${fullWidthClassName} desktop:${
    desktopFullWidth ? fullWidthClassName : 'w-auto'
  } ${borderRadius ? borderRadius : 'rounded-lg'} ${
    fontSize ? fontSize : 'text-base'
  }
  text-center text-base font-bold cursor-pointer`;
  const disabledClasses =
    'disabled:opacity-35 disabled:cursor-not-allowed disabled:transition-none';
  const containerOrientationClass =
    iconPosition === 'top' ? 'flex-col' : 'flex-row';
  let baseClasses;
  let hoverClasses;
  let activeClasses;
  let paddingXClass;
  let paddingYClass;

  switch (type) {
    case 'outlined':
      baseClasses = `text-clc-blue bg-white border-solid border-2 border-clc-blue ${
        noUpperCase ? '' : 'uppercase'
      }`;
      hoverClasses = 'hover:bg-clc-blue hover:text-white';
      activeClasses = 'active:bg-clc-blue-medium active:text-white';
      paddingXClass = paddingX ? paddingX : 'px-[18px]';
      paddingYClass = paddingY ? paddingY : 'py-[9.5px]';
      break;
    case 'outlined-transparent':
      baseClasses = `text-clc-blue bg-transparent border-solid border-2 border-clc-blue ${
        noUpperCase ? '' : 'uppercase'
      }`;
      hoverClasses = 'hover:bg-clc-blue hover:text-white';
      activeClasses = 'active:bg-clc-blue-medium active:text-white';
      paddingXClass = paddingX ? paddingX : 'px-[18px]';
      paddingYClass = paddingY ? paddingY : 'py-[9.5px]';
      break;
    case 'icon':
      baseClasses = 'desktop:max-w-[45px] h-[45px] text-clc-blue bg-white';
      hoverClasses = 'hover:text-white hover:bg-clc-blue';
      activeClasses = 'active:bg-clc-blue-medium active:text-white';
      paddingXClass = paddingX ? paddingX : 'px-[18px]';
      paddingYClass = paddingY ? paddingY : 'py-[9.5px]';
      break;
    case 'underline':
      baseClasses = `text-h7 ${
        fontWeightUnderline ? fontWeightUnderline : 'font-semibold'
      } text-clc-blue underline ${noUpperCase ? '' : 'uppercase'}`;
      paddingXClass = paddingX ? paddingX : 'px-0';
      paddingYClass = paddingY ? paddingY : 'py-0';
      break;
    case 'social':
      baseClasses = 'text-dark-gray bg-white';
      paddingXClass = paddingX ? paddingX : 'px-[30px]';
      paddingYClass = paddingY ? paddingY : 'py-[10.5px]';
      break;
    default:
      baseClasses = `text-white bg-clc-blue border-solid border-2 border-clc-blue ${
        noUpperCase ? '' : 'uppercase'
      }`;
      hoverClasses = 'hover:text-clc-blue hover:bg-white';
      activeClasses = 'active:bg-clc-blue-medium active:text-white';
      paddingXClass = paddingX ? paddingX : 'px-[18px]';
      paddingYClass = paddingY ? paddingY : 'py-[9.5px]';
  }

  return (
    <button
      data-testid={testID}
      className={twMerge(
        `${commonClasses} ${baseClasses} ${hoverClasses} ${activeClasses} ${paddingXClass} ${paddingYClass} ${disabledClasses}`,
        className,
      )}
      onClick={onClick}
      disabled={disabled}
    >
      {disabled && showSpinner ? (
        <Spinner />
      ) : (
        <div
          className={`flex ${containerClassName} ${containerOrientationClass} items-center justify-center gap-2.5`}
        >
          {(iconPosition === 'top' || iconPosition === 'left') && Icon && (
            <IconComponent
              width={iconWidth}
              height={`${iconHeight} max-h-[22px]`}
              icon={Icon}
              fill={fill || 'fill-current'}
              stroke={stroke || 'stroke-current'}
              hoverStroke={hoverStroke}
              hoverFill={hoverFill}
            />
          )}
          {children}
          {iconPosition === 'right' && Icon && (
            <>
              <IconComponent
                width={iconWidth}
                height={`${iconHeight} max-h-[22px]`}
                icon={Icon}
                fill={fill || 'fill-current'}
                stroke={stroke || 'stroke-current'}
                hoverStroke={hoverStroke}
                hoverFill={hoverFill}
              />
            </>
          )}
        </div>
      )}
    </button>
  );
};

export default ButtonComponent;
