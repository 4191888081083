import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useCamera } from 'hooks/useCamera';
import { useGetGenericActions } from 'hooks/useGetGenericActions';
import { ReactComponent as CloseIcon } from 'assets/icons/iconClose.svg';
import { ReactComponent as FlashIcon } from 'assets/icons/flash.svg';
import { ReactComponent as TakePhotoIcon } from 'assets/icons/takePhoto.svg';
import { ReactComponent as RotateIcon } from 'assets/icons/rotate.svg';
import { ReactComponent as RotateDeviceIcon } from 'assets/icons/rotate-device.svg';
import { ReactComponent as ScanGuide } from 'assets/images/scanGuide.svg';
import {
  genericActionsIds,
  LOCAL_STORAGE_USER_FROM_QR,
  pageIds,
} from 'utilities/constants';
import { useGetPage } from 'hooks/useGetPage';
import { MY_SKIN_SUBMIT_PHOTO } from 'utilities/routes';
import { ModalButtonProps } from 'components/modal/modal.interfaces';
import { Helmet } from 'react-helmet';
import Loader from 'components/loaderComponent';
import Modal from 'components/modal/modalComponent';
import Alert from 'components/alertComponent';
import {
  ScreenOrientation,
  useScreenOrientation,
} from 'hooks/useScreenOrientation';

export const Camera = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const token = location.state?.token || '';
  const { data: locale, loading } = useGetPage({
    locale: 'en',
    pageId: pageIds.CAMERA,
  });
  const { data: genericAction, loading: genericActionLoading } =
    useGetGenericActions({
      locale: 'en',
      genericActionId: [genericActionsIds.TRYAGAIN],
    });

  const {
    videoRef,
    canvasRef,
    cameraFacingMode,
    switchCameraFacingMode,
    isTorchSupported,
    supportsFacingMode,
    captureImage,
    toggleFlash,
    initializeCamera,
    stopCamera,
    isLoading: isLoadingCamera,
    error,
  } = useCamera();
  const handleCaptureImage = () => {
    const image = captureImage();
    if (image) {
      stopCamera();
      navigate(MY_SKIN_SUBMIT_PHOTO, {
        state: { image, token },
        replace: true,
      });
    }
  };
  const userFromQrCode =
    localStorage.getItem(LOCAL_STORAGE_USER_FROM_QR) === 'true';

  const onOrientationChange = useCallback(
    (orientation: ScreenOrientation) => {
      switch (orientation) {
        case ScreenOrientation.Portrait:
          initializeCamera();
          break;
        case ScreenOrientation.Landscape:
          stopCamera();
          break;
        default:
          console.log(`Unexpected orientation: ${orientation}`);
          stopCamera();
          break;
      }
    },
    [initializeCamera, stopCamera],
  );

  const { screenOrientation } = useScreenOrientation(onOrientationChange);

  const navigateBack = () => {
    navigate(-1);
  };

  const retryInitializeCamera = () => {
    initializeCamera();
  };

  if (loading || !locale || genericActionLoading || !genericAction) return null;

  const ErrorButtons: ModalButtonProps[] = [
    {
      label: genericAction?.[genericActionsIds.TRYAGAIN].tryAgain,
      onClick: retryInitializeCamera,
    },
  ];

  return (
    <>
      <Helmet>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
        />
      </Helmet>
      {screenOrientation === 'portrait' ? (
        <>
          <div
            className={`${error === 'fatal' ? 'flex flex-col' : 'hidden'} z-50`}
          >
            <Modal
              isOpen={true}
              title={locale?.cameraError}
              buttons={ErrorButtons}
              disableMobileClose={true}
            >
              <Alert
                type="warning"
                text={locale?.errorWhenInitializingCamera}
              />
            </Modal>
          </div>

          <div
            className={`${
              isLoadingCamera ? 'flex flex-col' : 'hidden'
            } fixed top-0 left-0 w-full h-full items-center justify-center bg-base-content gap-10 z-100`}
          >
            <Loader />

            <p className="text-h3 font-semibold text-dark-gray">
              {locale?.loading}
            </p>
          </div>

          <div
            className={`${isLoadingCamera || error ? 'hidden' : 'flex-col'}`}
          >
            <div className="fixed top-0 left-0 w-screen h-screen bg-camera-blur">
              <video
                className="w-full h-full object-cover"
                ref={videoRef}
                autoPlay={true}
              />
              <canvas className="w-full h-full opacity-0" ref={canvasRef} />
            </div>

            <div className="absolute w-full bg-camera-blur top-0 left-0 h-[165px]" />
            <div className="flex flex-col w-full items-center p-0 absolute left-0 top-2.5 px-[38px] z-50">
              <div className="flex flex-row w-full self-start justify-between">
                <p className="text-h3 font-semibold text-white pt-[19px] pb-2.5">
                  {locale?.title}
                </p>
                {!userFromQrCode && (
                  <CloseIcon
                    className="w-8 h-full stroke-white pt-2.5"
                    onClick={() => navigateBack()}
                  />
                )}
              </div>
              <p className="text-base font-bold self-start text-white">
                {locale?.subtitle}
              </p>
            </div>

            <div className="absolute flex flex-row items-center justify-center inset-0 w-full h-full">
              <ScanGuide />
            </div>

            <div className="absolute flex flex-row justify-between items-center w-full bg-camera-blur bottom-0 left-0 h-[113px] px-7 py-[21px] z-50">
              <div className="w-9">
                {cameraFacingMode === 'environment' && isTorchSupported() && (
                  <FlashIcon onClick={toggleFlash} />
                )}
              </div>
              <TakePhotoIcon onClick={handleCaptureImage} />
              <div className="w-9">
                {supportsFacingMode() && (
                  <RotateIcon onClick={switchCameraFacingMode} />
                )}
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="flex flex-col bg-base-content justify-center items-center h-screen w-full gap-5 z-50">
          <RotateDeviceIcon className="w-16 h-16 fill-dark-gray" />
          <p className="text-h3 font-semibold text-dark-gray">
            {locale?.rotateDevice}
          </p>
        </div>
      )}
    </>
  );
};
