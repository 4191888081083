import { phoneNumberPattern } from 'utilities/variables';
import { useGetPage } from 'hooks/useGetPage';
import { pageIds } from 'utilities/constants';
import InputComponent, {
  SelectWithFlagsValues,
} from 'components/inputComponent';
import { parseMappedCountriesIntoSelectValues } from 'utilities/functions';
import { useMemo } from 'react';

interface MfaPhoneInputComponentProps {
  country: string;
  countries: {
    code: string | null | undefined;
    flag: string | null | undefined;
  }[];
  onCountryChange: (country: SelectWithFlagsValues) => void;
  onPhoneNumberChange: (phoneNumber: string) => void;
  phoneNumber: string;
}

export const MfaPhoneInputComponent: React.FC<MfaPhoneInputComponentProps> = ({
  countries,
  onCountryChange,
  onPhoneNumberChange,
  country,
  phoneNumber,
}) => {
  const { data: locale, loading } = useGetPage({
    locale: 'en',
    pageId: pageIds.MULTI_FACTOR_AUTH,
  });

  const parseSelectedValue = (
    currentCountry: string,
  ): SelectWithFlagsValues | undefined => {
    const values = parseMappedCountriesIntoSelectValues(countries);
    const matchingValue = values.find((item) => item.value === currentCountry);

    if (
      matchingValue &&
      matchingValue.label &&
      matchingValue.value &&
      matchingValue.flag
    ) {
      return {
        label: matchingValue.label,
        value: matchingValue.value,
        flag: matchingValue.flag,
      };
    } else {
      return undefined;
    }
  };

  const getMemoizedDefaultParsedValue = useMemo(
    () => parseMappedCountriesIntoSelectValues(countries)[0],
    [countries],
  );

  const getMemoizedParsedCountries = useMemo(
    () => parseMappedCountriesIntoSelectValues(countries),
    [countries],
  );

  if (loading && !locale) return null;

  return (
    <>
      <hr className="mt-4 mb-[15px]" />
      <p className="text-base text-med-gray pb-[15px] desktop:mt-5 desktop:mb-2.5">
        {locale?.mfaSMS.smsNotice}
      </p>

      <div className="flex flex-col items-left desktop:flex-row desktop:items-center desktop:justify-center">
        <label
          className="block mb-2.5 text-base text-dark-gray font-bold desktop:justify-self-center desktop:mb-2 desktop:mr-2.5"
          htmlFor="phoneNumber"
        >
          {locale?.mfaSMS.phoneLabel}
        </label>

        <div className="flex flex-row">
          <InputComponent
            testID="phoneNumber-country-select"
            type="select-with-country-icons"
            name="country"
            borderRadiusRight={true}
            selectInputWithFlagsProps={{
              currentValue: parseSelectedValue(country),
              defaultValue:
                getMemoizedDefaultParsedValue as SelectWithFlagsValues,
              options: getMemoizedParsedCountries as SelectWithFlagsValues[],
              onSelectChange: (e) =>
                onCountryChange(e as SelectWithFlagsValues),
            }}
          />

          <InputComponent
            testID="phoneNumber-input"
            disableTelWidth={true}
            type="tel"
            pattern={phoneNumberPattern}
            name="phoneNumber"
            onChange={(e) => onPhoneNumberChange(e.target.value)}
            borderRadiusLeft={true}
            value={phoneNumber}
          />
        </div>
      </div>
    </>
  );
};
