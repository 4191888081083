import { useGetPage } from 'hooks/useGetPage';
import { pageIds } from 'utilities/constants';
import { ProviderFilter } from '../components/ProviderFilter';
import { ProviderCard } from '../components/ProviderCard';
import TabsComponent from 'components/tabs/tabsComponent';
import { Tab } from 'components/tabs/tabs.interface';
import {
  PROVIDERS,
  PROVIDERS_FIND_A_PROVIDER,
  PROVIDERS_PAST_PROVIDERS,
} from 'utilities/routes';
import { useState } from 'react';
import {
  ProviderOutput,
  parseServiceType,
} from 'app/my-appointments/components/select-a-provider/ProviderCardProps';
import {
  AppointmentTypeEnum,
  useGetProvidersByPatientIdQuery,
} from 'graphql/generated/remote-schema-hasura';
import Loader from 'components/loaderComponent';
import { useAuth } from 'auth/context/AuthContext';
import { useChannelSignal, SIGNAL_CHANNELS } from 'hooks/useChannelSignal';

const DESKTOP_COlUMNS = 4;

type FindAProviderFilterType = Record<string, string>;

const initialFilter = {
  search: '',
};

export const MyProviders = () => {
  const { data: locale, loading } = useGetPage({
    locale: 'en',
    pageId: pageIds.PROVIDERS_SELECTION,
  });
  const [currentFilter, setCurrentFilter] = useState<FindAProviderFilterType>(
    {},
  );
  const [filter, setFilter] = useState<FindAProviderFilterType>(initialFilter);
  const [providers, setProviders] = useState<ProviderOutput[]>([]);
  const { loading: loadingProviders, refetch } =
    useGetProvidersByPatientIdQuery({
      fetchPolicy: 'network-only',
      variables: {
        ...filter,
      },
      onError: (error) => {
        console.error('Error getting providers', error);
        return setProviders([]);
      },
      onCompleted: (data) => {
        if (!data?.getProvidersByPatientId?.providers?.length) {
          return setProviders([]);
        }
        const providers =
          data.getProvidersByPatientId.providers.map<ProviderOutput>(
            (provider) =>
              ({
                ...provider,
                isFavorite: false,
                accepted_appointment_types: provider.serviceOfferings.map(
                  (serviceOffering) => ({
                    price: serviceOffering.price,
                    serviceType: parseServiceType(serviceOffering.serviceType),
                  }),
                ),
              } as ProviderOutput),
          );
        setProviders(providers);
      },
    });
  const isFirstInRow = (index: number, cols: number) => index % cols === 0;

  const renderProviderCards = (items: ProviderOutput[], cols: number) => {
    return items.map((item, index) => (
      <ProviderCard
        key={item.id}
        providerId={item.id}
        name={`${item.SENSITIVE_firstname} ${item.SENSITIVE_lastname}`}
        title={item.SENSITIVE_credentials.titles[0]}
        isFirstItem={isFirstInRow(index, cols)}
        location={`${item?.SENSITIVE_address_information?.address1}, ${item?.SENSITIVE_address_information?.city}, ${item?.SENSITIVE_address_information?.state}`}
        specialties={item.SENSITIVE_credentials.certifications}
        profileImage={item?.SENSITIVE_profile_picture_id ?? ''}
        rateAndServices={{
          video: Number(
            item.accepted_appointment_types.find(
              (type) => type.serviceType === AppointmentTypeEnum.Video,
            )?.price ?? 0,
          ),
          chat: Number(
            item.accepted_appointment_types.find(
              (type) => type.serviceType === AppointmentTypeEnum.Chat,
            )?.price ?? 0,
          ),
          messaging: Number(
            item.accepted_appointment_types.find(
              (type) => type.serviceType === AppointmentTypeEnum.Email,
            )?.price ?? 0,
          ),
        }}
        favorite={item.isFavorite}
        calendarId={item?.acuityCalendarId}
        ownerId={item?.acuityOwnerId}
      />
    ));
  };

  const tabs: Tab[] = [
    {
      name: locale?.myProviders?.title,
      url: PROVIDERS,
    },
    {
      name: locale?.pastProviders?.title,
      url: PROVIDERS_PAST_PROVIDERS,
    },
    {
      name: locale?.findAProvider?.title,
      url: PROVIDERS_FIND_A_PROVIDER,
    },
  ];

  const onSearchClick = () => {
    setFilter(() => ({
      ...currentFilter,
    }));
  };

  const { user } = useAuth();
  useChannelSignal(
    () => refetch(),
    SIGNAL_CHANNELS.PATIENTS_OF_PROVIDERS,
    user?.uuid,
  );

  if (loading || !locale) return null;

  return (
    <div className="px-7 pt-[30px] desktop:pt-0">
      <TabsComponent tabs={tabs} />
      <div className="flex flex-col desktop:flex-row w-full justify-between items-center p-0 gap-[30px] desktop:mb-[34px]">
        <div className="flex flex-row  w-full desktop:w-auto justify-between items-center gap-[7px]">
          <h1 className="text-left text-h1 text-dark-gray font-medium">
            {locale?.myProviders?.title}
          </h1>
        </div>
      </div>
      <div className="flex flex-col items-center px-5 pt-5 pb-[30px] desktop:p-[30px] gap-5 bg-white rounded-10">
        <ProviderFilter
          search={currentFilter?.search}
          onClick={onSearchClick}
          setCurrentFilter={setCurrentFilter}
        />
        {loadingProviders && (
          <div className="flex items-center justify-center">
            <Loader />
          </div>
        )}
        {!loadingProviders && (
          <div className="grid grid-cols-1 desktop:grid-cols-4 desktop:grid-rows-2 w-full border-t border-gray-opacity-15">
            {renderProviderCards(providers, DESKTOP_COlUMNS)}
          </div>
        )}
      </div>
    </div>
  );
};
