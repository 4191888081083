import { useGetPage } from 'hooks/useGetPage';
import { messageTemplatesIds, pageIds } from 'utilities/constants';
import { NotificationItem } from './NotificationItem';
import { ReactComponent as ArrowLoadMore } from 'assets/icons/arrowLoadMore.svg';
import { useEffect, useState } from 'react';
import { Notification } from 'app/dashboard/interfaces/notifications.interfaces';
import {
  User_Notification_Status_Enum,
  useUpdateInAppNotificationMutation,
} from 'graphql/generated/hasura';
import { relativeDate } from 'utilities/functions';
import { useLocation, useNavigate } from 'react-router-dom';

import ButtonComponent from 'components/button/buttonComponent';
import { useGetMessageTemplates } from 'hooks/useGetMessageTemplates';
import { ProfileSectionTypes } from 'app/my-account/pages/my-profile/enums';
import Loader from 'components/loaderComponent';
import { AlertExtraActions } from 'utilities/interfaces';
import { NOTIFICATIONS_MODAL } from 'utilities/routes';
import {
  NotificationResult,
  useNotificationsResult,
} from 'hooks/useNotifications';

const TIME_TO_CHANGE_NOTIFICATION_COLOR = 300;

interface NotificationsContentProps {
  userNotificationsQuery: useNotificationsResult;
  fetchMore: () => void;
}

export const NotificationsContent: React.FC<NotificationsContentProps> = ({
  userNotificationsQuery,
  fetchMore,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { data: locale, loading } = useGetPage({
    locale: 'en',
    pageId: pageIds.NOTIFICATIONS,
  });
  const { loading: loadingNotifications, notifications: notificationsData } =
    userNotificationsQuery;
  const [updateInAppNotificationMutation] = useUpdateInAppNotificationMutation(
    {},
  );

  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [selectedItemIndex, setSelectedItemIndex] = useState<number | null>(
    null,
  );
  const [textColor, setTextColor] = useState<string>(
    'text-clc-blue font-medium',
  );
  const { data: messageLocale, loading: messageLoading } =
    useGetMessageTemplates({
      locale: 'en',
      templateId: [
        messageTemplatesIds.MY_PROFILE,
        messageTemplatesIds.MY_PERSONAL_INFO,
        messageTemplatesIds.MY_CONTACT_INFO,
        messageTemplatesIds.HEALTH_QUESTIONNAIRE,
      ],
    });

  useEffect(() => {
    const populateNotificationsData = async (
      notifications: NotificationResult,
    ) => {
      const populateNotifications: Notification[] =
        notifications?.notifications?.map((notification) => {
          return {
            id: notification.id,
            body: notification.data.message,
            date: relativeDate(notification.data.date),
            isRead: notification.status === User_Notification_Status_Enum.Read,
            action: notification.data.action,
            extraActions: notification.data.extraActions,
          };
        }) ?? [];
      setNotifications(populateNotifications);
    };

    if (notificationsData?.notifications?.length) {
      populateNotificationsData(notificationsData);
    } else {
      setNotifications([]);
    }
  }, [notificationsData]);

  const handleLoadMore = () => {
    if (notificationsData) {
      fetchMore();
    }
  };

  const validateMyProfileNotification = (notification: string) => {
    return messageLocale?.includes(notification);
  };

  const handleOnClick = async (
    notificationId: number | undefined,
    route: string | undefined,
    index: number,
    message = '',
    isRead = false,
    action: AlertExtraActions | undefined,
    extraActions: AlertExtraActions[] | undefined,
    body: string,
  ) => {
    setSelectedItemIndex(index);
    setTextColor('text-clc-blue font-medium');
    setTimeout(() => {
      setTextColor('text-med-gray');
    }, TIME_TO_CHANGE_NOTIFICATION_COLOR);

    if (extraActions && !isRead) {
      return navigate(NOTIFICATIONS_MODAL, {
        state: {
          action,
          extraActions,
          body,
          notificationId,
        },
      });
    }

    if (notificationId) {
      try {
        const response = await updateInAppNotificationMutation({
          variables: {
            id: String(notificationId),
            status:
              validateMyProfileNotification(message) && !isRead
                ? User_Notification_Status_Enum.Seen
                : User_Notification_Status_Enum.Read,
          },
        });
        if (!response.data) {
          throw new Error('Failed to update notification');
        }
      } catch (error: unknown) {
        throw new Error('Failed to update notification');
      }
    }

    if (extraActions || action?.customAction) {
      return navigate(NOTIFICATIONS_MODAL, {
        state: { action, extraActions, body },
      });
    }

    if (route) {
      if (
        message
          .toLocaleUpperCase()
          .includes(ProfileSectionTypes.PROFILE.toLocaleUpperCase())
      ) {
        return navigate(route, {
          state: { section: ProfileSectionTypes.PROFILE },
        });
      }
      if (
        message
          .toLocaleUpperCase()
          .includes(ProfileSectionTypes.CONTACT.toLocaleUpperCase())
      ) {
        return navigate(route, {
          state: { section: ProfileSectionTypes.CONTACT },
        });
      }
      return navigate(route, { state: { referringPage: location.pathname } });
    }
  };

  if (
    loading ||
    !locale ||
    loadingNotifications ||
    messageLoading ||
    !messageLocale
  )
    return <Loader />;

  return (
    <>
      <div className="flex flex-col items-start px-7 desktop:items-center desktop:pb-[60px] desktop:gap-5">
        <div className="flex flex-col items-start desktop:items-center w-full desktop:px-[250px] gap-2.5 desktop:gap-5">
          <div className="flex flex-col items-center bg-white w-full pt-5 px-5 pb-10 desktop:p-[30px] gap-5 rounded-10">
            {notifications.length > 0 ? (
              <>
                {notifications.map((item, index) => (
                  <NotificationItem
                    key={item.id}
                    body={item.body}
                    date={item.date}
                    isRead={item.isRead}
                    isSelect={index === selectedItemIndex}
                    textColor={textColor}
                    onClick={() =>
                      handleOnClick(
                        item.id,
                        item?.action?.route,
                        index,
                        item.body,
                        item.isRead,
                        item?.action,
                        item?.extraActions,
                        item.body,
                      )
                    }
                  />
                ))}

                {notifications.length >=
                (notificationsData?.total ?? 0) ? null : (
                  <div className="flex flex-col w-full items-start p-0 gap-1 desktop:gap-[5px]">
                    <hr className="flex flex-row w-full items-center h-px bg-black-blur my-2.5" />
                    <div className="flex flex-row items-center cursor-pointer w-full desktop:w-fit">
                      <ButtonComponent
                        type="underline"
                        Icon={ArrowLoadMore}
                        iconPosition="right"
                        onClick={handleLoadMore}
                      >
                        {locale.seeMore}
                      </ButtonComponent>
                    </div>
                  </div>
                )}
              </>
            ) : (
              <div className="flex flex-col items-start gap-2.5 text-base text-dark-gray font-medium">
                {locale.noNotifications}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
