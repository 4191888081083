import { AppointmentTypeEnum, ServiceType } from 'graphql/generated/hasura';

export interface ProviderCredentialsOutput {
  titles: string[];
  states?: string[];
  certifications?: string[];
}

export interface AddressInformationOutput {
  address1?: string | null;
  city?: string | null;
  state?: string | null;
}

export function parseServiceType(
  serviceType: ServiceType,
): AppointmentTypeEnum {
  switch (serviceType) {
    case ServiceType.Chat:
      return AppointmentTypeEnum.Chat;
    case ServiceType.Video:
      return AppointmentTypeEnum.Video;
  }
}
export interface ServiceOfferingType {
  serviceType: AppointmentTypeEnum;
  price: string;
}

export interface ProviderOutput {
  id: string;
  SENSITIVE_firstname: string;
  SENSITIVE_lastname: string;
  SENSITIVE_address_information?: AddressInformationOutput;
  SENSITIVE_credentials: ProviderCredentialsOutput;
  SENSITIVE_profile_picture_id?: string | null;
  accepted_appointment_types: ServiceOfferingType[];
  isFavorite?: boolean;
  acuityOwnerId?: string | null;
  acuityCalendarId?: string | null;
}

export interface ProviderCardProps {
  providerInfo: ProviderOutput;
  onSelect: (
    codexProviderId: string,
    calendarId?: string | null,
    ownerId?: string | null,
  ) => void;
}
