import { useLocation, useNavigate } from 'react-router-dom';
import { useGetPage } from 'hooks/useGetPage';
import { pageIds } from 'utilities/constants';
import { ModalButtonProps } from 'components/modal/modal.interfaces';
import { AUTH_LOGIN, MY_SKIN_FACE_CAMERA } from 'utilities/routes';
import AlertComponent from 'components/alertComponent';
import Modal from 'components/modal/modalComponent';

export const ScanCompleted = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const token = location.state?.token || '';
  const invalidToken = location.state?.invalidToken || '';
  const unexpectedError = location.state?.unexpectedError || '';
  const { data: locale, loading } = useGetPage({
    locale: 'en',
    pageId: pageIds.SCAN_COMPLETED,
  });

  const handleLogin = () => {
    navigate(AUTH_LOGIN, { replace: true });
  };

  const handleCreateAnotherScan = () => {
    navigate(MY_SKIN_FACE_CAMERA, { state: { token }, replace: true });
  };

  if (loading || !locale) return null;

  const loginButton: ModalButtonProps = {
    label: locale?.logIn,
    onClick: handleLogin,
  };

  const createScanButton: ModalButtonProps = {
    label: locale?.createAnotherScan,
    type: 'outlined',
    onClick: handleCreateAnotherScan,
  };

  const Buttons: ModalButtonProps[] = invalidToken
    ? [loginButton]
    : [loginButton, createScanButton];

  const getAlertMessage = () => {
    if (invalidToken) {
      return locale?.invalidToken;
    } else if (unexpectedError) {
      return locale?.unexpectedError;
    } else {
      return locale?.successResults;
    }
  };

  return (
    <Modal
      isOpen={true}
      title={locale?.dermScoreResults}
      buttons={Buttons}
      disableMobileClose={true}
    >
      <div className="flex flex-col">
        <AlertComponent
          type={invalidToken || unexpectedError ? 'warning' : 'positive'}
          text={getAlertMessage()}
        />
      </div>
    </Modal>
  );
};
