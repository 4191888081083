import { AppointmentTypeEnum } from 'graphql/generated/remote-schema-hasura';
import { MyAppointmentDeets } from '../MyAppointmentDeets';
import { useEffect, useRef, useState } from 'react';

interface AppointmentNotStartedProps {
  locale: Record<string, Record<string, string>>;
  appointmentType: AppointmentTypeEnum;
  providerName?: string | null;
  patientName?: string | null;
  title?: string | null;
  appointmentStartsAt: number;
  onAppointmentStart?: () => void;
  profileImage?: string | null;
}

const COUNTDOWN_MEETING_START = 1000;

export const AppointmentNotStarted: React.FC<AppointmentNotStartedProps> = ({
  locale,
  appointmentType,
  providerName = null,
  patientName = null,
  title = null,
  appointmentStartsAt,
  onAppointmentStart,
  profileImage,
}) => {
  const [countdown, setCountdown] = useState(
    () => (appointmentStartsAt - Date.now()) / 1000,
  );
  const timerRef = useRef<NodeJS.Timer>();
  const timerSet = useRef<boolean>(false);

  useEffect(() => {
    if (timerSet.current) return;
    timerSet.current = true;
    timerRef.current = setInterval(() => {
      setCountdown((c) => (c > 0 ? c - 1 : c));
    }, COUNTDOWN_MEETING_START);
  }, [onAppointmentStart]);
  useEffect(() => {
    if (countdown <= 0) {
      setCountdown(0);
      onAppointmentStart && onAppointmentStart();
      clearInterval(timerRef.current);
    }
  }, [countdown, onAppointmentStart]);

  const hours = Math.floor(countdown / 3600);
  const remainingMinutes = countdown % 3600;
  const minutes = Math.floor(remainingMinutes / 60);
  const seconds = remainingMinutes % 60;

  return (
    <>
      <div className="w-full h-full flex flex-col items-center justify-center gap-[60px]">
        <div className="flex flex-col items-center gap-2">
          <div className="text-h1 font-medium text-dark-gray mb-9 text-center">
            {locale?.consultation?.welcome}
          </div>
          <div className="text-h6 font-normal text-med-gray">
            {locale?.consultation?.start}
          </div>
          <div className="flex flex-row gap-16">
            <div className="flex flex-col">
              <div className="flex justify-center text-h1 font-medium text-dark-gray">
                {hours}
              </div>
              <div className="text-h7 font-bold text-med-gray">
                {locale?.consultation?.hours.toUpperCase()}
              </div>
            </div>
            <div className="flex flex-col">
              <div className="flex justify-center text-h1 font-medium text-dark-gray">
                {minutes}
              </div>
              <div className="text-h7 font-bold text-med-gray">
                {locale?.consultation?.minutes.toUpperCase()}
              </div>
            </div>
            <div className="flex flex-col">
              <div className="flex justify-center text-h1 font-medium text-dark-gray">
                {Math.floor(seconds)}
              </div>
              <div className="text-h7 font-bold text-med-gray">
                {locale?.consultation?.seconds.toUpperCase()}
              </div>
            </div>
          </div>
        </div>
        <MyAppointmentDeets
          appointmentType={appointmentType}
          date={new Date(appointmentStartsAt).toISOString()}
          fullName={(providerName || patientName) as string}
          title={title}
          isProvider={Boolean(providerName)}
          profileImage={profileImage}
        />
      </div>
    </>
  );
};
