import { useEffect, useState } from 'react';
import { Dot } from './interfaces';
import { useGetComponent } from 'hooks/useGetComponent';
import { componentIds } from 'utilities/constants';
import { initialDotsState } from './constants';

export const useGetFrontAndBackDots = () => {
  const [frontDots, setFrontDots] = useState<Dot[]>([]);
  const [backDots, setBackDots] = useState<Dot[]>([]);

  const { data: locale, loading } = useGetComponent({
    locale: 'en',
    componentId: componentIds.APPOINTMENT_PREWORK,
  });

  /* Setting the locale into the dots to avoid hardcoding texts */
  useEffect(() => {
    if (locale && !loading) {
      const frontLocaleCheckbox = locale?.frontCheckboxes;
      const backLocaleCheckbox = locale?.backCheckboxes;
      if (
        frontLocaleCheckbox ||
        Object.values(frontLocaleCheckbox).length > 0
      ) {
        const mappedFrontLocaleCheckboxes = initialDotsState.front.map(
          (dot) => ({
            ...dot,
            location: frontLocaleCheckbox[dot.strapiKey],
          }),
        );
        setFrontDots(mappedFrontLocaleCheckboxes);
      }
      if (backLocaleCheckbox || Object.values(backLocaleCheckbox).length > 0) {
        const mappedBackLocaleCheckboxes = initialDotsState.back.map((dot) => ({
          ...dot,
          location: backLocaleCheckbox[dot.strapiKey],
        }));
        setBackDots(mappedBackLocaleCheckboxes);
      }
    }
  }, [locale, loading]);

  if (loading || !locale)
    return {
      frontDots,
      backDots,
      setFrontDots,
      setBackDots,
    };

  return { frontDots, backDots, setFrontDots, setBackDots };
};
