import { useGetPage } from 'hooks/useGetPage';
import { genericActionsIds, pageIds } from 'utilities/constants';
import { ReactComponent as ChevronDownIcon } from 'assets/icons/chevron-down.svg';
import { ReactComponent as ChevronUpIcon } from 'assets/icons/chevron-up.svg';
import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import { useGetGenericActions } from 'hooks/useGetGenericActions';
import { useGetUnauthenticatedQuestionsByIdsQuery } from 'graphql/generated/hasura';

import {
  GeneralStates,
  ProviderProfileDataOptional,
} from 'app/my-account/interfaces/profile.interfaces';
import {
  findQuestionById,
  getSelectedValues,
  parseQuestionOptionsForDropdownWithProperties,
  scrollToTop,
} from 'utilities/functions';
import ButtonComponent from 'components/button/buttonComponent';
import { usePatchFhirProviderAccountInfoByCodexIdMutation } from 'graphql/generated/remote-schema-hasura';
import {
  ProviderPersonalInfo,
  ProviderProfileInfoProps,
  Question,
} from './interfaces';
import { personalInfoRequiredFields, questionsIds } from '../constants';
import InputComponent from 'components/inputComponent';
import Select, { MultiValue } from 'react-select';
import {
  DropdownItem,
  DropdownOption,
  DropdownOptionsState,
} from 'components/dynamicQuestionnaire/interfaces/dynamicQuestionnaireResponse.interface';
import useFormValidation, { ValidationRules } from 'hooks/useFormValidation';
import ErrorMessageWithIcon from 'components/errorMessageWithIcon';

export const PersonalInfo: React.FC<ProviderProfileInfoProps> = ({
  gender,
  languagesOfCommunication,
  about,
  handleProviderProfileInfoChange,
  handleUpdatedProviderAccountInfo,
}) => {
  const [isSubmiting, setIsSubmiting] = useState(false);
  const { data: locale, loading } = useGetPage({
    locale: 'en',
    pageId: pageIds.MY_PROFILE,
  });
  const { data: genericAction, loading: genericActionLoading } =
    useGetGenericActions({
      locale: 'en',
      genericActionId: [genericActionsIds.UPDATE],
    });
  const [isFormDisplayed, setIsFormDisplayed] = useState<boolean>(true);
  const [formValues, setFormValues] = useState<ProviderProfileDataOptional>({
    gender,
    languagesOfCommunication,
    about,
  } as ProviderProfileDataOptional);

  const [patchFhirProviderAccountInfo] =
    usePatchFhirProviderAccountInfoByCodexIdMutation({});

  const handleShowForm = () => {
    setIsFormDisplayed(!isFormDisplayed);
  };

  const genderCapitalized =
    gender && gender?.charAt(0).toUpperCase() + gender?.slice(1);

  const [radioSelected, setRadioSelected] = useState<string>(
    genderCapitalized ?? '',
  );

  const handleGenderChange = (value: string) => {
    setRadioSelected(value);
    setFormValues({ ...formValues, gender: value });
  };

  const { data: questionsData, loading: questionsLoading } =
    useGetUnauthenticatedQuestionsByIdsQuery({
      variables: {
        ids: [
          questionsIds.GENDER_QUESTION,
          questionsIds.LANGUAGES_QUESTION,
          questionsIds.ABOUT_QUESTION,
        ],
      },
    });

  const genderQuestionData = (!questionsLoading &&
    questionsData &&
    findQuestionById(questionsData, questionsIds.GENDER_QUESTION)) as Question;

  const languagesQuestionData = (!questionsLoading &&
    questionsData &&
    findQuestionById(
      questionsData,
      questionsIds.LANGUAGES_QUESTION,
    )) as Question;

  const aboutQuestionData = (!questionsLoading &&
    questionsData &&
    findQuestionById(questionsData, questionsIds.ABOUT_QUESTION)) as Question;

  const [selectedValue, setSelectedValue] = useState<DropdownOptionsState[]>([
    { value: (formValues.languagesOfCommunication as string[]) ?? [] },
  ]);

  const handleOnInputChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const { value, name } = e.target;

    setFormValues({ ...formValues, [name]: value });
  };

  const validationRules: ValidationRules = {
    gender: [
      {
        validator: (value) => value.length > 0,
        message: locale?.providerLocales.fieldRequired,
      },
    ],
    languagesOfCommunication: [
      {
        validator: (value) => value.length > 0,
        message: locale?.providerLocales.fieldRequired,
      },
    ],
    about: [
      {
        validator: (value) => value.length > 0,
        message: locale?.providerLocales.fieldRequired,
      },
    ],
  };

  const { errors, validateForm } = useFormValidation(validationRules);

  useEffect(() => {
    handleProviderProfileInfoChange(formValues);
  }, [formValues, handleProviderProfileInfoChange]);

  const handleValidation = useCallback(
    (providerData: ProviderProfileDataOptional) => {
      const formData = JSON.parse(JSON.stringify(providerData));
      return validateForm(formData);
    },
    [validateForm],
  );

  const prevLocalFormDataRef = useRef<ProviderProfileDataOptional | null>(null);
  const validationExecutedRef = useRef(false);

  const handleValidationCallback = useCallback(() => {
    // Check if the validation has already been executed
    if (!validationExecutedRef.current) {
      if (handleValidation) {
        handleValidation(formValues);
      }
      validationExecutedRef.current = true;
    }
  }, [formValues, handleValidation]);

  useEffect(() => {
    // Check if localFormData is different from the previous data
    if (formValues !== prevLocalFormDataRef.current) {
      // Update the ref with the latest current data
      prevLocalFormDataRef.current = formValues;

      // Reset the validation executed ref when localFormData changes
      validationExecutedRef.current = false;

      // Run validation when localFormData changes
      handleValidationCallback();
    }
  }, [formValues, handleValidationCallback]);

  const validateRequiredFields = () => {
    for (const key in formValues) {
      if (
        personalInfoRequiredFields.find(
          (requiredField) => requiredField === key,
        )
      ) {
        if (
          !formValues[key as keyof ProviderPersonalInfo] ||
          formValues[key as keyof ProviderPersonalInfo]?.length === 0
        ) {
          return false;
        }
      }
    }
    return true;
  };

  const handleOnSubmit = async () => {
    const isFormValid = validateRequiredFields();
    if (!isFormValid)
      return handleUpdatedProviderAccountInfo(GeneralStates.MISSING_INFO);

    try {
      setIsSubmiting(true);
      const response = await patchFhirProviderAccountInfo({
        variables: {
          providerAccountInfo: {
            gender: formValues.gender,
            languagesOfCommunication:
              formValues.languagesOfCommunication as string[],
            about: formValues.about || '',
          },
        },
      });
      if (!response.data) {
        setIsSubmiting(false);
        handleUpdatedProviderAccountInfo(GeneralStates.ERROR);
        scrollToTop();
        throw new Error('Failed to update user');
      }

      handleUpdatedProviderAccountInfo(GeneralStates.SUCCESS);

      setIsSubmiting(false);
      scrollToTop();
    } catch (error: unknown) {
      setIsSubmiting(false);
      console.log(error);
      handleUpdatedProviderAccountInfo(GeneralStates.ERROR);
      scrollToTop();
      throw new Error('Failed to update user');
    }
  };

  const handleChange = (e: MultiValue<DropdownOption>, no: number) => {
    const newItem: DropdownOptionsState[] = selectedValue.map(
      (item: DropdownOptionsState) => {
        return selectedValue.indexOf(item) === no
          ? { value: Array.isArray(e) ? e.map((x) => x.value) : [] }
          : item;
      },
    );
    // If length of array is > 1 and none option is selected, remove it
    const filteredOptions = newItem.map((item) => {
      if (
        item.value.some((value) => value.toLowerCase() === 'none') &&
        item.value.length > 1
      ) {
        return {
          ...item,
          value: item.value.filter((value) => value.toLowerCase() !== 'none'),
        };
      } else {
        return item;
      }
    });
    setSelectedValue(filteredOptions);
    setFormValues({
      ...formValues,
      languagesOfCommunication: filteredOptions[0].value,
    });
  };

  if (
    loading ||
    !locale ||
    genericActionLoading ||
    !genericAction ||
    questionsLoading
  )
    return null;

  return (
    <div className="flex flex-col items-center bg-white w-full p-5 desktop:p-[30px] gap-5 rounded-10">
      <div
        className="flex flex-col items-start w-full gap-2.5 p-0 cursor-pointer desktop:cursor-default"
        onClick={handleShowForm}
      >
        <div className="flex flex-row w-full items-center justify-between">
          <div className="flex flex-row items-center">
            <h3 className="text-h5 desktop:text-h4 text-dark-gray font-semibold desktop:mr-2.5">
              {locale?.providerLocales?.personalInfoSection}
            </h3>
          </div>
          {isFormDisplayed ? (
            <ChevronUpIcon
              className="desktop:hidden w-[18px] h-2.5 fill-current cursor-pointer"
              onClick={handleShowForm}
            />
          ) : (
            <ChevronDownIcon
              className="desktop:hidden w-[18px] h-2.5 fill-current cursor-pointer"
              onClick={handleShowForm}
            />
          )}
        </div>
        <hr
          className={`desktop:flex flex-row w-full items-center h-px bg-black-blur ${
            isFormDisplayed ? 'flex' : 'hidden'
          }`}
        />
      </div>

      <div
        className={`w-full desktop:flex flex-col gap-[15px] ${
          isFormDisplayed ? 'flex' : 'hidden'
        }`}
      >
        <div className="flex flex-col w-full max-w-[750px] mx-auto">
          <div className="flex flex-col w-full desktop:w-[690px] mb-[35px]">
            <label className="w-full text-base text-dark-gray font-semibold mb-2">
              {genderQuestionData.text}
              <span className="text-base font-bold text-clc-red">
                {locale?.accountDeletion.form.required}
              </span>
            </label>
            {genderQuestionData &&
              genderQuestionData.answers &&
              genderQuestionData.answers.map((questionValue, key) => (
                <InputComponent
                  key={key}
                  type="radio"
                  radioInputProps={{
                    radioError: null,
                    radioInputValue: questionValue,
                    radioInputLabel: questionValue,
                    radioInputCheckedValue: radioSelected,
                    onRadioClick: (value) => {
                      handleGenderChange(value);
                    },
                  }}
                />
              ))}
          </div>

          <div className="flex flex-col w-full desktop:w-[750px] mb-[35px]">
            <div className="flex flex-col desktop:flex-row justify-between">
              <label className="justify-between w-full text-base text-dark-gray font-semibold mb-2">
                {languagesQuestionData.text}
                <span className="text-base font-bold text-clc-red">
                  {locale?.accountDeletion.form.required}
                </span>
              </label>
              <p className="w-full desktop:max-w-[150px] italic text-med-gray font-normal">
                {languagesQuestionData.subtitle}
              </p>
            </div>
            <Select
              className="dropdown"
              classNamePrefix={
                errors.languagesOfCommunication
                  ? 'react-select-with-error'
                  : 'react-select'
              }
              placeholder={locale?.selectLanguagePlaceholder}
              value={getSelectedValues(
                parseQuestionOptionsForDropdownWithProperties(
                  languagesQuestionData.answers as unknown as DropdownItem[],
                ),
                selectedValue[0].value,
              )}
              options={parseQuestionOptionsForDropdownWithProperties(
                languagesQuestionData.answers as unknown as DropdownItem[],
              )}
              onChange={(event) => {
                return handleChange(event, 0);
              }}
              isMulti={true}
              noOptionsMessage={() => locale?.selectLanguageNoOptions}
            />
          </div>

          <div className="flex flex-col w-full desktop:w-[750px]">
            <div className="flex flex-col justify-between">
              <label className="justify-between w-full text-base text-dark-gray font-semibold">
                {aboutQuestionData.text}
                <span className="text-base font-bold text-clc-red">
                  {locale?.accountDeletion.form.required}
                </span>
              </label>
              <p className="w-full text-med-gray font-normal">
                {aboutQuestionData.subtitle}
              </p>
            </div>
            <InputComponent
              type="text-area"
              value={formValues.about}
              textAreaProps={{
                errorClasses: errors.about
                  ? 'border-alert-negative bg-error-yellow'
                  : '',
                name: 'about',
                onTextAreaChange: (e) => handleOnInputChange(e),
              }}
            />
            {errors.about && <ErrorMessageWithIcon message={errors.about} />}
          </div>
        </div>

        <div className="hidden w-full desktop:flex justify-end">
          <ButtonComponent
            disabled={isSubmiting}
            iconPosition="right"
            className="px-10"
            onClick={handleOnSubmit}
          >
            {genericAction?.[genericActionsIds.UPDATE].update}
          </ButtonComponent>
        </div>
      </div>
    </div>
  );
};
