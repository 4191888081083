import { ChatMessage, ChatMessageLocale } from './interfaces';
import { ChatMessageComponent } from './ChatMessageComponent';
import { useCallback, useEffect, useRef } from 'react';

interface ChatComponentProps {
  messages: ChatMessage[];
  locale: ChatMessageLocale;
}

export const ChatComponent: React.FC<ChatComponentProps> = ({
  messages,
  locale,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const scrollToBottom = useCallback(() => {
    containerRef.current?.scrollTo({
      top:
        containerRef.current.scrollHeight - containerRef.current.clientHeight,
      behavior: 'instant' as unknown as ScrollBehavior, //https://github.com/Microsoft/TypeScript/issues/28755,
    });
  }, []);
  useEffect(() => {
    messages.length > 0 && scrollToBottom();
  }, [messages, scrollToBottom]);
  return (
    <div
      ref={containerRef}
      className="flex flex-col pr-2 gap-4 max-h-full overflow-y-auto"
    >
      {messages &&
        messages.map((message, key) => (
          <ChatMessageComponent
            locale={locale}
            key={key}
            mediaURL={message.mediaURL}
            message={message.message}
            messageGroup={message.messageGroup}
            senderPicture={message.senderPicture}
            senderType={message.senderType}
            type={message.type}
          />
        ))}
    </div>
  );
};
