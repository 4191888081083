import Loader from 'components/loaderComponent';
import Modal from 'components/modal/modalComponent';
import { useGetPage } from 'hooks/useGetPage';
import { genericActionsIds, pageIds } from 'utilities/constants';
import { VONAGE_WAITING_ROOM_ROUTES_TEST } from 'utilities/routes';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as ChatIcon } from 'assets/icons/appointmentsIcons/chatIconL.svg';
import { ReactComponent as VideoIcon } from 'assets/icons/appointmentsIcons/videoIconL.svg';
import { interpolateVariables } from 'utilities/functions';
import { ModalButtonProps } from 'components/modal/modal.interfaces';
import { useGetGenericActions } from 'hooks/useGetGenericActions';
import { AppointmentNotStarted } from './modalContent/AppointmentNotStarted';
import { AppointmentWillStart } from './modalContent/AppoinmentWillStart';
import { AppointmetInconvenience } from './modalContent/AppointmetInconvenience';
import { useEffect, useState } from 'react';
import { AppointmentComplete } from './modalContent/AppointmentComplete';
import {
  AppointmentTypeEnum,
  useGetAppointmentByCodexAppointmentIdQuery,
  useGetFhirPatientByCodexIdLazyQuery,
  useGetFhirProviderByCodexIdLazyQuery,
} from 'graphql/generated/remote-schema-hasura';

import { useGetPatientImage } from 'hooks/useGetPatientImage';
import { useGetSignUrlFromStorageForRequesterMutation } from 'graphql/generated/hasura';

interface MyAppointmentModalProps {
  appointmentType: AppointmentTypeEnum;
  isPatient?: boolean;
  appointmentId?: string | null;
  appointmentComplete?: boolean;
}

export const MyAppointmentsModal: React.FC<MyAppointmentModalProps> = ({
  appointmentType,
  isPatient = false,
  appointmentId = null,
  appointmentComplete = false,
}) => {
  const { data: locale, loading } = useGetPage({
    locale: 'en',
    pageId: pageIds.MY_APPOINTMENTS,
  });
  const [providerName, setProviderName] = useState<string | null>(null);
  const [profileImage, setProfileImage] = useState<string>();
  const [patientName, setPatientName] = useState<string | null>(null);
  const [title, setTitle] = useState<string | null>(null);
  const [roomId, setRoomId] = useState<string | null>(null);
  const { data: genericAction, loading: genericActionLoading } =
    useGetGenericActions({
      locale: 'en',
      genericActionId: [genericActionsIds.CLOSE],
    });
  const [getFileUrlFromStorageForRequester] =
    useGetSignUrlFromStorageForRequesterMutation({});
  const navigate = useNavigate();
  const [currentComponentIndex, setCurrentComponentIndex] = useState(0);
  const [didAppointmentStarted, setDidAppointmentStarted] = useState<
    boolean | null
  >(null);

  const [getProvider] = useGetFhirProviderByCodexIdLazyQuery({
    onCompleted(data) {
      if (data?.getFHIRProviderbyCodexIdArgument?.providers.length > 0) {
        setProviderName(
          `${data?.getFHIRProviderbyCodexIdArgument.providers[0].providers.SENSITIVE_firstname} ${data?.getFHIRProviderbyCodexIdArgument.providers[0].providers.SENSITIVE_lastname}` ||
            '',
        );
        setTitle(
          data?.getFHIRProviderbyCodexIdArgument.providers[0].providers
            .SENSITIVE_credentials.titles[0] || '',
        );
      }
    },
  });

  useEffect(() => {
    if (didAppointmentStarted && roomId && !appointmentComplete) {
      navigate(VONAGE_WAITING_ROOM_ROUTES_TEST, {
        state: {
          appointmentType,
          roomId: roomId,
        },
      });
    }
  }, [
    appointmentType,
    didAppointmentStarted,
    navigate,
    roomId,
    appointmentComplete,
  ]);

  const [getPatient] = useGetFhirPatientByCodexIdLazyQuery({
    onCompleted(data) {
      if (data?.getFHIRPatientbyCodexIDParameter?.users.length > 0) {
        setPatientName(
          `${data?.getFHIRPatientbyCodexIDParameter.users[0].SENSITIVE_firstname} ${data?.getFHIRPatientbyCodexIDParameter.users[0].SENSITIVE_lastname}` ||
            '',
        );
      }
    },
  });

  const getPatientImage = useGetPatientImage();

  const { loading: getPatientAppointmentLoading, data: appointmentData } =
    useGetAppointmentByCodexAppointmentIdQuery({
      variables: {
        appointmentCodexId: appointmentId || '',
      },
      async onCompleted(data) {
        const appointment = data?.getFHIRAppointmentByCodexId?.appointment;
        if (!appointment || !appointment.start) {
          console.error('Appointment not found');
          handleCancel();
          return;
        }
        setRoomId(appointment?.appointmentCodexId);
        const didAppointmentStarted =
          Date.now() > new Date(appointment.start).getTime();
        setDidAppointmentStarted(didAppointmentStarted);
        if (isPatient) {
          const providerId = appointment.codexProviderId || '';
          const provider = await getProvider({
            variables: {
              codexProviderId: providerId || '',
            },
          });
          const pictureId =
            provider.data?.getFHIRProviderbyCodexIdArgument.providers[0]
              .providers.SENSITIVE_profile_picture_id;

          if (pictureId) {
            const profileImage = await getFileUrlFromStorageForRequester({
              variables: {
                fileId: pictureId,
                requestedUserId: providerId,
              },
            });
            setProfileImage(
              profileImage.data?.GetSignUrlFromStorageForRequester?.url ??
                undefined,
            );
          }
        } else {
          const patientId = appointment.codexPatientId || '';
          const patient = await getPatient({
            variables: { patientCodexId: patientId },
          });
          const pictureId =
            patient.data?.getFHIRPatientbyCodexIDParameter.users[0]
              .SENSITIVE_profile_picture_id;
          const profileImage =
            pictureId && (await getPatientImage(patientId, pictureId));
          setProfileImage(profileImage ?? undefined);
        }
      },
    });

  const handleCancel = () => {
    navigate(-1);
  };

  const getIcon = () => {
    switch (appointmentType) {
      case AppointmentTypeEnum.Video:
        return <VideoIcon height="20" width="20" />;
      case AppointmentTypeEnum.Chat:
        return <ChatIcon height="20" width="20" />;
      default:
        return <ChatIcon height="20" width="20" />;
    }
  };

  const components = [
    <AppointmentWillStart
      locale={locale}
      appointmentType={appointmentType}
      providerName={providerName}
      patientName={patientName}
      title={title}
      room={roomId || ''}
      isPatient={isPatient}
    />,
    <AppointmetInconvenience locale={locale} isPatient={isPatient} />,
    <AppointmentComplete locale={locale} />,
  ];

  // TODO: This is a temporary solution to switch between the components
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentComponentIndex(
        (prevIndex) => (prevIndex + 1) % components.length,
      );
    }, 30000);

    return () => clearInterval(interval);
  }, [components.length]);

  const Buttons: ModalButtonProps[] = [
    {
      label: genericAction?.[genericActionsIds.CLOSE].close,
      onClick: handleCancel,
    },
  ];
  const appointment = appointmentData?.getFHIRAppointmentByCodexId.appointment;

  if (appointmentComplete) {
    return <AppointmentComplete locale={locale} />;
  }

  if (
    loading ||
    !locale ||
    genericActionLoading ||
    !genericAction ||
    getPatientAppointmentLoading ||
    !appointment ||
    !appointment.start ||
    didAppointmentStarted === null
  ) {
    return <Loader />;
  }

  if (!didAppointmentStarted) {
    return (
      <AppointmentNotStarted
        locale={locale}
        appointmentType={appointmentType}
        providerName={providerName}
        patientName={patientName}
        title={title}
        appointmentStartsAt={new Date(appointment.start).getTime()}
        profileImage={profileImage}
        onAppointmentStart={() => setDidAppointmentStarted(true)}
      />
    );
  }

  return (
    <>
      <Modal
        isOpen={true}
        icon={getIcon()}
        title={`${interpolateVariables(locale?.[appointmentType].title, {
          provider: (providerName || patientName) as string,
        })}`.toUpperCase()}
        modalDesktopWidth="desktop:max-w-full"
        modalDesktopHeight="desktop:h-full"
        onClose={handleCancel}
        buttons={Buttons}
      >
        {components[currentComponentIndex]}
      </Modal>
    </>
  );
};
