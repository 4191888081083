import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetLegalDocument } from 'hooks/legalDocuments/useGetLegalDocument';
import { useIsMobile } from 'hooks/useIsMobile';
import { BASE_ROUTE } from 'utilities/routes';

export const LegalDocument = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const { legalDocument } = useGetLegalDocument({ legalDocumentId: id });

  useEffect(() => {
    if (legalDocument === null) {
      navigate(BASE_ROUTE);
    }
  }, [legalDocument, navigate]);

  if (legalDocument?.legalDocumentId) {
    return (
      <div className="flex-wrap px-2.5 desktop:px-[30px]">
        <div
          id="legalDocument"
          className="overflow-x-auto"
          dangerouslySetInnerHTML={{
            __html: isMobile
              ? legalDocument.contentMobile
              : legalDocument.contentWeb,
          }}
        />
      </div>
    );
  }

  return null;
};
