import { PatientHeader } from 'app/my-patients/components/PatientHeader';
import { TabsBasedNavigator } from 'app/my-patients/components/TabsBasedNavigator';
import { TabContent } from 'app/my-patients/interfaces/tab.interface';
import { PatientInfo } from './PatientInfo';
import {
  MY_PATIENTS_BIO_INFO_APPOINTMENT_HISTORY,
  MY_PATIENTS_BIO_INFO_DERMSCORE_HISTORY,
  MY_PATIENTS_BIO_INFO_MEDICATION_HISTORY,
  MY_PATIENTS_BIO_INFO_PATIENT_INFO,
  MY_PATIENTS_BIO_INFO_SHARED_MEDIA,
  MY_PATIENTS_BIO_INFO_TEST_KIT_RESULTS,
} from 'utilities/routes';
import { DermScoreHistoryPage } from './DermScoreHistoryPage';
import { SharedMediaPage } from './SharedMediaPage';
import { TestKitResults } from './TestKitResults';
import { MedicationHistory } from './MedicationHistory';
import { MyAppointmentsTab } from './MyAppointmentsTab';
import { TestKitResultModal } from '../components/TestKitResultModal';
import { useModalParams } from 'components/modal/useModalManager';
import { getAge } from 'utilities/functions';
import {
  PARAM_MODALS_IDENTIFIERS,
  componentIds,
  pageIds,
} from 'utilities/constants';
import AppointmentDetailModalProvider from 'components/appointmentDetailModal/AppointmentDetailModalProvider';
import { useGetFhirPatientRelatedToProviderByPatientIdQuery } from 'graphql/generated/remote-schema-hasura';

import { useParams } from 'react-router-dom';
import Loader from 'components/loaderComponent';
import { PatientInfo as PatientInfoInterface } from 'app/my-patients/interfaces/patient.interface';
import { useCallback, useState } from 'react';
import { useGetProviderPatientSignedUrlMutation } from 'graphql/generated/hasura';
import { MyAppointmentsLocaleProvider } from 'app/my-appointments/pages/MyAppointments';
import { useGetComponent } from 'hooks/useGetComponent';

// TODO COD-1823: Implement the rest of the tabs
const tabsContent: TabContent[] = [
  {
    id: MY_PATIENTS_BIO_INFO_PATIENT_INFO,
    content: PatientInfo,
  },
  {
    id: MY_PATIENTS_BIO_INFO_APPOINTMENT_HISTORY,
    content: MyAppointmentsTab,
  },
  {
    id: MY_PATIENTS_BIO_INFO_DERMSCORE_HISTORY,
    content: DermScoreHistoryPage,
  },
  {
    id: MY_PATIENTS_BIO_INFO_TEST_KIT_RESULTS,
    content: TestKitResults,
  },
  {
    id: MY_PATIENTS_BIO_INFO_MEDICATION_HISTORY,
    content: MedicationHistory,
  },
  {
    id: MY_PATIENTS_BIO_INFO_SHARED_MEDIA,
    content: SharedMediaPage,
  },
];

export const PatientBioInfo = () => {
  const { isOpen: isTestKitResultsModalOpen } = useModalParams(
    PARAM_MODALS_IDENTIFIERS.TEST_KIT_RESULT_MODAL_ID,
  );

  const { isOpen: isAppointmentDetailsModalOpen } = useModalParams(
    PARAM_MODALS_IDENTIFIERS.APPOINTMENT_DETAILS_MODAL_PROVIDER_ID,
  );
  const { data: locale, loading: localeLoading } = useGetComponent({
    locale: 'en',
    componentId: componentIds.PATIENT_BIO,
  });
  const params = useParams();
  const [patientData, setPatientData] = useState<PatientInfoInterface>();
  const [getProviderPatientFileUrlFromStorage] =
    useGetProviderPatientSignedUrlMutation({});

  const signImage = useCallback(
    async (patientId: string, SENSITIVE_profile_picture_id: string) => {
      const image = await getProviderPatientFileUrlFromStorage({
        variables: {
          patientCodexId: patientId,
          fileId: SENSITIVE_profile_picture_id,
        },
      });
      return image.data?.GetProviderPatientSignedUrl?.url || '';
    },
    [getProviderPatientFileUrlFromStorage],
  );

  const { loading: patientDataLoading } =
    useGetFhirPatientRelatedToProviderByPatientIdQuery({
      variables: {
        patientCodexId: params.patientId ?? '',
      },
      skip: !params.patientId,
      onCompleted: async (data) => {
        if (data.getFHIRPatientsById.patients.length) {
          const patient = data.getFHIRPatientsById.patients[0];
          const dob =
            patient.SENSITIVE_birth_date &&
            new Date(patient.SENSITIVE_birth_date);
          if (!dob) {
            return;
          }
          const age = getAge(dob);
          const name = `${patient.SENSITIVE_firstname} ${patient.SENSITIVE_lastname}`;
          const state = patient.SENSITIVE_address_information?.state ?? '';
          const city = patient.SENSITIVE_address_information?.city ?? '';
          const gender = patient.SENSITIVE_gender ?? '';
          const profileImgSrc = patient.SENSITIVE_profile_picture_id
            ? await signImage(
                patient.id,
                patient.SENSITIVE_profile_picture_id ?? '',
              )
            : '';
          setPatientData({
            patientCodexId: patient.id,
            age,
            city,
            gender,
            name,
            state,
            profileImgSrc,
          });
        }
      },
    });
  if (patientDataLoading || !patientData || localeLoading) {
    return <Loader />;
  }
  return (
    <>
      <MyAppointmentsLocaleProvider
        locale="en"
        pageId={pageIds.APPOINTMENTS_HISTORY_MY_PATIENT_PROVIDER_TAB}
      >
        {isTestKitResultsModalOpen && (
          <TestKitResultModal locale={locale.tabsResult} />
        )}
        {isAppointmentDetailsModalOpen && <AppointmentDetailModalProvider />}
        <div className="flex flex-col gap-8 py-5 desktop:py-0">
          <PatientHeader {...patientData} locale={locale.patient} />
          <TabsBasedNavigator tabsContent={tabsContent} locale={locale.tabs} />
        </div>
      </MyAppointmentsLocaleProvider>
    </>
  );
};
