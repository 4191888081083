import {
  AccountSettingsState,
  SectionsLoaded,
} from './providerModuleInterfaces';

export interface EmailPreferencesProps {
  locale: Record<string, string>;
  setAccountSettings: React.Dispatch<
    React.SetStateAction<AccountSettingsState | undefined>
  >;
  setIsAllSectionsLoaded: React.Dispatch<React.SetStateAction<SectionsLoaded>>;
}

export enum EmailPreferencesKeys {
  EMAIL_MONTHLY_NEWSLETTER,
  EMAIL_NEW_FEATURES_SERVICES_SETTINGS,
  EMAIL_SPECIAL_OFFERS_SETTING,
  EMAIL_SPECIAL_OFFER_FRIENDS_SETTING,
  EMAIL_UNSUBSCRIBED,
}

export interface EmailPreferencesState {
  EMAIL_MONTHLY_NEWSLETTER: boolean;
  EMAIL_NEW_FEATURES_SERVICES_SETTINGS: boolean;
  EMAIL_SPECIAL_OFFERS_SETTING: boolean;
  EMAIL_SPECIAL_OFFER_FRIENDS_SETTING: boolean;
  EMAIL_UNSUBSCRIBED: boolean;
}
