import InputComponent from 'components/inputComponent';
import {
  DynamicQuestionnaireCurrentIndexOfSection,
  HandleOnInputChangeType,
  ModuleFormErrors,
} from '../interfaces/dynamicQuestionnaire.interface';
import {
  DynamicQuestionResponseSection,
  QuestionnaireResponseInterface,
  questionStatusI,
  questionnaire_questions_question,
  RequiredIdsList,
  DropdownItem,
} from '../interfaces/dynamicQuestionnaireResponse.interface';
import {
  MedicationItemResponse,
  MedicationItemResponseForFiltering,
  MedicationItemResponseWithLabel,
  getUiControlType,
  hasMonthElapsed,
  parseQuestionOptionsForDropdownWithLookup,
  updateStateWithMedicationValues,
  validateQuestionResponse,
} from '../utils/utils';
import {
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { UI_CONTROL } from '../interfaces/uiControl.interface';
import {
  HORMONE_MEDICATION_MAX_LENGTH,
  HORMONE_QUESTIONNAIRE_SECTIONS,
  HORMONE_QUESTIONNAIRE_SPECIFIC_IDS,
  medicationIdsFromHormoneQuestionnaire,
  hormoneValidationFunctionsRules,
  MEDICATIONS_IDS,
} from '../utils/constants';
import DatePicker from 'components/datePicker/DatePicker';
import CreatableSelect from 'react-select/creatable';
import { useGetComponent } from 'hooks/useGetComponent';
import {
  REGEX_ONLY_NUMBERS_AND_COLONS,
  componentIds,
  questionnairesIds,
} from 'utilities/constants';
import { SingleValue } from 'react-select';
import Alert from 'components/alertComponent';
import { useGetFhirPatientbyCodexIdQuery } from 'graphql/generated/remote-schema-hasura';

import useFormValidation, { ValidationRules } from 'hooks/useFormValidation';
import Slider from 'rc-slider';
import { ReactComponent as MinusIcon } from '../../../assets/icons/minusIcon.svg';
import { ReactComponent as PlusIcon } from '../../../assets/icons/plusIcon.svg';
import { HormoneMedicationTable } from '../components/HormoneMedicationTable';

interface LocalFormData {
  firstname: string;
  lastname: string;
  height5: string;
  height8: string;
  waist: string;
  weight: string;
  fast_time: string;
  blood_sample_time13: string;
  dob?: string;
  gender?: string;
}
interface SliderValue {
  currentIndexOfSection: number;
  currentSection: string;
  questionID: number;
  response: string;
}

interface HormoneQuestionnaireModule {
  questionList: questionnaire_questions_question[];
  questionnaireData: QuestionnaireResponseInterface[];
  currentIndexSection: DynamicQuestionnaireCurrentIndexOfSection;
  radioCheckedState: QuestionnaireResponseInterface[];
  checkboxesCheckedState: QuestionnaireResponseInterface[];
  section: DynamicQuestionResponseSection;
  onChange: HandleOnInputChangeType;
  handleCheckboxToggle: (
    answer: string,
    questionID: number | undefined,
    questionParentID?: number,
    childrenOriginalQuestionID?: string,
  ) => void;
  setRadioCheckedState: Dispatch<
    SetStateAction<QuestionnaireResponseInterface[]>
  >;
  setCheckboxesCheckedState?: Dispatch<
    SetStateAction<QuestionnaireResponseInterface[]>
  >;
  matchSelectedRadio: (
    question: number,
    stringToMatch: string,
    latestState: QuestionnaireResponseInterface[],
  ) => QuestionnaireResponseInterface | undefined;
  handleRadioClick: (question: number, value: string | undefined) => void;
  nestedQuestionList: questionStatusI[];
  renderNestedQuestions: (
    questionStatus: questionStatusI,
    question: questionnaire_questions_question,
    questionParentID: number,
    childrenOriginalQuestionID: string | null | undefined,
    handleCheckboxToggle: (
      answer: string,
      questionID: number | undefined,
      questionParentID?: number,
      childrenOriginalQuestionID?: string,
    ) => void,
    clearCheckboxState: (questionID: number) => void,
    currentSection: DynamicQuestionResponseSection,
    radioCheckedState?: QuestionnaireResponseInterface[],
    setRadioCheckedState?: Dispatch<
      SetStateAction<QuestionnaireResponseInterface[]>
    >,
    checkboxesCheckedState?: QuestionnaireResponseInterface[],
    setCheckboxesCheckedState?: Dispatch<
      SetStateAction<QuestionnaireResponseInterface[]>
    >,
    textFieldResponses?: QuestionnaireResponseInterface[],
    setTextFieldResponses?: Dispatch<
      SetStateAction<QuestionnaireResponseInterface[]>
    >,
  ) => ReactNode;
  clearCheckboxState: (questionID: number) => void;
  textFieldResponses: QuestionnaireResponseInterface[];
  setTextFieldResponses: Dispatch<
    SetStateAction<QuestionnaireResponseInterface[]>
  >;
  requiredQuestionsIds: RequiredIdsList;
  getIncompleteMainQuestion: () => string[];
  submitWasTriggered: boolean;
  setPersistentData: Dispatch<
    React.SetStateAction<QuestionnaireResponseInterface[]>
  >;
  questionnaireID: number;
  persistentData: QuestionnaireResponseInterface[];
  onDoubleTextFieldChange: (
    value: QuestionnaireResponseInterface[],
    questionID: number,
  ) => void;
  setModuleFormErrors: Dispatch<SetStateAction<ModuleFormErrors | null>>;
  currentSection: DynamicQuestionResponseSection;
  hormoneTableMedicationsSelected: QuestionnaireResponseInterface[];
  setHormoneTableMedicationsSelected: Dispatch<
    React.SetStateAction<QuestionnaireResponseInterface[]>
  >;
  hormoneTableCheckboxesCheckedState: QuestionnaireResponseInterface[];
  setHormoneTableCheckboxesCheckedState: Dispatch<
    React.SetStateAction<QuestionnaireResponseInterface[]>
  >;
  hormoneTableCheckboxCheckAll: boolean;
  setHormoneTableCheckboxAll: Dispatch<React.SetStateAction<boolean>>;
}

const HormoneQuestionnaireModule: React.FC<HormoneQuestionnaireModule> = ({
  questionList,
  section,
  onChange,
  textFieldResponses,
  setTextFieldResponses,
  requiredQuestionsIds,
  getIncompleteMainQuestion,
  submitWasTriggered,
  setPersistentData,
  matchSelectedRadio,
  radioCheckedState,
  handleRadioClick,
  nestedQuestionList,
  handleCheckboxToggle,
  clearCheckboxState,
  setRadioCheckedState,
  checkboxesCheckedState,
  setCheckboxesCheckedState,
  renderNestedQuestions,
  questionnaireID,
  persistentData,
  setModuleFormErrors,
  currentSection,
  hormoneTableMedicationsSelected,
  setHormoneTableMedicationsSelected,
  hormoneTableCheckboxesCheckedState,
  setHormoneTableCheckboxesCheckedState,
  hormoneTableCheckboxCheckAll,
  setHormoneTableCheckboxAll,
}) => {
  const processUserDataIntoQuestionnaire = (user: LocalFormData) => {
    const idsToMatch = [
      { key: 'firstname', id: HORMONE_QUESTIONNAIRE_SPECIFIC_IDS.FIRST_NAME },
      { key: 'lastname', id: HORMONE_QUESTIONNAIRE_SPECIFIC_IDS.LAST_NAME },
      {
        key: 'height5',
        id: HORMONE_QUESTIONNAIRE_SPECIFIC_IDS.HEIGHT_FIRST_FIELD,
      },
      {
        key: 'height8',
        id: HORMONE_QUESTIONNAIRE_SPECIFIC_IDS.HEIGHT_SECOND_FIELD,
      },
      { key: 'waist', id: HORMONE_QUESTIONNAIRE_SPECIFIC_IDS.WAIST },
      { key: 'weight', id: HORMONE_QUESTIONNAIRE_SPECIFIC_IDS.WEIGHT },
      { key: 'dob', id: HORMONE_QUESTIONNAIRE_SPECIFIC_IDS.DOB },
      { key: 'gender', id: HORMONE_QUESTIONNAIRE_SPECIFIC_IDS.GENDER },
    ];

    const processedItems = idsToMatch.map((item) => {
      return {
        currentIndexOfSection: 0,
        currentSection: HORMONE_QUESTIONNAIRE_SECTIONS.INDIVIDUAL_INFORMATION,
        questionID: item.id,
        response: user[item.key as keyof LocalFormData],
      };
    });

    setPersistentData((prevState) => [...prevState, ...processedItems]);
  };

  const { loading: fhirPatientDataLoading } = useGetFhirPatientbyCodexIdQuery({
    onCompleted(data) {
      if (data?.getFHIRPatientbyCodexID.users.length > 0) {
        const user = data?.getFHIRPatientbyCodexID.users[0];
        setLocalFormData({
          firstname: user?.SENSITIVE_firstname || '',
          lastname: user?.SENSITIVE_lastname || '',
          height5: user.SENSITIVE_user_measurements?.heightFt
            ? String(user?.SENSITIVE_user_measurements?.heightFt)
            : '',
          height8: user.SENSITIVE_user_measurements?.heightIn
            ? String(user?.SENSITIVE_user_measurements?.heightIn)
            : '',
          waist: user.SENSITIVE_user_measurements?.waist
            ? String(user?.SENSITIVE_user_measurements?.waist)
            : '',
          weight: user.SENSITIVE_user_measurements?.weight
            ? String(user?.SENSITIVE_user_measurements?.weight)
            : '',
          fast_time: '',
          blood_sample_time13: '',
        });
        setDateOfBirth(user?.SENSITIVE_dob || '');
        setGenderSelected(user?.SENSITIVE_gender || '');
        processUserDataIntoQuestionnaire({
          firstname: user?.SENSITIVE_firstname || '',
          lastname: user?.SENSITIVE_lastname || '',
          height5: user.SENSITIVE_user_measurements?.heightFt
            ? String(user?.SENSITIVE_user_measurements?.heightFt)
            : '',
          height8: user.SENSITIVE_user_measurements?.heightIn
            ? String(user?.SENSITIVE_user_measurements?.heightIn)
            : '',
          waist: user.SENSITIVE_user_measurements?.waist
            ? String(user?.SENSITIVE_user_measurements?.waist)
            : '',
          weight: user.SENSITIVE_user_measurements?.weight
            ? String(user?.SENSITIVE_user_measurements?.weight)
            : '',
          dob: user?.SENSITIVE_dob || '',
          gender: user?.SENSITIVE_gender || '',
          fast_time: '',
          blood_sample_time13: '',
        });
      }
    },
  });

  const [dateOfBirth, setDateOfBirth] = useState('');
  const [genderSelected, setGenderSelected] = useState('');
  const [bloodSampleDate, setBloodSampleDate] = useState('');
  const [lastMensesDate, setLastMensesDate] = useState('');
  const [userLastUpdatedDate, setUserLastUpdatedDate] = useState('');
  const [userAnsweredBloodWorkQuestions, setUserAnsweredBloodworkQuestions] =
    useState({
      date: false,
      hour: false,
      timeOfDay: false,
    });
  const [sliderValues, setSliderValues] = useState<SliderValue[]>([]);

  const [medicationList, setMedicationList] = useState<
    QuestionnaireResponseInterface[]
  >([]);

  const [textLocalFieldResponses, setTextLocalFieldResponses] = useState<
    QuestionnaireResponseInterface[]
  >([]);

  const [enableShareData, setEnableShareData] = useState(false);
  const [showTimeElapsedAlert, setShowTimeElapsedAlert] = useState(false);

  const {
    data: dynamicQuestionnaireLocale,
    loading: dynamicQuestionnaireLocaleLoading,
  } = useGetComponent({
    locale: 'en',
    componentId: componentIds.HORMONE_QUESTIONNAIRE_MODULE,
  });

  const { data: fhirUserData, loading: fhirLoadingUserData } =
    useGetFhirPatientbyCodexIdQuery({
      onCompleted: (data) => {
        if (data.getFHIRPatientbyCodexID.users.length > 0) {
          setUserLastUpdatedDate(
            data.getFHIRPatientbyCodexID.users[0].updated_at || '',
          );
        }
      },
    });

  const userHasOutdatedProfileInformation =
    hasMonthElapsed(userLastUpdatedDate);

  const allBloodWorkQuestionsAnswered = Object.values(
    userAnsweredBloodWorkQuestions,
  ).every((value) => value);

  const isRequiredQuestion = (questionId: string): boolean => {
    const isRequired = requiredQuestionsIds.find((id) => id === questionId);
    return Boolean(isRequired);
  };

  const missingMainQuestionIds = getIncompleteMainQuestion();

  const sortedQuestionList = [...questionList].sort(
    (a, b) =>
      (a.questionnaire_questions_question.id ?? 0) -
      (b.questionnaire_questions_question.id ?? 0),
  );

  const validationRules: ValidationRules = {
    firstname: [
      {
        validator: (value) => value.length > 0,
        message: dynamicQuestionnaireLocale?.fieldRequired,
      },
    ],
    lastname: [
      {
        validator: (value) => value.length > 0,
        message: dynamicQuestionnaireLocale?.fieldRequired,
      },
    ],
    height5: [
      {
        validator: (value) => value.length > 0,
        message: dynamicQuestionnaireLocale?.fieldRequired,
      },
    ],
    height8: [
      {
        validator: (value) => value.length > 0,
        message: dynamicQuestionnaireLocale?.fieldRequired,
      },
    ],
    weight: [
      {
        validator: (value) => value.length > 0,
        message: dynamicQuestionnaireLocale?.fieldRequired,
      },
    ],
    waist: [
      {
        validator: (value) => value.length > 0,
        message: dynamicQuestionnaireLocale?.fieldRequired,
      },
    ],
    fast_time: [
      {
        validator: (value) => value.length > 0,
        message: dynamicQuestionnaireLocale?.fieldRequired,
      },
    ],
    blood_sample_time13: [
      {
        validator: (value) => value.length > 0 && value.length === 5,
        message: dynamicQuestionnaireLocale?.fieldRequired,
      },
    ],
  };

  const { errors, validateForm } = useFormValidation(validationRules);

  const [localFormData, setLocalFormData] = useState<LocalFormData>({
    firstname: '',
    lastname: '',
    height5: '',
    height8: '',
    waist: '',
    weight: '',
    fast_time: '',
    blood_sample_time13: '',
  });

  const handleValidation = useCallback(
    (providerData: LocalFormData) => {
      const formData = JSON.parse(JSON.stringify(providerData));
      return validateForm(formData);
    },
    [validateForm],
  );

  const prevLocalFormDataRef = useRef<LocalFormData | null>(null);
  const validationExecutedRef = useRef(false);

  const handleValidationCallback = useCallback(() => {
    // Check if the validation has already been executed
    if (!validationExecutedRef.current) {
      if (handleValidation) {
        handleValidation(localFormData);
      }
      validationExecutedRef.current = true;
    }
  }, [localFormData, handleValidation]);

  const handleTextFieldResponses = (
    value: string,
    questionID: number,
    question: questionnaire_questions_question,
  ) => {
    if (Array.isArray(textFieldResponses) && setTextFieldResponses) {
      // Check if the questionID already exists in the array
      const existingIndex = textFieldResponses.findIndex(
        (response) => response.questionID === questionID,
      );

      if (existingIndex !== -1) {
        // If the questionID exists, update the existing item
        const updatedResponses = [...textFieldResponses];
        updatedResponses[existingIndex].response = value;
        setTextFieldResponses(updatedResponses);
        onChange(
          (value as string) || '',
          question?.questionnaire_questions_question.id || 0,
        );
      } else {
        // If the questionID doesn't exist, create a new response
        const newResponse: QuestionnaireResponseInterface = {
          questionID: questionID,
          response: value,
        };

        setTextFieldResponses([...textFieldResponses, newResponse]);

        onChange(
          (value as string) || '',
          question?.questionnaire_questions_question.id || 0,
        );
      }
    }
  };

  const handleInputTextChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    question: questionnaire_questions_question,
    questionID?: number,
  ) => {
    if (questionID) {
      // handles manually setting question ids
      if (
        validateQuestionResponse(
          question,
          e.target.value,
          hormoneValidationFunctionsRules,
          `${question.questionnaire_questions_question.response_property}${questionID}`,
        )
      ) {
        textFieldResponses &&
          handleTextFieldResponses(e.target.value, questionID, {
            ...question,
            questionnaire_questions_question: {
              ...question.questionnaire_questions_question,
              id: questionID,
            },
          });
        setLocalFormData({
          ...localFormData,
          [`${question.questionnaire_questions_question.response_property}${questionID}`]:
            e.target.value,
        });
      }
    } else {
      if (
        validateQuestionResponse(
          question,
          e.target.value,
          hormoneValidationFunctionsRules,
        )
      ) {
        textFieldResponses &&
          handleTextFieldResponses(
            e.target.value,
            question.questionnaire_questions_question.id,
            question,
          );
        setLocalFormData({
          ...localFormData,
          [question.questionnaire_questions_question
            .response_property as string]: e.target.value,
        });
      }
    }
  };

  const handleTimeInput = (e: React.ChangeEvent<HTMLInputElement>): string => {
    let inputText = e.target.value;
    const cursorPosition = e.target.selectionStart ?? 0;

    // Check if input contains only numbers and colons
    if (!REGEX_ONLY_NUMBERS_AND_COLONS.test(inputText)) {
      // If not, clear the input
      e.target.value = inputText.replace(/[^\d:]/g, '');
      return '';
    }

    // Limit hours to not exceed 23
    if (inputText.length >= 2) {
      const hours = parseInt(inputText.substring(0, 2), 10);
      const minutes = parseInt(inputText.substring(3, 5), 10);
      if (hours > 23) {
        inputText = '00' + inputText.substring(2);
      }
      if (minutes > 59) {
        inputText = inputText.substring(0, 3) + '59';
      }
    }

    // Insert semicolon after second character if cursor is beyond the third character
    if (
      inputText.length > 2 &&
      cursorPosition > 2 &&
      inputText.charAt(2) !== ':'
    ) {
      inputText = inputText.slice(0, 2) + ':' + inputText.slice(2);
    }

    return inputText;
  };

  const handleDateInputTextChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    question: questionnaire_questions_question,
    questionID?: number,
  ) => {
    const inputText = handleTimeInput(e);

    if (questionID) {
      // handles manually setting question ids
      textFieldResponses &&
        handleTextFieldResponses(inputText, questionID, {
          ...question,
          questionnaire_questions_question: {
            ...question.questionnaire_questions_question,
            id: questionID,
          },
        });
      setLocalFormData({
        ...localFormData,
        [`${question.questionnaire_questions_question.response_property}${questionID}`]:
          inputText,
      });
    } else {
      textFieldResponses &&
        handleTextFieldResponses(
          inputText,
          question.questionnaire_questions_question.id,
          question,
        );
      setLocalFormData({
        ...localFormData,
        [question.questionnaire_questions_question.response_property as string]:
          inputText,
      });
    }
  };

  const handleDatepickerChange = (
    question: questionnaire_questions_question,
    value: string,
    type: 'dob' | 'bloodsample' | 'menses',
  ) => {
    if (type === 'dob') {
      setDateOfBirth(value);
    }

    if (type === 'bloodsample') {
      setBloodSampleDate(value);
    }

    if (type === 'menses') {
      setLastMensesDate(value);
    }

    handleTextFieldResponses(
      value,
      question.questionnaire_questions_question.id,
      question,
    );
  };

  const handleDropdownOptionSelected = (
    value: SingleValue<string | DropdownItem>,
    question: questionnaire_questions_question,
    questionID?: number,
  ) => {
    const selectedValue = (value?.valueOf() as { value: string }).value;
    if (questionID) {
      // handles manually setting question ids
      textFieldResponses &&
        handleTextFieldResponses(selectedValue, questionID, {
          ...question,
          questionnaire_questions_question: {
            ...question.questionnaire_questions_question,
            id: questionID,
          },
        });
    } else {
      textFieldResponses &&
        handleTextFieldResponses(
          selectedValue,
          question.questionnaire_questions_question.id,
          question,
        );
      setGenderSelected(selectedValue);
    }
  };

  const handleSliderChange = (
    value: string,
    questionID: number,
    question: questionnaire_questions_question,
  ) => {
    textFieldResponses &&
      handleTextFieldResponses(value, questionID, {
        ...question,
        questionnaire_questions_question: {
          ...question.questionnaire_questions_question,
          id: questionID,
        },
      });
  };

  const handleMedicationDropdownOptionSelected = (
    value: SingleValue<string | { label: string; value: string }>,
    questionID: number,
    dropdownID: string,
    medicationListID: number,
  ) => {
    const selectedValue = (value?.valueOf() as { value: string }).value;
    const updatedMedicationList = medicationList.map((medicationItem) => {
      if (medicationItem.id === medicationListID && medicationItem.answers) {
        // Update the answers for the matching medication
        const updatedAnswers = [...medicationItem.answers];

        // Check if there's an existing answer for the same questionID
        const existingAnswerIndex = updatedAnswers.findIndex(
          (answer) => answer.questionID === questionID,
        );

        if (existingAnswerIndex !== -1) {
          // Update the existing answer with the new response values
          updatedAnswers[existingAnswerIndex] = {
            ...updatedAnswers[existingAnswerIndex], // Create a shallow copy of the answer object
            response: selectedValue,
          };
        } else {
          // If there is no existing answer, create a new one
          updatedAnswers.push({
            questionID: questionID,
            response: selectedValue,
          });
        }

        return {
          ...medicationItem,
          answers: updatedAnswers,
        };
      }

      return medicationItem;
    });

    // Update the state with the modified medicationList
    setMedicationList(updatedMedicationList);
  };

  const handleMedicationTextFieldResponses = (
    value: string,
    questionID: number,
    medicationID: number,
  ) => {
    // Ensure that textLocalFieldResponses is initialized as an empty array initially
    if (!Array.isArray(textLocalFieldResponses)) {
      setTextLocalFieldResponses([]);
    }

    // Find an existing response with the same questionID and medicationID
    const existingResponseIndex = textLocalFieldResponses.findIndex(
      (response) =>
        response.medicationID === medicationID &&
        response.questionID === questionID,
    );

    if (existingResponseIndex !== -1) {
      // If the response exists, update it
      const updatedResponses = [...textLocalFieldResponses];
      updatedResponses[existingResponseIndex].response = value;
      setTextLocalFieldResponses(updatedResponses);
    } else {
      // If the response doesn't exist, create a new response
      const newResponse: MedicationItemResponse = {
        questionID: questionID,
        medicationID: medicationID,
        response: value,
      };

      setTextLocalFieldResponses([...textLocalFieldResponses, newResponse]);
    }

    const updatedMedicationList = medicationList.map((medicationItem) => {
      if (medicationItem.id === medicationID) {
        const existingAnswers = medicationItem.answers || [];

        // Check if the questionID already exists in the answers
        const existingAnswerIndex = existingAnswers.findIndex(
          (answer) => answer.questionID === questionID,
        );

        const updatedAnswers =
          existingAnswerIndex !== -1
            ? existingAnswers.map((answer) =>
                answer.questionID === questionID
                  ? { ...answer, response: value } // Update existing answer
                  : answer,
              )
            : [...existingAnswers, { questionID, response: value }]; // Add new answer

        // Create a new object with the updated or new answers and assign it
        medicationItem = { ...medicationItem, answers: updatedAnswers };
      }

      return medicationItem;
    });

    setMedicationList(updatedMedicationList);
  };

  const handleMedicationDoubleFieldDropdownOptionSelected = (
    value: SingleValue<string | { label: string; value: string }>,
    questionID: number,
    dropdownID: string,
    medicationListID: number,
    inputType: string,
  ) => {
    const selectedValue = (value?.valueOf() as { value: string }).value;
    const updatedMedicationList = medicationList.map((medicationItem) => {
      if (medicationItem.id === medicationListID && medicationItem.answers) {
        // Update the answers for the matching medication
        const updatedAnswers = [...medicationItem.answers];

        // Check if there's an existing answer for the same questionID
        const existingAnswerIndex = updatedAnswers.findIndex(
          (answer) =>
            answer.questionID === questionID && answer.inputLabel === inputType,
        );

        if (existingAnswerIndex !== -1) {
          // Update the existing answer with the new response values
          updatedAnswers[existingAnswerIndex] = {
            ...updatedAnswers[existingAnswerIndex], // Create a shallow copy of the answer object
            response: selectedValue,
          };
        } else {
          // If there is no existing answer, create a new one
          updatedAnswers.push({
            questionID: questionID,
            response: selectedValue,
            inputLabel: inputType,
          });
        }

        // Check if dropdownID includes 'double-2'
        if (dropdownID.includes('double-2')) {
          // Find the existing response for 'double-2'
          const existingDoubleResponseIndex = updatedAnswers.findIndex(
            (answer) =>
              answer.questionID === questionID &&
              answer.inputLabel === inputType,
          );

          if (existingDoubleResponseIndex !== -1) {
            updatedAnswers[existingDoubleResponseIndex] = {
              ...updatedAnswers[existingDoubleResponseIndex],
              response: selectedValue,
              inputLabel: inputType,
            };
          } else {
            // If there is no existing response, create a new one
            updatedAnswers.push({
              questionID: questionID,
              response: selectedValue as string,
              inputLabel: inputType,
            });
          }
        }

        return {
          ...medicationItem,
          answers: updatedAnswers,
        };
      }

      return medicationItem;
    });

    // Update the state with the modified medicationList
    setMedicationList(updatedMedicationList);
  };

  const handleMedicationDoubleTextFieldResponses = (
    value: string,
    questionID: number,
    medicationID: number,
    inputType?: string,
  ) => {
    // Ensure that textLocalFieldResponses is initialized as an empty array initially
    if (!Array.isArray(textLocalFieldResponses)) {
      setTextLocalFieldResponses([]);
    }

    // Find an existing response with the same questionID and medicationID
    const existingResponseIndex = textLocalFieldResponses.findIndex(
      (response) =>
        response.medicationID === medicationID &&
        response.questionID === questionID &&
        response.inputLabel === inputType,
    );

    if (existingResponseIndex !== -1) {
      // If the response exists, update it
      const updatedResponses = [...textLocalFieldResponses];
      updatedResponses[existingResponseIndex].response = value;
      setTextLocalFieldResponses(updatedResponses);
    } else {
      // If the response doesn't exist, create a new response
      const newResponse: MedicationItemResponseWithLabel = {
        questionID: questionID,
        medicationID: medicationID,
        response: value,
        inputLabel: inputType || '',
      };

      setTextLocalFieldResponses([...textLocalFieldResponses, newResponse]);
    }

    const updatedMedicationList = medicationList.map((medicationItem) => {
      if (medicationItem.id === medicationID) {
        const existingAnswers = medicationItem.answers || [];

        // Check if the questionID already exists in the answers
        const existingAnswerIndex = existingAnswers.findIndex(
          (answer) =>
            answer.questionID === questionID && answer.inputLabel === inputType,
        );

        const updatedAnswers =
          existingAnswerIndex !== -1
            ? existingAnswers.map((answer) =>
                answer.questionID === questionID &&
                answer.inputLabel === inputType
                  ? { ...answer, response: value } // Update existing answer
                  : answer,
              )
            : [
                ...existingAnswers,
                { questionID, response: value, inputLabel: inputType },
              ]; // Add new answer

        // Create a new object with the updated or new answers and assign it
        medicationItem = { ...medicationItem, answers: updatedAnswers };
      }

      return medicationItem;
    });

    setMedicationList(updatedMedicationList);
  };

  const renderIndividualInformationQuestionFactory = (
    question: questionnaire_questions_question,
  ) => {
    const { questionnaire_questions_question } = question;

    if (
      (!section.name as unknown as string) ===
      (HORMONE_QUESTIONNAIRE_SECTIONS.INDIVIDUAL_INFORMATION as string)
    ) {
      return null;
    }

    const dynamicProperty =
      question &&
      question.questionnaire_questions_question &&
      question.questionnaire_questions_question.response_property;
    const errorStatus = dynamicProperty ? errors[dynamicProperty] : null;
    switch (questionnaire_questions_question.ui_control) {
      case UI_CONTROL.TEXT:
        if (questionnaire_questions_question.measurement_unit !== 'HEIGHT') {
          return (
            <div className="desktop:min-w-[435px] desktop:max-w-[435px]">
              <InputComponent
                isDisabled={true}
                value={
                  localFormData[
                    question.questionnaire_questions_question
                      .response_property as keyof LocalFormData
                  ]
                }
                noMarginBottom={true}
                type={getUiControlType(
                  questionnaire_questions_question.ui_control,
                )}
                onChange={(e) => handleInputTextChange(e, question)}
                errorStatus={!!errorStatus}
                errorMsgWithIcon={errorStatus}
              />
            </div>
          );
        } else if (
          questionnaire_questions_question.measurement_unit === 'HEIGHT' &&
          question.questionnaire_questions_question.id ===
            HORMONE_QUESTIONNAIRE_SPECIFIC_IDS.HEIGHT_FIRST_FIELD
        ) {
          return (
            <div className="flex">
              <div className="w-full flex flex-row items-center mr-[35px] max-w-[100px]">
                <InputComponent
                  isDisabled={true}
                  value={localFormData.height5}
                  noMarginBottom={true}
                  type={getUiControlType(
                    question.questionnaire_questions_question.ui_control,
                  )}
                  onChange={(e) => {
                    handleInputTextChange(
                      e,
                      question,
                      HORMONE_QUESTIONNAIRE_SPECIFIC_IDS.HEIGHT_FIRST_FIELD,
                    );
                  }}
                  errorStatus={!!errors?.height5}
                />
                <p className="ml-[10px] font-semibold text-med-gray">ft.</p>
              </div>
              <div className="w-full flex flex-row items-center max-w-[100px]">
                <InputComponent
                  isDisabled={true}
                  value={localFormData.height8}
                  noMarginBottom={true}
                  type={getUiControlType(
                    question.questionnaire_questions_question.ui_control,
                  )}
                  onChange={(e) => {
                    handleInputTextChange(
                      e,
                      question,
                      HORMONE_QUESTIONNAIRE_SPECIFIC_IDS.HEIGHT_SECOND_FIELD,
                    );
                  }}
                  errorStatus={!!errors?.height8}
                />
                <p className="ml-[10px] font-semibold text-med-gray">
                  {dynamicQuestionnaireLocale?.in}
                </p>
              </div>
            </div>
          );
        }
        break;

      case UI_CONTROL.DATE:
        return (
          <DatePicker
            onChange={(e) =>
              handleDatepickerChange(question, e.target.value, 'dob')
            }
            value={dateOfBirth}
            isOpen={false}
            isDisabled={true}
          />
        );

      case UI_CONTROL.SMALL_TEXT:
        if (
          question.questionnaire_questions_question.id ===
          HORMONE_QUESTIONNAIRE_SPECIFIC_IDS.FAST_FIELD
        ) {
          return (
            <div className="w-[135px] mt-[10px]">
              <InputComponent
                value={
                  localFormData[
                    question.questionnaire_questions_question
                      .response_property as keyof LocalFormData
                  ]
                }
                noMarginBottom={true}
                type={getUiControlType(
                  question.questionnaire_questions_question.ui_control,
                )}
                onChange={(e) => {
                  handleInputTextChange(e, question);
                }}
                errorStatus={!!errorStatus}
              />
            </div>
          );
        } else {
          return (
            <div className="w-[100px] flex items-center">
              <InputComponent
                isDisabled={true}
                value={
                  localFormData[
                    question.questionnaire_questions_question
                      .response_property as keyof LocalFormData
                  ]
                }
                noMarginBottom={true}
                type={getUiControlType(
                  question.questionnaire_questions_question.ui_control,
                )}
                onChange={(e) => {
                  handleInputTextChange(e, question);
                }}
                errorStatus={!!errorStatus}
              />
              {question.questionnaire_questions_question.id ===
              HORMONE_QUESTIONNAIRE_SPECIFIC_IDS.WEIGHT ? (
                <p className="ml-[10px] font-semibold text-med-gray">
                  {dynamicQuestionnaireLocale?.lbs}
                </p>
              ) : (
                <p className="ml-[10px] font-semibold text-med-gray">
                  {dynamicQuestionnaireLocale?.in}
                </p>
              )}
            </div>
          );
        }

      case UI_CONTROL.DROPDOWN_50_PERCENT:
        return (
          <div className="w-full desktop:max-w-[10rem]">
            <CreatableSelect
              value={
                {
                  label: genderSelected,
                  value: genderSelected,
                } as SingleValue<string | DropdownItem>
              }
              isDisabled={true}
              className="dropdown"
              classNamePrefix="react-select"
              options={parseQuestionOptionsForDropdownWithLookup(
                question.questionnaire_questions_question.answers
                  ? question.questionnaire_questions_question.answers
                  : [],
              )}
              onChange={(e) => {
                handleDropdownOptionSelected(e, question);
              }}
            />
          </div>
        );

      case UI_CONTROL.DATE_WITH_HOURS:
        return (
          <div className="flex flex-col mt-[10px]">
            <div className="flex flex-col desktop:flex-row gap-4 desktop:gap-0">
              <div className="desktop:mr-10">
                <DatePicker
                  onChange={(e) => {
                    setUserAnsweredBloodworkQuestions((prevState) => ({
                      ...prevState,
                      date: true,
                    }));
                    handleDatepickerChange(
                      question,
                      e.target.value,
                      'bloodsample',
                    );
                  }}
                  value={bloodSampleDate}
                  isOpen={false}
                  hasError={bloodSampleDate.length === 0}
                />
              </div>
              <div className="flex">
                <div className="w-full max-w-[120px] mr-[15px]">
                  <InputComponent
                    value={localFormData.blood_sample_time13}
                    placeholder="hh:mm"
                    noMarginBottom={true}
                    type={getUiControlType(
                      question.questionnaire_questions_question.ui_control,
                    )}
                    onChange={(e) => {
                      if (e.target.value.length !== 0) {
                        setUserAnsweredBloodworkQuestions((prevState) => ({
                          ...prevState,
                          hour: true,
                        }));
                      } else {
                        setUserAnsweredBloodworkQuestions((prevState) => ({
                          ...prevState,
                          hour: false,
                        }));
                      }
                      handleDateInputTextChange(e, question, 13);
                    }}
                    maxLengthValue={5}
                    errorStatus={
                      userAnsweredBloodWorkQuestions.hour === false ||
                      (userAnsweredBloodWorkQuestions.hour === true &&
                        !!errors.blood_sample_time13)
                    }
                  />
                </div>
                <div className="w-full max-w-[120px]">
                  <CreatableSelect
                    placeholder={dynamicQuestionnaireLocale?.time}
                    className="dropdown"
                    classNamePrefix={
                      !userAnsweredBloodWorkQuestions.timeOfDay
                        ? 'react-select-with-error'
                        : 'react-select'
                    }
                    options={[
                      { label: 'AM', value: 'AM' },
                      { label: 'PM', value: 'PM' },
                    ]}
                    onChange={(e) => {
                      setUserAnsweredBloodworkQuestions((prevState) => ({
                        ...prevState,
                        timeOfDay: true,
                      }));
                      handleDropdownOptionSelected(e, question, 14);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        );

      default:
        return null; // Return null for unsupported UI controls
    }
  };

  const renderMenstrualStatusQuestionFactory = (
    question: questionnaire_questions_question,
  ) => {
    const { questionnaire_questions_question } = question;

    if (
      (!section.name as unknown as string) ===
      (HORMONE_QUESTIONNAIRE_SECTIONS.MENSTRUAL_STATUS as string)
    ) {
      return null;
    }

    switch (questionnaire_questions_question.ui_control) {
      case UI_CONTROL.DATE:
        return (
          <DatePicker
            onChange={(e) =>
              handleDatepickerChange(question, e.target.value, 'menses')
            }
            value={lastMensesDate}
            isOpen={false}
          />
        );
      case UI_CONTROL.RADIO_BUTTON:
        return (
          <div className="desktop:min-w-[435px] desktop:max-w-[435px]">
            <div className="flex-row">
              {question.questionnaire_questions_question.answers &&
                question.questionnaire_questions_question.answers.map(
                  (answer) => {
                    return (
                      <div key={answer} className="flex flex-col">
                        <InputComponent
                          key={answer}
                          type={getUiControlType(
                            question.questionnaire_questions_question
                              .ui_control,
                          )}
                          radioInputProps={{
                            radioError: null,
                            radioInputValue: answer,
                            radioInputLabel: answer,
                            radioInputCheckedValue: matchSelectedRadio(
                              question.questionnaire_questions_question.id,
                              answer,
                              radioCheckedState,
                            )
                              ? answer
                              : undefined,
                            onRadioClick: () =>
                              handleRadioClick(
                                question.questionnaire_questions_question.id,
                                answer,
                              ),
                          }}
                        />
                      </div>
                    );
                  },
                )}
            </div>

            {nestedQuestionList.map((questionStatus, key) => (
              <div className="desktop:pl-10 pb-1" key={key}>
                {renderNestedQuestions(
                  questionStatus,
                  question,
                  question.questionnaire_questions_question.id,
                  questionStatus.dependency,
                  handleCheckboxToggle,
                  clearCheckboxState,
                  section,
                  radioCheckedState,
                  setRadioCheckedState,
                  checkboxesCheckedState,
                  setCheckboxesCheckedState,
                  textFieldResponses,
                  setTextFieldResponses,
                )}
              </div>
            ))}
          </div>
        );

      default:
        return null; // Return null for unsupported UI controls
    }
  };

  const renderSymptomsQuestionFactory = (
    question: questionnaire_questions_question,
  ) => {
    const { questionnaire_questions_question } = question;

    if (
      (!section.name as unknown as string) ===
      (HORMONE_QUESTIONNAIRE_SECTIONS.SYMPTOMS as string)
    ) {
      return null;
    }

    const numberOfMarks = 3;
    const range = 100;

    const marks: { [key: number]: string } = {};

    // makes sure that the marks are always splitted between them equally
    for (let i = 0; i <= numberOfMarks; i++) {
      const value = Math.round((range / numberOfMarks) * i);
      const label = `${i} - ${question?.questionnaire_questions_question.answers?.[i]}`;
      marks[value] = label;
    }

    const getValueForSlider = persistentData.filter(
      (item) =>
        item.questionID === question.questionnaire_questions_question.id,
    );

    let matchingKey;
    Object.entries(marks).forEach(([key, value]) => {
      if (value === getValueForSlider[0]?.response) {
        matchingKey = parseInt(key);
      }
    });

    switch (questionnaire_questions_question.ui_control) {
      case UI_CONTROL.SLIDER:
        return (
          <div className="w-[100%] mt-[20px] mb-[60px]">
            <Slider
              defaultValue={0}
              value={matchingKey}
              marks={marks}
              step={null}
              onChange={(idx) => {
                handleSliderChange(
                  marks[idx as number],
                  questionnaire_questions_question.id,
                  question,
                );
              }}
            />
          </div>
        );
      default:
        return null; // Return null for unsupported UI controls
    }
  };

  const renderHormoneAndMedicationsFactory = (
    question: questionnaire_questions_question,
    medicationID: number,
    medicationIndex: number,
  ) => {
    const { questionnaire_questions_question } = question;

    if (
      (!section.name as unknown as string) ===
      (HORMONE_QUESTIONNAIRE_SECTIONS.HORMONE_AND_MEDICATIONS as string)
    ) {
      return null;
    }

    const getCurrentIndexOfMedicationList = medicationList.filter(
      (obj: QuestionnaireResponseInterface) => {
        if (obj.id === medicationIndex) {
          return obj;
        } else {
          return obj;
        }
      },
    );

    const getCurrentIndexAnswers = () => {
      const medicationEntry = getCurrentIndexOfMedicationList[medicationID];
      if (
        medicationEntry &&
        medicationEntry.answers !== undefined &&
        medicationEntry.answers.length > 0
      ) {
        const medicationAnswers = medicationEntry.answers;
        return medicationAnswers;
      } else {
        return [];
      }
    };

    const getValueByfilterAnswers = (
      answers: MedicationItemResponseForFiltering[] | undefined,
      questionID: number,
      inputLabel?: string | null,
      parseAsSelect?: boolean,
    ) => {
      if (answers && answers.length > 0) {
        const filteredAnswers = answers.filter(
          (answer) => answer.questionID === questionID,
        );
        const filteredAnswersWithInputLabel = answers.filter(
          (answer) =>
            answer.questionID === questionID &&
            answer.inputLabel === inputLabel,
        );

        if (
          filteredAnswers.length > 0 &&
          !parseAsSelect &&
          inputLabel === null
        ) {
          return filteredAnswers[0].response;
        } else if (
          filteredAnswers.length > 0 &&
          parseAsSelect &&
          inputLabel === null
        ) {
          return {
            value: filteredAnswers[0].response,
            label: filteredAnswers[0].response,
          };
        } else if (
          filteredAnswersWithInputLabel.length > 0 &&
          inputLabel &&
          !parseAsSelect
        ) {
          return filteredAnswersWithInputLabel[0].response;
        } else if (
          filteredAnswersWithInputLabel.length > 0 &&
          inputLabel &&
          parseAsSelect
        ) {
          return {
            value: filteredAnswersWithInputLabel[0].response,
            label: filteredAnswersWithInputLabel[0].response,
          };
        } else {
          return '';
        }
      } else {
        return '';
      }
    };

    switch (questionnaire_questions_question.ui_control) {
      case UI_CONTROL.TEXT:
        return (
          <div className="w-[100%]">
            <InputComponent
              value={
                getValueByfilterAnswers(
                  getCurrentIndexAnswers() as Pick<
                    MedicationItemResponse,
                    'questionID' | 'response'
                  >[],
                  questionnaire_questions_question.id,
                  null,
                ) as string
              }
              placeholder={
                questionnaire_questions_question.id ===
                HORMONE_QUESTIONNAIRE_SPECIFIC_IDS.MEDICATION_TYPE_QUESTION
                  ? dynamicQuestionnaireLocale?.placeholder1
                  : ''
              }
              noMarginBottom={true}
              type={getUiControlType(
                questionnaire_questions_question.ui_control,
              )}
              onChange={(e) =>
                handleMedicationTextFieldResponses(
                  e.target.value,
                  question.questionnaire_questions_question.id,
                  medicationIndex,
                )
              }
            />
          </div>
        );

      case UI_CONTROL.DROPDOWN_50_PERCENT:
        return (
          <div className="w-full">
            <CreatableSelect
              value={getValueByfilterAnswers(
                getCurrentIndexAnswers() as Pick<
                  MedicationItemResponse,
                  'questionID' | 'response'
                >[],
                questionnaire_questions_question.id,
                null,
                true,
              )}
              className="dropdown"
              classNamePrefix="react-select"
              options={parseQuestionOptionsForDropdownWithLookup(
                question.questionnaire_questions_question.answers
                  ? question.questionnaire_questions_question.answers
                  : [],
              )}
              onChange={(e) => {
                handleMedicationDropdownOptionSelected(
                  e,
                  question.questionnaire_questions_question.id,
                  medicationID +
                    '-' +
                    question.questionnaire_questions_question.id,
                  medicationIndex,
                );
              }}
            />
          </div>
        );

      case UI_CONTROL.COMBO_TEXT_WITH_DROPDOWN:
        if (questionnaire_questions_question.id === 163) {
          return (
            <div className="flex flex-col">
              <div className="flex desktop:flex-row flex-col">
                <div className="w-full desktop:max-w-[160px] desktop:mr-[15px] mb-[15px] desktop:mb-0">
                  <InputComponent
                    value={
                      getValueByfilterAnswers(
                        getCurrentIndexAnswers() as Pick<
                          MedicationItemResponse,
                          'questionID' | 'response'
                        >[],
                        questionnaire_questions_question.id,
                        null,
                      ) as string
                    }
                    placeholder={dynamicQuestionnaireLocale?.placeholder2}
                    noMarginBottom={true}
                    type={getUiControlType(
                      question.questionnaire_questions_question.ui_control,
                    )}
                    onChange={(e) => {
                      handleMedicationDoubleTextFieldResponses(
                        e.target.value,
                        question.questionnaire_questions_question.id,
                        medicationIndex,
                        'input-163-1',
                      );
                    }}
                  />
                </div>
                <div
                  className={`w-full ${
                    question.questionnaire_questions_question.id ===
                    HORMONE_QUESTIONNAIRE_SPECIFIC_IDS.MEDICATION_USAGE_TIME_QUESTION
                      ? 'desktop:max-w-[160px]'
                      : 'desktop:max-w-[120px]'
                  }`}
                >
                  <CreatableSelect
                    value={getValueByfilterAnswers(
                      getCurrentIndexAnswers() as Pick<
                        MedicationItemResponse,
                        'questionID' | 'response'
                      >[],
                      questionnaire_questions_question.id,
                      'input-163-2',
                      true,
                    )}
                    placeholder={dynamicQuestionnaireLocale?.placeholder3}
                    className="dropdown"
                    classNamePrefix={'react-select'}
                    options={parseQuestionOptionsForDropdownWithLookup(
                      question.questionnaire_questions_question
                        .dropdown_combo_answers
                        ? (question.questionnaire_questions_question
                            .dropdown_combo_answers as unknown as string[])
                        : [],
                    )}
                    onChange={(e) => {
                      handleMedicationDoubleFieldDropdownOptionSelected(
                        e,
                        question.questionnaire_questions_question.id,
                        medicationID +
                          '-double-2-' +
                          question.questionnaire_questions_question.id,
                        medicationIndex,
                        'input-163-2',
                      );
                    }}
                  />
                </div>
              </div>
            </div>
          );
        } else if (questionnaire_questions_question.id === 166) {
          return (
            <div className="flex flex-col">
              <div className="flex desktop:flex-row flex-col">
                <div className="w-full desktop:max-w-[160px] desktop:mr-[15px] mb-[15px] desktop:mb-0">
                  <InputComponent
                    value={
                      getValueByfilterAnswers(
                        getCurrentIndexAnswers() as Pick<
                          MedicationItemResponse,
                          'questionID' | 'response'
                        >[],
                        questionnaire_questions_question.id,
                        null,
                      ) as string
                    }
                    placeholder={dynamicQuestionnaireLocale?.placeholder2}
                    noMarginBottom={true}
                    type={getUiControlType(
                      question.questionnaire_questions_question.ui_control,
                    )}
                    onChange={(e) => {
                      handleMedicationDoubleTextFieldResponses(
                        e.target.value,
                        question.questionnaire_questions_question.id,
                        medicationIndex,
                        'input-166-1',
                      );
                    }}
                  />
                </div>
                <div
                  className={`w-full ${
                    question.questionnaire_questions_question.id ===
                    HORMONE_QUESTIONNAIRE_SPECIFIC_IDS.MEDICATION_USAGE_TIME_QUESTION
                      ? 'desktop:max-w-[160px]'
                      : 'desktop:max-w-[120px]'
                  }`}
                >
                  <CreatableSelect
                    value={getValueByfilterAnswers(
                      getCurrentIndexAnswers() as Pick<
                        MedicationItemResponse,
                        'questionID' | 'response'
                      >[],
                      questionnaire_questions_question.id,
                      'input-166-2',
                      true,
                    )}
                    placeholder={dynamicQuestionnaireLocale?.placeholder3}
                    className="dropdown"
                    classNamePrefix={'react-select'}
                    options={parseQuestionOptionsForDropdownWithLookup(
                      question.questionnaire_questions_question
                        .dropdown_combo_answers
                        ? (question.questionnaire_questions_question
                            .dropdown_combo_answers as unknown as string[])
                        : [],
                    )}
                    onChange={(e) => {
                      handleMedicationDoubleFieldDropdownOptionSelected(
                        e,
                        question.questionnaire_questions_question.id,
                        medicationID +
                          '-double-2-' +
                          question.questionnaire_questions_question.id,
                        medicationIndex,
                        'input-166-2',
                      );
                    }}
                  />
                </div>
              </div>
            </div>
          );
        } else {
          return null;
        }

      case UI_CONTROL.DATE_WITH_HOURS_DROPDOWN:
        return (
          <div className="flex flex-col">
            <div className="flex desktop:flex-row flex-col">
              <div className="desktop:mr-[15px]">
                <DatePicker
                  value={
                    getValueByfilterAnswers(
                      getCurrentIndexAnswers() as Pick<
                        MedicationItemResponse,
                        'questionID' | 'response'
                      >[],
                      questionnaire_questions_question.id,
                      'input-5',
                      false,
                    ) as string
                  }
                  onChange={(e) => {
                    handleMedicationDoubleTextFieldResponses(
                      e.target.value,
                      question.questionnaire_questions_question.id,
                      medicationIndex,
                      'input-5',
                    );
                  }}
                  isOpen={false}
                />
              </div>
              <div className="w-full flex flex-row desktop:mt-0 mt-[15px]">
                <div className="desktop:w-auto flex items-center desktop:mr-[15px]">
                  <p className="mr-[15px] text-base text-dark-gray font-semibold">
                    {dynamicQuestionnaireLocale?.placeholder5}
                  </p>
                  <div className="w-[107px] desktop:w-full desktop:max-w-[120px] mr-[15px] desktop:mr-0">
                    <InputComponent
                      value={
                        getValueByfilterAnswers(
                          getCurrentIndexAnswers() as Pick<
                            MedicationItemResponse,
                            'questionID' | 'response'
                          >[],
                          questionnaire_questions_question.id,
                          'input-3',
                          false,
                        ) as string
                      }
                      placeholder="hh:mm"
                      noMarginBottom={true}
                      type={getUiControlType(
                        question.questionnaire_questions_question.ui_control,
                      )}
                      onChange={(e) => {
                        const inputText = handleTimeInput(e);
                        handleMedicationDoubleTextFieldResponses(
                          inputText,
                          question.questionnaire_questions_question.id,
                          medicationIndex,
                          'input-3',
                        );
                      }}
                      maxLengthValue={5}
                    />
                  </div>
                </div>
                <div className="w-autodesktop:w-full flex items-center desktop:max-w-[120px]">
                  <div className="desktop:w-auto w-full">
                    <CreatableSelect
                      value={getValueByfilterAnswers(
                        getCurrentIndexAnswers() as Pick<
                          MedicationItemResponse,
                          'questionID' | 'response'
                        >[],
                        questionnaire_questions_question.id,
                        'input-4',
                        true,
                      )}
                      placeholder={dynamicQuestionnaireLocale?.time}
                      className="dropdown"
                      classNamePrefix={'react-select'}
                      options={[
                        { label: 'AM', value: 'AM' },
                        { label: 'PM', value: 'PM' },
                      ]}
                      onChange={(e) => {
                        handleMedicationDoubleFieldDropdownOptionSelected(
                          e,
                          question.questionnaire_questions_question.id,
                          medicationID +
                            '-double-4-' +
                            question.questionnaire_questions_question.id,
                          medicationIndex,
                          'input-4',
                        );
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        );

      case UI_CONTROL.SMALL_TEXT:
        return (
          <div className="flex items-center">
            <div className="flex flex-row w-full desktop:max-w-[160px] mr-[10px]">
              <InputComponent
                placeholder={dynamicQuestionnaireLocale?.placeholder2}
                value={
                  getValueByfilterAnswers(
                    getCurrentIndexAnswers() as Pick<
                      MedicationItemResponse,
                      'questionID' | 'response'
                    >[],
                    questionnaire_questions_question.id,
                    null,
                  ) as string
                }
                noMarginBottom={true}
                type={getUiControlType(
                  question.questionnaire_questions_question.ui_control,
                )}
                onChange={(e) => {
                  handleMedicationTextFieldResponses(
                    e.target.value,
                    question.questionnaire_questions_question.id,
                    medicationIndex,
                  );
                }}
              />
            </div>
            <p className="text-med-gray text-sm font-semibold flex-shrink-0">
              per day
            </p>
          </div>
        );
      default:
        return null; // Return null for unsupported UI controls
    }
  };

  const insertAfter = (
    id: number,
    medication: QuestionnaireResponseInterface,
  ) => {
    const array = [...medicationList];
    const index = medicationList.findIndex((item) => item.id === id);
    array.splice(index + 1, 0, medication);
    setMedicationList(array);
  };

  const insertAfterLastMedication = () => {
    const lastMedication = medicationList[medicationList.length - 1];
    if (lastMedication && lastMedication.id !== undefined) {
      insertAfter(lastMedication.id, {
        id: medicationList.length,
        title: `Medication List ${medicationList.length + 1}`,
        completed: false,
        answers: [],
      });
    } else if (lastMedication === undefined) {
      insertAfter(0, {
        id: 0,
        title: 'Medication List 1',
        completed: false,
        answers: [],
      });
    }
  };

  const removeMedication = (idToRemove: number) => {
    const updatedArray = medicationList
      .filter((medication) => medication.id !== idToRemove)
      .map((medication, index) => ({
        ...medication,
        id: index, // Resetting the id based on the new index
      }));
    setMedicationList(updatedArray);
  };

  useEffect(() => {
    // Check if it's the initial render or if localFormData is different from the previous data
    if (
      prevLocalFormDataRef.current === undefined ||
      localFormData !== prevLocalFormDataRef.current
    ) {
      // Update the ref with the latest current data
      prevLocalFormDataRef.current = localFormData;

      // Reset the validation executed ref when localFormData changes
      validationExecutedRef.current = false;

      // Run validation when localFormData changes
      handleValidationCallback();
    }
  }, [localFormData, handleValidationCallback, fhirPatientDataLoading]);

  const generateDefaultValues = useCallback(() => {
    let defaultValues: SliderValue[] = [];
    let startId = 0;
    let endId = 0;

    if (questionnaireID === questionnairesIds.HORMONE_QUESTIONNAIRE_FEMALE) {
      startId =
        HORMONE_QUESTIONNAIRE_SPECIFIC_IDS.FEMALE_SYMPTOMS_FIRST_QUESTION;
      endId = HORMONE_QUESTIONNAIRE_SPECIFIC_IDS.FEMALE_SYMPTOMS_LAST_QUESTION;
      defaultValues = Array.from(
        { length: endId - startId + 1 },
        (_, index) => {
          const id = startId + index;
          return {
            currentIndexOfSection: 2,
            currentSection: HORMONE_QUESTIONNAIRE_SECTIONS.SYMPTOMS,
            questionID: id,
            response: '0 - None',
          };
        },
      );
    } else if (
      questionnaireID === questionnairesIds.HORMONE_QUESTIONNAIRE_MALE
    ) {
      startId = HORMONE_QUESTIONNAIRE_SPECIFIC_IDS.MALE_SYMPTOMS_FIRST_QUESTION;
      endId = HORMONE_QUESTIONNAIRE_SPECIFIC_IDS.MALE_SYMPTOMS_LAST_QUESTION;
      defaultValues = Array.from(
        { length: endId - startId + 1 },
        (_, index) => {
          const id = startId + index;
          // Filter out specific values
          if (
            !HORMONE_QUESTIONNAIRE_SPECIFIC_IDS.MALE_SYMPTOMS_SKIP_IDS.includes(
              id,
            )
          ) {
            return {
              currentIndexOfSection: 2,
              currentSection: HORMONE_QUESTIONNAIRE_SECTIONS.SYMPTOMS,
              questionID: id,
              response: '0 - None',
            };
          }
          return null;
        },
      ).filter((value): value is SliderValue => value !== null);
    }

    return defaultValues;
  }, [questionnaireID]);

  useEffect(() => {
    const defaultValues = generateDefaultValues();

    // Set slider values default values only if it's empty
    if (sliderValues.length === 0) {
      // Update persistentData with the newValues, maintaining the already existing ones filled by the user
      // and generating the ones that are not present based on default values
      setPersistentData((prevState) => {
        const updatedData = [
          ...prevState,
          ...defaultValues.filter(
            (newValue) =>
              !prevState.some(
                (item) => item.questionID === newValue.questionID,
              ),
          ),
        ];

        return updatedData;
      });
      setSliderValues(defaultValues);
    }
  }, [
    generateDefaultValues,
    setPersistentData,
    sliderValues,
    submitWasTriggered,
  ]);

  const updateErrorsInSections = useCallback(
    (
      prevState: ModuleFormErrors | null,
      currentSectionName: string,
    ): ModuleFormErrors => {
      const updatedSections = (prevState?.sectionsHasErrors ?? []).map(
        (section) =>
          section === currentSection.name ? currentSection.name : '',
      );

      // Add the value if it doesn't exist
      if (!updatedSections.includes(currentSectionName)) {
        updatedSections.push(currentSectionName);
      }

      return {
        sectionsHasErrors: updatedSections,
      };
    },
    [currentSection.name],
  );

  const checkSectionForNullErrors = useCallback(
    (persistentData: QuestionnaireResponseInterface[]): boolean => {
      let status = false;
      const medicationList: QuestionnaireResponseInterface[] =
        persistentData.filter(
          (data) =>
            data.questionID === MEDICATIONS_IDS.MEDICATIONS_DYNAMIC_LIST,
        );

      const validQuestionIDs = medicationIdsFromHormoneQuestionnaire;

      const hasValidMedicationAnswers = medicationList.every((medication) => {
        const medicationAnswers =
          medication &&
          medication.response &&
          Array.isArray(medication.response) &&
          medication.response.map((item) => item.answers);

        const validLength =
          medicationAnswers &&
          medicationAnswers.every((answers) => {
            return (
              answers &&
              answers.length &&
              answers.length === HORMONE_MEDICATION_MAX_LENGTH
            );
          });

        const hasAllValidIDs =
          medicationAnswers &&
          medicationAnswers.every((answers) => {
            const hasAllValidIDsForAnswers = validQuestionIDs.every(
              (id) =>
                answers &&
                answers.some((answer) => answer.questionID === id) &&
                answers.every((answer) => answer.response !== ''),
            );
            return hasAllValidIDsForAnswers;
          });

        return validLength && hasAllValidIDs;
      });

      // Search for questionID hasMedicationDynamicList and hasTableMedication in persistentData
      const hasMedicationDynamicList = persistentData.some((data) => {
        return (
          data.questionID === MEDICATIONS_IDS.MEDICATIONS_DYNAMIC_LIST &&
          data.response &&
          Array.isArray(data.response) &&
          data.response.length != 0
        );
      });
      const hasTableMedication = persistentData.some(
        (data) => data.questionID === MEDICATIONS_IDS.TABLE_MEDICATIONS,
      );

      if (!hasMedicationDynamicList && !hasTableMedication) {
        return true;
      }

      if (hasTableMedication && !hasMedicationDynamicList) {
        status = true;
      }

      if (hasMedicationDynamicList && !hasTableMedication) {
        status = hasValidMedicationAnswers;
      }

      if (hasTableMedication && hasMedicationDynamicList) {
        status = hasValidMedicationAnswers;
      }

      return status;
    },
    [],
  );

  const handleErrorsUpdate = useCallback(
    (
      prevState: ModuleFormErrors | null,
      currentSectionName: string,
      checkForNullErrors: boolean,
    ): ModuleFormErrors => {
      if (!checkForNullErrors) {
        return updateErrorsInSections(prevState, currentSectionName);
      } else {
        const updatedSections = (prevState?.sectionsHasErrors ?? []).filter(
          (section) => section !== currentSectionName,
        );
        return {
          sectionsHasErrors: updatedSections,
        };
      }
    },
    [updateErrorsInSections],
  );

  useEffect(() => {
    const isSectionValid =
      checkSectionForNullErrors(persistentData) && enableShareData === true;
    setModuleFormErrors((prevState: ModuleFormErrors | null) =>
      handleErrorsUpdate(prevState, currentSection.name, isSectionValid),
    );
  }, [
    errors,
    setModuleFormErrors,
    currentSection,
    handleErrorsUpdate,
    checkSectionForNullErrors,
    persistentData,
    enableShareData,
  ]);

  useEffect(() => {
    if (
      section.name === HORMONE_QUESTIONNAIRE_SECTIONS.HORMONE_AND_MEDICATIONS
    ) {
      setPersistentData((prevState) =>
        updateStateWithMedicationValues(
          prevState,
          section,
          medicationList,
          MEDICATIONS_IDS.MEDICATIONS_DYNAMIC_LIST,
        ),
      );
    }
  }, [section, medicationList, textLocalFieldResponses, setPersistentData]);

  useEffect(() => {
    if (enableShareData) {
      setPersistentData((prevState) => [
        ...prevState,
        {
          currentSection:
            HORMONE_QUESTIONNAIRE_SECTIONS.HORMONE_AND_MEDICATIONS,
          currentIndexOfSection: 2,
          questionID: HORMONE_QUESTIONNAIRE_SPECIFIC_IDS.ENABLE_SHARE_DATA_ID,
          response: 'enable',
        },
      ]);
    } else {
      setPersistentData((prevState) =>
        prevState.filter(
          (item) =>
            item.id !== HORMONE_QUESTIONNAIRE_SPECIFIC_IDS.ENABLE_SHARE_DATA_ID,
        ),
      );
    }
  }, [enableShareData, setPersistentData]);

  if (
    dynamicQuestionnaireLocaleLoading &&
    !dynamicQuestionnaireLocale &&
    !fhirUserData &&
    fhirLoadingUserData &&
    fhirPatientDataLoading
  ) {
    return null;
  }
  return (
    <>
      <div className="mt-5">
        {section.name ===
          HORMONE_QUESTIONNAIRE_SECTIONS.HORMONE_AND_MEDICATIONS &&
          showTimeElapsedAlert && (
            <div className="grid gap-y-2.5 mb-5">
              <Alert
                type="info"
                text={dynamicQuestionnaireLocale?.timeElapsedAlert}
              />
            </div>
          )}

        {section.name ===
          HORMONE_QUESTIONNAIRE_SECTIONS.INDIVIDUAL_INFORMATION &&
          !allBloodWorkQuestionsAnswered && (
            <div className="grid gap-y-2.5 mb-5">
              <Alert
                type="info"
                text={dynamicQuestionnaireLocale?.bloodAlert}
              />
            </div>
          )}
        {section.name ===
          HORMONE_QUESTIONNAIRE_SECTIONS.INDIVIDUAL_INFORMATION &&
          userHasOutdatedProfileInformation !== null &&
          userHasOutdatedProfileInformation === true && (
            <div className="grid gap-y-2.5 mb-5">
              <Alert
                type="info"
                text={dynamicQuestionnaireLocale?.outdatedInfoAlert}
              />
            </div>
          )}
        {section.name ===
          HORMONE_QUESTIONNAIRE_SECTIONS.INDIVIDUAL_INFORMATION && (
          <p className="text-h7 font-bold uppercase mb-[15px]">
            {dynamicQuestionnaireLocale?.patientDetails}
          </p>
        )}

        {section.name === HORMONE_QUESTIONNAIRE_SECTIONS.SYMPTOMS && (
          <p className="pb-10 text-base font-semibold text-dark-gray">
            {dynamicQuestionnaireLocale?.paragraphSymptoms}
          </p>
        )}

        {sortedQuestionList.map((question, idx) => {
          if (
            medicationIdsFromHormoneQuestionnaire.includes(
              question.questionnaire_questions_question.id,
            )
          ) {
            return null; // Skip rendering for these IDs
          }
          return (
            <div key={idx}>
              {question.questionnaire_questions_question.id === 11 && (
                <>
                  <hr className="my-[25px]" />
                  <p className="text-h7 font-bold uppercase mb-[15px]">
                    {dynamicQuestionnaireLocale?.backgroundInformation}
                  </p>
                </>
              )}
              <div
                className={`w-full flex flex-col justify-start mb-4 
                ${
                  section.name ===
                  HORMONE_QUESTIONNAIRE_SECTIONS.MENSTRUAL_STATUS
                    ? 'w-full desktop:flex-col'
                    : ''
                }
                ${
                  section.name === HORMONE_QUESTIONNAIRE_SECTIONS.SYMPTOMS
                    ? 'w-full desktop:flex-col px-[22px] desktop:px-[120px]'
                    : ''
                }
                ${
                  question.questionnaire_questions_question.id ===
                    HORMONE_QUESTIONNAIRE_SPECIFIC_IDS.FAST_FIELD ||
                  question.questionnaire_questions_question.id ===
                    HORMONE_QUESTIONNAIRE_SPECIFIC_IDS.BLOOD_SAMPLE ||
                  question.questionnaire_questions_question.id ===
                    HORMONE_QUESTIONNAIRE_SPECIFIC_IDS.MEDICATION_USAGE_TIME_QUESTION
                    ? 'w-full desktop:flex-col'
                    : 'desktop:flex-row'
                }`}
                key={question.questionnaire_questions_question.id}
              >
                <div
                  className={`flex w-full items-center 
                  ${
                    section.name ===
                    HORMONE_QUESTIONNAIRE_SECTIONS.MENSTRUAL_STATUS
                      ? 'w-full !max-w-[100%]'
                      : ''
                  }
                  
                  ${
                    question.questionnaire_questions_question.id ===
                      HORMONE_QUESTIONNAIRE_SPECIFIC_IDS.FAST_FIELD ||
                    question.questionnaire_questions_question.id ===
                      HORMONE_QUESTIONNAIRE_SPECIFIC_IDS.BLOOD_SAMPLE ||
                    question.questionnaire_questions_question.id ===
                      HORMONE_QUESTIONNAIRE_SPECIFIC_IDS.MEDICATION_USAGE_TIME_QUESTION
                      ? 'w-full'
                      : 'max-w-[250px]'
                  }`}
                >
                  <p
                    className={`
                  flex
                  ${
                    missingMainQuestionIds.includes(
                      question.questionnaire_questions_question.id.toString(),
                    ) && submitWasTriggered
                      ? 'text-clc-red'
                      : 'text-dark-gray'
                  } font-semibold `}
                  >
                    {question.questionnaire_questions_question.text}
                    {isRequiredQuestion(
                      question.questionnaire_questions_question.id.toString(),
                    ) && (
                      <span className="text-base font-bold text-clc-red">
                        {' '}
                        *
                      </span>
                    )}
                  </p>
                  {question.questionnaire_questions_question.subtitle && (
                    <p className="italic text-med-gray">
                      {question.questionnaire_questions_question.subtitle}
                    </p>
                  )}
                </div>

                {section.name ===
                  HORMONE_QUESTIONNAIRE_SECTIONS.INDIVIDUAL_INFORMATION &&
                  renderIndividualInformationQuestionFactory(question)}
                {questionnaireID ===
                  questionnairesIds.HORMONE_QUESTIONNAIRE_FEMALE &&
                  section.name ===
                    HORMONE_QUESTIONNAIRE_SECTIONS.MENSTRUAL_STATUS &&
                  renderMenstrualStatusQuestionFactory(question)}

                {section.name === HORMONE_QUESTIONNAIRE_SECTIONS.SYMPTOMS && (
                  <div className="desktop:px-[60px]">
                    {renderSymptomsQuestionFactory(question)}
                  </div>
                )}
              </div>
            </div>
          );
        })}
        {section.name ===
          HORMONE_QUESTIONNAIRE_SECTIONS.HORMONE_AND_MEDICATIONS && (
          <>
            <div className="w-full">
              <p className="text-lg text-dark-gray font-semibold mb-5">
                {dynamicQuestionnaireLocale?.medicationHormoneParagraph}
              </p>
              <div className="mb-6">
                <HormoneMedicationTable
                  locale={dynamicQuestionnaireLocale}
                  persistentData={persistentData}
                  setPersistentData={setPersistentData}
                  setShowTimeElapsedAlert={setShowTimeElapsedAlert}
                  hormoneTableMedicationsSelected={
                    hormoneTableMedicationsSelected
                  }
                  setHormoneTableMedicationsSelected={
                    setHormoneTableMedicationsSelected
                  }
                  hormoneTableCheckboxesCheckedState={
                    hormoneTableCheckboxesCheckedState
                  }
                  setHormoneTableCheckboxesCheckedState={
                    setHormoneTableCheckboxesCheckedState
                  }
                  hormoneTableCheckboxCheckAll={hormoneTableCheckboxCheckAll}
                  setHormoneTableCheckboxAll={setHormoneTableCheckboxAll}
                />
              </div>
              {medicationList.map((medication, medicationIdx) => {
                return (
                  <div key={medicationIdx}>
                    {sortedQuestionList.map((question, idx) => {
                      return (
                        <div key={idx}>
                          <div
                            className={`w-full flex flex-col justify-start mb-4 
                ${
                  question.questionnaire_questions_question.id ===
                  HORMONE_QUESTIONNAIRE_SPECIFIC_IDS.MEDICATION_USAGE_TIME_QUESTION
                    ? 'w-full desktop:flex-col'
                    : 'desktop:flex-row'
                }`}
                            key={question.questionnaire_questions_question.id}
                          >
                            <div
                              className={`flex w-full items-center 
                  
                  ${
                    question.questionnaire_questions_question.id ===
                    HORMONE_QUESTIONNAIRE_SPECIFIC_IDS.MEDICATION_USAGE_TIME_QUESTION
                      ? 'w-full'
                      : 'max-w-[250px]'
                  }`}
                            >
                              <p
                                className={`
                  flex
                  ${
                    missingMainQuestionIds.includes(
                      question.questionnaire_questions_question.id.toString(),
                    ) && submitWasTriggered
                      ? 'text-clc-red'
                      : 'text-dark-gray'
                  } font-semibold `}
                              >
                                {question.questionnaire_questions_question.text}
                                {isRequiredQuestion(
                                  question.questionnaire_questions_question.id.toString(),
                                ) && (
                                  <span className="text-base font-bold text-clc-red">
                                    {' '}
                                    *
                                  </span>
                                )}
                              </p>
                              {question.questionnaire_questions_question
                                .subtitle && (
                                <p className="italic text-med-gray">
                                  {
                                    question.questionnaire_questions_question
                                      .subtitle
                                  }
                                </p>
                              )}
                            </div>

                            {renderHormoneAndMedicationsFactory(
                              question,
                              medication.id ?? 0,
                              medicationIdx,
                            )}
                          </div>
                        </div>
                      );
                    })}
                    <div className="flex pb-8">
                      {
                        <button
                          className="flex mr-6"
                          onClick={() => removeMedication(medicationIdx)}
                        >
                          <MinusIcon className="text-clc-blue mr-2" />
                          <span className="text-clc-blue font-bold underline uppercase">
                            {dynamicQuestionnaireLocale.removeMedication}
                          </span>
                        </button>
                      }
                    </div>
                  </div>
                );
              })}
              {medicationList.length === 0 && (
                <div className="min-h-[103px]">
                  <p className="text-base">
                    {dynamicQuestionnaireLocale?.medicationPlaceholderParagraph}
                  </p>
                </div>
              )}

              <div>
                <hr />
                <button
                  className="flex mr-3 my-5"
                  onClick={insertAfterLastMedication}
                >
                  <PlusIcon className="mr-2" />
                  <span className="text-clc-blue font-bold underline uppercase">
                    {medicationList.length > 0
                      ? dynamicQuestionnaireLocale.addAnotherMedication
                      : dynamicQuestionnaireLocale.addMedication}
                  </span>
                </button>
              </div>
            </div>

            <hr className="mt-5" />
            <div className="grid gap-y-2.5 mt-5 mb-1">
              <Alert
                type="info"
                text={dynamicQuestionnaireLocale?.alertShareData}
              />
            </div>
            <div className="flex desktop:mr-5 py-5">
              <InputComponent
                type="checkbox"
                checkboxProps={{
                  checked: enableShareData,
                  onCheckboxChange: () =>
                    setEnableShareData((prevState) => !prevState),
                }}
              />
              <label
                className="ml-3 group"
                htmlFor="labSampleAndResultPrivacyStatement"
              >
                <span className="group-hover:cursor-pointer text-base text-med-gray font-semibold">
                  {dynamicQuestionnaireLocale?.checkboxShareData}
                </span>
              </label>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default HormoneQuestionnaireModule;
