import { useState, useRef, useCallback } from 'react';
import _ from 'lodash';
import * as VideoExpress from '@vonage/video-express';
import { useBackgroundBlur } from './useBackgroundBlur';
import CameraPublisher from '@vonage/video-express/dist/mp/camera-publisher';
import ScreenPublisher from '@vonage/video-express/dist/mp/screen-publisher';
import RoomWrapper from '@vonage/video-express/dist/mp/room';
import {
  AUDIO_LEVEL,
  CURRENT_LOG_LEVEL,
  MOVING_AVERAGE,
  MAX_VIDEO_PARTICIPANTS,
  pageIds,
} from 'utilities/constants';
import { useGetPage } from 'hooks/useGetPage';
import { RoomProperties } from '@vonage/video-express/dist/utils/types';

interface RoomParticipant {
  id: string;
  name: string;
}

export interface RoomCredentials {
  apikey: string;
  sessionId: string;
  token: string;
}

export interface RoomVideoEffects {
  backgroundBlur: boolean;
  videoSourceId: string;
}

export interface RoomPublisherOptions {
  publishAudio: boolean;
  publishVideo: boolean;
  audioSource?: string | undefined;
  videoSource?: string | undefined;
  audioOutput?: string | undefined;
}

interface RoomHook {
  createCall: (
    credentials: RoomCredentials,
    roomContainer: string,
    userName: string,
    videoEffects: RoomVideoEffects,
    publisherOptions: RoomPublisherOptions,
  ) => Promise<void>;
  connected: boolean;
  camera: CameraPublisher | null;
  screen: ScreenPublisher | null;
  room: VideoExpress.Room | null;
  participants: RoomParticipant[];
  networkStatus: string | null;
  publisherIsSpeaking: boolean;
  cameraPublishing: boolean;
  localParticipant: RoomParticipant | null;
  removeLocalParticipant: ({
    participant,
  }: {
    participant: RoomParticipant;
  }) => void;
  addPublisherCameraEvents: () => void;
}

export const useRoom = (): RoomHook => {
  const { data: locale } = useGetPage({
    locale: 'en',
    pageId: pageIds.FIREBASE_ERRORS,
  });
  const { startBackgroundBlur } = useBackgroundBlur();
  const roomRef = useRef<VideoExpress.Room | null>(null);
  const publisherOptionsRef = useRef<RoomPublisherOptions | null>(null);
  const [camera, setCamera] = useState<CameraPublisher | null>(null);
  const [screen, setScreen] = useState<ScreenPublisher | null>(null);
  const [localParticipant, setLocalParticipant] =
    useState<RoomParticipant | null>(null);
  const [connected, setConnected] = useState<boolean>(false);
  const [participants, setParticipants] = useState<RoomParticipant[]>([]);
  const [networkStatus, setNetworkStatus] = useState<string | null>(null);
  const [publisherIsSpeaking, setPublisherIsSpeaking] =
    useState<boolean>(false);
  const [cameraPublishing, setCameraPublishing] = useState<boolean>(false);

  const addParticipants = useCallback(
    ({ participant }: { participant: RoomParticipant }) => {
      setParticipants((prev) => [...prev, participant]);
    },
    [],
  );

  const removeParticipants = useCallback(
    ({ participant }: { participant: RoomParticipant }) => {
      setParticipants((prev) =>
        prev.filter((prevparticipant) => prevparticipant.id !== participant.id),
      );
    },
    [],
  );

  const addLocalParticipant = useCallback(
    ({ room }: { room: VideoExpress.Room }) => {
      if (room) {
        setLocalParticipant({
          id: room?.participantId || '',
          name: room?.participantName || '',
        });
      }
    },
    [],
  );

  const removeLocalParticipant = useCallback(() => {
    setParticipants([]);
  }, []);

  const onAudioLevel = useCallback((audioLevel: number) => {
    let movingAvg = null;
    if (movingAvg === null || movingAvg <= audioLevel) {
      movingAvg = audioLevel;
    } else {
      movingAvg = MOVING_AVERAGE * movingAvg + AUDIO_LEVEL * audioLevel;
    }

    const currentLogLevel = Math.log(movingAvg) / Math.LN10 / 1.5 + 1;
    if (currentLogLevel > CURRENT_LOG_LEVEL) {
      setPublisherIsSpeaking(true);
    } else {
      setPublisherIsSpeaking(false);
    }
  }, []);

  const addPublisherCameraEvents = useCallback(() => {
    if (roomRef.current?.camera) {
      roomRef.current.camera.on(
        'audioLevelUpdated',
        _.throttle((event) => onAudioLevel(event), 250),
      );
    }
  }, [onAudioLevel]);

  const startRoomListeners = useCallback(() => {
    if (roomRef.current) {
      roomRef.current.on('connected', () => {
        console.log('Room: connected');
      });
      roomRef.current.on('disconnected', () => {
        setNetworkStatus('disconnected');
        console.log('Room: disconnected');
      });
      roomRef.current?.camera.on('created', () => {
        setCameraPublishing(true);
        console.log('camera publishing now');
      });
      roomRef.current.on('activeSpeakerChanged', (participant) => {
        console.log('Active speaker changed', participant);
      });

      roomRef.current.on('reconnected', () => {
        setNetworkStatus('reconnected');
        console.log('Room: reconnected');
      });
      roomRef.current.on('reconnecting', () => {
        setNetworkStatus('reconnecting');
        console.log('Room: reconnecting');
      });
      roomRef.current.on('participantJoined', (participant) => {
        console.log(participant);
        addParticipants({ participant } as { participant: never });
        console.log('Room: participant joined: ', participant);
      });
      roomRef.current.on('participantLeft', (participant, reason) => {
        removeParticipants({ participant } as { participant: never });
        console.log('Room: participant left', participant, reason);
      });
    }
  }, [addParticipants, removeParticipants]);

  const createCall = useCallback(
    async (
      {
        apikey,
        sessionId,
        token,
      }: { apikey: string; sessionId: string; token: string },
      roomContainer: string,
      userName: string,
      videoEffects: { backgroundBlur: boolean; videoSourceId: string },
      publisherOptions: RoomPublisherOptions,
    ): Promise<void> => {
      if (!apikey || !sessionId || !token) {
        throw new Error(locale?.invalidCredentials);
      }

      roomRef.current = new VideoExpress.Room({
        apiKey: apikey,
        sessionId: sessionId,
        token: token,
        roomContainer: 'roomContainer',
        maxVideoParticipantsOnScreen: MAX_VIDEO_PARTICIPANTS,
        participantName: userName,
        managedLayoutOptions: {
          deviceLayoutMode: 'auto',
          layoutMode: 'active-speaker',
          screenPublisherContainer: 'screenSharingContainer',
        },
      } as RoomProperties);
      startRoomListeners();
      if (videoEffects.backgroundBlur) {
        const outputVideoStream = await startBackgroundBlur(
          videoEffects.videoSourceId,
        );

        publisherOptionsRef.current = Object.assign({}, publisherOptions, {
          style: {
            buttonDisplayMode: 'off',
            nameDisplayMode: 'auto',
            audioLevelDisplayMode: 'off',
          },
          mirror: true,

          videoSource: outputVideoStream?.getVideoTracks()[0],
          name: userName,
          showControls: true,
        });
      } else {
        publisherOptionsRef.current = Object.assign({}, publisherOptions, {
          style: {
            buttonDisplayMode: 'off',
            nameDisplayMode: 'auto',
            audioLevelDisplayMode: 'off',
          },
          name: userName,
          showControls: true,
        });
      }

      roomRef.current
        ?.join({ publisherProperties: publisherOptionsRef.current as never })
        .then(() => {
          setConnected(true);
          setCamera(roomRef.current?.camera || null);
          setScreen(roomRef.current?.screen || null);
          addLocalParticipant({ room: roomRef.current as RoomWrapper });
        })
        .catch((e) => console.log(e));
    },
    [
      addLocalParticipant,
      locale?.invalidCredentials,
      startBackgroundBlur,
      startRoomListeners,
    ],
  );

  return {
    createCall,
    connected,
    camera,
    screen,
    room: roomRef.current,
    participants,
    networkStatus,
    publisherIsSpeaking,
    cameraPublishing,
    localParticipant,
    removeLocalParticipant,
    addPublisherCameraEvents,
  };
};
