import { Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { Login } from 'auth/pages/Login/Login';
import { SignUp } from 'auth/pages/SignUp/SignUp';
import { Verification } from 'auth/pages/Verification/Verification';
import { ForgotPassword } from 'auth/pages/ForgotPassword/ForgotPassword';
import { ResetPassword } from 'auth/pages/ResetPassword/ResetPassword';
import { Action } from 'auth/pages/Action/Action';
import { SendVerificationEmail } from 'auth/pages/SendVerificationEmail/SendVerificationEmail';
import { SendResetPasswordEmail } from 'auth/pages/SendResetPasswordEmail/SendResetPasswordEmail';
import { FooterTypes } from 'components/footer/footer.types';
import AuthLayout from 'layout/AuthLayout';
import NetworkRequiredRoutes from 'components/networkRequiredRoutes';
import { MultiFactorAuthVerify } from 'auth/pages/MultiFactorAuth/MultiFactorAuthVerify';
import { MultiFactorAuthSetup } from 'auth/pages/MultiFactorAuth/MultiFactorAuthSetup';
import { BASE_ROUTE, SIGN_UP_PROVIDER } from 'utilities/routes';
import { SignUpProvider } from 'auth/pages/SignUpProvider/SignUpProvider';

enum ContentSize {
  DEFAULT = 'desktop:w-[48%]',
  LARGE = 'desktop:w-[59%]',
  FULL_WIDTH = 'desktop:w-full',
}

export const AuthRoutes = () => {
  const location = useLocation();
  const isSignUpOrMfaRoute =
    location.pathname === '/auth/sign-up' ||
    location.pathname === '/auth/mfa-setup';

  const bgImageClass = isSignUpOrMfaRoute
    ? 'bg-register-m bg-contain desktop:bg-register'
    : 'bg-login-m bg-cover desktop:bg-login';

  let contentSize: ContentSize = ContentSize.DEFAULT;
  let footerType: FooterTypes;

  switch (location.pathname) {
    case '/auth/sign-up':
      footerType = 'signup';
      break;
    case '/auth/sign-up-provider':
      contentSize = ContentSize.FULL_WIDTH;
      footerType = 'default';
      break;
    case '/auth/mfa-setup':
      contentSize = ContentSize.LARGE;
      footerType = 'signup';
      break;
    case '/auth/login':
      contentSize = ContentSize.LARGE;
      footerType = 'login';
      break;
    case '/auth/mfa-verify':
      contentSize = ContentSize.LARGE;
      footerType = 'login';
      break;
    case '/auth/forgot-password':
    case '/auth/reset-password':
    case '/auth/verification':
    case '/auth/send-verification-email':
    case '/auth/send-reset-password-email':
      footerType = 'login';
      break;
    default:
      footerType = 'default';
      break;
  }

  return (
    <AuthLayout
      background={bgImageClass}
      footerType={footerType}
      contentSize={contentSize}
    >
      <NetworkRequiredRoutes>
        <Routes>
          <Route path={BASE_ROUTE} element={<Navigate to="/login" />} />
          <Route path="/login" element={<Login />} />
          <Route path="/sign-up" element={<SignUp />} />
          <Route path={SIGN_UP_PROVIDER} element={<SignUpProvider />} />
          <Route path="/mfa-verify" element={<MultiFactorAuthVerify />} />
          <Route path="/mfa-setup" element={<MultiFactorAuthSetup />} />
          <Route
            path="/send-verification-email"
            element={<SendVerificationEmail />}
          />
          <Route
            path="/send-reset-password-email"
            element={<SendResetPasswordEmail />}
          />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/verification" element={<Verification />} />
          <Route path="/action" element={<Action />} />
          <Route path="/*" element={<Navigate to="/" />} />
        </Routes>
      </NetworkRequiredRoutes>
    </AuthLayout>
  );
};
