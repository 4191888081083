import { BodySymptomsPlacerProps, Dot } from './interfaces';
import DotPlacer from './DotPlacer';
import InputComponent from 'components/inputComponent';
import { componentIds } from 'utilities/constants';
import { useGetComponent } from 'hooks/useGetComponent';
import { useMemo, useState } from 'react';
import { initialDotsState } from 'app/my-skin/components/BodySymtomsLocation/constants';

const BodySymptomsPlacer: React.FC<BodySymptomsPlacerProps> = ({
  noForm = false,
  backDots,
  frontDots,
  setBackDots,
  setFrontDots,
  onClick,
}) => {
  const [experiencingSymptoms, setExperiencingSymptoms] = useState(null);
  const headFrontDots: Dot[] = frontDots.filter(
    (dot) => dot.headPoints !== undefined,
  );

  const headBackDots: Dot[] = backDots.filter(
    (dot) => dot.headPoints !== undefined,
  );

  const { data: locale, loading } = useGetComponent({
    locale: 'en',
    componentId: componentIds.APPOINTMENT_PREWORK,
  });

  const toggleFrontDot = (id: number): void => {
    const newFrontDots = frontDots.map((dot) => ({
      ...dot,
      selected: dot.id === id ? !dot.selected : dot.selected,
    }));
    setFrontDots(newFrontDots);
    setExperiencingSymptoms(null);
  };
  const toggleBackDot = (id: number): void => {
    const newBackDots = backDots.map((dot) => ({
      ...dot,
      selected: dot.id === id ? !dot.selected : dot.selected,
    }));
    setBackDots(newBackDots);
    setExperiencingSymptoms(null);
  };

  const toggleOnAllDots = (): void => {
    setFrontDots((dots) =>
      dots.map((dot) => ({
        ...dot,
        selected: true,
      })),
    );
    setBackDots((dots) =>
      dots.map((dot) => ({
        ...dot,
        selected: true,
      })),
    );
  };

  const toggleOffAllDots = (): void => {
    const newFrontDots = frontDots.map((dot) => ({
      ...dot,
      selected: false,
    }));
    const newBackDots = backDots.map((dot) => ({
      ...dot,
      selected: false,
    }));
    setFrontDots(newFrontDots);
    setBackDots(newBackDots);
  };

  const handleBodyParsing = (direction: 'front' | 'back', bodyId: number) => {
    const dots = initialDotsState;
    const dot = dots[direction].find((dot) => dot.id === bodyId);
    if (!dot) return;
    let label = '';
    const frontKeys = locale?.frontCheckboxes;
    const backKeys = locale?.backCheckboxes;

    switch (direction) {
      case 'front':
        {
          const filteredLabel = dots.front.filter((dot) => dot.id === bodyId);
          label = frontKeys[filteredLabel[0].strapiKey];
        }
        break;
      case 'back':
        {
          const filteredLabel = dots.back.filter((dot) => dot.id === bodyId);
          label = backKeys[filteredLabel[0].strapiKey];
        }
        break;
      default:
        label = 'N/A';
        break;
    }

    if (!onClick || !label) {
      if (direction === 'back') {
        toggleBackDot(bodyId);
      } else {
        toggleFrontDot(bodyId);
      }
      return;
    }
    onClick(label, direction, bodyId);
  };

  const FormComponent = useMemo<React.FC<{ children: React.ReactNode }>>(() => {
    if (noForm) {
      return ({ children }) => <>{children}</>;
    }
    return ({ children }) => (
      <form className="flex flex-col items-center gap-2.5 p-8 rounded-lg bg-white w-4/5">
        {children}
      </form>
    );
  }, [noForm]);

  if (loading || !locale) return null;
  return (
    <FormComponent>
      <div className="flex flex-col desktop:flex-row items-center desktop:items-start w-full">
        <div className="mb-4 desktop:mb-0 w-full">
          <h3 className="font-semibold text-h6">
            {locale?.whereExperiencingSymptoms}{' '}
            <span className="text-sm text-med-gray">
              {locale?.selectAllThatApply}
            </span>
          </h3>
          <ul className="mt-2.5">
            <li>
              <InputComponent
                type="radio"
                name="experiencingSymptomsNoneOfTheBelow"
                radioInputProps={{
                  radioInputLabel: locale?.noneOfTheBelow,
                  radioInputValue: locale?.noneOfTheBelow,
                  radioInputCheckedValue:
                    experiencingSymptoms === locale?.noneOfTheBelow
                      ? locale?.noneOfTheBelow
                      : undefined,
                  radioError: null,
                  onRadioClick: () => {
                    setExperiencingSymptoms(locale?.noneOfTheBelow);
                    toggleOffAllDots();
                  },
                }}
              />
            </li>
            <li className="hidden">
              <InputComponent
                type="radio"
                name="experiencingSymptomsAllOfTheBelow"
                radioInputProps={{
                  radioInputLabel: locale?.allOfTheBelow,
                  radioInputValue: locale?.allOfTheBelow,
                  radioInputCheckedValue:
                    experiencingSymptoms === locale?.allOfTheBelow
                      ? locale?.allOfTheBelow
                      : undefined,
                  radioError: null,
                  onRadioClick: () => {
                    setExperiencingSymptoms(locale?.allOfTheBelow);
                    toggleOnAllDots();
                  },
                }}
              />
            </li>
          </ul>
          <h3 className="font-bold text-sm mt-2.5">{locale?.frontTitle}</h3>
          <div className="flex flex-col desktop:flex-row-reverse w-full desktop:justify-between">
            <div className="flex flex-col items-center desktop:flex-row desktop:items-start">
              <DotPlacer
                dots={headFrontDots}
                onDotSelected={(id) => handleBodyParsing('front', id)}
                type="HeadFront"
              />
              <DotPlacer
                dots={frontDots.filter((dot) => dot.headPoints === undefined)}
                onDotSelected={(id) => handleBodyParsing('front', id)}
                type="BodyFront"
              />
            </div>
            <div className="grid grid-cols-1 desktop:grid-cols-2 gap-4 mt-2">
              {frontDots.map((dot) => (
                <div className="flex" key={dot.id}>
                  <InputComponent
                    name={dot.id.toString()}
                    type="checkbox"
                    checkboxProps={{
                      checked: dot.selected,
                      isAlternativeCheckboxColor: true,
                      onCheckboxChange: () =>
                        handleBodyParsing('front', dot.id),
                    }}
                  />
                  <label
                    htmlFor={dot.id.toString()}
                    className="ml-2 hover:cursor-pointer text-sm self-center"
                  >
                    {dot.location}
                  </label>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col desktop:flex-row items-center desktop:items-start desktop:justify-between w-full">
        <div className="mb-4 desktop:mb-0 w-full">
          <h3 className="font-bold text-sm">{locale.backTitle}</h3>
          <div className="flex flex-col desktop:flex-row-reverse w-full desktop:justify-between">
            <div className="flex flex-col items-center desktop:flex-row desktop:items-start">
              <DotPlacer
                dots={headBackDots}
                onDotSelected={(id) => handleBodyParsing('back', id)}
                type="HeadBack"
              />
              <DotPlacer
                dots={backDots.filter((dot) => dot.headPoints === undefined)}
                onDotSelected={(id) => handleBodyParsing('back', id)}
                type="BodyBack"
              />
            </div>
            <div className="grid grid-cols-1 desktop:grid-cols-2 gap-4 mt-2.5">
              {backDots.map((dot) => (
                <div className="flex" key={dot.id}>
                  <InputComponent
                    name={dot.id.toString()}
                    type="checkbox"
                    checkboxProps={{
                      checked: dot.selected,
                      isAlternativeCheckboxColor: true,
                      onCheckboxChange: () => handleBodyParsing('back', dot.id),
                    }}
                  />
                  <label
                    htmlFor={dot.id.toString()}
                    className="ml-2 hover:cursor-pointer text-sm self-center"
                  >
                    {dot.location}
                  </label>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </FormComponent>
  );
};

export default BodySymptomsPlacer;
