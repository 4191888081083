import {
  HistoricAppointmentsPatientFilter,
  MyVisitSummaryHistoryPatientTable,
} from 'app/appointments/components/interfaces';
import { Card } from 'app/my-appointments/components/Card';
import { useGetPage } from 'hooks/useGetPage';
import { useCallback, useMemo, useRef, useState } from 'react';
import {
  PARAM_MODALS_IDENTIFIERS,
  componentIds,
  pageIds,
} from 'utilities/constants';
import InLineLastVisitSummary from '../components/InLineLastVisitSummary';
import MyVisitSummariesHistoryTableDesktop, {
  columnKeys,
} from '../components/MyVisitSummariesHistoryTableDesktop';
import { MyVisitSummariesHistoryFilter } from '../components/MyVisitSummariesHistoryFilter';
import { MyVisitSummaryHistoryFilterMobile } from '../components/MyVisitSummaryHistoryFilterMobile';
import MyVisitSummariesHistoryTableMobile from '../components/MyVisitSummariesHistoryTableMobile';
import {
  AppointmentTypeEnum,
  useGetFulfilledAppointmentsByPatientQuery,
} from 'graphql/generated/remote-schema-hasura';

import { formatDateToCustomFormat } from 'utilities/functions';
import { useGetComponent } from 'hooks/useGetComponent';
import { useIsMobile } from 'hooks/useIsMobile';
import Loader from 'components/loaderComponent';
import ButtonComponent from 'components/button/buttonComponent';
import { ReactComponent as ArrowDownIcon } from 'assets/icons/arrowDown.svg';
import { useModalParams } from 'components/modal/useModalManager';
import MyVisitSummaryModalPatient from 'app/appointments/components/MyVisitSummaryModalPatient';
import { useGetMyVisitSummary } from 'hooks/useGetMyVisitSummary';

const BATCH_SIZE = 10;

const MyVisitSummaries: React.FC = () => {
  const [surveyOption, setSelectedSurveyOption] = useState('');
  const [historicAppointmentFilter, setHistoricAppointmentFilter] =
    useState<HistoricAppointmentsPatientFilter>({});
  const [
    historicAppointmentAppliedFilter,
    setHistoricAppointmentAppliedFilter,
  ] = useState<HistoricAppointmentsPatientFilter>({});
  const [currentLimit, setCurrentLimit] = useState(BATCH_SIZE);

  const { data: locale, loading: localeLoading } = useGetPage({
    locale: 'en',
    pageId: pageIds.MY_VISIT_SUMMARY_MODAL,
  });
  const {
    data: appointmentsTableLocale,
    loading: appointmentsTableLocaleLoading,
  } = useGetComponent({
    locale: 'en',
    componentId: componentIds.APPOINTMENTS_PATIENT_FILTER,
  });

  const { isOpen: isMyVisitSummaryModalOpen } = useModalParams(
    PARAM_MODALS_IDENTIFIERS.MY_VISIT_SUMMARY_MODAL_PATIENT_MODAL_ID,
  );
  const isMobile = useIsMobile();

  const [myVisitSummary, lastVisitSummaryLoading] = useGetMyVisitSummary();

  const { data: appointmentsData, loading: appointmentsLoading } =
    useGetFulfilledAppointmentsByPatientQuery({
      fetchPolicy: 'network-only',
      variables: {
        codexProviderId: historicAppointmentAppliedFilter.provider,
        from: historicAppointmentAppliedFilter.from,
        to: historicAppointmentAppliedFilter.to,
        type: historicAppointmentAppliedFilter.type,
        sort: historicAppointmentAppliedFilter.sort,
        sortDirection: historicAppointmentAppliedFilter.sortDirection,
        limit: currentLimit,
      },
    });

  const currentTableData = useRef<MyVisitSummaryHistoryPatientTable[]>();
  const tableData = useMemo<MyVisitSummaryHistoryPatientTable[]>(() => {
    if (!appointmentsTableLocale || !appointmentsData) {
      return currentTableData.current || [];
    }

    currentTableData.current =
      appointmentsData.getFHIRAppointmentByRequestPatientCodexId.appointment.map<MyVisitSummaryHistoryPatientTable>(
        (data) => {
          const providerId = data.codexProviderId ?? '';
          const providerName =
            data.providerFirstName || data.providerLastName
              ? `${data.providerFirstName} ${data.providerLastName}`
              : appointmentsTableLocale?.dnaVisitProviderText;
          const startDate = new Date(data.start || Date.now());
          const dateString = formatDateToCustomFormat(startDate.toISOString());
          let type: AppointmentTypeEnum;
          switch (data.appointmentType) {
            case AppointmentTypeEnum.Chat:
              type = AppointmentTypeEnum.Chat;
              break;
            case AppointmentTypeEnum.Video:
              type = AppointmentTypeEnum.Video;
              break;
            case AppointmentTypeEnum.Email:
              type = AppointmentTypeEnum.Email;
              break;
            default:
              type = AppointmentTypeEnum.Dnavisit;
          }
          return {
            id: data.appointmentCodexId,
            providerName,
            providerId,
            subject: data.subjectOfAppointment || '',
            summaryDate: dateString,
            type: type,
          };
        },
      );
    return currentTableData.current;
  }, [appointmentsTableLocale, appointmentsData]);

  const surveyOptionHandler = (value: string) => {
    setSelectedSurveyOption(value);

    // TODO: implement connection to back-end to send survey details with summary data
    // COD-2553
  };

  const handleOnLoadMore = useCallback(() => {
    setCurrentLimit((limit) => limit + BATCH_SIZE);
  }, [setCurrentLimit]);

  const handleOnFilter = useCallback(() => {
    const filterToApply: HistoricAppointmentsPatientFilter = {
      ...historicAppointmentFilter,
    };
    if (filterToApply.from) {
      const fromDate = new Date(filterToApply.from);
      fromDate.setHours(0, 0, 0, 0);
      filterToApply.from = fromDate.toISOString();
    }
    if (filterToApply.to) {
      const toDate = new Date(filterToApply.to);
      toDate.setHours(23, 59, 59, 999);
      filterToApply.to = toDate.toISOString();
    }
    setHistoricAppointmentAppliedFilter((oldFilter) => {
      const oldAppliedSort: HistoricAppointmentsPatientFilter = !isMobile
        ? {
            sort: oldFilter.sort,
            sortDirection: oldFilter.sortDirection,
          }
        : {};
      return {
        ...filterToApply,
        ...oldAppliedSort,
      };
    });
  }, [historicAppointmentFilter, isMobile]);

  if (
    localeLoading ||
    !locale ||
    appointmentsTableLocaleLoading ||
    !appointmentsTableLocale ||
    lastVisitSummaryLoading ||
    !currentTableData.current ||
    myVisitSummary === undefined
  ) {
    return <Loader />;
  }

  const canLoadMore =
    tableData.length <
    (appointmentsData?.getFHIRAppointmentByRequestPatientCodexId.total || 0);

  return (
    <>
      {isMyVisitSummaryModalOpen && <MyVisitSummaryModalPatient />}
      <div className="flex flex-col gap-10 px-5">
        {myVisitSummary && (
          <Card
            title={'Most recent summary'}
            borderedSubtitleDesktop
            showSeparator
          >
            <InLineLastVisitSummary
              myVisitSummary={myVisitSummary}
              surveyOption={surveyOption}
              surveyOptionHandler={surveyOptionHandler}
            />
          </Card>
        )}
        <Card title={'History'} borderedSubtitleDesktop showSeparator>
          <MyVisitSummariesHistoryFilter
            filter={historicAppointmentFilter}
            setFilter={setHistoricAppointmentFilter}
            onFilter={handleOnFilter}
          />
          <div className="flex flex-col self-stretch items-start gap-3">
            <MyVisitSummaryHistoryFilterMobile
              filter={historicAppointmentFilter}
              setFilter={setHistoricAppointmentFilter}
              sortColumns={[...columnKeys]}
              onFilter={handleOnFilter}
            />
            <MyVisitSummariesHistoryTableDesktop
              myVisitSummaries={tableData}
              historicAppointmentsPatientFilter={
                historicAppointmentAppliedFilter
              }
              setHistoricAppointmentsPatientFilter={
                setHistoricAppointmentAppliedFilter
              }
              loading={appointmentsLoading}
            />
            <MyVisitSummariesHistoryTableMobile
              myVisitSummaries={tableData}
              loading={appointmentsLoading}
            />
            <ButtonComponent
              disabled={!canLoadMore}
              type="underline"
              Icon={ArrowDownIcon}
              onClick={handleOnLoadMore}
            >
              {locale.seeMore}
            </ButtonComponent>
          </div>
        </Card>
      </div>
    </>
  );
};

export default MyVisitSummaries;
