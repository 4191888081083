import { PARAM_MODALS_IDENTIFIERS } from 'utilities/constants';
import {
  UpcomingAppointmentAction,
  UpcomingAppointmentsRowActionProps,
  appointmentActionRenderInfo,
} from '../interfaces/upcomingAppointments.interface';
import { useNavigate } from 'react-router-dom';
import { MY_APPOINTMENTS } from 'utilities/routes';

export const UpcomingAppointmentsRowAction: React.FC<
  UpcomingAppointmentsRowActionProps
> = ({ action, appointmentId }) => {
  const navigate = useNavigate();
  const renderInfo = appointmentActionRenderInfo[action];
  if (!renderInfo) {
    console.error(`No render info for appointment action ${action}`);
    return null;
  }
  const Icon = renderInfo.icon;

  const handleViewAppointmentDetails = () => {
    navigate(
      `${MY_APPOINTMENTS}?${PARAM_MODALS_IDENTIFIERS.APPOINTMENT_DETAILS_MODAL_PROVIDER_ID}=true`,
      {
        state: { appointmentId },
      },
    );
  };
  const handleCancelAppointment = () => {
    navigate(
      `${MY_APPOINTMENTS}?${PARAM_MODALS_IDENTIFIERS.CANCEL_APPOINTMENT_MODAL_PROVIDER_ID}=true`,
      {
        state: { appointmentId },
      },
    );
  };

  const handleOnClick = () => {
    switch (action) {
      case UpcomingAppointmentAction.View:
        handleViewAppointmentDetails();
        break;
      case UpcomingAppointmentAction.Cancel:
        handleCancelAppointment();
        break;
      default:
        console.error(`No action handler for appointment action ${action}`);
    }
  };

  return (
    <div className="desktop:px-5 flex-1 flex items-center justify-center">
      <div
        key={action}
        onClick={handleOnClick}
        className="py-2 flex-1 flex justify-center items-center cursor-pointer desktop:h-6 desktop:w-6"
      >
        <Icon />
      </div>
    </div>
  );
};
