import { ReactComponent as PwEyeShowIcon } from 'assets/icons/pwEyeShow.svg';
import ButtonComponent from 'components/button/buttonComponent';
import { ReactComponent as CalendarIcon } from 'assets/icons/cancelCalendar.svg';
import { ReactComponent as EditBlueIcon } from 'assets/icons/edit-blue.svg';
import { ReactComponent as GoToMessageAppointmentIcon } from 'assets/icons/goToMessageAppointment.svg';
import { appointmentRenderInfo } from '../../interfaces';
import { formatDateToCustomFormat } from 'utilities/functions';
import { useMyAppointmentsLocale } from 'app/my-appointments/pages/MyAppointments';
import { AuthContext } from 'auth/context/AuthContext';
import { useContext } from 'react';
import { Roles } from '../../../../firebase/interfaces';
import { UpcomingAppoinment } from '../../interfaces';
import { AppointmentTypeEnum } from 'graphql/generated/hasura';
import { useNavigate } from 'react-router-dom';
import {
  MY_APPOINTMENTS_CHAT_CONSULTATION,
  MY_APPOINTMENTS_VIDEO_CONSULTATION,
  MY_PATIENTS,
  MY_PATIENTS_BIO_INFO,
  PROVIDERS_PROVIDER_BIO,
} from 'utilities/routes';
import { PARAM_MODALS_IDENTIFIERS } from 'utilities/constants';
import { MY_APPOINTMENTS_TERMS_OF_USE } from 'utilities/routes';
import { useIsJoinable } from 'hooks/useIsJoinable';
import { FHIR_APPOINTMENT_STATUS } from 'utilities/interfaces';

interface Props extends UpcomingAppoinment {
  isDashboardView: boolean;
  hideProvider?: boolean;
}

export const UpcomingAppointmentMobile: React.FC<Props> = ({
  date,
  name: provider,
  appointmentType,
  appointmentId,
  providerId,
  patientId,
  hideProvider = true,
  status,
}) => {
  const providerRender = appointmentRenderInfo[appointmentType];
  const ProviderRenderIcon = providerRender.icon;
  const { user } = useContext(AuthContext);
  const { locale } = useMyAppointmentsLocale();
  const navigate = useNavigate();
  const userRole = user?.role;

  const handleViewAppointmentDetails = () => {
    if (userRole === Roles.PROVIDER) {
      navigate(
        `.?${PARAM_MODALS_IDENTIFIERS.APPOINTMENT_DETAILS_MODAL_PROVIDER_ID}=true`,
        {
          state: { appointmentId },
        },
      );
    } else {
      navigate(
        `.?${PARAM_MODALS_IDENTIFIERS.VIEW_APPOINTMENT_DETAILS_MODAL_PATIENT}=true`,
        {
          state: { appointmentId },
        },
      );
    }
  };
  const handleCancelAppointment = () => {
    if (userRole === Roles.PROVIDER) {
      navigate(
        `.?${PARAM_MODALS_IDENTIFIERS.CANCEL_APPOINTMENT_MODAL_PROVIDER_ID}=true`,
        {
          state: { appointmentId },
        },
      );
    } else {
      navigate(`.?${PARAM_MODALS_IDENTIFIERS.CANCEL_APPOINTMENT_MODAL}=true`, {
        state: { appointmentId },
      });
    }
  };
  const handleJoinAppointment = () => {
    const base =
      appointmentType === AppointmentTypeEnum.Chat
        ? MY_APPOINTMENTS_CHAT_CONSULTATION
        : MY_APPOINTMENTS_VIDEO_CONSULTATION;
    navigate(`${base}&appointment-id=${appointmentId}`);
  };
  const handleEditAppointment = () => {
    navigate(MY_APPOINTMENTS_TERMS_OF_USE, { state: { appointmentId } });
  };

  const handleMessagingAppoinment = () => {
    // we can add the email if desired: 'mailto:email@example.com?subject=Subject&body=Body%20goes%20here'
    window.open('mailto:subject=Appointment');
  };

  const handleProviderClick = () => {
    if (user?.role === Roles.PROVIDER) {
      const path = `${MY_PATIENTS}/${patientId}/${MY_PATIENTS_BIO_INFO}`;
      navigate(path);
      return;
    }
    navigate(`${PROVIDERS_PROVIDER_BIO}`, {
      state: { providerId },
    });
  };

  const isJoinable = useIsJoinable({ startDate: date, status });

  const canEdit =
    user?.role === Roles.PATIENT && status === FHIR_APPOINTMENT_STATUS.BOOKED;

  return (
    <div className="flex desktop:hidden flex-col gap-5 w-full">
      <hr className="flex flex-row w-full items-center h-px bg-black-blur" />
      <div className="flex flex-col">
        <p className="font-bold text-charcoal-gray text-h6">
          {locale.dateAndTime}
        </p>
        <p className="text-sm font-semibold text-med-gray ">
          {formatDateToCustomFormat(date)}
          {status === FHIR_APPOINTMENT_STATUS.PROPOSED && (
            <>&nbsp;({locale.pedingStatus})</>
          )}
        </p>
      </div>

      {!hideProvider && (
        <div className="flex flex-col">
          <p className="font-bold text-charcoal-gray text-h6">
            {locale.provider}
          </p>
          <p
            className="text-sm font-semibold underline cursor-pointer"
            onClick={handleProviderClick}
          >
            {provider}
          </p>
        </div>
      )}

      <div className="flex flex-col">
        <p className="font-bold text-charcoal-gray text-h6">{locale.type}</p>
        <p className="text-med-gray text-base font-normal leading-snug">
          <ProviderRenderIcon className="inline" />
          <span className="ml-[5px]"> {locale[providerRender.text]}</span>
        </p>
      </div>
      {(appointmentType === AppointmentTypeEnum.Video ||
        appointmentType === AppointmentTypeEnum.Chat) &&
        isJoinable && (
          <div>
            <ButtonComponent
              className="max-h-[45px]"
              onClick={handleJoinAppointment}
            >
              {locale.joinNow}
            </ButtonComponent>
          </div>
        )}
      <div className="grid grid-cols-3 items-center pb-3 justify-items-center">
        <PwEyeShowIcon
          className="cursor-pointer"
          onClick={handleViewAppointmentDetails}
        />
        {canEdit && (
          <EditBlueIcon
            className="cursor-pointer"
            onClick={handleEditAppointment}
          />
        )}
        <CalendarIcon
          className="cursor-pointer fill-clc-blue col-start-3"
          onClick={handleCancelAppointment}
        />
        {appointmentType === AppointmentTypeEnum.Email && (
          <GoToMessageAppointmentIcon
            className="cursor-pointer"
            onClick={handleMessagingAppoinment}
          />
        )}
      </div>
    </div>
  );
};
