import { formatDateToCustomFormat } from 'utilities/functions';
import { SharedMedia } from '../interfaces/interfaces';
import SharedMediaActions from './SharedMediaActions';
import useLoadModalAppointmentInfo from 'app/my-account/pages/my-media-library/hooks/useLoadModalAppointmentInfo';
import { appointmentRenderInfo } from 'app/my-appointments/interfaces';
import { useGoToAppointmentDetails } from 'hooks/useGoToAppointmentDetails';

export interface SharedMediaRecentResultProps {
  media: SharedMedia;
  locale: Record<string, string>;
}

export const SharedMediaRecentResult: React.FC<
  SharedMediaRecentResultProps
> = ({ locale, media }) => {
  const { loading, appointmentTypeByState, providerNameByState } =
    useLoadModalAppointmentInfo(media.appointmentId);
  const goToAppointmentDetails = useGoToAppointmentDetails();

  const handleAppointmentDetails = () => {
    media.appointmentId && goToAppointmentDetails(media.appointmentId);
  };
  const AppointmentIcon =
    appointmentTypeByState !== undefined &&
    appointmentRenderInfo[appointmentTypeByState]?.icon;
  return (
    <div
      key={media.id}
      className="flex flex-col desktop:flex-row desktop:gap-4"
    >
      <div className="flex items-center justify-center desktop:block">
        <div className="overflow-hidden rounded-10 w-36 h-36 bg-cover bg-center">
          <img
            src={media.img}
            className="object-cover w-full h-full"
            alt={locale.sharedMediaAltText}
          />
        </div>
      </div>
      <div className="flex grow flex-col gap-1.5 w-fit px-7 py-5 desktop:px-0 desktop:py-0 justify-center items-center desktop:justify-start desktop:items-stretch">
        {loading ? null : (
          <>
            <div className="text-zinc-800 text-base font-semibold w-fit font-exo">
              {locale?.inAppointmentShare}
            </div>
            <div className="text-neutral-500 text-sm font-normal leading-snug">
              {formatDateToCustomFormat(media.date.toISOString())}
            </div>
            <div className="justify-start items-center gap-2.5 inline-flex">
              {AppointmentIcon && (
                <div className="justify-center items-center w-6 h-6 flex">
                  <AppointmentIcon />
                </div>
              )}
              <div className="grow shrink basis-0 text-zinc-800 text-sm font-bold leading-snug">
                {providerNameByState}
              </div>
            </div>
            {media.appointmentId && (
              <ul className="px-2.5 list-inside w-fit list-disc">
                <li
                  className="text-blue-700 w-fit text-sm font-bold underline leading-snug cursor-pointer"
                  onClick={handleAppointmentDetails}
                >
                  {locale.appointmentDetails}
                </li>
              </ul>
            )}
            <div className="flex flex-row grow justify-around items-end" />
            <div className="flex flex-row justify-around desktop:justify-start items-center">
              <SharedMediaActions media={media} hidePrint />
            </div>
          </>
        )}
      </div>
    </div>
  );
};
