import { MultiFactorResolver } from 'firebase/auth';
import { Roles, SignInProvider } from 'firebase/interfaces';
import { createContext } from 'react';
import { useContext } from 'react';

export interface UserData {
  email: string;
  displayName?: string;
  uuid?: string;
  phoneNumber?: string;
  mfaPhoneNumber?: string;
  country?: string;
  signInProvider: SignInProvider;
  profilePicture?: string;
  role?: Roles;
  providerEnabled?: boolean;
}

export interface AuthContextType {
  isLoggedIn: boolean;
  user: UserData | null;
  mfaResolver: MultiFactorResolver | null;
  isAuthResolved: boolean;
  profilePicture: string | null;
  setUserUuid: (uuid: string) => void;
  setPhoneNumber: (phoneNumber: string) => void;
  setMFAPhoneNumber: (PhoneNumber: string) => void;
  setCountry: (country: string) => void;
  setMfaResolver: (resolver: MultiFactorResolver | null) => void;
  setProfilePicture: (resolver: string | null) => void;
  setRole: (role: Roles) => void;
  login: (redirect?: string) => void;
  logout: () => void;
}

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);

  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider');
  }

  return context;
};

export const AuthContext = createContext<AuthContextType>({
  isLoggedIn: false,
  user: null,
  isAuthResolved: false,
} as AuthContextType);
